import React, { useContext } from "react";
import Helper from "./Helper";
import { HelpContext } from "../../context";
import { PostContext } from "../../context";
import { Link, useNavigate } from "react-router-dom";
import Api from "../Api/Api";

function Pub(props) {
  const history = useNavigate();
  const post = props.post;
  const TimeAgo = (dateString) => {
    const currentDate = new Date();
    const pastDate = new Date(dateString);

    const timeDifference = currentDate.getTime() - pastDate.getTime();

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return years === 1 ? "1 year ago" : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? "1 month ago" : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1 min ago" : `${minutes} min ago`;
    } else {
      return seconds === 1 ? "Now" : "Now";
    }
  };
  const user_id = localStorage.getItem("userid");
  const AddLike = async (post_id) => {
    if (localStorage.getItem("userid")) {
      try {
        const response = await Api.post("/api/likes", { post_id, user_id });
        console.log(response.data);
        // Handle success response
      } catch (error) {
        console.error(error);
        // Handle error response
      }
    }
  };

  const AddPreference = async (post_id) => {
    if (localStorage.getItem("userid")) {
      try {
        const response = await Api.post("/api/posts/view", {
          post_id,
          user_id,
        });
        console.log(response.data);

        if (response.data) {
          history(`/pub/${post_id}`);
        }
        // Handle success response
      } catch (error) {
        console.error(error);
        // Handle error response
      }
    }
  };

  const isUserLiked = (Post_likes, user_id) => {
    Post_likes.map((like) => {
      if (like.user_id == user_id) {
        return true;
      }
    });

    return false;
  };

  const help = useContext(HelpContext);
  const pub = useContext(PostContext);
  return (
    <>
      <div className="items min-h-48 flex items-center justify-center">
        <custom-card3>
          <div className="bg-gray-100 text-gray-900 border border-gray-300 rounded-lg shadow-md ">
            <div className="flex items-center justify-between p-3">
              <div className="flex items-center space-x-2">
                <img
                  src={`http://backend.novadiscova.server.cisabu.bi/uploads/Posts/${post.file}`}
                  alt=""
                  className="bg-coolGray-500 border-coolGray-700 h-8 w-8 rounded-full object-cover object-center shadow-sm"
                />
                <div className="-space-y-1">
                  <h2 className="text-sm font-semibold leading-none">
                    {post.User.username}
                  </h2>
                  <span className="text-coolGray-400 inline-block text-xs leading-none">
                    {post.User.job}
                  </span>
                </div>
              </div>
              <button title="Open options" type="button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="h-5 w-5 fill-current"
                >
                  <path d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"></path>
                  <path d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"></path>
                  <path d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"></path>
                </svg>
              </button>
            </div>

            <div className="flex flex-col w-full items-start justify-start px-7 text-sm">
              <p className="px-3 text-base text-gray-700">
                {post.description}
                {/* {post.description.length >= 20
                  ? post.description.substring(0, 20) + "...."
                  : post.description} */}
              </p>
              <div class="w-full flex flex-row justify-end items-end">
                <div className="w-auto rounded-t-lg  bg-sky-500 px-3 text-white">
                  {" "}
                  {TimeAgo(post.createdAt)}
                </div>
              </div>
            </div>
            <div className="">
              <button onClick={() => AddPreference(post.id)}>
                <div className="relative  w-full  overflow-hidden">
                  <div className="h-32 md:h-[291px] ">
                    <img
                      src={`http://backend.novadiscova.server.cisabu.bi/uploads/Posts/${post.file}`}
                      alt="product 1"
                      className="object-cover border border-sky-300 md:min-w-[289px] h-full w-full rounded-2xl"
                    />
                  </div>
                </div>
              </button>
            </div>
            <div className="p-3">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <button
                    type="button"
                    title="Like post"
                    onClick={() => AddLike(post.id)}
                    className="flex items-center justify-center"
                  >
                    {isUserLiked(post.Post_likes, user_id) ? (
                      <>
                        <span class="">
                          <svg
                            aria-label="like"
                            class=""
                            color="rgb(255, 48, 64)"
                            fill="rgb(255, 48, 64)"
                            height="24"
                            role="img"
                            viewBox="0 0 48 48"
                            width="24"
                          >
                            <title>like</title>
                            <path d="M34.6 3.1c-4.5 0-7.9 1.8-10.6 5.6-2.7-3.7-6.1-5.5-10.6-5.5C6 3.1 0 9.6 0 17.6c0 7.3 5.4 12 10.6 16.5.6.5 1.3 1.1 1.9 1.7l2.3 2c4.4 3.9 6.6 5.9 7.6 6.5.5.3 1.1.5 1.6.5s1.1-.2 1.6-.5c1-.6 2.8-2.2 7.8-6.8l2-1.8c.7-.6 1.3-1.2 2-1.7C42.7 29.6 48 25 48 17.6c0-8-6-14.5-13.4-14.5z"></path>
                          </svg>
                        </span>
                      </>
                    ) : (
                      <>
                        {" "}
                        <span class="">
                          <svg
                            aria-label="Unlike"
                            class=""
                            color="rgb(255, 48, 64)"
                            fill=" rgb(140 140 140)"
                            height="24"
                            role="img"
                            viewBox="0 0 48 48"
                            width="24"
                          >
                            <title>Unlike</title>
                            <path d="M34.6 3.1c-4.5 0-7.9 1.8-10.6 5.6-2.7-3.7-6.1-5.5-10.6-5.5C6 3.1 0 9.6 0 17.6c0 7.3 5.4 12 10.6 16.5.6.5 1.3 1.1 1.9 1.7l2.3 2c4.4 3.9 6.6 5.9 7.6 6.5.5.3 1.1.5 1.6.5s1.1-.2 1.6-.5c1-.6 2.8-2.2 7.8-6.8l2-1.8c.7-.6 1.3-1.2 2-1.7C42.7 29.6 48 25 48 17.6c0-8-6-14.5-13.4-14.5z"></path>
                          </svg>
                        </span>
                      </>
                    )}
                    <span className="px-2">
                      <span>
                        {!post.Post_likes
                          ? "0"
                          : post.Post_likes.length > 1000
                          ? `${(post.Post_likes.length / 1000).toFixed(1)}K`
                          : post.Post_likes.length}
                      </span>
                      <span> likes </span>
                    </span>
                  </button>
                  <button
                    type="button"
                    title="Add a comment"
                    className="flex items-center justify-center"
                  >
                    <svg viewBox="0 0 24 24" aria-hidden="true" class="w-5 h-5">
                      <g>
                        <path d="M8.75 21V3h2v18h-2zM18 21V8.5h2V21h-2zM4 21l.004-10h2L6 21H4zm9.248 0v-7h2v7h-2z"></path>
                      </g>
                    </svg>
                    <span className="px-2">
                      <span>
                        {!post.Post_likes
                          ? "0"
                          : post.Post_likes.length > 1000
                          ? `${(post.Post_likes.length / 1000).toFixed(1)}K`
                          : post.Post_likes.length}
                      </span>
                      <span> spreads </span>
                    </span>
                  </button>
                </div>
                <button
                  type="button"
                  title="Bookmark post"
                  className="flex items-center justify-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill=" rgb(140 140 140)"
                    className="h-7 w-5 fill-current"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex flex-wrap items-center pt-3 pb-1">
                <div className="flex items-center space-x-2">
                  <div className="flex -space-x-1">
                    {help.helps?.map((help) => {
                      return <Helper key={help.id} help={help} />;
                    })}
                  </div>
                  <span className="text-sm">
                    Helped by{" "}
                    <span className="font-semibold"> {post.helper} </span> and
                    <span className="font-semibold">
                      {" "}
                      {post.helperNumb} others
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </custom-card3>
      </div>
    </>
  );
}
export default Pub;
