import React from 'react'

import HomePageComponent from '../components/Home/HomePageComponent'
function Home() {
  return (
    <>
      <HomePageComponent />
    </>
  )
}
export default Home