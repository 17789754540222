import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Api from "../Api/Api";
import LoadingPage from "../LoadingPage.js/LoadingPage";

const validationSchema = Yup.object().shape({
  category: Yup.string().required("Please Select Category"),
});

const initialValues = {
  category: "",
};

function Personal_Info({ formData, setFormData, setPage }) {
  const [category, setcategory] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const getAllCategory = () => {
    Api.get("/api/category/getall").then((response) => {
      {
        setcategory(response.data);
      }
    });
    //console.log(options);
  };
  useEffect(() => {
    getAllCategory();
  }, []);

  const onSubmit = async (values, { setSubmitting }) => {
    setFormData({
      ...formData,
      category_id: values.category,
    });
    setPage((currPage) => currPage + 1);
    setSubmitting(false);
  };

  const handleSearchQueryChange = (event) => {
    const querytext = event.target.value;
    setSearchQuery(querytext);

    const filteredResults = category.filter((item) =>
      item.title.toLowerCase().includes(querytext.toLowerCase())
    );
    setSearchResults(filteredResults);
  };



  return (
    <>
      <div className="flex w-full flex-row items-center justify-center">
        <div className="p-2 rounded-full border border-sky-500 text-sky-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.2"
            stroke="currentColor"
            class="w-7 h-7"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
            />
          </svg>
        </div>
      </div>
      <div className="personal_info flex flex-col">
        <div className="w-full flex items-center justify-center">
          <p className="mb-6 max-w-2xl font-medium text-sky-500 dark:text-gray-400 md:text-lg lg:mb-8 lg:text-xl">
            What Goods do you have ?
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <div className="z-10 border overflow-hidden border-sky-100 bg-white rounded-lg shadow w-full dark:bg-gray-700">
                <div className="p-3">
                  <label for="input-group-search" className="sr-only">
                    Search
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="input-group-search"
                      className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Search Category"
                      value={searchQuery}
                      onChange={handleSearchQueryChange}
                    />
                    <div
                      onClick={() => {
                        setFieldValue("category", null);
                        setSearchQuery("");
                        setSearchResults(null);
                      }}
                      className="absolute inset-y-0 right-0 flex items-center px-3  cursor-pointer hover:bg-sky-100 rounded-lg "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6= h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="-mx-3 -my-2">
                  <ul
                    className="h-48 px-3 py-2 pb-1 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownSearchButton"
                  >
                    {searchResults
                      ? searchResults?.map((element, index) => {
                          return (
                            <li key={index}>
                              <div className="flex p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600">
                                <div className="flex items-center h-5">
                                  <input
                                    id="helper-radio-4"
                                    name="helper-radio"
                                    type="radio"
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                    onChange={() => {
                                      setFieldValue("category", element.id);
                                    }}
                                  />
                                </div>
                                <div className="ml-2 text-sm">
                                  <label
                                    for="helper-radio-4"
                                    className="font-medium text-gray-900 dark:text-gray-300"
                                  >
                                    <div>{element.title}</div>
                                    <p
                                      id="helper-radio-text-4"
                                      className="text-xs font-normal text-gray-500 dark:text-gray-300"
                                    >
                                      {element.description.length > 80
                                        ? element.description.substring(0, 80)
                                        : element.description}
                                    </p>
                                  </label>
                                </div>
                              </div>
                            </li>
                          );
                        })
                      : category?.map((element, index) => {
                          return (
                            <li key={index}>
                              <div className="flex p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600">
                                <div className="flex items-center h-5">
                                  <input
                                    id="helper-radio-4"
                                    name="helper-radio"
                                    type="radio"
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                    onChange={() => {
                                      setFieldValue("category", element.id);
                                    }}
                                  />
                                </div>
                                <div className="ml-2 text-sm">
                                  <label
                                    for="helper-radio-4"
                                    className="font-medium text-gray-900 dark:text-gray-300"
                                  >
                                    <div>{element.title}</div>
                                    <p
                                      id="helper-radio-text-4"
                                      className="text-xs font-normal text-gray-500 dark:text-gray-300"
                                    >
                                      {element.description.length > 80
                                        ? element.description.substring(0, 80)
                                        : element.description}
                                    </p>
                                  </label>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                  </ul>
                </div>
                <a
                  href="#"
                  className="flex items-center p-2 text-sm font-medium text-red-600  rounded-b-lg bg-gray-50 dark:border-gray-600 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-red-500 hover:underline"
                ></a>
              </div>
              <div class="flex items-center justify-center mt-2">
                <ErrorMessage
                  name="category"
                  component="div"
                  className="text-left mt-2 text-red-500 text-sm"
                />
              </div>

              <div className="mb-5 mt-5 flex w-full items-center justify-center">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-1/2 rounded-xl border-0 bg-sky-500 px-6 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none"
                >
                  {isSubmitting ? "Continue..." : "Continue"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default Personal_Info;
