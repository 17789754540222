import React from "react";

import CompanieBuyComponent from "../components/Buy/CompanieBuyComponent";
function CompaniesBuy() {
  return (
    <>
      <CompanieBuyComponent />
    </>
  );
}
export default CompaniesBuy;
