import React from 'react'
import BrowserHaveComponent from '../components/BrowserHaves/BrowserHaveComponent'

function BrowserHave() {
  return (
    <>
        <BrowserHaveComponent/>
    </>
  )
}
export default BrowserHave