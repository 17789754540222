import React, {useState} from 'react'
import Pubs from '../Trends/Pubs'

function Histories(props) {
  return (
    <>
    <div className="mb-4 flex h-auto w-full flex-col rounded-3xl ">
        <div className="  font-light dark:border-gray-700 dark:bg-gray-900">
            <Pubs />
        </div>
    </div>
    </>
  )
}
export default Histories