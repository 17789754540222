import React, { useContext  } from "react";
import { StepContext } from "../../context";
function WeOffer() {
  const value = useContext(StepContext);
  return (
    <>
      <section className="z-0  my-8 px-3  dark:bg-gray-900">
        <div className="mx-auto flex flex-col lg:grid max-w-screen-xl px-4 pb-8 pt-10 lg:grid-cols-12 lg:gap-8 lg:py-16 lg:pt-10 xl:gap-0">
          <div className="z-0   mr-auto lg:col-span-5 lg:mt-0 flex  ">
            <div className="">
              <img
                src="images/monetization(1).png"
                className="h-full w-full object-cover scale-130"
              />
            </div>
          </div>

          <div className="z-20  flex flex-col lg:order-last order-first mb-11 items-center justify-center place-self-center pt-8 lg:pt-12 lg:col-span-7">
            <h1 className="mb-4 max-w-2xl text-center text-4xl font-extrabold leading-none tracking-tight text-sky-500 dark:text-white md:text-5xl xl:text-6xl">
              Monetization Configuration
              <br />
            </h1>
            <p className="mb-6 max-w-2xl font-light text-gray-500 dark:text-gray-400 md:text-lg lg:mb-8 lg:text-xl">
              We offer monetization Configuration for all social media platforms{" "}
              <br />
              Our platform is designed to turn your social media presence
              <br /> into a sustainable income stream.
              <br />
              <i>
                Join our platform today and Start Monetize with 100% Assurance.
              </i>
            </p>
            <div className="space-y-4 sm:flex sm:space-x-4 sm:space-y-0">
              <a
                href="#"
                className="inline-flex w-full items-center justify-center rounded-lg border border-sky-500 hover:bg-sky-100 px-5 py-3 text-center text-sm font-medium text-sky-600 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-800 sm:w-auto"
              >
                Join Now
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default WeOffer;
