import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalFunctionContext } from "../../context";

function WhoHomePageComponent() {
  const Global = useContext(GlobalFunctionContext);
  const [userlocation, setuserLocation] = useState(null);
  useEffect(() => {
    const getLocationFromLocalStorage = () => {
      const locationString = localStorage.getItem("location");
      if (locationString) {
        const locationplace = JSON.parse(locationString);
        setuserLocation(locationplace);
      }
    };
    getLocationFromLocalStorage();
  }, [Global]);
  return (
    <>
      <div className="All_Post mb-3">
        <div className="mb-4 flex h-auto w-full flex-col rounded-3xl ">
          <div className="  font-light dark:border-gray-700 dark:bg-gray-900">
            <div className="z-0   mr-auto lg:col-span-5 lg:mt-0 flex flex-col  ">
              <div class="mt-16 ">
                <div>
                  <div class="border-t border-gray-200 font-light dark:border-gray-700 dark:bg-gray-900">
                    <div class="mb-6 inline-flex w-full items-center justify-center">
                      <div class="absolute flex h-10 auto  items-center justify-center rounded-xl border-2 border-gray-300 bg-gray-100 text-sky-500 px-3 py-2 shadow">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 mr-2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zm-7.518-.267A8.25 8.25 0 1120.25 10.5M8.288 14.212A5.25 5.25 0 1117.25 10.5"
                          />
                        </svg>

                        <span class="font-bold w-auto rounded-r-xl text-xl text-sky">
                          {" "}
                          Products /Services Orders
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-8 grid grid-cols-2 gap-10  text-gray-500 dark:text-gray-400 lg:grid-cols-2">
                <div className="item relative  shadow-md flex    break-words bg-sky-50  shadow-soft-xl rounded-2xl bg-clip-border">
                  <div className="flex items-center w-full justify-center p-4">
                    <div className="flex flex-col justify-between -mx-3">
                      <div className="flex-none  text-center max-w-full px-3">
                        {userlocation ? (
                          <Link to="/who/createhave">
                            <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">
                              Create Have
                            </p>
                            <p className="text-xs mt-2">
                              Sell Product or Services directly Any time Any
                              Where.
                            </p>
                          </Link>
                        ) : (
                          <button
                            onClick={() => {
                              Global.setLocationPop(true);
                            }}
                          >
                            <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">
                              Create Have
                            </p>
                            <p className="text-xs mt-2">
                              Sell Product or Services Any time Any Where.
                            </p>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item relative  shadow-md flex    break-words bg-sky-50  shadow-soft-xl rounded-2xl bg-clip-border">
                  <div className="flex items-center w-full justify-center p-4">
                    <div className="flex flex-col justify-between -mx-3">
                      <div className="flex-none  text-center max-w-full px-3">
                        {userlocation ? (
                          <Link to="/who/createneed">
                            <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">
                              Create Need
                            </p>
                            <p className="text-xs mt-2">
                              Create a Need Of Whatever Products/Services.
                            </p>
                          </Link>
                        ) : (
                          <button
                            onClick={() => {
                              Global.setLocationPop(true);
                            }}
                          >
                            <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">
                              Create Need
                            </p>
                            <p className="text-xs mt-2">
                              Create a Need Of Whatever Products/Services.
                            </p>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item relative  shadow-md flex    break-words bg-sky-50  shadow-soft-xl rounded-2xl bg-clip-border">
                  <div className="flex items-center w-full justify-center p-4">
                    <div className="flex flex-col justify-between -mx-3">
                      <div className="flex-none  text-center max-w-full px-3">
                        {userlocation ? (
                          <Link to="/who/browserhave">
                            <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">
                              Browser Haves
                            </p>
                            <p className="text-xs mt-2">
                              Browser All Available Product/Services of Specific
                              Categories In Your Community
                            </p>
                          </Link>
                        ) : (
                          <button
                            onClick={() => {
                              Global.setLocationPop(true);
                            }}
                          >
                            <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">
                              Browser Haves
                            </p>
                            <p className="text-xs mt-2">
                              Browser All Available Product/Services of Specific
                              Categories In Your Community
                            </p>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item relative  shadow-md flex    break-words bg-sky-50  shadow-soft-xl rounded-2xl bg-clip-border">
                  <div className="flex items-center w-full justify-center p-4">
                    <div className="flex flex-col justify-between -mx-3">
                      <div className="flex-none  text-center max-w-full px-3">
                        {userlocation ? (
                          <Link to="/who/browserneed">
                            <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">
                              Browser Needs
                            </p>
                            <p className="text-xs mt-2">
                              Browser All Needs and Wants of Specific Categories
                              In Your Community
                            </p>
                          </Link>
                        ) : (
                          <button
                            onClick={() => {
                              Global.setLocationPop(true);
                            }}
                          >
                            <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">
                              Browser Needs
                            </p>
                            <p className="text-xs mt-2">
                              Browser All Needs and Wants of Specific Categories
                              In Your Community
                            </p>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-16">
                <div>
                  <div class="border-t border-gray-200 font-light dark:border-gray-700 dark:bg-gray-900">
                    <div class="mb-6 inline-flex w-full items-center justify-center">
                      <div class="absolute flex h-10 auto  items-center justify-center rounded-xl border-2 border-gray-300 bg-gray-100 text-sky-500 px-3 py-2 shadow">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 mr-2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                          />
                        </svg>

                        <span class="font-bold w-auto rounded-r-xl text-xl text-sky">
                          {" "}
                          Jobs /Opportunities ..
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-8 grid grid-cols-2 gap-10  text-gray-500 dark:text-gray-400 lg:grid-cols-2">
                <div className="item relative  shadow-md flex    break-words bg-sky-50  shadow-soft-xl rounded-2xl bg-clip-border">
                  <div className="flex items-center w-full justify-center p-4">
                    <div className="flex flex-col justify-between -mx-3">
                      <div className="flex-none  text-center max-w-full px-3">
                        {userlocation ? (
                          <Link to="/who/createhave">
                            <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">
                              Post a Job
                            </p>
                            <p className="text-xs mt-2">
                              Post a Job for Worker to Apply on it. Ereka bose
                              Have Many Workers To solve your matter
                            </p>
                          </Link>
                        ) : (
                          <button
                            onClick={() => {
                              Global.setLocationPop(true);
                            }}
                          >
                            <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">
                              Post a Job
                            </p>
                            <p className="text-xs mt-2">
                              Post a Job for Worker to Apply on it. Ereka bose
                              Have Many Excellents Workers To solve your
                              problems/Jobs
                            </p>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item relative  shadow-md flex    break-words bg-sky-50  shadow-soft-xl rounded-2xl bg-clip-border">
                  <div className="flex items-center w-full justify-center p-4">
                    <div className="flex flex-col justify-between -mx-3">
                      <div className="flex-none  text-center max-w-full px-3">
                        {userlocation ? (
                          <Link to="/who/createneed">
                            <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">
                              Post Your CV online
                            </p>
                            <p className="text-xs mt-2">
                              Post Your CV online to show employers what you're
                              capable of and get a work ..
                            </p>
                          </Link>
                        ) : (
                          <button
                            onClick={() => {
                              Global.setLocationPop(true);
                            }}
                          >
                            <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">
                              Post Your CV online
                            </p>
                            <p className="text-xs mt-2">
                              Post Your CV online to show employers what you're
                              capable of and get a work ..
                            </p>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item relative  shadow-md flex    break-words bg-sky-50  shadow-soft-xl rounded-2xl bg-clip-border">
                  <div className="flex items-center w-full justify-center p-4">
                    <div className="flex flex-col justify-between -mx-3">
                      <div className="flex-none  text-center max-w-full px-3">
                        {userlocation ? (
                          <Link to="/who/browserhave">
                            <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">
                              Browsers Jobs
                            </p>
                            <p className="text-xs mt-2">
                              See All Available Jobs for You ..And Get it easely
                              without wasting time
                            </p>
                          </Link>
                        ) : (
                          <button
                            onClick={() => {
                              Global.setLocationPop(true);
                            }}
                          >
                            <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">
                              {" "}
                              Browsers Jobs
                            </p>
                            <p className="text-xs mt-2">
                              {" "}
                              See All Available Jobs for You ..And Get it easely
                              without wasting time
                            </p>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item relative  shadow-md flex    break-words bg-sky-50  shadow-soft-xl rounded-2xl bg-clip-border">
                  <div className="flex items-center w-full justify-center p-4">
                    <div className="flex flex-col justify-between -mx-3">
                      <div className="flex-none  text-center max-w-full px-3">
                        {userlocation ? (
                          <Link to="/who/browserneed">
                            <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">
                              Browser Workers CV
                            </p>
                            <p className="text-xs mt-2">
                              Get the worker which Suit you and your company ..
                              Talented Workers Will Boost your business
                            </p>
                          </Link>
                        ) : (
                          <button
                            onClick={() => {
                              Global.setLocationPop(true);
                            }}
                          >
                            <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">
                              Browser Workers CV
                            </p>
                            <p className="text-xs mt-2">
                              Get the worker which Suit you and your company ..
                              Talented Workers Will Boost your business
                            </p>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="item relative  shadow-md flex    break-words bg-sky-50  shadow-soft-xl rounded-2xl bg-clip-border">
                  <div className="flex items-center w-full justify-center p-4">
                    <div className="flex flex-col justify-between -mx-3">
                      <div className="flex-none  text-center max-w-full px-3">
                        {userlocation ? (
                          <Link to="/who/browserneed">
                            <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">
                              Browser Bourses Opportunities
                            </p>
                            <p className="text-xs mt-2">
                              See all National/International Bourses Available
                              for You .. Expand Your Knowledge...
                            </p>
                          </Link>
                        ) : (
                          <button
                            onClick={() => {
                              Global.setLocationPop(true);
                            }}
                          >
                            <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">
                              Browser Bourses Opportunities
                            </p>
                            <p className="text-xs mt-2">
                              See all National/International Bourses Available
                              for You .. Expand Your Knowledge...
                            </p>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="z-20  flex flex-col  mb-11 items-center justify-center place-self-center pt-8 lg:pt-12 lg:col-span-7">
            <h1 className="mb-4 max-w-5xl text-2xl text-center font-extrabold leading-none tracking-tight text-sky-500 dark:text-white md:text-4xl xl:text-5sssxl">
              Ereka Bose for EveryLife <br /> Any Time , Any Where
            </h1>
            <p className="mb-6 max-w-2xl font-light text-gray-500 dark:text-gray-400 md:text-lg lg:mb-8 lg:text-xl">
              Use This Feature to get Sell What You Have <br /> and Order
              Products or Services
              <br />
              in Short Moment and at Best Prices.
              <br />
              <i>
                Get Informed For new orders <br></br>From Customers Via Whatsapp{" "}
                <br></br>Any Time/Any Where
              </i>
            </p>
            <div className="space-y-4 sm:flex sm:space-x-4 sm:space-y-0"></div>
          </div>

          <div class="flex flex-row items-center mt-2">
            <div class="flex flex-row items-center">
              <button class="flex h-7 w-7 items-center justify-center rounded-full bg-gray-200 text-gray-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-56 w-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                  ></path>
                </svg>
              </button>
              <div class="ml-1 text-sm font-semibold text-gray-700">
                Sponsored Ads
              </div>
            </div>
            <div class="ml-auto">
              <button class="flex h-7 w-7 items-center justify-center rounded-full bg-gray-200 text-gray-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-4 w-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div
            class="custom-slant w-full mb-5 p-2"
            style={{ height: "182px;" }}
          >
            <div class="hide-with-banner relative">
              <a
                href="https://material-tailwind.com?ref=tailwindcomponents"
                target="_blank"
                class="flex h-full w-full flex-row py-5"
              >
                <div class="z-10 w-1/2 flex-col items-start justify-center px-6 xl:px-8">
                  <div class="flex flex-row space-x-2 ">
                    <img
                      src="images/Logo.png"
                      class="mb-4 w-12 h-12 object-contain bg-white rounded-xl"
                    />
                    <p class="text-xl font-bold text-white mt-2">Ereka Bose</p>
                  </div>
                  <p class="text-xs text-white mt-3 ">Marketing Company</p>
                  <p class="mb-5 font-sans text-2xl font-bold text-white">
                    VA HASI DUKORE VYOSE BIRASHOBOKA?
                  </p>
                  <div class="mb-3">
                    <span class="h-8 rounded-lg bg-sky-500 px-4 py-3 font-sans text-xs font-bold text-white shadow-md">
                      Get Started
                    </span>
                  </div>
                </div>
                <div class="z-10 w-1/2 flex-col items-start justify-center px-6 xl:px-8">
                  <img
                    src="http://backend.novadiscova.server.cisabu.bi/uploads/Posts/4cb88535-14ab-492e-8b37-c8a7cb97e191.jpg"
                    class="h-64 w-full object-cover rounded-2xl border "
                  />
                </div>
                <div class="absolute  bg-gradient-to-br   from-sky-500 to-white border-sky-500 border bottom-0 left-0 h-full w-full overflow-hidden rounded-2xl object-cover"></div>
                <img
                  src="images/Ads/background-layer.png"
                  class="absolute bottom-0 left-0  opacity-40 h-full w-full overflow-hidden rounded-2xl object-cover"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default WhoHomePageComponent;
