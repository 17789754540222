import React, {useContext} from 'react'
import Review from './Review'
import {ReviewContext } from '../../context'
function Reviews() {
    const value = useContext(ReviewContext);
  return (
    <>
    <section className="dark:bg-gray-800">     
  <div className="bg-gray-50 flex items-center justify-center ">
      <div className="w-full bg-white border-t border-b border-gray-200 px-5  py-4 text-gray-800">
          <div className="w-full max-w-6xl mx-auto">
              <div className="text-center max-w-xl mx-auto">
                <div className="inline-flex w-full items-center justify-center">
                    <hr className="my-8 h-px w-full rounded border-0 bg-gray-200 dark:bg-gray-700"/>
                    <div className="absolute left-1/2 -translate-x-1/2 bg-gray-200 rounded-xl px-4 py-2 dark:bg-gray-900">
                      <span className="text inline-block self-center whitespace-nowrap bg-gradient-to-r from-sky-500 to-sky-800 bg-clip-text text-2xl text-transparent font-light dark:text-white">Reviews About ErekaBose ?</span>
                    </div>
                  </div>
                  <div className="text-center mb-10">
                      <span className="inline-block w-1 h-1 rounded-full bg-sky-500 ml-1"></span>
                      <span className="inline-block w-3 h-1 rounded-full bg-sky-500 ml-1"></span>
                      <span className="inline-block w-40 h-1 rounded-full bg-sky-500"></span>
                      <span className="inline-block w-3 h-1 rounded-full bg-sky-500 ml-1"></span>
                      <span className="inline-block w-1 h-1 rounded-full bg-sky-500 ml-1"></span>
                  </div>
              </div>
              <div className="-mx-3 md:flex items-start">
              {
                value.reviews?.map(review=>{
                        return <Review key={review.id} review={review} />
                        })
                    }
              </div>
          </div>
      </div>
  </div>
  </section>
    </>
  )
}
export default Reviews
