export const SocialData = [
    {
      id: 1,
      name: "FaceBook",
      logo: "https://upload.wikimedia.org/wikipedia/commons/b/b8/2021_Facebook_icon.svg"
      },
    {
        id: 2,
        name: "WhatsApp",
        logo: "https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
    },
    {
        id: 3,
        name: "Twitter",
        logo: "https://www.svgrepo.com/show/475689/twitter-color.svg"
        },
    {
        id: 4,
        name: "TikTok",
        logo: "https://www.svgrepo.com/show/303156/tiktok-icon-white-1-logo.svg"
    }]
    ;