import React from "react";
import AllTrendComponent from "../components/Trends/AllTrendComponent";

function Trend() {
  return (
    <>
      <AllTrendComponent />
    </>
  );
}
export default Trend;
