import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PostContext } from "../../../context";
import { HelpContext } from "../../../context";
import Helper from "./Helper";
import ShareMedia from "./ShareMedia";
function PostInfos() {
  var post = useContext(PostContext);
  const pub = useContext(PostContext);
  const { id } = useParams();
  const help = useContext(HelpContext);
  post = post.detailProduct;
  const [showmore, SetShow] = useState(false);
  useEffect(() => {
    pub.handleDetail(id);
  }, []);
  const TimeAgo = (dateString) => {
    const currentDate = new Date();
    const pastDate = new Date(dateString);

    const timeDifference = currentDate.getTime() - pastDate.getTime();

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return years === 1 ? "1 year ago" : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? "1 month ago" : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1 min ago" : `${minutes} min ago`;
    } else {
      return seconds === 1 ? "Now" : "Now";
    }
  };
  return (
    <>
      {post ? (
        <div className="mb-4  rounded-3xl  ">
          <div className="items min-h-48 flex items-start justify-start w-full">
            <div className="flex flex-row w-full">
              <div className="bg-gray-50 text-gray-900 border border-gray-300 rounded-lg shadow-md w-full">
                <div className="flex items-center justify-between p-3">
                  <div className="flex items-center space-x-2">
                    <img
                      src={`http://backend.novadiscova.server.cisabu.bi/uploads/Posts/${post.file}`}
                      alt=""
                      className="border border-sky-300 h-16 w-16 rounded-full object-cover object-center shadow-sm"
                    />
                    <div className="-space-y-1">
                      <h2 className="text-lg text-sky-600 font-semibold leading-none">
                        {post.User.username}
                      </h2>
                      <span className="text-coolGray-400 inline-block text-sm leading-none">
                        {" "}
                        {post.User.job}{" "}
                      </span>
                    </div>
                  </div>

                  <div className="w-auto ml-10 bg-gray-200 rounded-lg flex flex-row space-x-2 ">
                    <span className=" font-semibold italic">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </span>
                    <span className="w-auto rounded-lg bg-sky-500 px-2 text-white">
                      {TimeAgo(post.createdAt)}
                    </span>
                  </div>

                  <button title="Open options" type="button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="h-5 w-5 fill-current"
                    >
                      <path d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"></path>
                      <path d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"></path>
                      <path d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"></path>
                    </svg>
                  </button>
                </div>

                <div className="flex w-full items-start justify-between flex-col px-3 text-sm">
                  <div className="description w-full   bg-gray-100 px-2 py-2 rounded-3xl text-gray-700 ">
                    <div
                      className={
                        showmore
                          ? "description_text  overflow-hidden "
                          : "description_text  overflow-hidden  h-10"
                      }
                    >
                      <p className="px-3 pt-1 text-lg ">{post.description} </p>
                    </div>
                    {post.description.length >= 20 ? (
                      <button
                        type="button"
                        onClick={() => SetShow(!showmore)}
                        className="text-gray-900 cursor-auto font-extralight  bg-gray-300 rounded-lg px-1 mt-2"
                      >
                        {!showmore ? "ShowMore" : "ShowLess"}
                      </button>
                    ) : null}
                  </div>
                </div>

                <div className="mt-5">
                  <img
                    src={`http://backend.novadiscova.server.cisabu.bi/uploads/Posts/${post.file}`}
                    alt=""
                    className="bg-coolGray-500   w-full rounded-lg border-2 object-cover object-center"
                  />
                </div>
                <div className="p-3">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-10">
                      <button
                        type="button"
                        title="Like post"
                        className="flex items-center justify-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className="h-5 w-5 fill-current"
                        >
                          <path d="M453.122,79.012a128,128,0,0,0-181.087.068l-15.511,15.7L241.142,79.114l-.1-.1a128,128,0,0,0-181.02,0l-6.91,6.91a128,128,0,0,0,0,181.019L235.485,449.314l20.595,21.578.491-.492.533.533L276.4,450.574,460.032,266.94a128.147,128.147,0,0,0,0-181.019ZM437.4,244.313,256.571,425.146,75.738,244.313a96,96,0,0,1,0-135.764l6.911-6.91a96,96,0,0,1,135.713-.051l38.093,38.787,38.274-38.736a96,96,0,0,1,135.765,0l6.91,6.909A96.11,96.11,0,0,1,437.4,244.313Z"></path>
                        </svg>
                        <span className="px-2">{post.liked}K</span>
                      </button>
                      <button
                        type="button"
                        title="Add a comment"
                        className="flex items-center justify-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="h-5 w-5 fill-current"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
                        </svg>
                        <span className="px-2">{post.listen}K</span>
                      </button>
                    </div>
                    <button
                      type="button"
                      title="Add To My Constribution List"
                      className="flex items-center justify-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="h-5 w-5 fill-current text-sky-600 border-b-2 rounded-lg border-sky-600 "
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.75 0a1 1 0 0 0-.8.4L.1 4.2a.5.5 0 0 0-.1.3V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4.5a.5.5 0 0 0-.1-.3L13.05.4a1 1 0 0 0-.8-.4h-8.5ZM8.5 4h6l.5.667V5H1v-.333L1.5 4h6V1h1v3ZM8 7.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z" />
                      </svg>
                    </button>
                  </div>
                  <div className="flex flex-wrap items-center pt-3 pb-1">
                    <div className="flex items-center space-x-2">
                      <div className="flex -space-x-1">
                        {help.helps?.map((help) => {
                          return <Helper key={help.id} help={help} />;
                        })}
                      </div>
                      <span className="text-base text-sky-600">
                        Helped by{" "}
                        <span className="font-semibold">{post.helper}</span> and
                        <span className="font-semibold">
                          {" "}
                          {post.helperNumb} others
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
export default PostInfos;
