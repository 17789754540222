import React, {useContext} from 'react'
import {SmsContext} from '../../context'
import ReceiveedSms from './ReceiveedSms'
import SentMessage from './SentMessage'

export default function ReadSms() {
  const msgs = useContext(SmsContext)
  return (
    <>
        <div className="h-full overflow-hidden py-4">
        <div className="h-full overflow-y-auto">
          <div className="grid grid-cols-8 gap-y-2">
            {msgs.msgs?.map(msg=>{
              if(msg.received) return <ReceiveedSms key={msg.id} msg = {msg} />
              else return <SentMessage key={msg.id} msg = {msg}  />
            })}
          </div>
        </div>
      </div>
    </>
  )
}
