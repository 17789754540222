export const NotifyDatas = [
    {
      id: 1,
      user: "Flo Steinle",
      photo: "https://source.unsplash.com/150x150/?portrait?6",
      time: 12,
      body: "Good after noon! how can i help you?",
      readed: false
        
    },
    {
      id: 2,
      user: "Flo Steinle",
      photo: "https://source.unsplash.com/150x150/?portrait?1",
      time: 3,
      body: "Good morning noon! how can i help you?",
      readed: false
    },
    {
        id: 3,
        user: "Flo Steinle",
        photo: "https://source.unsplash.com/150x150/?portrait?2",
        time: 4,
        body: "Good after noon! how can i help you?",
        readed: true
    },
    {
        id: 4,
        user: "Flo Steinle",
        photo: "https://source.unsplash.com/150x150/?portrait?3",
        time: 5,
        body: "Good after noon! how can i help you?",
        readed: true
    },
    {
      id: 5,
      user: "Flo Steinle",
      photo: "https://source.unsplash.com/150x150/?portrait?4",
      time: 9,
      body: "Good after noon! how can i help you?",
      readed: true
    },
    {
      id: 6,
      user: "Flo Steinle",
      photo: "https://source.unsplash.com/150x150/?portrait?5",
      time: 10,
      body: "Good after noon! how can i help you?",
      readed: true
    }
  ];
  