import React, { useContext } from "react";
import { PricingContext } from "../../context";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
function SocialBuyComponent() {
  const value = useContext(PricingContext);
  const { platform_id } = useParams();
  useEffect(() => {
    value.handleDetail(platform_id);
  }, []);

  return (
    <>
      {value.detailBuy?.map((order) => {
        return (
          <>
            <div className="item w-128  card text-grey-darkest flex h-auto rounded-xl border bg-white shadow-inner">
              <div className="flex w-full flex-col">
                <div className="flex-1 p-4 pb-0">
                  <div className="flex items-center justify-between p-3">
                    <div className="flex items-center space-x-2">
                      <button
                        className="rounded-full border-2 border-gray-200 p-1"
                        onClick={() => "hello"}
                      >
                        <img
                          className="h-12 w-12 rounded-full"
                          src={`../../../images/${order.file}`}
                          alt="logo"
                        />
                      </button>
                      <div className="flex items-center justify-center">
                        <h2 className="text-2xl font-semibold text-sky-500">
                          {order.platform}
                        </h2>
                      </div>
                    </div>

                    <div className="flex items-center justify-center">
                      <h2 className="flex flex-row items-center text-xl font-semibold text-gray-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="mr-2 h-7 w-7"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                          ></path>
                        </svg>
                        {order.platform_services.length} Services Availables
                      </h2>
                    </div>

                    <button
                      title="Open options"
                      type="button"
                      onClick={() => "hello"}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="h-5 w-5 fill-current"
                      >
                        <path d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"></path>
                        <path d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"></path>
                        <path d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"></path>
                      </svg>
                    </button>
                  </div>
                  <div className="rounded-md bg-gray-100 px-2 py-2">
                    <span className="rounded-r-xl text-lg text-gray-700">
                      {" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
              <button
                type="button"
                className="flex w-full items-center justify-between rounded-t-xl border border-b-0 border-gray-200 bg-sky-50 p-5 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800"
              >
                <span className="text-xl">List Of Services</span>
              </button>
              <div>
                <div className="border border-b-0 border-gray-200 font-light dark:border-gray-700 dark:bg-gray-900">
                  <div className="mb-6 inline-flex w-full items-center justify-center">
                    <div className="absolute flex h-8 w-32 items-center justify-center rounded-xl border-2 border-gray-300 bg-gray-500 px-3 py-2 shadow">
                      <span className="font-thing w-auto rounded-r-xl text-lg text-white">
                        {" "}
                        Shares{" "}
                      </span>
                    </div>
                  </div>

                  <div className="my-4 grid w-full grid-cols-1 gap-x-4 gap-y-6 px-6 ">
                    {order.platform_services?.map((service) => {
                      return (
                        <div className="item-row flex flex-row items-center justify-between text-sm border border-sky-200  tracking-wider  px-3 py-1  bg-sky-100 bg-opacity-50 text-sky-500 rounded-xl mb-4">
                          <div className="flex flex-row space-x-3 items-center justify-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              class="w-3 h-3 text-gray-600 hidden"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"
                              />
                            </svg>
                            <span class="text-lg text-gray-600 ">
                              {order.platform} {service.title}
                            </span>
                          </div>
                          <div className="flex flex-col ">
                            {" "}
                            <label
                              htmlFor="username"
                              className="mb-2 ml-1 block text-base text-gray-700 dark:text-white"
                            >
                              <span class="text-sm lg:text-lg text-gray-600 ">
                                {service.price}
                                <sub>fbu / 100</sub>
                              </span>
                            </label>
                            <label
                              htmlFor="username"
                              className="mb-2 ml-1 block  hidden text-base text-gray-700 dark:text-white"
                            >
                              <span class="text-sm lg:text-lg text-gray-600 ">
                                Min : {service.min}
                              </span>

                              <span class="text-sm lg:text-lg text-gray-600 ">
                                Max :{service.max}
                              </span>
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div className="Notification m-1  rounded-2xl mb-5 bg-gray-50 text-center shadow-sm  p-2">
                    <span className="text-lg text-center">
                      The Ordering Process is made while creating a post <br />{" "}
                      Create a new post
                    </span>
                    <div className="font-display mt-3 text-center text-sm font-semibold text-gray-500 flex items-center justify-center">
                      <Link
                        to="/createpost"
                        className="rounded-xl border-0 bg-sky-500 px-6 py-2 text-lg text-white hover:bg-sky-600"
                      >
                        Order Now
                      </Link>
                    </div>
                  </div>

                  <div className="my-6 inline-flex hidden w-full items-center justify-center">
                    <hr className="my-2 h-px w-full rounded border-0 bg-gray-200 dark:bg-gray-700" />
                    <div className="absolute flex h-8 w-32 items-center justify-center rounded-xl border-2 border-gray-300 bg-gray-500 px-3 py-2 shadow">
                      <span className="font-thing w-auto rounded-r-xl text-lg text-white">
                        Growth{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}

export default SocialBuyComponent;
