import React from "react";

import RadioBuyComponent from "../components/Buy/RadioBuyComponent";
function RadioBuy() {
  return (
    <>
      <RadioBuyComponent />
    </>
  );
}
export default RadioBuy;
