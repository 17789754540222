import React, { useState, useContext } from "react";
import UserAside from "../AsideBar/UserAside";
import Histories from "./Histories";
import { EventContext } from "../../context";
import Event from "../Companies/Event";
export default function MyHistory() {
  const evts = useContext(EventContext);
  const [typeHst, setTypeHst] = useState(false);
  const [dropHst, setDropHst] = useState(false);
  const handleGetType = (type) => {
    if (type) return setTypeHst(true);
    else return setTypeHst(false);
  };
  const handleDropHst = () => {
    if (dropHst) setDropHst(false);
    else setDropHst(true);
  };
  return (
    <>
      <div className="History">
        <div className="flex w-full items-center justify-between rounded-t-xl p-1 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800">
          <span className="inline-block w-32">History</span>
          <hr className="h-px ml-4 w-full my-4 bg-gray-200 border-0 dark:bg-gray-700" />
          <div className=" w-48 mr-2 mb-2 text-sm font-medium text-gray-900 ">
            <button
              onClick={() => handleDropHst()}
              id="dropdownHoverButton"
              data-dropdown-toggle="dropdownHover"
              data-dropdown-trigger="hover"
              className="text-gray-700 bg-gray-200 hover:bg-gray-300  focus:outline-none font-medium rounded-lg text-xs px-2 py-1 text-center inline-flex items-center "
              type="button"
            >
              Type
              <svg
                className="w-4 h-4 ml-2"
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>
            {dropHst ? (
              <div
                id="dropdownHover"
                className="z-10 absolute bg-white shadow-md divide-y divide-gray-100 rounded-lg  w-24 dark:bg-gray-700"
              >
                <ul
                  className="py-2 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownHoverButton"
                >
                  <li>
                    <div
                      onClick={() => handleGetType(false)}
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Post
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => handleGetType(true)}
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Event
                    </div>
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
        </div>
        {typeHst ? (
          <section className="bg-white dark:bg-gray-900">
            <div className="mb-4 flex h-auto w-full flex-col rounded-3xl ">
              <div className=" w-full">
                <ul className="colums-2  grid   grid-cols-1 gap-4 w-full  divide-y divide-gray-200 px-2 dark:divide-gray-700">
                  {evts.evts?.map((evt) => {
                    return <Event key={evt.id} evt={evt} />;
                  })}
                </ul>
              </div>
            </div>
          </section>
        ) : (
          <Histories handleGetType={handleGetType} />
        )}
      </div>
    </>
  );
}
