import React from "react";
import PostInfos from "./PostInfos";
import Post_Orders from "./Post_Orders";
import Payement from "./Payement";
function PostOrderDetail() {
  return (
    <>
      <PostInfos />
      <Post_Orders />
      <Payement />
    </>
  );
}
export default PostOrderDetail;
