//pour ajouter une comande
export const ADD = (items, quantity) => {
  return {
    type: "Add_Cart",
    payload: { items, quantity },
  };
};

//pour supprimer une commnde

export const UPDATE = (prod_items,prod_quantity) => {
  return {
    type: "Update_Cart",
    payload: { prod_items,prod_quantity },
  };
};

//pour supprimer une commnde
export const DELETE = (id) => {
  return {
    type: "Remove_Cart",
    payload: id,
  };
};

//pour supprimer le quantite de la commande

export const REMOVE = (event) => {
  return {
    type: "Remove_One",
    payload: event,
  };
};
