import React from "react";
import PostDetail from "../components/Trends/PostDetail/PostDetail";

function PubPage() {
  return (
    <>
      <PostDetail />
    </>
  );
}
export default PubPage;
