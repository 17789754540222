import React from "react";
import Companies from "../components/Companies/Companies";

function CompaniesPage() {
  return (
    <>
      <Companies />
    </>
  );
}
export default CompaniesPage;
