import React, { useContext } from "react";
import UserAside from "./AsideBar/UserAside";
import { PostContext } from "../context";
import Pub from "./Allposts/Pub";
export default function SavedPost() {
  const posts = useContext(PostContext);
  return (
    <>
      <div className="All_Post">
        <div className="flex w-full items-center justify-between rounded-t-xl p-1 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800">
          <span className="inline-block w-32">My Saved</span>
          <hr className="h-px ml-4 w-full my-4 bg-gray-200 border-0 dark:bg-gray-700" />
          <button
            onClick={() => posts.handleClear()}
            type="button"
            className="py-2.5 px-2 w-32 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-sky-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          >
            Clear All
          </button>
        </div>
        <div className="mb-4 flex h-auto w-full flex-col rounded-3xl ">
          <div className="  font-light dark:border-gray-700 dark:bg-gray-900">
            <div className="w-full  space-y-4">
              <div className="grid grid-cols-2 mb-6 gap-5 p-2">
                {posts.posts
                  ? posts.posts?.map((post) => {
                      return <Pub key={post.id} post={post} />;
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
