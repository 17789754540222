import React, {useContext} from 'react'
import { Link } from 'react-router-dom';
import {EventContext} from '../../context'

export default function WaitList(props) {
    const evnt = useContext(EventContext)
    const evt = props.evt
    var date = new Date(evt.date);
    const eDate = date.toString('YYYY-MM-dd').split(' ')
  return (
    <>
    <Link to=''>
       <div onClick={()=>evnt.handleEvent(evt.id)} className="flex flex-row items-center p-4">
            <div className="flex h-10 w-14 flex-shrink-0 flex-col items-center justify-center rounded-lg bg-sky-500 text-sm font-bold text-white">
              <p>{eDate[1]} {eDate[2]}</p>
              <p>{eDate[3]}</p>
            </div>
            <div className="ml-3 flex flex-grow flex-col">
              <div className="flex items-center">
                <div className="text-sm font-medium">{evt.name}</div>
                <div className="ml-2 h-2 w-2 rounded-full bg-green-500"></div>
              </div>
              <div className="w-40 truncate text-xs">{evt.desc}</div>
            </div>
          </div> 
        </Link>
    </>
  )
}
