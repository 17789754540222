import React from 'react'
import UserAside from '../AsideBar/UserAside'
import PubsBox from './PubsBox'
import './topbar.css'
function AllTrendComponent() {
  return (
    <>
        <PubsBox />
    </>
  )
}
export default AllTrendComponent