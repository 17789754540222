import React, { useContext, useState, useEffect } from "react";
import UserAside from "../AsideBar/UserAside";
import { SupsContext } from "../../context";
import Suppoter from "./Suppoter";
function Suppoters() {
  const sups = useContext(SupsContext);
  const [search, setSearch] = useState(null);
  const [debouncedSearch, setDebouncedSearch] = useState(null);
  const handleSearch = (username) => {
    setSearch(username);
  };
  const [page, setPage] = useState(1);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (debouncedSearch) {
      if (sups.totalPages) {
        if (page <= sups.totalPages) {
          console.log("pass");
          console.log("page", page);
          setloading(true);
          sups.fetchUser(page, debouncedSearch);
          setloading(false);
        }
      } else {
        setloading(true);
        sups.fetchUser(page, debouncedSearch);
        setloading(false);
      }
    } else {
      if (sups.totalPages) {
        if (page <= sups.totalPages) {
          console.log("pass");
          console.log("page", page);
          setloading(true);
          sups.fetchData(page);
          setloading(false);
        }
      } else {
        setloading(true);
        sups.fetchData(page);
        setloading(false);
      }
    }
  }, [page, debouncedSearch]);

  useEffect(() => {
    const delay = 500; // Delay in milliseconds
    const debounceTimer = setTimeout(() => {
      setDebouncedSearch(search);
    }, delay);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [search]);
  // Add 'data' as a dependency to reattach event listener when data changes
  console.log(sups);

  // Fonction pour passer à la page suivante
  const nextPage = () => {
    if (page < sups.totalPages) {
      setPage(page + 1);
      console("next page", page);
    }
  };

  // Fonction pour passer à la page précédente
  const previousPage = () => {
    if (page > 1) {
      setPage(page - 1);
      console("next page", page);
    }
  };

  return (
    <>
      <div className="Supported mb-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
        <div className="flex w-full flex-row items-center justify-center rounded-t-xl border border-b-0 border-gray-200 bg-gray-50 p-5 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800">
          <form className="w-full">
            <div className="flex w-full items-center  justify-between ">
              <button type="button" className="text-lg">
                <span>Find </span>
              </button>
              <div className="relative">
                <input
                  type="search"
                  onChange={(e) => handleSearch(e.target.value)}
                  id="password"
                  className="w-full rounded-xl border-2 border-gray-200 py-2 pl-3 pr-10 text-gray-600 transition-colors hover:border-gray-300 focus:border-sky-500 focus:outline-none"
                  placeholder="Search..."
                  value={search}
                />
                <button className="leading-0 absolute top-2 right-2 block h-7 items-center justify-center rounded-xl bg-sky-600 px-3 text-center text-xl text-white transition-colors hover:text-gray-300 focus:outline-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    ></path>
                  </svg>
                </button>
              </div>
              <div class="flex flex-row space-x-2">
                <ul className="inline-flex items-center -space-x-px">
                  <li>
                    <button
                      onClick={previousPage}
                      disabled={page === 1}
                      className="ml-0 block rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      <span className="sr-only">Previous</span>
                      <svg
                        aria-hidden="true"
                        className="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={nextPage}
                      disabled={page === sups.totalPages}
                      className="block rounded-r-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      <span className="sr-only">Next</span>
                      <svg
                        aria-hidden="true"
                        className="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </li>
                </ul>
                <span class="block rounded-lg border border-gray-300 bg-gray-100 px-3 py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ">
                  <span>
                    <span class="ts">{page}</span>–
                    <span class="ts">{sups.totalPages}</span>
                  </span>{" "}
                  sur <span class="ts">{sups.totalUsers}</span>
                </span>
              </div>
            </div>
          </form>
        </div>
        <div>
          <div className="border border-b-0 border-gray-200 p-5 font-light dark:border-gray-700 dark:bg-gray-900">
            <div className="flex w-full flex-col space-y-4">
              <div className="grid grid-cols-2 gap-4">
                {sups.users?.map((sup) => {
                  return <Suppoter key={sup.id} sup={sup} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Suppoters;
