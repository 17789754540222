import React from 'react'
import WorkIndestrie from '../components/Indestie/WorkIndestrie'

export default function IndutriesPage() {
  return (
    <>
        <WorkIndestrie />
    </>
  )
}
