import React from 'react'
import IndestrieBox from './IndestrieBox'

export default function WorkIndestrie() {
  return (
    <>
      <IndestrieBox />
    </>
  )
}
