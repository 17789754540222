import { faVenusMars } from "@fortawesome/free-solid-svg-icons";
import React from "react";

function member({ have }) {
  console.log(have);

  const TimeAgo = (dateString) => {
    const currentDate = new Date();
    const pastDate = new Date(dateString);

    const timeDifference = currentDate.getTime() - pastDate.getTime();

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return years === 1 ? "1 year ago" : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? "1 month ago" : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1 min ago" : `${minutes} min ago`;
    } else {
      return seconds === 1 ? "Now" : "Now";
    }
  };
  return (
    <>
      <div className="flex w-auto flex-col items-center justify-center rounded-3xl bg-gray-50 p-5">
        <div className="w-auto flex flex-col items-center justify-center">
          {have.user.profile_picture ? (
            <img
              className="w-28 h-auto mx-auto aspect-square rounded-full border-2 border-gray-300 dark:bg-gray-500"
              src={`http://backend.novadiscova.server.cisabu.bi/uploads/PhotoUser/${have.user.profile_picture}`}
              alt={""}
            />
          ) : (
            <div className=" h-28 w-28 flex-shrink-0 flex items-center justify-center rounded-full bg-sky-50 border-sky-300 border object-cover">
              <span class="text-sky-500 text-5xl uppercase font-medium">
                {have.user.username?.substring(0, 1)}
              </span>
            </div>
          )}
          <div className="space-y-4 divide-y divide-sky-700 px-3 text-center">
            <div>
              <div className="my-3 space-y-1">
                <h2 className="text-lg uppercase font-semibold text-sky-500">
                  {have.user.username}
                </h2>
                <p className="px-5 text-xl text-sky-700 dark:text-gray-400">
                  {have.user.job}
                </p>
              </div>
            </div>

            <div className="flex items-center justify-center space-x-4 rounded-3xl bg-gray-50 pt-2">
              <button
                type="button"
                className="text-white bg-gradient-to-r from-sky-500 to-sky-700  font-medium rounded-2xl text-lg px-5 py-2.5 text-center mr-2 mb-2 opacity-80"
              >
                Message
              </button>
            </div>
            <div className="flex items-center justify-center pt-2">
              <div className="flex items-center justify-center hidden space-x-2">
                <div className="-space-y-1">
                  <span className="text-gray-500 inline-block text-sm font-semibold leading-none">
                    Alerted At
                  </span>
                </div>
              </div>
              <div className="w-auto  bg-gray-200 rounded-lg border-sky-500 opacity-70 shadow border flex flex-row space-x-1 ">
                <span className="flex items-center justify-center pl-1 font-semibold italic">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-4 h-4 text-gray-800"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </span>
                <span className="w-auto rounded-lg text-sm bg-sky-500 px-2 text-white">
                  {have.likeCount} Times
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default member;
