import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { PostContext } from "../../context";

export default function Top(props) {
  const post = props.post;
  const pub = useContext(PostContext);
  return (
    <>
      <Link to={`/pub/${post.id}`}>
        <div
          className="item_trend relative h-48 w-36 border border-sky-500 cursor-pointer rounded-xl to-pink-500 shadow img-hvr hvr-6 "
          style={{
            backgroundImage: `url(${`http://backend.novadiscova.server.cisabu.bi/uploads/Posts/${post.file}`})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="absolute bottom-0 flex  h-1/3 w-full flex-col items-center justify-end rounded-xl bg-gradient-to-t from-sky-100 to-transparent">
            <p className="font-thin text-sky-500 rounded-xl px-2 py-1">
              {" "}
              {post.User.job.length >= 10
                ? post.User.job.substring(0, 10) + "...."
                : post.User.job}
            </p>
          </div>

          <div className="text border-t border-sky-500">
            <div>
              <div className="flex flex-row items-center space-x-2">
                <img
                  src={`http://backend.novadiscova.server.cisabu.bi/uploads/Posts/${post.file}`}
                  alt=""
                  className="bg-coolGray-500 border-coolGray-700 h-8 w-8 rounded-full object-cover object-center shadow-sm"
                />
                <div className="-space-y-1">
                  <h2 className="text-sm font-semibold leading-none">
                    {post.User.username}
                  </h2>
                  <span className="text-coolGray-400 inline-block text-xs leading-none">
                    {post.description.length >= 10
                      ? post.description.substring(0, 10) + "...."
                      : post.description}
                  </span>
                </div>
              </div>
            </div>
            <p className="px-3 text-base text-gray-700">
              {" "}
              {post.User.job.length >= 10
                ? post.User.job.substring(0, 10) + "...."
                : post.User.job}
            </p>
          </div>
        </div>
      </Link>
    </>
  );
}
