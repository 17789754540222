import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./Redux/Storege/Storege";
import { Provider } from "react-redux";
import App from "./App";
import {
  StepProvider,
  ReviewProvider,
  PostsProvider,
  SocialProvider,
  HelpsProvider,
  TargetProvider,
  NotifyProvider,
  SupsProvider,
  SugesProvider,
  EventProvider,
  WaitUProvider,
  MySmsProvider,
  SmsProvider,
  PricingProvider,
  PostTabProvider,
  PostsOrdersProvider,
  GlobalFunctionProvider,
  HavesProvider,
  NeedsProvider,
  AdsProvider,
  NewsProvider,
} from "./context";

ReactDOM.createRoot(document.querySelector("#root")).render(
  <Provider store={store}>
  <AdsProvider>
    <HavesProvider>
      <NeedsProvider>
        <GlobalFunctionProvider>
          <PostsOrdersProvider>
            <StepProvider>
              <ReviewProvider>
                <NewsProvider>
                  <PostsProvider>
                    <PostTabProvider>
                      <PricingProvider>
                        <SocialProvider>
                          <HelpsProvider>
                            <TargetProvider>
                              <NotifyProvider>
                                <SupsProvider>
                                  <SugesProvider>
                                    <EventProvider>
                                      <WaitUProvider>
                                        <MySmsProvider>
                                          <SmsProvider>
                                            <Router>
                                              <App />
                                            </Router>
                                          </SmsProvider>
                                        </MySmsProvider>
                                      </WaitUProvider>
                                    </EventProvider>
                                  </SugesProvider>
                                </SupsProvider>
                              </NotifyProvider>
                            </TargetProvider>
                          </HelpsProvider>
                        </SocialProvider>
                      </PricingProvider>
                    </PostTabProvider>
                  </PostsProvider>
                </NewsProvider>
              </ReviewProvider>
            </StepProvider>
          </PostsOrdersProvider>
        </GlobalFunctionProvider>
      </NeedsProvider>
    </HavesProvider>
  </AdsProvider>
  </Provider>
);
