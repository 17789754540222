import React, { useContext } from "react";
import { PricingContext } from "../../context";
import { useNavigate } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import SwiperCore, { Autoplay, Pagination } from "swiper";
SwiperCore.use([Autoplay, Pagination]);
function PricingSup() {
  const swiper = useSwiper();
  const value = useContext(PricingContext);
  let i = 0;
  const navigate = useNavigate();
  return (
    <>
      <section className="bg-white dark:bg-gray-900">
        <div className="mx-auto overflow-hidden max-w-screen-xl  pb-4 lg:pb-4">
          <div className="navigation_items relative top-60 z-30 opacity-50 hover:opacity-100 hover:duration-200 w-full flex items-center justify-between ">
            <button
              onClick={() => swiper.slidePrev()}
              className="swiper-prev bg-gray-100 rounded-full p-1 flex items-center justify-center swiper-button-disabled"
              tabIndex="-1"
              role="button"
              aria-label="Previous slide"
              aria-controls="swiper-wrapper-66d7ef5548866805"
              aria-disabled="true"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-gray-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                ></path>
              </svg>
            </button>
            <button
              onClick={() => swiper.slideNext()}
              className="swiper-next bg-gray-100 rounded-full p-1 flex items-center justify-center"
              tabIndex="0"
              role="button"
              aria-label="Next slide"
              aria-controls="swiper-wrapper-66d7ef5548866805"
              aria-disabled="false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-gray-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                ></path>
              </svg>
            </button>
          </div>
          <div className=" z-10 mx-10 overflow-hidden w-auto h-auto">
            <Swiper
              spaceBetween={13}
              slidesPerView={3}
              autoplay={{ delay: 2000, disableOnInteraction: false }}
              loop={true}
              className="tab-btns w-auto h-auto "
              id="swiper-wrapper-66d7ef5548866805"
              aria-live="polite"
            >
              <div className="swiper-wrapper">
                {value.Pricing?.map((Pricing) => {
                  return (
                    <>
                      {Pricing.platform_services.length > 0 ? (
                        <SwiperSlide
                          data-tab-btn={Pricing.id}
                          key={Pricing.id}
                          className="swiper-slide tab  w-auto flex flex-row items-center rounded-xl py-1 pr-1 h-auto swiper-slide-active"
                          role="group"
                          aria-label="1 / 21"
                          style={{
                            width: 228.667 + "px",
                            marginRight: 15 + "px",
                          }}
                        >
                          <div className="mb-4 w-full">
                            <div className="rounded-lg border border-gray-200 bg-white active-btn px-4 py-5 text-left shadow dark:border-gray-700 dark:bg-gray-800">
                              <div className="flex flex-row rounded-xl bg-gray-200 px-2 py-2">
                                <button className="rounded-full border-2 border-gray-200 p-1">
                                  <img
                                    className="h-12 w-12 rounded-full"
                                    src={`images/${Pricing.file}`}
                                    alt="check-mark"
                                  />
                                </button>
                                <div className="flex items-center justify-center">
                                  <h2 className="text-2xl font-semibold text-gray-500 ml-2">
                                    {Pricing.platform}
                                  </h2>
                                </div>
                              </div>
                              <div className="inline-flex mt-3 items-center justify-center w-full">
                                <hr className="w-64 h-px my-2 bg-gray-200 border-0 dark:bg-gray-700" />
                                <span className="absolute px-3 font-normal text-sm text-gray-600 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">
                                  Sharing
                                </span>
                              </div>
                              <ul className="mb-6 flex flex-col pt-2">
                                {Pricing.platform_services
                                  ?.slice(0, 10)
                                  .map((Pricing) => {
                                    return (
                                      <>
                                        <li
                                          className="mb-2.5 flex items-center"
                                          key={Pricing.id}
                                        >
                                          <img
                                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                            className="mr-4 dark:hidden"
                                            alt="check-mark"
                                          />
                                          <img
                                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                            className="mr-4 hidden dark:block"
                                            alt="check-mark"
                                          />
                                          <p className="text-base w-full font-normal flex  flex-row items-center justify-between  text-gray-800 dark:text-white">
                                            <span> {Pricing.title}</span>{" "}
                                            <span className="font-semibold mr-4 leading-6 text-base text-gray-600">
                                              {Pricing.price -
                                                Pricing.price * 0.1}
                                              <sub className="ml-1">
                                                fbu/100
                                              </sub>
                                            </span>
                                          </p>
                                        </li>
                                      </>
                                    );
                                  })}
                              </ul>
                              <p className="relative pl-3 text-base text-sky-700">
                                <span className="text-2xl font-semibold">
                                  Cash
                                </span>
                                <sub className="text-lg font-light">fbu</sub>
                                <span className="text-lg font-light">/100</span>
                              </p>
                              <button
                                onClick={() => {
                                  navigate(`pricing/${Pricing.platform}`);
                                }}
                                className="mt-5 w-full hidden rounded bg-gray-200 px-8 py-3 text-base font-semibold text-sky-700 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-sky-700 focus:ring-offset-2"
                              >
                                Subscribe
                              </button>
                            </div>
                          </div>
                        </SwiperSlide>
                      ) : null}
                    </>
                  );
                })}
              </div>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}
export default PricingSup;
