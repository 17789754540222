import React,{useState} from 'react'
import UPricingSup from './UPricingSup'
import UPricingOwner from './UPricingOwner'
function AllPubs() {
  const [Price, setPrice] = useState(true)
    return ( <>
        <div className="All_Prices mb-3">
  <div  className="flex w-full items-center justify-between rounded-t-xl p-1 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800">
    <span>All Prices Availables</span>
    <hr className="h-px ml-4 w-auto my-4 bg-gray-200 border-0 dark:bg-gray-700" />
  </div>

  <div class="flex flex-grow h-10 mb-2 w-full flex-row items-center justify-center px-3">
                    <div class="flex flex-col">
                      { Price?
                      <div class="text-lg font-bold text-sky-600 bg-sky-100 border-sky-500 border w-fit rounded-xl px-3">P.Owner</div>
                        :<div class="text-lg font-bold text-sky-600 bg-gray-100 border-gray-300 border w-fit rounded-xl px-3">P.Owner</div>
                        }
                    </div>
                    <div class="ml-3 mr-5 relative mr-2 inline-block w-12 select-none align-middle transition duration-200 ease-in">
                      <input onClick={(e)=>setPrice(!Price)} type="checkbox" name="toggle" id="toggle" class="toggle-checkbox absolute block h-6 w-6 cursor-pointer appearance-none rounded-full border-4 bg-white" />
                      <label for="toggle" class="toggle-label block h-6 w-12 cursor-pointer overflow-hidden rounded-full bg-gray-300"></label>
                    </div>
                    <div class="flex flex-col">
                      { !Price?
                      <div class="text-lg font-bold text-sky-600 bg-sky-100 border-sky-500 border w-fit rounded-xl px-3">P.Supporter</div>
                        :<div class="text-lg font-bold text-sky-600 bg-gray-100 border-gray-300 border w-fit rounded-xl px-3">P.Supporter</div>
                        }
                    </div>
                  </div>  
  <div className="mb-4 flex h-auto w-full flex-col rounded-3xl ">

      <div className="  font-light dark:border-gray-700 dark:bg-gray-900">
      {Price? <UPricingOwner/> : <UPricingSup/>}
    </div>
</div>
  </div>
        </>
    )
}
export default AllPubs