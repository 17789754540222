import React from "react";
import GetStartField from "./GetStartField";
import HowWork from "./HowWork";
import OurPlan from "./OurPlan";
import Reviews from "./Reviews";
import WhyUs from "./WhyUs";
import WeOffer from "./WeOffer";
import "./home.css";
export const homeContext = React.createContext("yes");
function HomePageComponent() {
  return (
    <>
      <GetStartField />
      <WeOffer />
      <HowWork />
      <Reviews />
      <OurPlan />
      <WhyUs />
    </>
  );
}
export default HomePageComponent;
