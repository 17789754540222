import React, { useState } from "react";
import Personal_Info from "./Personal_Info";
import Success_Info from "./Success_Info";
import Payment_Info from "./Payment_Info";

export default function Signup(props) {
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    Account: "",
    Date_Birth: "",
    Gender: "",
    Phone_Number: "",
    Interest: "",
  });

  const PageDisplay = () => {
    if (page === 0) {
      return (
        <Personal_Info
          formData={formData}
          setFormData={setFormData}
          setPage={setPage}
        />
      );
    } else if (page === 1) {
      return (
        <Payment_Info
          formData={formData}
          setFormData={setFormData}
          setPage={setPage}
        />
      );
    } else {
      return (
        <Success_Info
          formData={formData}
          setFormData={setFormData}
          opensignupPopup={props.opensignupPopup}
        />
      );
    }
  };
  return (
    <>
      <div
        className="signup-popup fixed inset-0 z-40 flex flex-col items-center justify-center"
        style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
      >
        <div className="relative -mb-5 flex w-[410px] items-end justify-end md:w-[600px]">
          <div
            onClick={() => props.opensignupPopup(false)}
            className="close-signup-popup h-8 w-8 rounded-full bg-sky-500 opacity-80 hover:bg-sky-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-8 w-8 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </div>
        </div>
        <div className="flex w-[410px] flex-row items-center justify-center rounded-3xl bg-white text-center shadow-lg sm:text-left md:h-[450px] md:w-[600px]">
          <div className="flex max-w-xl flex-col rounded-lg p-8">
            <div className="flex w-full flex-row items-center justify-center">
              <img src="./images/Logo.png" className="h-16 w-16" alt="Logos" />
              <span className="text inline-block self-center whitespace-nowrap bg-gradient-to-r from-sky-500 to-sky-800 bg-clip-text text-lg font-semibold text-transparent dark:text-white">
                ErekaBose
              </span>
            </div>
            {PageDisplay()}
            <div className="flex flex-col">
              <div className="stepper flex w-full flex-row items-center justify-between space-x-5 px-8">
                {page === 0 ? (
                  <>
                    <div className="step--1 h-2 w-full rounded-lg bg-sky-500"></div>
                    <div className="step--1 h-2 w-full rounded-lg bg-gray-100"></div>
                    <div className="step--1 h-2 w-full rounded-lg bg-gray-100"></div>
                  </>
                ) : page == 1 ? (
                  <>
                    <div className="step--1 h-2 w-full rounded-lg bg-sky-500"></div>
                    <div className="step--1 h-2 w-full rounded-lg bg-sky-500"></div>
                    <div className="step--1 h-2 w-full rounded-lg bg-gray-100"></div>
                  </>
                ) : (
                  <>
                    <div className="step--1 h-2 w-full rounded-lg bg-sky-500"></div>
                    <div className="step--1 h-2 w-full rounded-lg bg-sky-500"></div>
                    <div className="step--1 h-2 w-full rounded-lg bg-sky-500"></div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
