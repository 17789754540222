import React from 'react'

export default function ReceiveedSms(props) {
  const msg = props.msg
  return (
    <>
        <div className="col-start-1 col-end-8 p-3 rounded-lg">
              <div className="flex flex-row items-center">
                <div
                    className="flex items-center justify-center h-10 w-10 rounded-full bg-sky-500 flex-shrink-0"
                >
                  <img src={msg.receiver} className='rounded-full' />
                </div>
                <div
                    className="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl"
                >
                  <div>{msg.sms}</div>
                </div>
              </div>
            </div>
    </>
  )
}
