import React from "react";

import AllCompanieBuyComponent from "../components/Buy/AllCompanieBuyComponent";
function AllCompanieBuy() {
  return (
    <>
      <AllCompanieBuyComponent />
    </>
  );
}
export default AllCompanieBuy;
