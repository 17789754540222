import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { NeedsContext } from "../../../context";
import { GlobalFunctionContext } from "../../../context";
import Api from "../../Api/Api";
function PostInfos() {
  var need = useContext(NeedsContext);
  need = need.detailNeed;
  const Ned = useContext(NeedsContext);
  const { id } = useParams();
  useEffect(() => {
    Ned.handleDetail(id);
  }, []);
  const [Error, SetError] = useState(null);
  const Global = useContext(GlobalFunctionContext);
  const [showmore, SetShow] = useState(false);
  const TimeAgo = (dateString) => {
    const currentDate = new Date();
    const pastDate = new Date(dateString);
    const timeDifference = currentDate.getTime() - pastDate.getTime();

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return years === 1 ? "1 year ago" : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? "1 month ago" : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1 min ago" : `${minutes} min ago`;
    } else {
      return seconds === 1 ? "Now" : "Now";
    }
  };
  const [issend, setsend] = useState(false);
  const user_id = localStorage.getItem("userid");
  const AlertUser = async (need_id) => {
    if (localStorage.getItem("userid")) {
      try {
        const response = await Api.post("/api/Order/Need", {
          need_id,
          user_id,
        });
        console.log(response.data);
        // Handle success response
        setsend(false);
        Global.setUserPop(true);
      } catch (error) {
        setsend(false);
        console.error(error);
        if (error.response) {
          const errorMessage = error.response.data.error;
          SetError(errorMessage);
          setTimeout(() => {
            SetError(null);
          }, 3000);
        }
        setsend(false);
        // Handle error response
      }
    }
  };

  return (
    <>
      {need ? (
        <div className="mb-4  rounded-3xl  ">
          <div className="items min-h-48 flex items-start justify-start">
            <div className="flex flex-row">
              <div className="rounded-lg shadow-md  text-gray-900 ">
                <div className="flex items-center justify-between p-3">
                  <div className="flex items-center space-x-2">
                    {need.User?.profile_picture ? (
                      <img
                        className="bg-coolGray-500 border-coolGray-700 h-12 w-12 rounded-full object-cover object-center shadow-sm"
                        src={`http://backend.novadiscova.server.cisabu.bi/uploads/Haves_Needs/${need.file}`}
                        alt={need.User.username}
                      />
                    ) : (
                      <div className="mx-1 h-12 w-12 flex-shrink-0 flex items-center justify-center rounded-full bg-sky-50 border-sky-300 border object-cover">
                        <span class="text-sky-500 uppercase font-medium">
                          {need.User.username?.substring(0, 1)}
                        </span>
                      </div>
                    )}
                    <div className="-space-y-1">
                      <h2 className="text-lg text-sky-600 font-semibold leading-none">
                        {need.User.username}
                      </h2>
                      <span className="text-coolGray-400 inline-block text-sm leading-none">
                        {need.User.job}
                      </span>
                    </div>
                  </div>

                  <div className="w-auto ml-10 bg-gray-200 rounded-lg flex flex-row space-x-2 ">
                    <span className=" font-semibold italic">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </span>
                    <span className="w-auto rounded-lg bg-sky-500 px-2 text-white">
                      {TimeAgo(need.createdAt)}
                    </span>
                  </div>

                  <button title="Open options" type="button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="h-5 w-5 fill-current"
                    >
                      <path d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"></path>
                      <path d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"></path>
                      <path d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"></path>
                    </svg>
                  </button>
                </div>
                <div className="my-4 flex flex-row justify-between px-3 ">
                  <div className="flex flex-row space-x-3">
                    <div class="bg-gray-100 py-2 px-3 flex flex-row rounded-xl">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                        />
                      </svg>

                      <span className="rounded-r-xl text-base text-gray-700">
                        {need.location}
                      </span>
                    </div>
                    <div class="bg-gray-100 py-2 px-3 flex flex-row rounded-xl">
                      <span className="rounded-r-xl text-base text-gray-700">
                        {need.Category.title}
                      </span>
                    </div>
                  </div>
                  <div class="bg-gray-100 py-2 px-3 flex flex-row rounded-xl">
                    <span className="rounded-r-xl text-lg font-bold text-gray-700">
                      {need.price}fbu
                    </span>
                  </div>
                </div>
                <div className="flex w-full mt-4 items-start justify-between flex-col px-3 text-sm">
                  <p className="px-5  text-lg  mb-3 w-full text-gray-700">
                    {need.description}
                  </p>
                </div>

                <div className="mt-5 mb-2">
                  <img
                    src={`http://backend.novadiscova.server.cisabu.bi/uploads/Haves_Needs/${need.file}`}
                    alt=""
                    className="bg-coolGray-500   w-full rounded-lg border-2 object-cover object-center"
                  />
                </div>
                <div className="p-3">
                  <div className="mt-1 mb-3 flex items-center justify-between">
                    <div className="flex flex-col items-center  justify-center space-x-1">
                      <div className="flex flex-row items-center  justify-center space-x-1 bg-sky-500 rounded-2xl px-3 py-2">
                        <button
                          onClick={() => {
                            Global.setPop(need.User);
                            AlertUser(need.id);
                          }}
                          className="pr-2 text-md text-white"
                        >
                          {issend ? "Alert Client..." : "Alert Client"}
                        </button>
                      </div>
                      {Error ? (
                        <label
                          htmlFor="username"
                          className="text-left  ml-3 block font-medium text-green-500 dark:text-white"
                        >
                          {Error}
                        </label>
                      ) : null}
                    </div>

                    {!need.status == false ? (
                      <button class="py-3 px-3 text-md leading-none border-red-200 border text-red-700 bg-red-100 rounded-3xl focus:outline-none">
                        Order Status: Processing
                      </button>
                    ) : (
                      <button class="py-3 px-3 text-md leading-none border-green-200 border text-green-700 bg-green-100 rounded-3xl focus:outline-none">
                        Order Status: Completed
                      </button>
                    )}
                    <div className="flex flex-row space-x-1 items-center justify-center">
                      <span class="text-lg text-gray-600 font-semibold ">
                        {need.Needs_orders.length} Seller Alerts
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
export default PostInfos;
