import React,{useState} from 'react'
import PricingOwner from './PricingOwner'
import PricingSup from './PricingSup'
function OurPlan() {
  const [Price, setPrice] = useState(true)
  return ( <>
      <section className="bg-white dark:bg-gray-900">
  <div className="mx-auto overflow-hidden max-w-screen-xl  ">
    <div className="mx-auto max-w-screen-xl px-4 py-2 lg:py-2 lg:px-6">
      <section className="px-6 xl:px-0">
        <div className="container mx-auto py-3 px-6">
          <div className="flex w-full flex-col justify-center lg:items-center">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl text-sky-500 dark:text-white ">Our Right Plan For You</h1>
            <p className="mt-2.5 text-2xl text-gray-700 dark:text-white font-light lg:w-1/2 lg:text-center">We have several plans to showcase For Pulbisher or Contributor.</p>
          </div>
          <div className="flex w-full items-center justify-center">
            <div className="pt-2 h-12">
              <div className="container mx-auto">
                <div className="mx-auto mb-20 w-11/12 xl:w-4/5">
                <div className="flex flex-grow h-10 w-full flex-row items-center justify-between px-3">
                    <div className="flex flex-col">
                      { Price?
                      <div className="text-lg font-bold text-sky-600 bg-sky-100 border-sky-500 border w-fit rounded-xl px-3">P.Owner</div>
                        :<div className="text-lg font-bold text-sky-600 bg-gray-100 border-gray-300 border w-fit rounded-xl px-3">P.Owner</div>
                        }
                    </div>
                    <div className="ml-3 mr-5 relative mr-2 inline-block w-12 select-none align-middle transition duration-200 ease-in">
                      <input onClick={(e)=>setPrice(!Price)} type="checkbox" name="toggle" id="toggle" className="toggle-checkbox absolute block h-6 w-6 cursor-pointer appearance-none rounded-full border-4 bg-white" />
                      <label htmlFor="toggle" className="toggle-label block h-6 w-12 cursor-pointer overflow-hidden rounded-full bg-gray-300"></label>
                    </div>
                    <div className="flex flex-col">
                      { !Price?
                      <div className="text-lg font-bold text-sky-600 bg-sky-100 border-sky-500 border w-fit rounded-xl px-3">P.Supporter</div>
                        :<div className="text-lg font-bold text-sky-600 bg-gray-100 border-gray-300 border w-fit rounded-xl px-3">P.Supporter</div>
                        }
                    </div>
                  </div>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    </div>
    </section>

     {Price? <PricingOwner/> : <PricingSup/>}
     </>
    
  )
}
export default OurPlan