export const StepDatas = [
    {
      id: 1,
      title: "Step 1",
      video: "https://www.youtube.com/embed/jytmYCC5OtE",
      info:
        "Skate ipsum dolor sit amet, alley oop vert mute-air Colby Carter flail 180 berm."
    },
    {
      id: 2,
      title: "Step 2",
      video: "https://www.youtube.com/embed/jytmYCC5OtE",
      info:
        "Skate ipsum dolor sit amet, alley oop vert mute-air Colby Carter flail 180 berm."
    },
    {
      id: 3,
      title: "Step 3",
      video: "https://www.youtube.com/embed/jytmYCC5OtE",
      info:
        "Skate ipsum dolor sit amet, alley oop vert mute-air Colby Carter flail 180 berm."
    }
  ];
  
  export const DetailStep = {
    id: 1,
      title: "Step 1",
      video: "https://www.youtube.com/embed/jytmYCC5OtE",
      info:
        "Skate ipsum dolor sit amet, alley oop vert mute-air Colby Carter flail 180 berm.",
  };
  