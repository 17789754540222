import React from 'react'

function SendFeedBack() {
  return (
    <>
     <div className="Questions hidden">
  <div className="mb-4 mt-4 flex h-auto w-full flex-col rounded-3xl border bg-sky-50 shadow-lg">
    {/* <!-- component --> */}
    <section className="mt-10">
      <div className="mx-auto max-w-7xl px-12">
        <div className="mx-auto w-full text-left md:w-11/12 md:text-center xl:w-9/12">
          <h1 className="mb-8 text-3xl font-extrabold leading-none tracking-normal text-gray-900 md:text-4xl md:tracking-tight">
            <span className="leading-12 block w-full bg-gradient-to-r from-sky-400 to-sky-500 bg-clip-text py-2 text-center text-transparent lg:inline">Thank You For Your Support</span>
          </h1>

          <div className="flex w-full flex-row items-center justify-center">
            <div className="flex flex-col items-center space-x-2" style={{cursor: "auto"}}>
              <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png" alt="" className="bg-coolGray-500 border-coolGray-700 h-14 w-14 rounded-full object-cover object-center shadow-sm" style={{cursor: "auto"}} />
              <div className="-space-y-1" style={{cursor: "auto"}}>
                <h2 className="text-lg font-semibold leading-none text-sky-700" style={{cursor: "auto"}}>BUK-C BOY</h2>
                <span className="text-coolGray-400 ml-3 inline-block text-sm leading-none text-sky-700" style={{cursor: "auto"}}>Musician</span>
              </div>
            </div>
          </div>
          <p className="mb-8 rounded-lg bg-sky-50 px-3 py-2 text-center text-lg font-thin text-gray-600 shadow-sm md:text-xl">"Keep Pushing Your Artist By Answering Some off The Questions Listed Below."</p>
        </div>
        <div className="mx-auto mt-2 w-full text-center md:w-11/12">
          <div className="lg:space-x- mx-auto mt-8 flex max-w-screen-sm flex-col lg:max-w-screen-lg lg:flex-row lg:flex-wrap">
            <div className="lg:w-1/2">
              <div className="question-and-answer group my-3 cursor-pointer select-none rounded-lg py-2 px-2 text-sm">
                <dt className="question">
                  <div className="flex justify-between">
                    <div className="ml-3 font-semibold text-sky-700">What Cool Thing About My Work?</div>
                  </div>
                </dt>
                <dd className="answer mt-2 leading-snug text-gray-700">
                  <form>
                    <div className="w-full rounded-lg border border-gray-200 bg-gray-50 dark:border-gray-600 dark:bg-gray-700">
                      <div className="rounded-lg bg-white px-4 py-2 dark:bg-gray-800">
                        <label htmlFor="comment" className="sr-only">Your comment</label>
                        <textarea id="comment" rows="3" className="w-full border-0 bg-white px-0 text-sm text-gray-600 focus:ring-0 dark:bg-gray-800 dark:text-white dark:placeHolder-gray-400" placeholder="Write Here..." required></textarea>
                      </div>
                    </div>
                  </form>
                </dd>
              </div>
            </div>
            <div className="lg:w-1/2">
              <div className="question-and-answer group my-3 cursor-pointer select-none rounded-lg py-2 px-2 text-sm">
                <dt className="question">
                  <div className="flex justify-between">
                    <div className="ml-3 font-semibold text-sky-700">What is not feel Well ?</div>
                  </div>
                </dt>
                <dd className="answer mt-2 leading-snug text-gray-700">
                  <form>
                    <div className="w-full rounded-lg border border-gray-200 bg-gray-50 dark:border-gray-600 dark:bg-gray-700">
                      <div className="rounded-lg bg-white px-4 py-2 dark:bg-gray-800">
                        <label htmlFor="comment" className="sr-only">Your comment</label>
                        <textarea id="comment" rows="3" className="w-full border-0 bg-white px-0 text-sm text-gray-900 focus:ring-0 dark:bg-gray-800 dark:text-white dark:placeHolder-gray-400" placeholder="Write Here..." required></textarea>
                      </div>
                    </div>
                  </form>
                </dd>
              </div>
            </div>
            <div className="lg:w-1/2">
              <div className="question-and-answer group my-3 cursor-pointer select-none rounded-lg py-2 px-2 text-sm">
                <dt className="question">
                  <div className="flex justify-between">
                    <div className="ml-3 font-semibold text-sky-700">What Advice You gave me?</div>
                  </div>
                </dt>
                <dd className="answer mt-2 leading-snug text-gray-700">
                  <form>
                    <div className="w-full rounded-lg border border-gray-200 bg-gray-50 dark:border-gray-600 dark:bg-gray-700">
                      <div className="rounded-lg bg-white px-4 py-2 dark:bg-gray-800">
                        <label htmlFor="comment" className="sr-only">Your comment</label>
                        <textarea id="comment" rows="3" className="w-full border-0 bg-white px-0 text-sm text-gray-900 focus:ring-0 dark:bg-gray-800 dark:text-white dark:placeHolder-gray-400" placeholder="Write Here..." required></textarea>
                      </div>
                    </div>
                  </form>
                </dd>
              </div>
            </div>
            <div className="lg:w-1/2">
              <div className="question-and-answer group my-3 cursor-pointer select-none rounded-lg py-2 px-2 text-sm">
                <dt className="question">
                  <div className="flex justify-between">
                    <div className="ml-3 font-semibold text-sky-700">What Future Work Would you like ?</div>
                  </div>
                </dt>
                <dd className="answer mt-2 leading-snug text-gray-700">
                  <form>
                    <div className="mb-4 w-full rounded-lg border border-gray-200 bg-gray-50 dark:border-gray-600 dark:bg-gray-700">
                      <div className="rounded-lg bg-white px-4 py-2 dark:bg-gray-800">
                        <label htmlFor="comment" className="sr-only">Your comment</label>
                        <textarea id="comment" rows="3" className="w-full border-0 bg-white px-0 text-sm text-gray-900 focus:ring-0 dark:bg-gray-800 dark:text-white dark:placeHolder-gray-400" placeholder="Write Here..." required></textarea>
                      </div>
                    </div>
                  </form>
                </dd>
              </div>
            </div>
          </div>

          <div className="mb-4 space-x-0 md:mb-8 md:space-x-2">
            <a href="#_" className="mb-2 inline-flex w-2/3 items-center justify-center rounded-2xl bg-sky-600  py-3 text-lg text-white sm:ml-0 sm:w-1/2 lg:w-1/4"> Send </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>   
    </>
  )
}
export default SendFeedBack