import React from 'react'

function Resources() {
  return (
    <div className="w-full px-4  sm:w-1/2 lg:w-2/12 shadow-sm rounded-3xl">
    <div className="mb-10 w-full">
      <h4 className="text-dark mb-9 text-lg font-semibold">Services</h4>
      <ul>
        <li>
          <a href=""className="text-body-color hover:text-primary mb-2 inline-block text-base text-gray-900 leading-loose "> All Services </a>
        </li>
        <li>
          <a href=""className="text-body-color hover:text-primary mb-2 inline-block text-base text-gray-900 leading-loose"> User Guide </a>
        </li>
        <li>
          <a href=""className="text-body-color hover:text-primary mb-2 inline-block text-base text-gray-900 leading-loose"> Payment </a>
        </li>
        <li>
          <a href=""className="text-body-color hover:text-primary mb-2 inline-block text-base text-gray-900 leading-loose">Create Account </a>
        </li>
      </ul>
    </div>
  </div>
  )
}
export default Resources