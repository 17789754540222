import React, { useContext, useEffect, useState } from "react";
import { SugesContext, NeedsContext } from "../../context";
import Event from "./Event";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
// Import Swiper styles
import "swiper/css";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function BrowserHaveComponent() {
  const suges = useContext(SugesContext);
  const evts = useContext(NeedsContext);

  const [page, setPage] = useState(1);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    setloading(true);
    evts.fetchneedsData(page);
    setPage(page + 1);
    setloading(false);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      // Check if user has scrolled to the bottom of the page

      if (page < evts.totalPages) {
        if (
          window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 50
        ) {
          console.log("pass");
          setloading(true);
          evts.fetchneedsData(page);
          setPage(page + 1); // Fetch next page when end is reached
          setloading(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [evts]); // Add 'data' as a dependency to reattach event listener when data changes

  const swiper = useSwiper();

  return (
    <>
      {/* <section className="bg-white dark:bg-gray-900">
        <div className="mx-auto overflow-hidden max-w-screen-xl  pb-4 lg:pb-4">
          <div className="navigation_items w-[615px] absolute   z-0 opacity-50 hover:opacity-100 hover:duration-200  flex items-center justify-between ">
            <button
              onClick={() => swiper.slidePrev()}
              className="swiper-prev bg-gray-100 rounded-lg h-14 p-1 flex items-center justify-center swiper-button-disabled"
              tabIndex="-1"
              role="button"
              aria-label="Previous slide"
              aria-controls="swiper-wrapper-66d7ef5548866805"
              aria-disabled="true"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-gray-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                ></path>
              </svg>
            </button>
            <button
              onClick={() => swiper.slideNext()}
              className="swiper-next bg-gray-100 rounded-lg h-14 p-1 flex items-center justify-center"
              tabIndex="0"
              role="button"
              aria-label="Next slide"
              aria-controls="swiper-wrapper-66d7ef5548866805"
              aria-disabled="false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-gray-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                ></path>
              </svg>
            </button>
          </div>
          <div className=" z-20 mx-10 overflow-hidden w-auto h-auto">
            <Swiper
              spaceBetween={13}
              slidesPerView={4}
              className="tab-btns w-auto h-auto "
              id="swiper-wrapper-66d7ef5548866805"
              aria-live="polite"
            >
              <div className="swiper-wrapper">
                {suges.suges?.map((sug) => {
                  return (
                    <>
                      <SwiperSlide key={sug.id}>
                        <div className="flex flex-row items-center justify-center rounded-xl bg-gray-200 px-3 py-4">
                          <div className="text-sm text-gray-700">
                            {sug.name}
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  );
                })}
              </div>
            </Swiper>
          </div>
        </div>
      </section> */}
      <div className="My_Contribution  ">
        <div className="flex w-full items-center   justify-between rounded-t-xl p-1 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800">
          <span className="inline-block w-24">This Week</span>
          <hr className="h-px ml-4 w-full my-4 bg-gray-200 border-0 dark:bg-gray-700" />
          <div className="bg-gray-100 rounded-full p-1 border-2 hover:border-gray-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="h-6 w-6 text-gray-600"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
              />
            </svg>
          </div>
        </div>
        <div className="mb-4 flex h-auto w-full flex-col rounded-3xl ">
          <div className="font-light dark:border-gray-700 dark:bg-gray-900">
            <div className=" w-full">
              <div className="colums-2  grid   grid-cols-1 gap-4 w-full  px-2 dark:divide-gray-700 pt-3">
                {evts.haves?.map((evt) => {
                  return <Event key={evt.id} evt={evt} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default BrowserHaveComponent;
