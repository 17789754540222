import React, {useContext} from 'react'
import { Link } from 'react-router-dom'
import {MySmsContext} from '../../context'

export default function Message(props) {
  const msg = useContext(MySmsContext)
    const sms = props.sms
  return (
    <>
    <Link to=''>
        <div onClick={()=>msg.handleSmsDetail(sms.id)} className="flex flex-row items-center p-4">
              <div className="flex items-center justify-center h-10 w-10 rounded-full bg-sky-500 text-sky-300 font-bold flex-shrink-0">
                <img src={sms.photo} className='rounded-full'/>
              </div>
              <div className="flex flex-col flex-grow ml-3">
                <div className="flex items-center">
                  <div className="text-sm font-medium">{sms.name} </div>
                  {sms.active ?
                  <div className="h-2 w-2 rounded-full bg-green-500 ml-2"></div>
                  :
                  null}
                  
                </div>
                <div className="text-xs truncate w-40">{sms.sms}</div>
              </div>
        </div>
        </Link>
    </>
  )
}
