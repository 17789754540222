import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalFunctionContext } from "../../context";
import Api from "../Api/Api";

export default function Top(props) {
  const post = props.post;
  const Global = useContext(GlobalFunctionContext);
  const user_id = localStorage.getItem("userid");
  const AddWatch = async (ads_id) => {
    if (localStorage.getItem("userid")) {
      try {
        const response = await Api.post("/api/watchs", { ads_id, user_id });
        console.log(response.data);
        Global.setAdsPop(true);
        Global.setAds(post);
        // Handle success response
      } catch (error) {
        console.error(error);
        // Handle error response
      }
    }
  };
  return (
    <>
      <div
        className="item_trend relative h-[320px] w-[270px] border border-sky-500 cursor-pointer rounded-xl to-pink-500 shadow img-hvr hvr-6 "
        style={{
          backgroundImage: `url(${`http://backend.novadiscova.server.cisabu.bi/uploads/Ads/${post.file}`})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="absolute bottom-0 flex  h-1/3 w-full flex-col items-center justify-end rounded-xl bg-gradient-to-t from-sky-100 to-transparent">
          <p className="font-thin text-sky-500 rounded-xl px-2 py-1">
            {" "}
            {post.User.job.length >= 20
              ? post.User.job.substring(0, 20) + "...."
              : post.User.job}
          </p>
        </div>

        <div className="text border-t border-sky-500">
          <div>
            <div className="ml-3 flex flex-row items-center space-x-2">
              <img
                src={`http://backend.novadiscova.server.cisabu.bi/uploads/Ads/${post.file}`}
                alt=""
                className="bg-coolGray-500 border-coolGray-700 h-8 w-8 rounded-full object-cover object-center shadow-sm"
              />
              <div className="-space-y-1 text-start">
                <h2 className="text-sm font-semibold leading-none">
                  {post.User.username}
                </h2>
                <span className="text-coolGray-400 inline-block text-xs leading-none">
                  {post.User.job.length >= 20
                    ? post.User.job.substring(0, 20) + "...."
                    : post.User.job}
                </span>
              </div>
            </div>
          </div>
          <p className="px-3 text-base text-gray-700">
            {" "}
            {post.description.length >= 20
              ? post.description.substring(0, 20) + "...."
              : post.description}{" "}
          </p>
          <button
            onClick={() => {
              AddWatch(post.id);
            }}
            className="rounded-xl border-0 bg-white mt-3 px-6 py-2 text-lg text-sky-500 hover:bg-gray-200 focus:outline-none"
          >
            Watch
          </button>
        </div>
      </div>
    </>
  );
}
