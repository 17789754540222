import React from "react";
import { Link } from "react-router-dom";
function Post_Order({ order }) {
  console.log(order);

  return (
    <>
      <tr class="hover:bg-gray-100 bg-gray-50 ">
        <td class="px-5 py-5 border-b border-gray-200  text-sm">
          <div class="flex">
            <div class="flex-shrink-0 w-10 h-14">
              <img
                class="w-full h-full rounded-md border border-sky-300"
                src={`http://backend.novadiscova.server.cisabu.bi/uploads/Posts/${order.Post.file}`}
                alt=""
              />
            </div>
            <div class="ml-3 flex flex-row items-center justify-center font-medium">
              <p class="text-gray-900 whitespace-no-wrap">
                {" "}
                {order.Post.description.length >= 10
                  ? order.Post.description.substring(0, 10) + "...."
                  : order.Post.description}
              </p>
            </div>
          </div>
        </td>
        <td class="px-5 py-5 border-b border-gray-200  ">
          <p class="text-gray-900 whitespace-no-wrap">
            {order.totalAmount}
            <span className="ml-1">Fbu</span>
          </p>
        </td>
        <td class="px-5 py-5 border-b border-gray-200 ">
          <p class="text-gray-900 whitespace-no-wrap">{order.createdAt}</p>
        </td>
        <td class="px-5 py-5 border-b border-gray-200">
          {order.Post.Post_proofs[0].file === null && (
            <span class="relative hidden inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
              <span
                aria-hidden
                class="absolute inset-0 bg-red-200 opacity-50 rounded-full"
              ></span>
              <span class="relative">No Proof</span>
            </span>
          )}
          {order.Post.Post_proofs[0].status === "0" && (
            <span class="relative inline-block px-3 py-1 font-semibold text-orange-900 leading-tight">
              <span
                aria-hidden
                class="absolute inset-0 bg-orange-200 opacity-50 rounded-full"
              ></span>
              <span class="relative">Pending</span>
            </span>
          )}
          {order.Post.Post_proofs[0].status === "1" && (
            <span class="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
              <span
                aria-hidden
                class="absolute inset-0 bg-green-200 opacity-50 rounded-full"
              ></span>
              <span class="relative">Paid</span>
            </span>
          )}
        </td>
        <td class="px-5 py-5 border-b border-gray-200 text-sm text-right">
          <Link
            to={`/orders/Post/${order.Post.id}`}
            class="inline-block text-white bg-sky-500 rounded-lg p-2 border-sky-500 border hover:bg-gray-100 hover:text-sky-500 "
          >
            Details
          </Link>
        </td>
      </tr>
    </>
  );
}
export default Post_Order;
