import React from "react";
import Pubs from "./Pubs";
function AllPubs() {
  return (
    <>
      <div className="All_Post mb-3">
        <div className="flex w-full items-center justify-between rounded-t-xl p-1 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800">
          <span>News</span>
          <hr className="h-px ml-4 w-full my-4 bg-gray-200 border-0 dark:bg-gray-700" />
        </div>
        <div class="flex flex-row items-center mt-2">
          <div class="flex flex-row items-center">
            <button class="flex h-7 w-7 items-center justify-center rounded-full bg-gray-200 text-gray-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-56 w-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                ></path>
              </svg>
            </button>
            <div class="ml-1 text-sm font-semibold text-gray-700">
              Sponsored Ads
            </div>
          </div>
          <div class="ml-auto">
            <button class="flex h-7 w-7 items-center justify-center rounded-full bg-gray-200 text-gray-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-4 w-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                ></path>
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div class="custom-slant w-full mb-5 p-2" style={{ height: "182px;" }}>
          <div class="hide-with-banner relative">
            <a
              href="https://material-tailwind.com?ref=tailwindcomponents"
              target="_blank"
              class="flex h-full w-full flex-row py-5"
            >
              <div class="z-10 w-1/2 flex-col items-start justify-center px-6 xl:px-8">
                <div class="flex flex-row space-x-2 ">
                  <img
                    src="images/Logo.png"
                    class="mb-4 w-12 h-12 object-contain bg-white rounded-xl"
                  />
                  <p class="text-xl font-bold text-white mt-2">Ereka Bose</p>
                </div>
                <p class="text-xs text-white mt-3 ">Marketing Company</p>
                <p class="mb-5 font-sans text-2xl font-bold text-white">
                  VA HASI DUKORE VYOSE BIRASHOBOKA?
                </p>
                <div class="mb-3">
                  <span class="h-8 rounded-lg bg-sky-500 px-4 py-3 font-sans text-xs font-bold text-white shadow-md">
                    Get Started
                  </span>
                </div>
              </div>
              <div class="z-10 w-1/2 flex-col items-start justify-center px-6 xl:px-8">
                <img
                  src="http://backend.novadiscova.server.cisabu.bi/uploads/Posts/4cb88535-14ab-492e-8b37-c8a7cb97e191.jpg"
                  class="h-64 w-full object-cover rounded-2xl border "
                />
              </div>
              <div class="absolute  bg-gradient-to-br   from-sky-500 to-white border-sky-500 border bottom-0 left-0 h-full w-full overflow-hidden rounded-2xl object-cover"></div>
              <img
                src="images/Ads/background-layer.png"
                class="absolute bottom-0 left-0  opacity-40 h-full w-full overflow-hidden rounded-2xl object-cover"
              />
            </a>
          </div>
        </div>

        <div className="mb-4 flex h-auto w-full flex-col rounded-3xl ">
          <div className="  font-light dark:border-gray-700 dark:bg-gray-900">
            <Pubs />
          </div>
        </div>
      </div>
    </>
  );
}
export default AllPubs;
