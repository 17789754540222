import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Api from "../Api/Api";
import LoadingPage from "../LoadingPage.js/LoadingPage";
import { GlobalFunctionContext } from "../../context";

export default function UserProfilePopUp(props) {
  const Global = useContext(GlobalFunctionContext);

  const [loading, setLoading] = useState(false);
  return (
    <>
      {loading && <LoadingPage />}
      {Global.Pop ? (
        <div
          className="login-popup fixed inset-0 z-40 flex flex-col items-center justify-center"
          style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
        >
          <div className="relative -mb-5 flex w-[320px] md:w-[360px] items-end justify-end">
            <div
              onClick={() => Global.setUserPop(false)}
              className="close-login-popup h-8 w-8 rounded-full bg-sky-500 opacity-80 hover:bg-sky-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-8 w-8 text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
            </div>
          </div>
          <div className="flex  w-[320px] h-[550px] md:w-[360px]  flex-col items-center justify-center rounded-3xl bg-white text-center shadow-lg sm:text-left">
            <div className="row-span-2 flex flex-col w-full h-full items-center justify-center rounded-3xl bg-sky-50 dark:bg-gray-800">
              <div className="m-2 mt-5 flex w-full flex-col justify-end p-2">
                <div className="image">
                  {Global.Pop.profile_picture ? (
                    <img
                      className="w-[170px] h-[170px] mx-auto aspect-square rounded-full border-2 p-2 border-sky-300 dark:bg-gray-500"
                      src={`http://backend.novadiscova.server.cisabu.bi/uploads/PhotoUser/${Global.Pop.profile_picture}`}
                      alt={""}
                    />
                  ) : (
                    <div className="flex items-center justify-center w-[170px] h-[170px] mx-auto aspect-square rounded-full border-2  border-sky-300 dark:bg-gray-500">
                      <span class="text-sky-500 text-[130px] uppercase font-medium">
                        {Global.Pop.username?.substring(0, 1)}
                      </span>
                    </div>
                  )}
                  <div className="w-full flex justify-center items-end ">
                    <div className="absolute inline-flex h-8 w-8 items-center justify-center rounded-full border-2 border-white  bg-orange-500 text-xs font-bold text-white dark:border-gray-900">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-3 h-3"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                        />
                      </svg>
                      P
                    </div>
                  </div>
                </div>

                <div className="space-y-4 divide-y divide-sky-700 px-3 text-center">
                  <div className="w-full flex flex-col items-center justify-center">
                    <div className="my-3 space-y-1">
                      <h2 className="text-xl font-semibold text-sky-500">
                        {Global.Pop.username}
                      </h2>
                      <p className="px-5 text-xs text-sky-700 dark:text-gray-400">
                        {Global.Pop.comp_type}
                      </p>
                    </div>

                    <div className="flex flex-col items-center justify-center  rounded-3xl bg-sky-100 border border-sky-300 pt-2  w-4/5">
                      <div className="my-2 flex flex-row justify-center w-full border-b-2 border-sky-500">
                        <h2 className="text-md font-semibold text-sky-700 mb-2">
                          Contact Us
                        </h2>
                      </div>

                      <div className="my-2 flex flex-row items-center justify-between w-full px-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="100"
                          height="100"
                          viewBox="0 0 48 48"
                          className="h-6 w-6 text-gray-700"
                        >
                          <path
                            fill="#48b564"
                            d="M35.76,26.36h0.01c0,0-3.77,5.53-6.94,9.64c-2.74,3.55-3.54,6.59-3.77,8.06	C24.97,44.6,24.53,45,24,45s-0.97-0.4-1.06-0.94c-0.23-1.47-1.03-4.51-3.77-8.06c-0.42-0.55-0.85-1.12-1.28-1.7L28.24,22l8.33-9.88	C37.49,14.05,38,16.21,38,18.5C38,21.4,37.17,24.09,35.76,26.36z"
                          ></path>
                          <path
                            fill="#fcc60e"
                            d="M28.24,22L17.89,34.3c-2.82-3.78-5.66-7.94-5.66-7.94h0.01c-0.3-0.48-0.57-0.97-0.8-1.48L19.76,15	c-0.79,0.95-1.26,2.17-1.26,3.5c0,3.04,2.46,5.5,5.5,5.5C25.71,24,27.24,23.22,28.24,22z"
                          ></path>
                          <path
                            fill="#2c85eb"
                            d="M28.4,4.74l-8.57,10.18L13.27,9.2C15.83,6.02,19.69,4,24,4C25.54,4,27.02,4.26,28.4,4.74z"
                          ></path>
                          <path
                            fill="#ed5748"
                            d="M19.83,14.92L19.76,15l-8.32,9.88C10.52,22.95,10,20.79,10,18.5c0-3.54,1.23-6.79,3.27-9.3	L19.83,14.92z"
                          ></path>
                          <path
                            fill="#5695f6"
                            d="M28.24,22c0.79-0.95,1.26-2.17,1.26-3.5c0-3.04-2.46-5.5-5.5-5.5c-1.71,0-3.24,0.78-4.24,2L28.4,4.74	c3.59,1.22,6.53,3.91,8.17,7.38L28.24,22z"
                          ></path>
                        </svg>

                        <h2 className="text-sm font-semibold text-sky-700">
                          Rumonge, Burundi
                        </h2>
                      </div>
                      <div className="my-2 flex flex-row justify-between w-full px-4 ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="100"
                          height="100"
                          viewBox="0 0 48 48"
                          className="h-6 w-6 text-gray-700"
                        >
                          <path
                            fill="#0f0"
                            d="M13,42h22c3.866,0,7-3.134,7-7V13c0-3.866-3.134-7-7-7H13c-3.866,0-7,3.134-7,7v22	C6,38.866,9.134,42,13,42z"
                          ></path>
                          <path
                            fill="#fff"
                            d="M35.45,31.041l-4.612-3.051c-0.563-0.341-1.267-0.347-1.836-0.017c0,0,0,0-1.978,1.153	c-0.265,0.154-0.52,0.183-0.726,0.145c-0.262-0.048-0.442-0.191-0.454-0.201c-1.087-0.797-2.357-1.852-3.711-3.205	c-1.353-1.353-2.408-2.623-3.205-3.711c-0.009-0.013-0.153-0.193-0.201-0.454c-0.037-0.206-0.009-0.46,0.145-0.726	c1.153-1.978,1.153-1.978,1.153-1.978c0.331-0.569,0.324-1.274-0.017-1.836l-3.051-4.612c-0.378-0.571-1.151-0.722-1.714-0.332	c0,0-1.445,0.989-1.922,1.325c-0.764,0.538-1.01,1.356-1.011,2.496c-0.002,1.604,1.38,6.629,7.201,12.45l0,0l0,0l0,0l0,0	c5.822,5.822,10.846,7.203,12.45,7.201c1.14-0.001,1.958-0.248,2.496-1.011c0.336-0.477,1.325-1.922,1.325-1.922	C36.172,32.192,36.022,31.419,35.45,31.041z"
                          ></path>
                        </svg>
                        <h2 className="text-sm font-semibold text-sky-700">
                          <a href={`tel:${Global.Pop.phone}`}>
                            {" "}
                            {Global.Pop.phone}
                          </a>
                        </h2>
                      </div>
                      <div className="my-2 flex flex-row items-center justify-between w-full px-4 ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="100"
                          height="100"
                          viewBox="0 0 48 48"
                          className="h-6 w-6 text-gray-700"
                        >
                          <path
                            fill="#fff"
                            d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
                          ></path>
                          <path
                            fill="#fff"
                            d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
                          ></path>
                          <path
                            fill="#cfd8dc"
                            d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
                          ></path>
                          <path
                            fill="#40c351"
                            d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
                          ></path>
                          <path
                            fill="#fff"
                            fill-rule="evenodd"
                            d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <h2 className="text-sm font-semibold text-sky-700">
                          <a href={`tel:${Global.Pop.phone}`}>
                            {" "}
                            {Global.Pop.phone}
                          </a>
                        </h2>
                      </div>
                    </div>

                    <div className="flex hidden items-center justify-center space-x-4 rounded-b-3xl bg-sky-100 pt-2  w-2/3">
                      <a
                        rel="noopener noreferrer"
                        href="#"
                        className="rounded-lg p-2 text-white hover:bg-sky-200"
                      >
                        <img
                          src="https://img.icons8.com/ios/256/facebook-new.png"
                          className="h-6 w-6"
                        />
                      </a>
                      <a
                        rel="noopener noreferrer"
                        href="#"
                        aria-label="Dribble"
                        className="rounded-md p-2 hover:bg-sky-200"
                      >
                        <img
                          src="https://img.icons8.com/ios/256/twitter.png"
                          className="h-6 w-6"
                        />
                      </a>
                      <a
                        rel="noopener noreferrer"
                        href="#"
                        aria-label="Twitter"
                        className="rounded-md p-2 hover:bg-sky-200 dark:text-gray-100 hover:dark:text-violet-400"
                      >
                        <img
                          src="https://img.icons8.com/ios/256/instagram-new.png"
                          className="h-6 w-6"
                        />
                      </a>
                      <a
                        rel="noopener noreferrer"
                        href="#"
                        aria-label="Email"
                        className="rounded-md p-2 hover:bg-sky-200 dark:text-gray-100 hover:dark:text-violet-400"
                      >
                        <img
                          src="https://img.icons8.com/ios/256/tiktok.png"
                          className="h-6 w-6"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full  flex flex-col justify-center items-end ">
                <div className="space-x-2 inline-flex px-2 py-3 w-full  items-center justify-center rounded-t-2xl border-2 border-white  bg-sky-50 text-sm font-semibold text-sky-500 dark:border-gray-900">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                    />
                  </svg>
                  <span> Your Order/Alert Sent Successfully !!</span>
                </div>
                <div className="space-x-2 inline-flex px-2 py-3 w-full  items-center justify-center rounded-b-2xl border-2 border-white  bg-sky-100 text-sm font-thin text-sky-500 dark:border-gray-900">
                  <span> Feel Free to Contact the Seller/Client Directly</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
