import React from 'react'
import EventWaitList from '../components/EventWaitList/EventWaitList'

export default function WaitListPage() {
  return (
    <div>
      <EventWaitList />
    </div>
  )
}
