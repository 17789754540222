import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { NotifyContext } from "../../context";
// import countries from "./countries";
const countries = [
  {
    id: "AF",
    name_country: "Afghanistan",
    url: "https://flagsapi.com/AF/flat/64.png",
  },
  {
    id: "AX",
    name_country: "Aland Islands",
    url: "https://flagsapi.com/AX/flat/64.png",
  },
  {
    id: "AL",
    name_country: "Albania",
    url: "https://flagsapi.com/AL/flat/64.png",
  },
  {
    id: "DZ",
    name_country: "Algeria",
    url: "https://flagsapi.com/DZ/flat/64.png",
  },
  {
    id: "AS",
    name_country: "American Samoa",
    url: "https://flagsapi.com/AS/flat/64.png",
  },
  {
    id: "AD",
    name_country: "Andorra",
    url: "https://flagsapi.com/AD/flat/64.png",
  },
  {
    id: "AO",
    name_country: "Angola",
    url: "https://flagsapi.com/AO/flat/64.png",
  },
  {
    id: "AI",
    name_country: "Anguilla",
    url: "https://flagsapi.com/AI/flat/64.png",
  },
  {
    id: "AQ",
    name_country: "Antarctica",
    url: "https://flagsapi.com/AQ/flat/64.png",
  },
  {
    id: "AG",
    name_country: "Antigua And Barbuda",
    url: "https://flagsapi.com/AG/flat/64.png",
  },
  {
    id: "AR",
    name_country: "Argentina",
    url: "https://flagsapi.com/AR/flat/64.png",
  },
  {
    id: "AM",
    name_country: "Armenia",
    url: "https://flagsapi.com/AM/flat/64.png",
  },
  {
    id: "AW",
    name_country: "Aruba",
    url: "https://flagsapi.com/AW/flat/64.png",
  },
  {
    id: "AU",
    name_country: "Australia",
    url: "https://flagsapi.com/AU/flat/64.png",
  },
  {
    id: "AT",
    name_country: "Austria",
    url: "https://flagsapi.com/AT/flat/64.png",
  },
  {
    id: "AZ",
    name_country: "Azerbaijan",
    url: "https://flagsapi.com/AZ/flat/64.png",
  },
  {
    id: "BS",
    name_country: "Bahamas",
    url: "https://flagsapi.com/BS/flat/64.png",
  },
  {
    id: "BH",
    name_country: "Bahrain",
    url: "https://flagsapi.com/BH/flat/64.png",
  },
  {
    id: "BD",
    name_country: "Bangladesh",
    url: "https://flagsapi.com/BD/flat/64.png",
  },
  {
    id: "BB",
    name_country: "Barbados",
    url: "https://flagsapi.com/BB/flat/64.png",
  },
  {
    id: "BY",
    name_country: "Belarus",
    url: "https://flagsapi.com/BY/flat/64.png",
  },
  {
    id: "BE",
    name_country: "Belgium",
    url: "https://flagsapi.com/BE/flat/64.png",
  },
  {
    id: "BZ",
    name_country: "Belize",
    url: "https://flagsapi.com/BZ/flat/64.png",
  },
  {
    id: "BJ",
    name_country: "Benin",
    url: "https://flagsapi.com/BJ/flat/64.png",
  },
  {
    id: "BM",
    name_country: "Bermuda",
    url: "https://flagsapi.com/BM/flat/64.png",
  },
  {
    id: "BT",
    name_country: "Bhutan",
    url: "https://flagsapi.com/BT/flat/64.png",
  },
  {
    id: "BO",
    name_country: "Bolivia",
    url: "https://flagsapi.com/BO/flat/64.png",
  },
  {
    id: "BA",
    name_country: "Bosnia And Herzegovina",
    url: "https://flagsapi.com/BA/flat/64.png",
  },
  {
    id: "BW",
    name_country: "Botswana",
    url: "https://flagsapi.com/BW/flat/64.png",
  },
  {
    id: "BV",
    name_country: "Bouvet Island",
    url: "https://flagsapi.com/BV/flat/64.png",
  },
  {
    id: "BR",
    name_country: "Brazil",
    url: "https://flagsapi.com/BR/flat/64.png",
  },
  {
    id: "IO",
    name_country: "British Indian Ocean Territory",
    url: "https://flagsapi.com/IO/flat/64.png",
  },
  {
    id: "BN",
    name_country: "Brunei Darussalam",
    url: "https://flagsapi.com/BN/flat/64.png",
  },
  {
    id: "BG",
    name_country: "Bulgaria",
    url: "https://flagsapi.com/BG/flat/64.png",
  },
  {
    id: "BF",
    name_country: "Burkina Faso",
    url: "https://flagsapi.com/BF/flat/64.png",
  },
  {
    id: "BI",
    name_country: "Burundi",
    url: "https://flagsapi.com/BI/flat/64.png",
  },
  {
    id: "KH",
    name_country: "Cambodia",
    url: "https://flagsapi.com/KH/flat/64.png",
  },
  {
    id: "CM",
    name_country: "Cameroon",
    url: "https://flagsapi.com/CM/flat/64.png",
  },
  {
    id: "CA",
    name_country: "Canada",
    url: "https://flagsapi.com/CA/flat/64.png",
  },
  {
    id: "CV",
    name_country: "Cape Verde",
    url: "https://flagsapi.com/CV/flat/64.png",
  },
  {
    id: "KY",
    name_country: "Cayman Islands",
    url: "https://flagsapi.com/KY/flat/64.png",
  },
  {
    id: "CF",
    name_country: "Central African Republic",
    url: "https://flagsapi.com/CF/flat/64.png",
  },
  {
    id: "TD",
    name_country: "Chad",
    url: "https://flagsapi.com/TD/flat/64.png",
  },
  {
    id: "CL",
    name_country: "Chile",
    url: "https://flagsapi.com/CL/flat/64.png",
  },
  {
    id: "CN",
    name_country: "China",
    url: "https://flagsapi.com/CN/flat/64.png",
  },
  {
    id: "CX",
    name_country: "Christmas Island",
    url: "https://flagsapi.com/CX/flat/64.png",
  },
  {
    id: "CC",
    name_country: "Cocos (Keeling) Islands",
    url: "https://flagsapi.com/CC/flat/64.png",
  },
  {
    id: "CO",
    name_country: "Colombia",
    url: "https://flagsapi.com/CO/flat/64.png",
  },
  {
    id: "KM",
    name_country: "Comoros",
    url: "https://flagsapi.com/KM/flat/64.png",
  },
  {
    id: "CG",
    name_country: "Congo",
    url: "https://flagsapi.com/CG/flat/64.png",
  },
  {
    id: "CD",
    name_country: "Congo, Democratic Republic",
    url: "https://flagsapi.com/CD/flat/64.png",
  },
  {
    id: "CK",
    name_country: "Cook Islands",
    url: "https://flagsapi.com/CK/flat/64.png",
  },
  {
    id: "CR",
    name_country: "Costa Rica",
    url: "https://flagsapi.com/CR/flat/64.png",
  },
  {
    id: "CI",
    name_country: 'Cote D"Ivoire',
    url: "https://flagsapi.com/CI/flat/64.png",
  },
  {
    id: "HR",
    name_country: "Croatia",
    url: "https://flagsapi.com/HR/flat/64.png",
  },
  {
    id: "CU",
    name_country: "Cuba",
    url: "https://flagsapi.com/CU/flat/64.png",
  },
  {
    id: "CY",
    name_country: "Cyprus",
    url: "https://flagsapi.com/CY/flat/64.png",
  },
  {
    id: "CZ",
    name_country: "Czech Republic",
    url: "https://flagsapi.com/CZ/flat/64.png",
  },
  {
    id: "DK",
    name_country: "Denmark",
    url: "https://flagsapi.com/DK/flat/64.png",
  },
  {
    id: "DJ",
    name_country: "Djibouti",
    url: "https://flagsapi.com/DJ/flat/64.png",
  },
  {
    id: "DM",
    name_country: "Dominica",
    url: "https://flagsapi.com/DM/flat/64.png",
  },
  {
    id: "DO",
    name_country: "Dominican Republic",
    url: "https://flagsapi.com/DO/flat/64.png",
  },
  {
    id: "EC",
    name_country: "Ecuador",
    url: "https://flagsapi.com/EC/flat/64.png",
  },
  {
    id: "EG",
    name_country: "Egypt",
    url: "https://flagsapi.com/EG/flat/64.png",
  },
  {
    id: "SV",
    name_country: "El Salvador",
    url: "https://flagsapi.com/SV/flat/64.png",
  },
  {
    id: "GQ",
    name_country: "Equatorial Guinea",
    url: "https://flagsapi.com/GQ/flat/64.png",
  },
  {
    id: "ER",
    name_country: "Eritrea",
    url: "https://flagsapi.com/ER/flat/64.png",
  },
  {
    id: "EE",
    name_country: "Estonia",
    url: "https://flagsapi.com/EE/flat/64.png",
  },
  {
    id: "ET",
    name_country: "Ethiopia",
    url: "https://flagsapi.com/ET/flat/64.png",
  },
  {
    id: "FK",
    name_country: "Falkland Islands (Malvinas)",
    url: "https://flagsapi.com/FK/flat/64.png",
  },
  {
    id: "FO",
    name_country: "Faroe Islands",
    url: "https://flagsapi.com/FO/flat/64.png",
  },
  {
    id: "FJ",
    name_country: "Fiji",
    url: "https://flagsapi.com/FJ/flat/64.png",
  },
  {
    id: "FI",
    name_country: "Finland",
    url: "https://flagsapi.com/FI/flat/64.png",
  },
  {
    id: "FR",
    name_country: "France",
    url: "https://flagsapi.com/FR/flat/64.png",
  },
  {
    id: "GF",
    name_country: "French Guiana",
    url: "https://flagsapi.com/GF/flat/64.png",
  },
  {
    id: "PF",
    name_country: "French Polynesia",
    url: "https://flagsapi.com/PF/flat/64.png",
  },
  {
    id: "TF",
    name_country: "French Southern Territories",
    url: "https://flagsapi.com/TF/flat/64.png",
  },
  {
    id: "GA",
    name_country: "Gabon",
    url: "https://flagsapi.com/GA/flat/64.png",
  },
  {
    id: "GM",
    name_country: "Gambia",
    url: "https://flagsapi.com/GM/flat/64.png",
  },
  {
    id: "GE",
    name_country: "Georgia",
    url: "https://flagsapi.com/GE/flat/64.png",
  },
  {
    id: "DE",
    name_country: "Germany",
    url: "https://flagsapi.com/DE/flat/64.png",
  },
  {
    id: "GH",
    name_country: "Ghana",
    url: "https://flagsapi.com/GH/flat/64.png",
  },
  {
    id: "GI",
    name_country: "Gibraltar",
    url: "https://flagsapi.com/GI/flat/64.png",
  },
  {
    id: "GR",
    name_country: "Greece",
    url: "https://flagsapi.com/GR/flat/64.png",
  },
  {
    id: "GL",
    name_country: "Greenland",
    url: "https://flagsapi.com/GL/flat/64.png",
  },
  {
    id: "GD",
    name_country: "Grenada",
    url: "https://flagsapi.com/GD/flat/64.png",
  },
  {
    id: "GP",
    name_country: "Guadeloupe",
    url: "https://flagsapi.com/GP/flat/64.png",
  },
  {
    id: "GU",
    name_country: "Guam",
    url: "https://flagsapi.com/GU/flat/64.png",
  },
  {
    id: "GT",
    name_country: "Guatemala",
    url: "https://flagsapi.com/GT/flat/64.png",
  },
  {
    id: "GG",
    name_country: "Guernsey",
    url: "https://flagsapi.com/GG/flat/64.png",
  },
  {
    id: "GN",
    name_country: "Guinea",
    url: "https://flagsapi.com/GN/flat/64.png",
  },
  {
    id: "GW",
    name_country: "Guinea-Bissau",
    url: "https://flagsapi.com/GW/flat/64.png",
  },
  {
    id: "GY",
    name_country: "Guyana",
    url: "https://flagsapi.com/GY/flat/64.png",
  },
  {
    id: "HT",
    name_country: "Haiti",
    url: "https://flagsapi.com/HT/flat/64.png",
  },
  {
    id: "HM",
    name_country: "Heard Island & Mcdonald Islands",
    url: "https://flagsapi.com/HM/flat/64.png",
  },
  {
    id: "VA",
    name_country: "Holy See (Vatican City State)",
    url: "https://flagsapi.com/VA/flat/64.png",
  },
  {
    id: "HN",
    name_country: "Honduras",
    url: "https://flagsapi.com/HN/flat/64.png",
  },
  {
    id: "HK",
    name_country: "Hong Kong",
    url: "https://flagsapi.com/HK/flat/64.png",
  },
  {
    id: "HU",
    name_country: "Hungary",
    url: "https://flagsapi.com/HU/flat/64.png",
  },
  {
    id: "IS",
    name_country: "Iceland",
    url: "https://flagsapi.com/IS/flat/64.png",
  },
  {
    id: "IN",
    name_country: "India",
    url: "https://flagsapi.com/IN/flat/64.png",
  },
  {
    id: "ID",
    name_country: "Indonesia",
    url: "https://flagsapi.com/ID/flat/64.png",
  },
  {
    id: "IR",
    name_country: "Iran, Islamic Republic Of",
    url: "https://flagsapi.com/IR/flat/64.png",
  },
  {
    id: "IQ",
    name_country: "Iraq",
    url: "https://flagsapi.com/IQ/flat/64.png",
  },
  {
    id: "IE",
    name_country: "Ireland",
    url: "https://flagsapi.com/IE/flat/64.png",
  },
  {
    id: "IM",
    name_country: "Isle Of Man",
    url: "https://flagsapi.com/IM/flat/64.png",
  },
  {
    id: "IL",
    name_country: "Israel",
    url: "https://flagsapi.com/IL/flat/64.png",
  },
  {
    id: "IT",
    name_country: "Italy",
    url: "https://flagsapi.com/IT/flat/64.png",
  },
  {
    id: "JM",
    name_country: "Jamaica",
    url: "https://flagsapi.com/JM/flat/64.png",
  },
  {
    id: "JP",
    name_country: "Japan",
    url: "https://flagsapi.com/JP/flat/64.png",
  },
  {
    id: "JE",
    name_country: "Jersey",
    url: "https://flagsapi.com/JE/flat/64.png",
  },
  {
    id: "JO",
    name_country: "Jordan",
    url: "https://flagsapi.com/JO/flat/64.png",
  },
  {
    id: "KZ",
    name_country: "Kazakhstan",
    url: "https://flagsapi.com/KZ/flat/64.png",
  },
  {
    id: "KE",
    name_country: "Kenya",
    url: "https://flagsapi.com/KE/flat/64.png",
  },
  {
    id: "KI",
    name_country: "Kiribati",
    url: "https://flagsapi.com/KI/flat/64.png",
  },
  {
    id: "KR",
    name_country: "Korea",
    url: "https://flagsapi.com/KR/flat/64.png",
  },
  {
    id: "KP",
    name_country: "North Korea",
    url: "https://flagsapi.com/KP/flat/64.png",
  },
  {
    id: "KW",
    name_country: "Kuwait",
    url: "https://flagsapi.com/KW/flat/64.png",
  },
  {
    id: "KG",
    name_country: "Kyrgyzstan",
    url: "https://flagsapi.com/KG/flat/64.png",
  },
  {
    id: "LA",
    name_country: 'Lao People"s Democratic Republic',
    url: "https://flagsapi.com/LA/flat/64.png",
  },
  {
    id: "LV",
    name_country: "Latvia",
    url: "https://flagsapi.com/LV/flat/64.png",
  },
  {
    id: "LB",
    name_country: "Lebanon",
    url: "https://flagsapi.com/LB/flat/64.png",
  },
  {
    id: "LS",
    name_country: "Lesotho",
    url: "https://flagsapi.com/LS/flat/64.png",
  },
  {
    id: "LR",
    name_country: "Liberia",
    url: "https://flagsapi.com/LR/flat/64.png",
  },
  {
    id: "LY",
    name_country: "Libyan Arab Jamahiriya",
    url: "https://flagsapi.com/LY/flat/64.png",
  },
  {
    id: "LI",
    name_country: "Liechtenstein",
    url: "https://flagsapi.com/LI/flat/64.png",
  },
  {
    id: "LT",
    name_country: "Lithuania",
    url: "https://flagsapi.com/LT/flat/64.png",
  },
  {
    id: "LU",
    name_country: "Luxembourg",
    url: "https://flagsapi.com/LU/flat/64.png",
  },
  {
    id: "MO",
    name_country: "Macao",
    url: "https://flagsapi.com/MO/flat/64.png",
  },
  {
    id: "MK",
    name_country: "Macedonia",
    url: "https://flagsapi.com/MK/flat/64.png",
  },
  {
    id: "MG",
    name_country: "Madagascar",
    url: "https://flagsapi.com/MG/flat/64.png",
  },
  {
    id: "MW",
    name_country: "Malawi",
    url: "https://flagsapi.com/MW/flat/64.png",
  },
  {
    id: "MY",
    name_country: "Malaysia",
    url: "https://flagsapi.com/MY/flat/64.png",
  },
  {
    id: "MV",
    name_country: "Maldives",
    url: "https://flagsapi.com/MV/flat/64.png",
  },
  {
    id: "ML",
    name_country: "Mali",
    url: "https://flagsapi.com/ML/flat/64.png",
  },
  {
    id: "MT",
    name_country: "Malta",
    url: "https://flagsapi.com/MT/flat/64.png",
  },
  {
    id: "MH",
    name_country: "Marshall Islands",
    url: "https://flagsapi.com/MH/flat/64.png",
  },
  {
    id: "MQ",
    name_country: "Martinique",
    url: "https://flagsapi.com/MQ/flat/64.png",
  },
  {
    id: "MR",
    name_country: "Mauritania",
    url: "https://flagsapi.com/MR/flat/64.png",
  },
  {
    id: "MU",
    name_country: "Mauritius",
    url: "https://flagsapi.com/MU/flat/64.png",
  },
  {
    id: "YT",
    name_country: "Mayotte",
    url: "https://flagsapi.com/YT/flat/64.png",
  },
  {
    id: "MX",
    name_country: "Mexico",
    url: "https://flagsapi.com/MX/flat/64.png",
  },
  {
    id: "FM",
    name_country: "Micronesia, Federated States Of",
    url: "https://flagsapi.com/FM/flat/64.png",
  },
  {
    id: "MD",
    name_country: "Moldova",
    url: "https://flagsapi.com/MD/flat/64.png",
  },
  {
    id: "MC",
    name_country: "Monaco",
    url: "https://flagsapi.com/MC/flat/64.png",
  },
  {
    id: "MN",
    name_country: "Mongolia",
    url: "https://flagsapi.com/MN/flat/64.png",
  },
  {
    id: "ME",
    name_country: "Montenegro",
    url: "https://flagsapi.com/ME/flat/64.png",
  },
  {
    id: "MS",
    name_country: "Montserrat",
    url: "https://flagsapi.com/MS/flat/64.png",
  },
  {
    id: "MA",
    name_country: "Morocco",
    url: "https://flagsapi.com/MA/flat/64.png",
  },
  {
    id: "MZ",
    name_country: "Mozambique",
    url: "https://flagsapi.com/MZ/flat/64.png",
  },
  {
    id: "MM",
    name_country: "Myanmar",
    url: "https://flagsapi.com/MM/flat/64.png",
  },
  {
    id: "NA",
    name_country: "Namibia",
    url: "https://flagsapi.com/NA/flat/64.png",
  },
  {
    id: "NR",
    name_country: "Nauru",
    url: "https://flagsapi.com/NR/flat/64.png",
  },
  {
    id: "NP",
    name_country: "Nepal",
    url: "https://flagsapi.com/NP/flat/64.png",
  },
  {
    id: "NL",
    name_country: "Netherlands",
    url: "https://flagsapi.com/NL/flat/64.png",
  },
  {
    id: "AN",
    name_country: "Netherlands Antilles",
    url: "https://flagsapi.com/AN/flat/64.png",
  },
  {
    id: "NC",
    name_country: "New Caledonia",
    url: "https://flagsapi.com/NC/flat/64.png",
  },
  {
    id: "NZ",
    name_country: "New Zealand",
    url: "https://flagsapi.com/NZ/flat/64.png",
  },
  {
    id: "NI",
    name_country: "Nicaragua",
    url: "https://flagsapi.com/NI/flat/64.png",
  },
  {
    id: "NE",
    name_country: "Niger",
    url: "https://flagsapi.com/NE/flat/64.png",
  },
  {
    id: "NG",
    name_country: "Nigeria",
    url: "https://flagsapi.com/NG/flat/64.png",
  },
  {
    id: "NU",
    name_country: "Niue",
    url: "https://flagsapi.com/NU/flat/64.png",
  },
  {
    id: "NF",
    name_country: "Norfolk Island",
    url: "https://flagsapi.com/NF/flat/64.png",
  },
  {
    id: "MP",
    name_country: "Northern Mariana Islands",
    url: "https://flagsapi.com/MP/flat/64.png",
  },
  {
    id: "NO",
    name_country: "Norway",
    url: "https://flagsapi.com/NO/flat/64.png",
  },
  {
    id: "OM",
    name_country: "Oman",
    url: "https://flagsapi.com/OM/flat/64.png",
  },
  {
    id: "PK",
    name_country: "Pakistan",
    url: "https://flagsapi.com/PK/flat/64.png",
  },
  {
    id: "PW",
    name_country: "Palau",
    url: "https://flagsapi.com/PW/flat/64.png",
  },
  {
    id: "PS",
    name_country: "Palestinian Territory, Occupied",
    url: "https://flagsapi.com/PS/flat/64.png",
  },
  {
    id: "PA",
    name_country: "Panama",
    url: "https://flagsapi.com/PA/flat/64.png",
  },
  {
    id: "PG",
    name_country: "Papua New Guinea",
    url: "https://flagsapi.com/PG/flat/64.png",
  },
  {
    id: "PY",
    name_country: "Paraguay",
    url: "https://flagsapi.com/PY/flat/64.png",
  },
  {
    id: "PE",
    name_country: "Peru",
    url: "https://flagsapi.com/PE/flat/64.png",
  },
  {
    id: "PH",
    name_country: "Philippines",
    url: "https://flagsapi.com/PH/flat/64.png",
  },
  {
    id: "PN",
    name_country: "Pitcairn",
    url: "https://flagsapi.com/PN/flat/64.png",
  },
  {
    id: "PL",
    name_country: "Poland",
    url: "https://flagsapi.com/PL/flat/64.png",
  },
  {
    id: "PT",
    name_country: "Portugal",
    url: "https://flagsapi.com/PT/flat/64.png",
  },
  {
    id: "PR",
    name_country: "Puerto Rico",
    url: "https://flagsapi.com/PR/flat/64.png",
  },
  {
    id: "QA",
    name_country: "Qatar",
    url: "https://flagsapi.com/QA/flat/64.png",
  },
  {
    id: "RE",
    name_country: "Reunion",
    url: "https://flagsapi.com/RE/flat/64.png",
  },
  {
    id: "RO",
    name_country: "Romania",
    url: "https://flagsapi.com/RO/flat/64.png",
  },
  {
    id: "RU",
    name_country: "Russian Federation",
    url: "https://flagsapi.com/RU/flat/64.png",
  },
  {
    id: "RW",
    name_country: "Rwanda",
    url: "https://flagsapi.com/RW/flat/64.png",
  },
  {
    id: "BL",
    name_country: "Saint Barthelemy",
    url: "https://flagsapi.com/BL/flat/64.png",
  },
  {
    id: "SH",
    name_country: "Saint Helena",
    url: "https://flagsapi.com/SH/flat/64.png",
  },
  {
    id: "KN",
    name_country: "Saint Kitts And Nevis",
    url: "https://flagsapi.com/KN/flat/64.png",
  },
  {
    id: "LC",
    name_country: "Saint Lucia",
    url: "https://flagsapi.com/LC/flat/64.png",
  },
  {
    id: "MF",
    name_country: "Saint Martin",
    url: "https://flagsapi.com/MF/flat/64.png",
  },
  {
    id: "PM",
    name_country: "Saint Pierre And Miquelon",
    url: "https://flagsapi.com/PM/flat/64.png",
  },
  {
    id: "VC",
    name_country: "Saint Vincent And Grenadines",
    url: "https://flagsapi.com/VC/flat/64.png",
  },
  {
    id: "WS",
    name_country: "Samoa",
    url: "https://flagsapi.com/WS/flat/64.png",
  },
  {
    id: "SM",
    name_country: "San Marino",
    url: "https://flagsapi.com/SM/flat/64.png",
  },
  {
    id: "ST",
    name_country: "Sao Tome And Principe",
    url: "https://flagsapi.com/ST/flat/64.png",
  },
  {
    id: "SA",
    name_country: "Saudi Arabia",
    url: "https://flagsapi.com/SA/flat/64.png",
  },
  {
    id: "SN",
    name_country: "Senegal",
    url: "https://flagsapi.com/SN/flat/64.png",
  },
  {
    id: "RS",
    name_country: "Serbia",
    url: "https://flagsapi.com/RS/flat/64.png",
  },
  {
    id: "SC",
    name_country: "Seychelles",
    url: "https://flagsapi.com/SC/flat/64.png",
  },
  {
    id: "SL",
    name_country: "Sierra Leone",
    url: "https://flagsapi.com/SL/flat/64.png",
  },
  {
    id: "SG",
    name_country: "Singapore",
    url: "https://flagsapi.com/SG/flat/64.png",
  },
  {
    id: "SK",
    name_country: "Slovakia",
    url: "https://flagsapi.com/SK/flat/64.png",
  },
  {
    id: "SI",
    name_country: "Slovenia",
    url: "https://flagsapi.com/SI/flat/64.png",
  },
  {
    id: "SB",
    name_country: "Solomon Islands",
    url: "https://flagsapi.com/SB/flat/64.png",
  },
  {
    id: "SO",
    name_country: "Somalia",
    url: "https://flagsapi.com/SO/flat/64.png",
  },
  {
    id: "ZA",
    name_country: "South Africa",
    url: "https://flagsapi.com/ZA/flat/64.png",
  },
  {
    id: "GS",
    name_country: "South Georgia And Sandwich Isl.",
    url: "https://flagsapi.com/GS/flat/64.png",
  },
  {
    id: "ES",
    name_country: "Spain",
    url: "https://flagsapi.com/ES/flat/64.png",
  },
  {
    id: "LK",
    name_country: "Sri Lanka",
    url: "https://flagsapi.com/LK/flat/64.png",
  },
  {
    id: "SD",
    name_country: "Sudan",
    url: "https://flagsapi.com/SD/flat/64.png",
  },
  {
    id: "SR",
    name_country: "Suriname",
    url: "https://flagsapi.com/SR/flat/64.png",
  },
  {
    id: "SJ",
    name_country: "Svalbard And Jan Mayen",
    url: "https://flagsapi.com/SJ/flat/64.png",
  },
  {
    id: "SZ",
    name_country: "Swaziland",
    url: "https://flagsapi.com/SZ/flat/64.png",
  },
  {
    id: "SE",
    name_country: "Sweden",
    url: "https://flagsapi.com/SE/flat/64.png",
  },
  {
    id: "CH",
    name_country: "Switzerland",
    url: "https://flagsapi.com/CH/flat/64.png",
  },
  {
    id: "SY",
    name_country: "Syrian Arab Republic",
    url: "https://flagsapi.com/SY/flat/64.png",
  },
  {
    id: "TW",
    name_country: "Taiwan",
    url: "https://flagsapi.com/TW/flat/64.png",
  },
  {
    id: "TJ",
    name_country: "Tajikistan",
    url: "https://flagsapi.com/TJ/flat/64.png",
  },
  {
    id: "TZ",
    name_country: "Tanzania",
    url: "https://flagsapi.com/TZ/flat/64.png",
  },
  {
    id: "TH",
    name_country: "Thailand",
    url: "https://flagsapi.com/TH/flat/64.png",
  },
  {
    id: "TL",
    name_country: "Timor-Leste",
    url: "https://flagsapi.com/TL/flat/64.png",
  },
  {
    id: "TG",
    name_country: "Togo",
    url: "https://flagsapi.com/TG/flat/64.png",
  },
  {
    id: "TK",
    name_country: "Tokelau",
    url: "https://flagsapi.com/TK/flat/64.png",
  },
  {
    id: "TO",
    name_country: "Tonga",
    url: "https://flagsapi.com/TO/flat/64.png",
  },
  {
    id: "TT",
    name_country: "Trinidad And Tobago",
    url: "https://flagsapi.com/TT/flat/64.png",
  },
  {
    id: "TN",
    name_country: "Tunisia",
    url: "https://flagsapi.com/TN/flat/64.png",
  },
  {
    id: "TR",
    name_country: "Turkey",
    url: "https://flagsapi.com/TR/flat/64.png",
  },
  {
    id: "TM",
    name_country: "Turkmenistan",
    url: "https://flagsapi.com/TM/flat/64.png",
  },
  {
    id: "TC",
    name_country: "Turks And Caicos Islands",
    url: "https://flagsapi.com/TC/flat/64.png",
  },
  {
    id: "TV",
    name_country: "Tuvalu",
    url: "https://flagsapi.com/TV/flat/64.png",
  },
  {
    id: "UG",
    name_country: "Uganda",
    url: "https://flagsapi.com/UG/flat/64.png",
  },
  {
    id: "UA",
    name_country: "Ukraine",
    url: "https://flagsapi.com/UA/flat/64.png",
  },
  {
    id: "AE",
    name_country: "United Arab Emirates",
    url: "https://flagsapi.com/AE/flat/64.png",
  },
  {
    id: "GB",
    name_country: "United Kingdom",
    url: "https://flagsapi.com/GB/flat/64.png",
  },
  {
    id: "US",
    name_country: "United States",
    url: "https://flagsapi.com/US/flat/64.png",
  },
  {
    id: "UM",
    name_country: "United States Outlying Islands",
    url: "https://flagsapi.com/UM/flat/64.png",
  },
  {
    id: "UY",
    name_country: "Uruguay",
    url: "https://flagsapi.com/UY/flat/64.png",
  },
  {
    id: "UZ",
    name_country: "Uzbekistan",
    url: "https://flagsapi.com/UZ/flat/64.png",
  },
  {
    id: "VU",
    name_country: "Vanuatu",
    url: "https://flagsapi.com/VU/flat/64.png",
  },
  {
    id: "VE",
    name_country: "Venezuela",
    url: "https://flagsapi.com/VE/flat/64.png",
  },
  {
    id: "VN",
    name_country: "Vietnam",
    url: "https://flagsapi.com/VN/flat/64.png",
  },
  {
    id: "VG",
    name_country: "Virgin Islands, British",
    url: "https://flagsapi.com/VG/flat/64.png",
  },
  {
    id: "VI",
    name_country: "Virgin Islands, U.S.",
    url: "https://flagsapi.com/VI/flat/64.png",
  },
  {
    id: "WF",
    name_country: "Wallis And Futuna",
    url: "https://flagsapi.com/WF/flat/64.png",
  },
  {
    id: "EH",
    name_country: "Western Sahara",
    url: "https://flagsapi.com/EH/flat/64.png",
  },
  {
    id: "YE",
    name_country: "Yemen",
    url: "https://flagsapi.com/YE/flat/64.png",
  },
  {
    id: "ZM",
    name_country: "Zambia",
    url: "https://flagsapi.com/ZM/flat/64.png",
  },
  {
    id: "ZW",
    name_country: "Zimbabwe",
    url: "https://flagsapi.com/ZW/flat/64.png",
  },
];

function ListingUserAside(props) {
  let pathname = props.pathname;
  let isHome = false;
  if (pathname == "/") isHome = true;
  const notifs = useContext(NotifyContext);
  let notreaded = notifs.notreaded;
  return (
    <>
      {isHome ? null : (
        <aside
          id="separator-sidebar"
          className="fixed left-0 z-40 h-auto w-64 -translate-x-full transition-transform sm:translate-x-0"
          aria-label="Sidebar"
        >
          <div className="w-full absolute mt-2 ml-3 flex items-end justify-end">
            <span className="ml-3  inline-flex h-3 w-3 place-items-end items-center justify-center rounded-full bg-blue-100 p-3 text-xl font-medium text-blue-800 dark:bg-blue-900 dark:text-blue-300 hover:scale-125 hover:duration-200">
              {" "}
              {">"}{" "}
            </span>
          </div>
          <div className="h-[740px]  rounded-r-3xl  bg-gray-50 border-r-2 px-3 py-4 dark:bg-gray-800">
            <ul className=" space-y-2 ">
              <li>
                {pathname == "/who" ? (
                  <Link
                    to="/who"
                    className="flex items-center rounded-lg border border-l-4 border-sky-600 p-2 text-base font-normal text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="h-6 w-6 text-sky-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                    >
                      <path d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"></path>
                    </svg>
                    <span className="ml-3 text-sky-500">Home</span>
                  </Link>
                ) : (
                  <Link
                    to="/who"
                    className="flex items-center rounded-lg p-2 text-base font-normal text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="h-6 w-6 text-sky-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                    >
                      <path d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"></path>
                    </svg>

                    <span className="ml-3 text-sky-500">Home</span>
                  </Link>
                )}
              </li>
              <hr className="my-8 h-px border-0 bg-gray-300 dark:bg-gray-700" />
            </ul>
            <ul className="h-[640px] space-y-2 overflow-y-scroll overflow-x-hidden hide-scroll ">
              {countries?.map((country) => (
                <li key={country.id}>
                  <Link
                    to="/who/browserhave"
                    className="flex items-center rounded-lg p-2 text-base font-normal text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    <img src={country.url} class="w-7 rounded-xl" />
                    <span className="ml-3 flex-1 whitespace-nowrap">
                      {country.name_country}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </aside>
      )}
    </>
  );
}
export default ListingUserAside;
