import React from "react";
import AllMyPostComponent from "../components/MyPosts/AllMyPostComponent";

function My_Posts() {
  return (
    <>
      <AllMyPostComponent />
    </>
  );
}
export default My_Posts;
