import React, { useContext } from "react";
import { PricingContext } from "../../context";
import { useNavigate } from "react-router-dom";

export default function UPricingSup() {
  const value = useContext(PricingContext);
  const navigate = useNavigate();
  return (
    <>
      <div className="w-full  space-y-4">
        <div className="grid grid-cols-1 mb-6 gap-5 p-2">
          {value.Pricing?.map((Pricing) => {
            return (
              <>
                <div
                  data-tab-btn={Pricing.id}
                  key={Pricing.id}
                  className=" w-auto flex flex-row items-center rounded-xl py-1 pr-1 h-auto swiper-slide-active"
                  role="group"
                  aria-label="1 / 21"
                >
                  <div className="mb-4 w-full">
                    <div className="rounded-lg border border-gray-200 bg-white active-btn px-4 py-5 text-left shadow dark:border-gray-700 dark:bg-gray-800">
                      <div className="flex flex-row justify-between rounded-xl bg-gray-200 px-2 py-2">
                        <div className="flex flex-row">
                          <button className="rounded-full border-2 border-gray-200 p-1">
                            <img
                              className="h-12 w-12 rounded-full"
                              src={`images/${Pricing.file}`}
                              alt="check-mark"
                            />
                          </button>
                          <div className="flex items-center justify-center">
                            <h2 className="text-2xl font-semibold text-gray-500 ml-2">
                              {Pricing.platform}
                            </h2>
                          </div>
                        </div>
                        <div className="hidden">
                          {" "}
                          {Pricing.platform_services.length > 0 ? (
                            <div className="flex flex-row space-x-3 items-center justify-center">
                              <span class="text-lg text-sky-600 font-semibold ">
                                {Pricing.platform_services.length} Services
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="inline-flex mt-3 items-center justify-center w-full">
                        <hr className="w-64 h-px my-2 bg-gray-200 border-0 dark:bg-gray-700" />
                        <span className="absolute px-3 font-normal text-sm text-gray-600 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">
                          Sharing
                        </span>
                      </div>
                      <ul className="mb-6 flex flex-col pt-2">
                        {Pricing.platform_services
                          ?.slice(0, 10)
                          .map((Pricing) => {
                            return (
                              <>
                                <li
                                  className="mb-2.5 flex items-center"
                                  key={Pricing.id}
                                >
                                  <img
                                    src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                    className="mr-4 dark:hidden"
                                    alt="check-mark"
                                  />
                                  <img
                                    src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                    className="mr-4 hidden dark:block"
                                    alt="check-mark"
                                  />
                                  <p className="text-base w-full font-normal flex  flex-row items-center justify-between  text-gray-800 dark:text-white">
                                    <span> {Pricing.title}</span>{" "}
                                    <span className="font-semibold mr-4 leading-6 text-base text-gray-600">
                                      {Pricing.price - Pricing.price * 0.1}
                                      <sub className="ml-1">fbu/100</sub>
                                    </span>
                                  </p>
                                </li>
                              </>
                            );
                          })}
                      </ul>
                      <p className="relative pl-3 text-base text-sky-700">
                        <span className="text-2xl font-semibold">Cash</span>
                        <sub className="text-lg font-light">fbu</sub>
                        <span className="text-lg font-light">/100</span>
                      </p>
                      <button
                        onClick={() => {
                          navigate(`pricing/${Pricing.platform}`);
                        }}
                        className="mt-5 w-full hidden rounded bg-gray-200 px-8 py-3 text-base font-semibold text-sky-700 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-sky-700 focus:ring-offset-2"
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}
