import React, { useContext } from "react";
import AllMessages from "./AllMessages";
import MessageDetail from "./MessageDetail";
import { MySmsContext } from "../../context";

export default function MyMessages() {
  const sms = useContext(MySmsContext);
  return (
    <>
      <div className="My_Contribution">
        <div className="mb-4 flex h-auto w-full flex-col border border-gray-200 rounded-3xl shadow-lg">
          {/* <!-- component --> */}
          <div
            className="flex flex-row h-full overflow-hidden antialiased text-gray-800"
            style={{ height: "calc(100vh - 110px)" }}
          >
            <AllMessages />
            <div className="flex flex-col h-full w-full bg-white rounded-3xl px-4 py-6">
              {sms.smsDetail ? <MessageDetail sms={sms.smsDetail} /> : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
