import React, { useContext } from "react";
import { Link } from "react-router-dom";
function Search({ searchwidth, openSearch }) {
  return (
    <>
      <section
        className={`search-popup fixed px-5 top-20 z-50 rounded-3xl py-2 ${searchwidth}`}
      >
        <div className="mx-auto border border-sky-200 bg-white bg-opacity-70 shadow-2xl max-w-screen-xl backdrop-blur-2xl overflow-hidden rounded-md p-4  lg:pb-4">
          <div className="auto mt-4 flex h-auto flex-row items-center justify-center   ">
            <div className="min-w-80 relative  w-full max-w-3xl resize-x overflow-hidden rounded-xl bg-gray-100  text-gray-800 shadow-lg">
              <div className="relative">
                <input
                  type="text"
                  id="password"
                  className="w-full rounded-xl border-2 border-gray-200 py-2 pl-3 pr-10 text-gray-600 transition-colors hover:border-gray-300 focus:border-sky-500 focus:outline-none"
                  placeholder="Search..."
                />
                <button className="   leading-0 absolute top-2 right-2 block h-7 items-center justify-center rounded-xl bg-sky-600 px-3 text-center text-xl text-white transition-colors hover:text-gray-300 focus:outline-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="rounded-lg ml-3 border border-sky-500">
              <button
                onClick={() => {
                  openSearch(false);
                }}
                className="Close_Search_popup flex h-8 w-8 items-center justify-center rounded-lg bg-gray-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6 text-sky-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="mt-6 mb-6">
            <div className="auto flex h-auto flex-col items-start justify-start   ">
              <span class="text-gray-700 text-md">Suggestions</span>
              <hr className="h-[2px] w-full my-4 bg-sky-500  dark:bg-gray-700 " />
              <ul className="h-full w-inherit flex flex-row items-start justify-start   gap-2">
                <li>
                  <Link
                    to="/trend"
                    className="flex h-9 items-center rounded-lg p-2 text-base font-normal text-gray-900 bg-sky-500 dark:text-white dark:hover:bg-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-3 h-3 text-white "
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                      />
                    </svg>
                    <span className="ml-2 text-white">Home sns,sksn</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/trend"
                    className="flex h-9 items-center rounded-lg p-2 text-base font-normal text-gray-900 bg-sky-500 dark:text-white dark:hover:bg-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-3 h-3 text-white "
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                      />
                    </svg>
                    <span className="ml-2 text-white">Home sns,sksn</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/trend"
                    className="flex h-9 items-center rounded-lg p-2 text-base font-normal text-gray-900 bg-sky-500 dark:text-white dark:hover:bg-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-3 h-3 text-white "
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                      />
                    </svg>
                    <span className="ml-2 text-white">Home sns,sksn</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/trend"
                    className="flex h-9 items-center rounded-lg p-2 text-base font-normal text-gray-900 bg-sky-500 dark:text-white dark:hover:bg-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-3 h-3 text-white "
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                      />
                    </svg>
                    <span className="ml-2 text-white">Home sns,sksn</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Search;
