import React from "react";
import ShareComponent from "../components/Share/ShareComponent";

export default function SharePage() {
  return (
    <>
      <ShareComponent />
    </>
  );
}
