import React from "react";
import AllMyAdsComponent from "../components/MyAds/AllMyAdsComponent";

function My_Ads() {
  return (
    <>
      <AllMyAdsComponent />
    </>
  );
}
export default My_Ads;
