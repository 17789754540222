import React, { useContext, useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingPage from "../LoadingPage.js/LoadingPage";
import Api from "../Api/Api";
import { useParams } from "react-router-dom";
import { PricingContext } from "../../context";

const validationSchema = Yup.object().shape({
  serviceTitle: Yup.string().required("Description is required"),
  serviceDescription: Yup.string().required("Description is required"),
  file: Yup.mixed(),
  price: Yup.string().required("Price is required"),
});

function Personal_Info({ formDatas, setFormDatas, setPage }) {
  const { id } = useParams();
  var value = useContext(PricingContext);
  const Pricing = useContext(PricingContext);
  useEffect(() => {
    Pricing.handleServiceDetail(id, formDatas.user_id);
  }, []);

  value = value.ServiceDetail;
  // console.log(value);
  const [photo, SetPhoto] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    setFieldValue("file", file);
    const Url = URL.createObjectURL(file);
    SetPhoto(Url);
  };

  const handleClose = () => {
    toast.dismiss();
  };
  const onSubmit = (values, { setSubmitting }) => {
    console.log(values);
    console.log(formDatas);
    setLoading(true);

    const formData = new FormData();
    formData.append(`user_id`, formDatas.user_id);
    formData.append(`service_id`, id);
    formData.append(`serviceTitle`, values.serviceTitle);
    formData.append(`file`, values.file);
    formData.append(`serviceDescription`, values.serviceDescription);
    formData.append(`price`, values.price);

    Api.post("api/updateservice", formData)
      .then((response) => {
        // Handle successful upload
        console.log(response.data.message);
        toast.success("Post added successfully!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClick: handleClose, // Utilisation de la référence à la fonction handleClose
        });
        setLoading(false);
        setSubmitting(false);
        setPage((currPage) => currPage + 1);
      })
      .catch((error) => {
        // Handle upload error
        toast.error(error.message);
        setLoading(false);
        setSubmitting(false);
      });
  };

  const initialValues = {
    serviceTitle: value?.title,
    serviceDescription: value?.description,
    price: parseInt(value?.price),
  };
  return (
    <>
      {value ? (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <div className="flex w-full flex-row items-center justify-center">
                  <div className="p-2 rounded-full border border-sky-500 text-sky-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      class="bi bi-postcard w-7 h-7"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2ZM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4Zm7.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7ZM2 5.5a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5ZM10.5 5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3ZM13 8h-2V6h2v2Z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="flex  flex-col">
                  <div className="w-full flex items-center justify-center">
                    <p className="mb-6 max-w-2xl font-medium text-sky-500 dark:text-gray-400 md:text-lg lg:mb-8 lg:text-2xl">
                      Edit a Service
                    </p>
                  </div>
                  <div className="grid w-full  grid-cols-1 gap-4 md:grid-cols-2">
                    <div className="col-span-2">
                      <div class="w-full flex flex-col space-y-3 ">
                        <div class="w-[100%] flex flex-col">
                          <Field
                            id="message"
                            name="serviceTitle"
                            class="block p-2.5 w-full text-xl text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-sky-500 focus:border-sky-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-sky-500 dark:focus:border-sky-500"
                            placeholder="Write Service Title here..."
                          />
                          <ErrorMessage
                            name="serviceTitle"
                            component="div"
                            className="text-left mt-2 text-red-500 text-sm"
                          />{" "}
                        </div>
                        <div class="w-[100%] flex flex-col">
                          <Field
                            as="textarea"
                            id="message"
                            rows="4"
                            name="serviceDescription"
                            class="block p-2.5 w-full text-xl text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-sky-500 focus:border-sky-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-sky-500 dark:focus:border-sky-500"
                            placeholder="Write your Service description here..."
                          />
                          <ErrorMessage
                            name="serviceDescription"
                            component="div"
                            className="text-left mt-2 text-red-500 text-sm"
                          />
                        </div>

                        <div class="w-[100%] flex flex-col">
                          <Field
                            id="message"
                            name="price"
                            class="block p-2.5 w-full text-xl text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-sky-500 focus:border-sky-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-sky-500 dark:focus:border-sky-500"
                            placeholder="Your Service Price here..."
                          />
                          <ErrorMessage
                            name="price"
                            component="div"
                            className="text-left mt-2 text-red-500 text-sm"
                          />{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div class=" border-gray-200 shadow-sm rounded-xl border-2 flex flex-row w-[100%] items-center justify-between  py-2 px-2 dark:border-gray-600">
                        <label
                          htmlFor="username"
                          className=" ml-3 block font-medium text-gray-700 dark:text-white"
                        >
                          Add to Your Post
                        </label>
                        <div className="">
                          <div class=" flex w-[px] items-center  ">
                            <div class="flex flex-row bg-gray-100 pl-0 space-x-1 py-1 border rounded-xl">
                              <label
                                htmlFor="username"
                                className=" ml-3 block font-medium text-gray-700 dark:text-white"
                              >
                                Image
                              </label>
                              <button
                                type="button"
                                class="inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                              >
                                <svg
                                  aria-hidden="true"
                                  class="w-4 h-4"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                    clip-rule="evenodd"
                                  ></path>
                                </svg>
                                <span class="sr-only">Upload image</span>
                              </button>
                            </div>
                            <input
                              type="file"
                              name="file"
                              accept="image/*"
                              class="absolute w-12 overflow-hidden px-4 opacity-0"
                              onChange={(event) =>
                                handleFileChange(event, setFieldValue)
                              }
                            />
                          </div>
                        </div>
                        <div class="flex flex-row bg-gray-100 pl-0 space-x-1 py-1 border rounded-xl">
                          <label
                            htmlFor="username"
                            className=" ml-3 block font-medium text-gray-700 dark:text-white"
                          >
                            Audio
                          </label>
                          <button
                            type="button"
                            class="inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              class="bi bi-music-note-beamed w-4 h-4"
                              viewBox="0 0 16 16"
                            >
                              <path d="M6 13c0 1.105-1.12 2-2.5 2S1 14.105 1 13c0-1.104 1.12-2 2.5-2s2.5.896 2.5 2zm9-2c0 1.105-1.12 2-2.5 2s-2.5-.895-2.5-2 1.12-2 2.5-2 2.5.895 2.5 2z" />
                              <path
                                fill-rule="evenodd"
                                d="M14 11V2h1v9h-1zM6 3v10H5V3h1z"
                              />
                              <path d="M5 2.905a1 1 0 0 1 .9-.995l8-.8a1 1 0 0 1 1.1.995V3L5 4V2.905z" />
                            </svg>
                            <span class="sr-only">Upload image</span>
                          </button>
                        </div>
                        <div class="flex flex-row bg-gray-100 pl-0 space-x-1 py-1 border rounded-xl">
                          <label
                            htmlFor="username"
                            className=" ml-3 block font-medium text-gray-700 dark:text-white"
                          >
                            Video
                          </label>
                          <button
                            type="button"
                            class="inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-4 h-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h1.5C5.496 19.5 6 18.996 6 18.375m-3.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-1.5A1.125 1.125 0 0118 18.375M20.625 4.5H3.375m17.25 0c.621 0 1.125.504 1.125 1.125M20.625 4.5h-1.5C18.504 4.5 18 5.004 18 5.625m3.75 0v1.5c0 .621-.504 1.125-1.125 1.125M3.375 4.5c-.621 0-1.125.504-1.125 1.125M3.375 4.5h1.5C5.496 4.5 6 5.004 6 5.625m-3.75 0v1.5c0 .621.504 1.125 1.125 1.125m0 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m1.5-3.75C5.496 8.25 6 7.746 6 7.125v-1.5M4.875 8.25C5.496 8.25 6 8.754 6 9.375v1.5m0-5.25v5.25m0-5.25C6 5.004 6.504 4.5 7.125 4.5h9.75c.621 0 1.125.504 1.125 1.125m1.125 2.625h1.5m-1.5 0A1.125 1.125 0 0118 7.125v-1.5m1.125 2.625c-.621 0-1.125.504-1.125 1.125v1.5m2.625-2.625c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125M18 5.625v5.25M7.125 12h9.75m-9.75 0A1.125 1.125 0 016 10.875M7.125 12C6.504 12 6 12.504 6 13.125m0-2.25C6 11.496 5.496 12 4.875 12M18 10.875c0 .621-.504 1.125-1.125 1.125M18 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m-12 5.25v-5.25m0 5.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125m-12 0v-1.5c0-.621-.504-1.125-1.125-1.125M18 18.375v-5.25m0 5.25v-1.5c0-.621.504-1.125 1.125-1.125M18 13.125v1.5c0 .621.504 1.125 1.125 1.125M18 13.125c0-.621.504-1.125 1.125-1.125M6 13.125v1.5c0 .621-.504 1.125-1.125 1.125M6 13.125C6 12.504 5.496 12 4.875 12m-1.5 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M19.125 12h1.5m0 0c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h1.5m14.25 0h1.5"
                              />
                            </svg>

                            <span class="sr-only">Upload image</span>
                          </button>
                        </div>
                        <div class="flex flex-row bg-gray-100 pl-0 space-x-1 py-1 border rounded-xl">
                          <label
                            htmlFor="username"
                            className=" ml-3 block font-medium text-gray-700 dark:text-white"
                          >
                            Emoji
                          </label>
                          <button
                            type="button"
                            class="inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              class="bi bi-emoji-smile w-4 h-4"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
                            </svg>
                            <span class="sr-only">Upload image</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ErrorMessage
                    name="file"
                    component="div"
                    className="text-left mt-2 text-red-500 text-sm"
                  />
                  {photo ? (
                    <div class="w-full flex items-center justify-center">
                      <div className="w-[15em] h-[15em] mt-8 overflow-hidden">
                        <img
                          src={photo}
                          alt=" "
                          className="w-full h-full object-cover rounded-2xl border border-sky-500"
                        />
                      </div>
                    </div>
                  ) : (
                    <div class="w-full flex items-center justify-center">
                      <div className="w-[15em] h-[15em] mt-8 overflow-hidden">
                        <img
                          src={`http://backend.novadiscova.server.cisabu.bi/uploads/MediaService/${value.file}`}
                          alt=" "
                          className="w-full h-full object-cover border border-sky-500 rounded-2xl"
                        />
                      </div>
                    </div>
                  )}
                  <div className="mb-5 mt-5 flex w-full items-center justify-center">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="w-1/2 rounded-xl border-0 bg-sky-500 px-6 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none"
                    >
                      {isSubmitting ? "Continue..." : "Continue"}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <ToastContainer />
        </>
      ) : null}
    </>
  );
}

export default Personal_Info;
