export const SugesDada = [
    {
        id: 0,
        name: "All",
        },
    {
      id: 1,
      name: "Music",
      },
    {
        id: 2,
        name: "Sports",
    },
    {
        id: 3,
        name: "Films",
        },
    {
        id: 4,
        name: "Education",
    },
    {
        id: 5,
        name: "Fashion",
    },
    {
        id: 6,
        name: "Arts",
    },{
        id: 7,
        name: "Shopping",
    },
    {
        id: 8,
        name: "Construction",
    },
    {
        id: 9,
        name: "Food",
    }]
    ;