import React from "react";
import PostInfos from "./PostInfos";
import TargetOfWork from "./TargetOfWork";
import PostToShare from "./PostToShare";
import SendFeedBack from "./SendFeedBack";

function PostDetail() {
  return (
    <>
      <PostInfos />
      <TargetOfWork />
    </>
  );
}
export default PostDetail;
