import React from 'react'
import WaitLists from './WaitLists'

function AllWaitLists() {
  return (
    <>
        <div className="-mr-4 flex h-full w-full flex-col py-4 pl-4 pr-4">
      <div className="flex flex-row items-center">
        <div className="flex flex-row items-center mr-2">
          <div className="inline-block text-xl text-sky-600 font-semibold">Events</div>
        </div>
        <div className="ml-auto">
          <div className="relative">
            <input type="text" id="password" className="w-full rounded-xl border-2 border-gray-200 py-2 pl-3 pr-10 text-gray-600 transition-colors hover:border-gray-300 focus:border-sky-500 focus:outline-none" placeholder="Search..." />
            <button className="leading-0 absolute top-2 right-2 block h-7 items-center justify-center rounded-xl bg-sky-600 px-3 text-center text-xl text-white transition-colors hover:text-gray-300 focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <WaitLists />
          
    </div>
    </>
  )
}
export default AllWaitLists