import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { NotifyContext } from "../../context";
import NotifItem from "./NotifItem";
import Api from "../Api/Api";
import { GlobalFunctionContext } from "../../context";
function NavbarLogged(props) {
  const id = localStorage.getItem("userid");
  const history = useNavigate();
  let pathname = props.pathname;
  let isWho = false;
  let Who = /^\/who.*$/;
  if (Who.test(pathname)) {
    isWho = true;
  } else {
    isWho = false;
  }
  const Global = useContext(GlobalFunctionContext);
  const notifs = useContext(NotifyContext);
  const [dropProf, setDropProf] = useState(false);
  const [dropNotif, setDropNotif] = useState(false);
  const [dropCreate, SetDropCreate] = useState(false);
  const [User, SetUser] = useState({});
  const handleDropProf = () => {
    if (dropProf) setDropProf(false);
    else setDropProf(true);
  };
  const handleDropCreate = () => {
    if (dropCreate) SetDropCreate(false);
    else SetDropCreate(true);
  };
  const handleDropNotif = () => {
    if (dropNotif) setDropNotif(false);
    else setDropNotif(true);
  };

  function logout() {
    if (localStorage.getItem("userRole") == "Admin") {
      localStorage.removeItem("t");
      localStorage.removeItem("userid");
      localStorage.removeItem("userRole");
      history("/");
    }

    if (localStorage.getItem("userRole") == "User") {
      localStorage.removeItem("t");
      localStorage.removeItem("userid");
      localStorage.removeItem("userRole");
      localStorage.removeItem("usertype");
      history("/");
    }

    window.location.reload();
  }

  useEffect(() => {
    Api.get(`/api/user/${id}`)
      .then((response) => {
        SetUser(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [userlocation, setuserLocation] = useState(null);

  useEffect(() => {
    const getLocationFromLocalStorage = () => {
      const locationString = localStorage.getItem("location");
      if (locationString) {
        const locationplace = JSON.parse(locationString);
        setuserLocation(locationplace);
      }
    };
    getLocationFromLocalStorage();
  }, [Global]);

  return (
    <nav className="user h-20 z-30 border-b-2  border-gray-300 bg-white py-2.5 shadow-md dark:bg-gray-900">
      <div className="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between px-4">
        <Link to="/" className="flex items-center">
          <img
            src="./images/Logo.png"
            className="mr-3 inline-block h-16 sm:h-9"
            alt="Logos"
          />
          <span className="text inline-block self-center whitespace-nowrap bg-gradient-to-r from-sky-500 to-sky-800 bg-clip-text text-lg font-semibold text-transparent dark:text-white">
            ErekaBose
          </span>
        </Link>
        <div className="flex items-center lg:order-2">
          {isWho ? (
            <button
              onClick={() => {
                Global.setLocationPop(true);
              }}
              class="-ml-[100px] flex flex-row items-center  space-x-1 "
              tabindex="0"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-10 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                  />
                </svg>
              </div>
              <div class="flex flex-col items-start justify-start">
                <span> Location: </span>
                {userlocation?.user_id == id ? (
                  <>
                    <span class="font-semibold text-base">
                      {userlocation?.Country}
                      <span class="ml-1">,{userlocation?.State}</span>
                    </span>
                  </>
                ) : (
                  <span class="font-semibold text-base">
                    Enter Your Location
                  </span>
                )}
              </div>
            </button>
          ) : null}
          <div className="space-x-2">
            <div className="ml-10">
              <div className="left-10 flex flex-row items-center justify-start">
                {/*  Dropdown toggle button  */}
                <div className="flex h-9 w-9 items-center justify-center rounded-full bg-slate-200">
                  <svg
                    onClick={() => {
                      props.openSearch(true);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6 text-gray-600"
                  >
                    <path d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                  </svg>
                </div>
                <div className="ml-3 flex h-9 w-9 items-center justify-center rounded-full bg-slate-200">
                  <svg
                    onClick={() => handleDropCreate()}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6 text-gray-600"
                  >
                    <path d="M12 4.5v15m7.5-7.5h-15" />
                  </svg>
                </div>
                <div className="ml-3 flex h-9 w-9 items-center justify-center rounded-full bg-slate-200">
                  <div className="absolute top-2 w-6 h-6 flex items-center justify-center  ml-3 rounded-full bg-blue-100 border border-sky-500">
                    <span className=" text-xs font-medium text-blue-800">
                      12
                    </span>
                  </div>
                  <svg
                    onClick={() => handleDropNotif()}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6 text-gray-600"
                  >
                    <path d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                  </svg>
                </div>

                <button
                  onClick={() => handleDropProf()}
                  className="relative z-10 flex items-center rounded-md  p-2 text-sm text-gray-600"
                >
                  {User.profile_picture ? (
                    <img
                      className="mx-1 h-9 w-9 flex-shrink-0 rounded-full object-cover"
                      src={`http://backend.novadiscova.server.cisabu.bi/uploads/PhotoUser/${User.profile_picture}`}
                      alt={User.username}
                    />
                  ) : (
                    <div className="mx-1 h-9 w-9 flex-shrink-0 flex items-center justify-center rounded-full bg-sky-50 border-sky-300 border object-cover">
                      <span class="text-sky-500 uppercase font-medium">
                        {User.username?.substring(0, 1)}
                      </span>
                    </div>
                  )}
                </button>

                <div className="ml-0 flex h-9 w-9 items-center justify-center rounded-full bg-slate-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-7 w-7 text-gray-600"
                  >
                    <path d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                  </svg>
                </div>
              </div>
              {/* Dropdown menu  */}
              {dropProf ? (
                <div className="user_drop absolute z-20  w-56 mt-3 overflow-hidden rounded-md bg-gray-100 py-2 shadow-xl dark:bg-gray-800">
                  <Link
                    to="/profile"
                    className="-mt-2 flex transform items-center p-3 text-sm text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    {User?.profile_picture ? (
                      <img
                        className="mx-1 h-9 w-9 flex-shrink-0 rounded-full object-cover"
                        src={`http://backend.novadiscova.server.cisabu.bi/uploads/PhotoUser/${User.profile_picture}`}
                        alt={User.username}
                      />
                    ) : (
                      <div className="mx-1 h-9 w-9 flex-shrink-0 flex items-center justify-center rounded-full bg-sky-50 border-sky-300 border object-cover">
                        <span class="text-sky-500 uppercase font-medium">
                          {User.username?.substring(0, 1)}
                        </span>
                      </div>
                    )}
                    <div className="mx-1">
                      <h1 className="text-sm font-semibold text-gray-700 dark:text-gray-200">
                        {User.username}
                      </h1>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {User.email}
                      </p>
                    </div>
                  </Link>

                  <hr className="border-gray-200 dark:border-gray-700" />

                  <div className="hover:shadow-md mx-2 rounded-lg transition border border-transparent  hover:duration-200 hover:border-gray-200 flex flex-row items-center justify-start px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-6 w-6 text-gray-600"
                    >
                      <path d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    <Link
                      to="/profile"
                      className="block transform px-2 py-3 text-sm capitalize text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      {" "}
                      view profile{" "}
                    </Link>
                  </div>
                  <div className="hover:shadow-md mx-2 rounded-lg transition border border-transparent  hover:duration-200 hover:border-gray-200 flex flex-row items-center justify-start px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="h-6 w-6 text-gray-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                      />
                    </svg>

                    <Link
                      to="/PostOrders/User"
                      className="block transform px-2 py-3 text-sm capitalize text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      {" "}
                      My Post Orders{" "}
                    </Link>
                  </div>

                  <div className="hover:shadow-md mx-2 rounded-lg transition border border-transparent  hover:duration-200 hover:border-gray-200 flex flex-row items-center justify-start px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="h-6 w-6 text-gray-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                      />
                    </svg>

                    <Link
                      to="/PostOrders/User"
                      className="block transform px-2 py-3 text-sm capitalize text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      {" "}
                      My Ads Orders{" "}
                    </Link>
                  </div>
                  <div className="hover:shadow-md mx-2 rounded-lg transition border border-transparent  hover:duration-200 hover:border-gray-200 flex flex-row items-center justify-start px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-6 w-6 text-gray-600"
                    >
                      <path d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                      <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>

                    <Link
                      to="/profile"
                      className="block transform px-2 py-3 text-sm capitalize text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      {" "}
                      Setting{" "}
                    </Link>
                  </div>

                  <hr className="border-gray-200 dark:border-gray-700" />

                  <div className="hover:shadow-md mx-2 rounded-lg transition border border-transparent  hover:duration-200 hover:border-gray-200 flex flex-row items-center justify-start px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-6 w-6 text-gray-600"
                    >
                      <path d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
                    </svg>
                    <Link
                      to="/saved"
                      className="block transform px-2 py-3 text-sm capitalize text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      {" "}
                      Saved Post{" "}
                    </Link>
                  </div>

                  <div className="hover:shadow-md mx-2 rounded-lg transition border border-transparent  hover:duration-200 hover:border-gray-200 flex flex-row items-center justify-start px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-6 w-6 text-gray-600"
                    >
                      <path d="M21 7.5l-2.25-1.313M21 7.5v2.25m0-2.25l-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3l2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75l2.25-1.313M12 21.75V19.5m0 2.25l-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25" />
                    </svg>
                    <Link
                      to="/contributions"
                      className="block transform px-2 py-3 text-sm capitalize text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      {" "}
                      My Contributions{" "}
                    </Link>
                  </div>

                  <hr className="border-gray-200 dark:border-gray-700" />

                  <div className="hover:shadow-md mx-2 rounded-lg transition border border-transparent  hover:duration-200 hover:border-gray-200 flex flex-row items-center justify-start px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-6 w-6 text-gray-600"
                    >
                      <path d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg>
                    <a
                      href="#"
                      className="block transform px-2 py-3 text-sm capitalize text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      {" "}
                      Help
                    </a>
                  </div>
                  <div className="hover:shadow-md mx-2 rounded-lg transition border border-transparent  hover:duration-200 hover:border-gray-200 flex flex-row items-center justify-start px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-6 w-6 text-gray-600"
                    >
                      <path d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                    </svg>
                    <button
                      onClick={logout}
                      className="block transform px-2 py-3 text-sm capitalize text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      {" "}
                      Sign out{" "}
                    </button>
                  </div>
                </div>
              ) : null}

              {dropNotif ? (
                <div className="notification_drop absolute shadow-md z-20 mt-3 w-80 border  right-8 overflow-hidden rounded-md bg-gray-100 pt-2 shadow-xl dark:bg-gray-800">
                  <div className="flex items-center justify-between px-5">
                    <p className="block transform  py- text-lg capitalize text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white">
                      Notifications
                    </p>
                  </div>
                  {notifs.notifs?.map((notif) => {
                    return <NotifItem Key={notif.id} notif={notif} />;
                  })}
                  <div className="mt-4 flex w-full items-center rounded-lg bg-sky-100 p-3">
                    <div className="w-full flex items-center justify-center">
                      <div className="flex items-center justify-between">
                        <Link to="/notifications">
                          <p
                            tabindex="0"
                            className="text-sm font-medium leading-none text-sky-700 focus:outline-none"
                          >
                            View All Notifications
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {dropCreate ? (
                <div className="Create_post_drop h-[330px] absolute border z-20 mt-3 w-56 right-12 overflow-hidden rounded-md bg-gray-100 py-2 shadow-xl dark:bg-gray-800">
                  <div className="hover:shadow-md mx-2 rounded-lg transition border border-transparent  hover:duration-200 hover:border-gray-200 flex flex-row items-center justify-start px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 text-gray-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z"
                      />
                    </svg>

                    <Link
                      to="/createpost"
                      className="block transform px-2 py-3 text-sm capitalize text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      {" "}
                      Create Post{" "}
                    </Link>
                  </div>
                  <div className="hover:shadow-md mx-2 rounded-lg transition border border-transparent  hover:duration-200 hover:border-gray-200 flex flex-row items-center justify-start px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 text-gray-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5"
                      />
                    </svg>

                    <Link
                      to="/createvent"
                      className="block transform px-2 py-3 text-sm capitalize text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      {" "}
                      Create Event{" "}
                    </Link>
                  </div>

                  <hr className="border-gray-200 dark:border-gray-700 my-2" />

                  <div className="hover:shadow-md mx-2 rounded-lg transition border border-transparent  hover:duration-200 hover:border-gray-200 flex flex-row items-center justify-start px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 text-gray-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122"
                      />
                    </svg>

                    <Link
                      to="/who/createhave"
                      className="block transform px-2 py-3 text-sm capitalize text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      {" "}
                      Create Haves{" "}
                    </Link>
                  </div>

                  <div className="hover:shadow-md mx-2 rounded-lg transition border border-transparent  hover:duration-200 hover:border-gray-200 flex flex-row items-center justify-start px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 text-gray-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10.05 4.575a1.575 1.575 0 10-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 013.15 0v1.5m-3.15 0l.075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 013.15 0V15M6.9 7.575a1.575 1.575 0 10-3.15 0v8.175a6.75 6.75 0 006.75 6.75h2.018a5.25 5.25 0 003.712-1.538l1.732-1.732a5.25 5.25 0 001.538-3.712l.003-2.024a.668.668 0 01.198-.471 1.575 1.575 0 10-2.228-2.228 3.818 3.818 0 00-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0116.35 15m.002 0h-.002"
                      />
                    </svg>

                    <Link
                      to="/who/createneed"
                      className="block transform px-2 py-3 text-sm capitalize text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      {" "}
                      Create Needs{" "}
                    </Link>
                  </div>

                  <hr className="border-gray-200 dark:border-gray-700 my-2" />

                  <div className="hover:shadow-md mx-2 rounded-lg transition border border-transparent  hover:duration-200 hover:border-gray-200 flex flex-row items-center justify-start px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 text-gray-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                      />
                    </svg>

                    <Link
                      to="/CreateMediaService"
                      className="block transform px-2 py-3 text-sm capitalize text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      {" "}
                      Create Media Services{" "}
                    </Link>
                  </div>

                  <div className="hover:shadow-md mx-2 rounded-lg transition border border-transparent  hover:duration-200 hover:border-gray-200 flex flex-row items-center justify-start px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      class="w-5 h-5 text-gray-600"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199V2.5zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0zm-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065zm-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z" />
                    </svg>

                    <Link
                      to="/createads"
                      className="block transform px-2 py-3 text-sm capitalize text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      {" "}
                      Create Ereka Ads{" "}
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <button
            data-collapse-toggle="mobile-menu-2"
            type="button"
            className="ml-1 inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 lg:hidden"
            aria-controls="mobile-menu-2"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg
              className="hidden h-6 w-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>

        <div
          className="hidden w-full items-center justify-between lg:order-1 lg:flex lg:w-auto"
          id="mobile-menu-2"
        >
          <ul className="mt-4 flex flex-col font-medium lg:mt-0 lg:flex-row lg:space-x-8">
            <li>
              <Link to="/trend" className="h-[62px] w-[49px]">
                {pathname == "/trend" ? (
                  <div className="flex  items-center justify-center space-y-1 px-3 py-1  border-b-4 border-sky-500  rounded-lg bg-gray-200">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="hover:text-sky h-9 w-9 text-gray-600 "
                    >
                      <path d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                    </svg>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center space-y-1 px-3 py-1 hover:rounded-lg hover:bg-gray-200">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="hover:text-sky h-9 w-9 text-gray-600 "
                    >
                      <path d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                    </svg>
                  </div>
                )}
              </Link>
            </li>

            <li>
              <Link to="/Companies" className="h-[62px] w-[49px]">
                {pathname == "/Companies" ? (
                  <div className="flex  items-center justify-center space-y-1 px-3 py-1  border-b-4 border-sky-500  rounded-lg bg-gray-200">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="hover:text-sky h-9 w-9 text-gray-600 "
                    >
                      <path d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                    </svg>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center space-y-1 px-3 py-1 hover:rounded-lg hover:bg-gray-200">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="hover:text-sky h-9 w-9 text-gray-600 "
                    >
                      <path d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                    </svg>
                  </div>
                )}
              </Link>
            </li>

            <li>
              <Link to="/News" className="h-[62px] w-[49px]">
                {pathname == "/News" ? (
                  <div className="flex  items-center justify-center space-y-1 px-3 py-1  border-b-4 border-sky-500  rounded-lg bg-gray-200">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="hover:text-sky h-9 w-9 text-gray-600"
                    >
                      <path d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
                    </svg>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center space-y-1 px-3 py-1 hover:rounded-lg hover:bg-gray-200">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="hover:text-sky h-9 w-9 text-gray-600"
                    >
                      <path d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
                    </svg>
                  </div>
                )}
              </Link>
            </li>

            <li>
              <Link to="/messages" className="h-[62px] w-[49px]">
                {pathname == "/messages" ? (
                  <div className="flex  items-center justify-center space-y-1 px-3 py-1  border-b-4 border-sky-500  rounded-lg bg-gray-200">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="hover:text-sky h-9 w-9 text-gray-600 "
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
                      />
                    </svg>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center space-y-1 px-3 py-1 hover:rounded-lg hover:bg-gray-200">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="hover:text-sky h-9 w-9 text-gray-600 "
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
                      />
                    </svg>
                  </div>
                )}
              </Link>
            </li>

            <li>
              <Link to="/who" className="h-[62px] w-[49px]">
                {pathname == "/who" ? (
                  <div className="flex  items-center justify-center space-y-1 px-3 py-1  border-b-4 border-sky-500  rounded-lg bg-gray-200">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="hover:text-sky h-9 w-9 text-gray-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M20.893 13.393l-1.135-1.135a2.252 2.252 0 01-.421-.585l-1.08-2.16a.414.414 0 00-.663-.107.827.827 0 01-.812.21l-1.273-.363a.89.89 0 00-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 01-1.81 1.025 1.055 1.055 0 01-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 01-1.383-2.46l.007-.042a2.25 2.25 0 01.29-.787l.09-.15a2.25 2.25 0 012.37-1.048l1.178.236a1.125 1.125 0 001.302-.795l.208-.73a1.125 1.125 0 00-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 01-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 01-1.458-1.137l1.411-2.353a2.25 2.25 0 00.286-.76m11.928 9.869A9 9 0 008.965 3.525m11.928 9.868A9 9 0 118.965 3.525"
                      />
                    </svg>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center space-y-1 px-3 py-1 hover:rounded-lg hover:bg-gray-200">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="hover:text-sky h-9 w-9 text-gray-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M20.893 13.393l-1.135-1.135a2.252 2.252 0 01-.421-.585l-1.08-2.16a.414.414 0 00-.663-.107.827.827 0 01-.812.21l-1.273-.363a.89.89 0 00-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 01-1.81 1.025 1.055 1.055 0 01-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 01-1.383-2.46l.007-.042a2.25 2.25 0 01.29-.787l.09-.15a2.25 2.25 0 012.37-1.048l1.178.236a1.125 1.125 0 001.302-.795l.208-.73a1.125 1.125 0 00-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 01-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 01-1.458-1.137l1.411-2.353a2.25 2.25 0 00.286-.76m11.928 9.869A9 9 0 008.965 3.525m11.928 9.868A9 9 0 118.965 3.525"
                      />
                    </svg>
                  </div>
                )}
              </Link>
            </li>

            <li>
              <Link to="/Events" className="h-[62px] w-[49px]">
                {pathname == "/Events" ? (
                  <div className="flex  items-center justify-center space-y-1 px-3 py-1  border-b-4 border-sky-500  rounded-lg bg-gray-200">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="hover:text-sky h-9 w-9 text-gray-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
                      />
                    </svg>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center space-y-1 px-3 py-1 hover:rounded-lg hover:bg-gray-200">
                  
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="hover:text-sky h-9 w-9 text-gray-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
                      />
                    </svg>
                  </div>
                )}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default NavbarLogged;
