//Importer les modules dont on a besoin
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import Default from "./components/Default";
import Home from "./Pages/Home";
import PubPage from "./Pages/PubPage";
import NewsDetail from "./components/News/PostDetail/NewsDetail";
import MyPostPage from "./Pages/MyPostPage";
import MyAdsPage from "./Pages/MyAdsPage";
import Layout from "./components/Layout/Layout";
import Layout_notlogged from "./components/Layout/Layout_notlogged";
import NotifyPage from "./Pages/NotifyPage";
import Haves_NeedsNotifyPage from "./Pages/Haves_NeedsNotifyPage";
import PostOrdersPage from "./Pages/PostOrdersPage";
import PostOrderDetailPage from "./Pages/PostOrderDetailPage";
import SavedPage from "./Pages/SavedPage";
import PageOwner from "./Pages/PageOwner";
import FindSupPage from "./Pages/FindSupPage";
import CompaniesPage from "./Pages/CompaniesPage";
import IndutriesPage from "./Pages/IndutriesPage";
import WaitListPage from "./Pages/WaitListPage";
import History from "./Pages/History";
import ContributePage from "./Pages/ContributePage";
import MessagesPage from "./Pages/MessagesPage";
import Trend from "./Pages/Trend";
import News from "./Pages/News";
import My_Posts from "./Pages/My_Posts";
import My_Ads from "./Pages/My_Ads";
import AllPrice from "./Pages/AllPrice";
import WhoHome from "./Pages/WhoHome";
import CreateNeed from "./Pages/CreateNeed";
import CreateHave from "./Pages/CreateHave";
import BrowserHave from "./Pages/BrowserHave";
import BrowserNeed from "./Pages/BrowserNeed";
import WhoNeedPostDetail from "./components/BrowserNeeds/PostDetail/WhoNeedPostDetail";
import WhoHavePostDetail from "./components/BrowserHaves/PostDetail/WhoHavePostDetail";
import My_Haves from "./Pages/My_Haves";
import My_Needs from "./Pages/My_Needs";
import My_NeedPost from "./Pages/My_NeedPost";
import My_HavePost from "./Pages/My_HavePost";
import CreatePost from "./Pages/CreatePost";
import CreateAds from "./Pages/CreateAds";
import EditPost from "./Pages/EditPost";
import EditAds from "./Pages/EditAds";
import SharePage from "./Pages/SharePage";
import LoadingPage from "./components/LoadingPage.js/LoadingPage";
import WebsiteBuy from "./Pages/WebsiteBuy";
import TvOnlineBuy from "./Pages/TvOnlineBuy";
import MyMedia_Services from "./Pages/MyMedia_Services";
import SocialBuy from "./Pages/SocialBuy";
import RadioBuy from "./Pages/RadioBuy";
import LocalTvBuy from "./Pages/LocalTvBuy";
import CompaniesBuy from "./Pages/CompaniesBuy";
import Quick_Ordering from "./Pages/Quick_Ordering";
import CreateMediaService from "./Pages/CreateMediaService";
import EditMediaService from "./Pages/EditMediaService";
import AllCompanieBuy from "./Pages/AllCompanieBuy";
import AllLocalTv from "./Pages/AllLocalTv";
import AllRadioBuy from "./Pages/AllRadioBuy";
import AllTvOnline from "./Pages/AllTvOnline";
import AllWebsiteBuy from "./Pages/AllWebsiteBuy";
import SettingPage from "./Pages/SettingPage";
//Creer le composant

function App() {
  const [User, SetUser] = useState(false);
  const [Admin, SetAdmin] = useState(false);
  const [Visitor, SetVisitor] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const setLoading = (loading) => {
    setIsLoading(loading);
  };
  useEffect(() => {
    const userRole = localStorage.getItem("userRole");
    if (userRole === "Admin") {
      SetAdmin(true);
      SetUser(false);
      SetVisitor(false);
    } else if (userRole === "User") {
      SetUser(true);
      SetAdmin(false);
      SetVisitor(false);
    } else {
      SetVisitor(true);
    }
  }, []);

  return User ? (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/who" element={<WhoHome />} />{" "}
          <Route path="/Setting" element={<SettingPage />} />
          <Route path="/who/browserhave" element={<BrowserHave />} />
          <Route path="/who/myhaves/:id" element={<My_HavePost />} />
          <Route path="/who/myneeds/:id" element={<My_NeedPost />} />
          <Route path="/who/myhaves" element={<My_Haves />} />
          <Route path="/who/myneeds" element={<My_Needs />} />
          <Route path="/who/havepost/:id" element={<WhoHavePostDetail />} />
          <Route path="/who/needpost/:id" element={<WhoNeedPostDetail />} />
          <Route path="/who/browserneed" element={<BrowserNeed />} />
          <Route path="/who/createneed" element={<CreateNeed />} />
          <Route path="/who/createhave" element={<CreateHave />} />
          <Route path="/who/notifications" element={<WhoHome />} />
          <Route path="/who/whoWorkspace" element={<WhoHome />} />
          <Route path="/createpost" element={<CreatePost />} />
          <Route path="/createads" element={<CreateAds />} />
          <Route path="/editpost/:id" element={<EditPost />} />
          <Route path="/editads/:id" element={<EditAds />} />
          <Route path="/CreateMediaService" element={<CreateMediaService />} />
          <Route path="/EditMediaService/:id" element={<EditMediaService />} />
          <Route path="/price" element={<AllPrice />} />
          <Route path="/trend" element={<Trend />} />
          <Route path="/news" element={<News />} />
          <Route path="/my_posts" element={<My_Posts />} />
          <Route path="/my_ads" element={<My_Ads />} />
          <Route path="/pricing/social/:platform_id" element={<SocialBuy />} />
          <Route path="/pricing/website/:user_id" element={<WebsiteBuy />} />
          <Route path="/pricing/radio/:user_id" element={<RadioBuy />} />
          <Route path="/pricing/tvonline/:user_id" element={<TvOnlineBuy />} />
          <Route path="/pricing/localtv/:user_id" element={<LocalTvBuy />} />
          <Route
            path="/pricing/companies/:user_id"
            element={<CompaniesBuy />}
          />
          <Route
            path="/pricing/allcompanies/:category_id"
            element={<AllCompanieBuy />}
          />
          <Route
            path="/pricing/alllocaltv/:category_id"
            element={<AllLocalTv />}
          />
          <Route
            path="/pricing/allradios/:category_id"
            element={<AllRadioBuy />}
          />
          <Route
            path="/pricing/alltvonline/:category_id"
            element={<AllTvOnline />}
          />
          <Route
            path="/pricing/allwebsite/:category_id"
            element={<AllWebsiteBuy />}
          />
          <Route path="/pub/:id" element={<PubPage />} />
          <Route path="/News/:id" element={<NewsDetail />} />
          <Route path="/MyAds/:id" element={<MyAdsPage />} />
          <Route path="/MyPost/:id" element={<MyPostPage />} />
          <Route path="/MyMedia_Services" element={<MyMedia_Services />} />
          <Route path="/orders/Post/:id" element={<PostOrderDetailPage />} />
          <Route path="/share/:user/:uuid" element={<SharePage />} />
          <Route path="/notifications" element={<NotifyPage />} />
          <Route
            path="/haves_needs/notifications"
            element={<Haves_NeedsNotifyPage />}
          />
          
          <Route path="/quick_ordering/:company_name/:company_id/:menu_name/:menu_id/:table_id" element={<Quick_Ordering />} />
          
          <Route path="/PostOrders/User" element={<PostOrdersPage />} />
          <Route path="/saved" element={<SavedPage />} />
          <Route path="/profile" element={<PageOwner />} />
          <Route path="/Find" element={<FindSupPage />} />
          <Route path="/Companies" element={<CompaniesPage />} />
          <Route path="/indentries" element={<IndutriesPage />} />
          <Route path="/waitlist_owner" element={<WaitListPage />} />
          <Route path="/history" element={<History />} />
          <Route path="/contributions" element={<ContributePage />} />
          <Route path="/messages" element={<MessagesPage />} />
          <Route path="/*" element={<Default />} />
      
        </Route>
      </Routes>
      <ToastContainer />
    </>
  ) : Admin ? (
    <></>
  ) : (
    <>
      <Routes>
        <Route path="/" element={<Layout_notlogged />}>
          <Route index element={<Home />} />
          <Route path="/*" element={<Default />} />
          <Route path="/quick_ordering/:company_id/:table_id/:menu_id" element={<Quick_Ordering />} />
       
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
}

//exporter le composant pour le rendre disponible
export default App;
