import React from "react";

import SocialBuyComponent from "../components/Buy/SocialBuyComponent";
function SocialBuy() {
  return (
    <>
      <SocialBuyComponent />
    </>
  );
}
export default SocialBuy;
