import React from 'react'
import Suppoters from '../components/Users/Suppoters'

function FindSupPage() {
  return (
    <>
        <Suppoters />
    </>
  )
}
export default FindSupPage