import React, { useContext } from "react";
import { PostContext } from "../../context";
import Top from "./Top";

function AllTop() {
  const posts = useContext(PostContext);
  return (
    <>
      <section className="bg-white dark:bg-gray-900">
        <div className="mx-auto max-w-screen-xl px-4 pb-4 lg:pb-4">
          <div className=" h-full w-full ">
            <div className="h-50 mb-3 flex w-full cursor-pointer space-x-2 ">
              {posts.posts?.slice(0, 4).map((post) => {
                return <Top key={post.id} post={post} />;
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default AllTop;
