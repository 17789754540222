import React, { useState } from "react";
import Login from "../Autentifiaction/Login";
import Signup from "../Autentifiaction/Signup";
import { Link } from "react-router-dom";

function NavbarUnlogged() {
  const [popLog, setPopLog] = useState(false);
  const [popRegist, setPopRegist] = useState(false);
  const openloginPopup = (pop) => {
    if (pop) setPopLog(true);
    else setPopLog(false);
  };
  const opensignupPopup = (pop) => {
    if (pop) setPopRegist(true);
    else setPopRegist(false);
  };
  return (
    <>
      {popLog ? (
        <Login openloginPopup={{ openloginPopup, opensignupPopup }} />
      ) : null}
      {popRegist ? <Signup opensignupPopup={opensignupPopup} /> : null}

      <nav className="guest h-20  z-30 bg-white   border-gray-300 border-b-2 shadow-md  py-2.5 dark:bg-gray-900">
        <div className="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto">
          <Link to="/" className="flex items-center">
            <img
              src=" ./images/Logo.png"
              className="h-16 inline-block  mr-3 sm:h-9"
              alt=" Logos"
            />
            <span className="self-center text-lg  text font-semibold whitespace-nowrap dark:text-white bg-gradient-to-r from-sky-500 to-sky-800 inline-block text-transparent bg-clip-text">
              ErekaBose
            </span>
          </Link>
          <div className="flex items-center lg:order-2">
            <div className="space-x-2 ">
              <button
                className="text-white bg-sky-500 hover:bg-sky-800 focus:ring-4 focus:ring-sky-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-sky-600 dark:hover:bg-sky-700 focus:outline-none dark:focus:ring-sky-800"
                onClick={() => openloginPopup(true)}
              >
                Login
              </button>
              <button
                className="text-white bg-sky-500 hover:bg-sky-800 focus:ring-4 focus:ring-sky-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-sky-600 dark:hover:bg-sky-700 focus:outline-none dark:focus:ring-sky-800"
                onClick={() => opensignupPopup(true)}
              >
                Sign up
              </button>
            </div>
            <button
              data-collapse-toggle=" mobile-menu-2"
              type=" button"
              className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls=" mobile-menu-2"
              aria-expanded=" false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                fill=" currentColor"
                viewBox=" 0 0 20 20"
                xmlns=" http://www.w3.org/2000/svg"
              >
                <path
                  fillRule=" evenodd"
                  d=" M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipPath=" evenodd"
                ></path>
              </svg>
              <svg
                className="hidden w-6 h-6"
                fill=" currentColor"
                viewBox=" 0 0 20 20"
                xmlns=" http://www.w3.org/2000/svg"
              >
                <path
                  fillRule=" evenodd"
                  d=" M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipPath=" evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </nav>
    </>
  );
}
export default NavbarUnlogged;
