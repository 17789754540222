import React from "react";
import { Link } from "react-router-dom";
function Suppoter({ sup }) {
  const TimeAgo = (dateString) => {
    const currentDate = new Date();
    const pastDate = new Date(dateString);

    const timeDifference = currentDate.getTime() - pastDate.getTime();

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return years === 1 ? "1 year ago" : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? "1 month ago" : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1 min ago" : `${minutes} min ago`;
    } else {
      return seconds === 1 ? "Now" : "Now";
    }
  };
  return (
    <>
      <div className="flex w-auto flex-col items-center justify-center rounded-3xl bg-gray-50 border p-5">
        <div className="w-auto flex flex-col items-center justify-center">
          {sup.profile_picture ? (
            <img
              className="w-28 h-auto mx-auto aspect-square rounded-full border-2 border-gray-300 dark:bg-gray-500"
              src={`http://backend.novadiscova.server.cisabu.bi/uploads/PhotoUser/${sup.profile_picture}`}
              alt={""}
            />
          ) : (
            <div className=" h-28 w-28 flex-shrink-0 flex items-center justify-center rounded-full bg-sky-50 border-sky-300 border object-cover">
              <span class="text-sky-500 text-5xl uppercase font-medium">
                {sup.username?.substring(0, 1)}
              </span>
            </div>
          )}
          <div className="space-y-4 divide-y divide-sky-700 px-3 text-center">
            <div>
              <div className="my-3 space-y-1">
                <h2 className="text-lg uppercase font-semibold text-sky-500">
                  {sup.username}
                </h2>
                <p className="px-5 text-xl text-sky-700 dark:text-gray-400">
                  {sup.job}
                </p>
              </div>
            </div>

            <div className="flex items-center justify-center space-x-4 rounded-3xl bg-gray-50 pt-2">
              <Link
                to="/messages"
                className="text-white bg-gradient-to-r from-sky-500 to-sky-700  font-medium rounded-2xl text-lg px-5 py-2.5 text-center mr-2 mb-2 opacity-80"
              >
                Message
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Suppoter;
