import React,{useContext} from 'react'
import UserAside from '../AsideBar/UserAside'
import {PostContext} from '../../context'
import Contrubution from './Contrubution'

export default function MyContribution() {
    const posts = useContext(PostContext)
  return (
    <>
          <div className="My_Contribution">
        <div type="button" className="flex w-full items-center justify-between rounded-t-xl p-1 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800">
            <span>Notifications</span>
        </div>
        <div className="mb-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
            <div className="flex w-full flex-row items-center justify-center rounded-t-xl border border-b-0 border-gray-200 bg-gray-100 p-5 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800">
            <form className="w-full">
                <div className="flex w-full items-center justify-center space-x-4">
                <div className="relative">
                    <input type="text" id="password" className="w-full rounded-xl border-2 border-gray-200 py-2 pl-3 pr-10 text-gray-600 transition-colors hover:border-gray-300 focus:border-sky-500 focus:outline-none" placeholder="Search..."/>
                    <button className="leading-0 absolute top-2 right-2 block h-7 items-center justify-center rounded-xl bg-sky-600 px-3 text-center text-xl text-white transition-colors hover:text-gray-300 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"></path>
                    </svg>
                    </button>
                </div>
                <div>
                    <ul className="inline-flex items-center -space-x-px"><li>
                <a href="#" className="ml-0 block rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                  <span className="sr-only">Previous</span>
                  <svg aria-hidden="true" className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                </a>
              </li>
              <li>
                <a href="#" className="block rounded-r-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                  <span className="sr-only">Next</span>
                  <svg aria-hidden="true" className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </form>
    </div>
    <div className="border border-b-0 border-gray-200 p-2 font-light dark:border-gray-700 dark:bg-gray-900">
      <div className="w-full space-y-4">
        <div className="relative h-full overflow-hidden">
          <div className=" flex h-full flex-col divide-y overflow-y-auto overflow-x-hidden">
            <div className="today mb-10">
              <div className="mt-3 mb-3 ml-5">
                <div className="text-xs font-semibold uppercase text-gray-400">Today</div>
              </div>
              <div className="today grid grid-cols-2 gap-5">
                {posts.posts?.map(post=>{
                    return <Contrubution key={post.id} post= {post} />
                })}
              </div>
            </div>
            </div>
            </div>
            </div>
        </div>
        </div>
        </div>
    </>
  )
}
