import React from "react";

import AllLocalTvComponent from "../components/Buy/AllLocalTvComponent";
function AllLocalTv() {
  return (
    <>
      <AllLocalTvComponent />
    </>
  );
}
export default AllLocalTv;
