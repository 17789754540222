import React from 'react'
import HeroBlock from './HeroBlock'
import SocialNetwork from './SocialNetwork'

export default function GetStartField() {
  return (
    <>
    <HeroBlock />
    <SocialNetwork />
    </>
  )
}
