import React, { useContext, useState, useEffect } from "react";
import { PostOrdersContext } from "../../../context";
import { Link, useParams } from "react-router-dom";
function Post_Orders() {
  const postorders = useContext(PostOrdersContext);
  const { id } = useParams();
  console.log(id);
  var post_id = id;
  useEffect(() => {
    postorders.handleOrder(post_id);
  }, []);

  return (
    <>
      <div className="my-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
        <button
          type="button"
          className="flex w-full items-center justify-between rounded-t-xl border border-b-0 border-gray-200 bg-sky-50 p-5 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800"
        >
          <span className="text-xl">Ordered Services</span>
        </button>
        <div>
          <div className="border border-b-0 border-gray-200 font-light dark:border-gray-700 dark:bg-gray-900">
            <div className="mb-6 inline-flex w-full items-center justify-center">
              <div className="absolute flex h-8 w-32 items-center justify-center rounded-xl border-2 border-gray-300 bg-gray-500 px-3 py-2 shadow">
                <span className="font-thing w-auto rounded-r-xl text-lg text-white">
                  {" "}
                  Shares{" "}
                </span>
              </div>
            </div>

            <div className="my-4 grid w-full grid-cols-2 gap-x-4 gap-y-6 px-6 ">
              {postorders.detailorder?.map((order) => {
                return (
                  <>
                    <div class="p-5 lg:p-6 text-center flex-grow bg-gray-100 rounded-xl border border-sky-300">
                      <span class="inline-flex space-x-1 items-center text-sm uppercase tracking-wider font-semibold px-3 py-1 bg-sky-200 bg-opacity-50 text-sky-500 rounded-xl mb-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          class="w-5 h-5"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"
                          />
                        </svg>
                        <span>
                          {order.platform.platform} {order.service}
                        </span>
                      </span>
                      <div>
                        <div class="flex flex-row space-x-3">
                          {" "}
                          <span class="text-lg text-gray-600 font-semibold">
                            Price :
                          </span>
                          <span class="text-sm lg:text-lg text-gray-600 font-bold">
                            {order.price / 100} Fbu
                          </span>
                        </div>
                      </div>
                      <div>
                        <div class="flex flex-row space-x-3">
                          {" "}
                          <span class="text-lg text-gray-600 font-semibold">
                            Quantity :
                          </span>
                          <span class="text-sm lg:text-lg text-gray-600 font-bold">
                            {order.quantity}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>

            <div className="m-8 flex flex-row justify-between rounded-md bg-gray-100 px-3 py-2 shadow">
              <span className="rounded-r-xl text-lg font-medium text-sky-700">
                Total Amount{" "}
              </span>
              <span className="mr-3 rounded-r-xl text-xl font-bold text-sky-700">
                {postorders.detailorder?.reduce(
                  (acc, item) => acc + (item.price / 100) * item.quantity,
                  0
                )}
                <span className="ml-1">Fbu</span>{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Post_Orders;
