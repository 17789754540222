import React from 'react'

function Review(props) {
  return (
    <div className="px-3 md:w-1/3">
    <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
        <div className="w-full flex mb-4 items-center">
            <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                <img src={props.review.photo} alt=""/>
            </div>
            <div className="flex-grow pl-3">
                <h6 className="font-bold text-sm uppercase text-gray-600">{props.review.name}</h6>
            </div>
        </div>
        <div className="w-full">
            <p className="text-sm leading-tight"><span className="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span>{props.review.info}<span className="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
        </div>
    </div>
    <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
        <div className="w-full flex mb-4 items-center">
            <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                <img src="https://i.pravatar.cc/100?img=2"alt=""/>
            </div>
            <div className="flex-grow pl-3">
                <h6 className="font-bold text-sm uppercase text-gray-600">Stevie Tifft.</h6>
            </div>
        </div>
        <div className="w-full">
            <p className="text-sm leading-tight"><span className="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span>Lorem ipsum, dolor sit amet, consectetur adipisicing elit. Dolore quod necessitatibus, labore sapiente, est, dignissimos ullam error ipsam sint quam tempora vel.<span className="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
        </div>
    </div>
</div>
  )
}
export default Review