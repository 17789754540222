import React,{useContext} from 'react'
import {MySmsContext} from '../../context'
import Message from './Message'

export default function Messages() {
    const sms = useContext(MySmsContext)
  return (
    <>
        <div className="flex flex-col divide-y h-full overflow-y-auto -mx-4">
            {sms.sms?.map(ms=>{
                return <Message key={ms.id} sms = {ms} />
            })}
          </div>
    </>
  )
}
