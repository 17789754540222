import React from 'react'
import MyHistory from '../components/History/MyHistory'

export default function History() {
  return (
    <>
        <MyHistory />
    </>
  )
}
