import React, { useContext, useState, useEffect } from "react";
import UAParser from "ua-parser-js";
import { useParams, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Api from "../Api/Api";
import LoadingPage from "../LoadingPage.js/LoadingPage";
import { CSSProperties } from "react";
import { BeatLoader } from "react-spinners";
import { v4 as uuidv4 } from "uuid";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
export default function Login() {
  const history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, seterror] = useState("");
  const [isSeen, setisSeen] = useState(false);
  const params = useParams();
  const link = params.user + "/" + params.uuid;
  const id = 2;
  const handleClose = () => {
    toast.dismiss();
  };

  useEffect(() => {
    if (localStorage.getItem("newDiffusion")) {
      const storedDiffusion = JSON.parse(localStorage.getItem("newDiffusion"));
      console.log(storedDiffusion);
      data = {
        post_id: storedDiffusion.post_id,
        visitor_id: storedDiffusion.visitor_id,
      };
      Api.post("api/share/check", data)
        .then((response) => {
          if (response.data) {
            console.log("store id ", storedDiffusion.post_id);
            console.log("reponse id ", response.datapost_id);
            setisSeen(true);
            toast.error("You'have Already View The Post", {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              onClick: handleClose, // Utilisation de la référence à la fonction handleClose
            });
            const parser = new UAParser();
            const userAgent = parser.getResult();
            var data = null;
            const ReverseGeocoding = async (latitude, longitude) => {
              try {
                const API_KEY = "pk.ed6b64ea99395afe453b0a42bc4b81e7";
                const response = await Api.get(
                  `https://us1.locationiq.com/v1/reverse.php?key=${API_KEY}&lat=${latitude}&lon=${longitude}&format=json`
                );
                data = {
                  link: link,
                  visitor_id: storedDiffusion.visitor_id,
                  repeated: true,
                  device: userAgent.device.vendor,
                  device_model: userAgent.device.model,
                  device_type: userAgent.device.type,
                  geolocation: response.data.display_name,
                  app: userAgent.browser.name,
                  os_name: userAgent.os.name,
                  country: response.data.address.country,
                  state: response.data.address.state,
                  town: response.data.address.town,
                };

                console.log(data);

                Api.post("/api/share", data)
                  .then((response) => {
                    toast.success("Success", {
                      position: "top-center",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      onClick: handleClose, // Utilisation de la référence à la fonction handleClose
                    });
                    const updatedData = {
                      ...data,
                      post_id: response.data.post_id,
                    };
                    // Set the object in localStorage
                    localStorage.setItem(
                      "newDiffusion",
                      JSON.stringify(updatedData)
                    );
                    history(`/pub/${response.data.post_id}`);
                    console.log(response.data);
                    setLoading(false);
                  })
                  .catch((error) => {
                    // Handle errors
                    if (error.response) {
                      setLoading(false);
                      // Le serveur a renvoyé une réponse avec un code d'erreur
                      const errorMessage = error.response.data.error;
                      seterror(errorMessage);
                      toast.error(errorMessage, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        onClick: handleClose, // Utilisation de la référence à la fonction handleClose
                      }); // Affiche le message d'erreur dans le toast
                    } else if (error.request) {
                      // La requête a été faite mais aucune réponse n'a été reçue
                      setLoading(false);
                      console.log(error.request);
                      seterror(error.request);
                    } else {
                      // Une erreur s'est produite lors de la configuration de la requête
                      console.log("Error", error.message);
                    }
                  });
              } catch (error) {
                console.error("Error:", error);
              }
            };

            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                async (position) => {
                  if (position.coords.latitude && position.coords.longitude) {
                    await ReverseGeocoding(
                      position.coords.latitude,
                      position.coords.longitude
                    );
                  }
                },
                (error) => {
                  console.error("Error getting geolocation:", error);
                }
              );
            } else {
              console.error("Geolocation is not supported by this browser.");
            }
          } else {
            localStorage.removeItem("newDiffusion");
            const uuid = uuidv4();
            const parser = new UAParser();
            const userAgent = parser.getResult();
            var data = null;
            const ReverseGeocoding = async (latitude, longitude) => {
              try {
                const API_KEY = "pk.ed6b64ea99395afe453b0a42bc4b81e7";
                const response = await Api.get(
                  `https://us1.locationiq.com/v1/reverse.php?key=${API_KEY}&lat=${latitude}&lon=${longitude}&format=json`
                );
                data = {
                  link: link,
                  visitor_id: uuid,
                  repeated: false,
                  device: userAgent.device.vendor,
                  device_model: userAgent.device.model,
                  device_type: userAgent.device.type,
                  geolocation: response.data.display_name,
                  app: userAgent.browser.name,
                  os_name: userAgent.os.name,
                  country: response.data.address.country,
                  state: response.data.address.state,
                  town: response.data.address.town,
                };

                console.log(data);

                Api.post("/api/share", data)
                  .then((response) => {
                    toast.success("Success", {
                      position: "top-center",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      onClick: handleClose, // Utilisation de la référence à la fonction handleClose
                    });
                    const updatedData = {
                      ...data,
                      post_id: response.data.post_id,
                    };
                    // Set the object in localStorage
                    localStorage.setItem(
                      "newDiffusion",
                      JSON.stringify(updatedData)
                    );
                    history(`/pub/${response.data.post_id}`);
                    console.log(response.data);
                    setLoading(false);
                  })
                  .catch((error) => {
                    // Handle errors
                    if (error.response) {
                      setLoading(false);
                      // Le serveur a renvoyé une réponse avec un code d'erreur
                      const errorMessage = error.response.data.error;
                      seterror(errorMessage);
                      toast.error(errorMessage, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        onClick: handleClose, // Utilisation de la référence à la fonction handleClose
                      }); // Affiche le message d'erreur dans le toast
                    } else if (error.request) {
                      // La requête a été faite mais aucune réponse n'a été reçue
                      setLoading(false);
                      console.log(error.request);
                      seterror(error.request);
                    } else {
                      // Une erreur s'est produite lors de la configuration de la requête
                      console.log("Error", error.message);
                    }
                  });
              } catch (error) {
                console.error("Error:", error);
              }
            };

            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                async (position) => {
                  if (position.coords.latitude && position.coords.longitude) {
                    await ReverseGeocoding(
                      position.coords.latitude,
                      position.coords.longitude
                    );
                  }
                },
                (error) => {
                  console.error("Error getting geolocation:", error);
                }
              );
            } else {
              console.error("Geolocation is not supported by this browser.");
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          // Handle errors
          if (error.response) {
            setLoading(false);
            // Le serveur a renvoyé une réponse avec un code d'erreur
            const errorMessage = error.response.data.error;
            seterror(errorMessage);
            toast.error(errorMessage, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              onClick: handleClose, // Utilisation de la référence à la fonction handleClose
            }); // Affiche le message d'erreur dans le toast
          } else if (error.request) {
            // La requête a été faite mais aucune réponse n'a été reçue
            setLoading(false);
            console.log(error.request);
            seterror(error.request);
          } else {
            // Une erreur s'est produite lors de la configuration de la requête
            console.log("Error", error.message);
          }
        });
    } else {
      const uuid = uuidv4();
      const parser = new UAParser();
      const userAgent = parser.getResult();
      var data = null;
      const ReverseGeocoding = async (latitude, longitude) => {
        try {
          const API_KEY = "pk.ed6b64ea99395afe453b0a42bc4b81e7";
          const response = await Api.get(
            `https://us1.locationiq.com/v1/reverse.php?key=${API_KEY}&lat=${latitude}&lon=${longitude}&format=json`
          );
          data = {
            link: link,
            visitor_id: uuid,
            repeated: false,
            device: userAgent.device.vendor,
            device_model: userAgent.device.model,
            device_type: userAgent.device.type,
            geolocation: response.data.display_name,
            app: userAgent.browser.name,
            os_name: userAgent.os.name,
            country: response.data.address.country,
            state: response.data.address.state,
            town: response.data.address.town,
          };

          console.log(data);

          Api.post("/api/share", data)
            .then((response) => {
              toast.success("Success", {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                onClick: handleClose, // Utilisation de la référence à la fonction handleClose
              });
              const updatedData = {
                ...data,
                post_id: response.data.post_id,
              };
              // Set the object in localStorage
              localStorage.setItem("newDiffusion", JSON.stringify(updatedData));
              history(`/pub/${response.data.post_id}`);
              console.log(response.data);
              setLoading(false);
            })
            .catch((error) => {
              // Handle errors
              if (error.response) {
                setLoading(false);
                // Le serveur a renvoyé une réponse avec un code d'erreur
                const errorMessage = error.response.data.error;
                seterror(errorMessage);
                toast.error(errorMessage, {
                  position: "top-center",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  onClick: handleClose, // Utilisation de la référence à la fonction handleClose
                }); // Affiche le message d'erreur dans le toast
              } else if (error.request) {
                // La requête a été faite mais aucune réponse n'a été reçue
                setLoading(false);
                console.log(error.request);
                seterror(error.request);
              } else {
                // Une erreur s'est produite lors de la configuration de la requête
                console.log("Error", error.message);
              }
            });
        } catch (error) {
          console.error("Error:", error);
        }
      };

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            if (position.coords.latitude && position.coords.longitude) {
              await ReverseGeocoding(
                position.coords.latitude,
                position.coords.longitude
              );
            }
          },
          (error) => {
            console.error("Error getting geolocation:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    }
  }, []);

  return (
    <>
      {" "}
      <div className="login-popup fixed inset-0 z-40 bg-white flex flex-col items-center justify-center">
        {loading && (
          <div className="flex flex-col items-center justify-center">
            <img
              src="../../images/Logo.png"
              className=" inline-block h-56 sm:h-32"
              alt="Logos"
            />
            <span className="text mb-4 inline-block self-center whitespace-nowrap bg-gradient-to-r from-sky-500 to-sky-800 bg-clip-text text-3xl font-semibold text-transparent dark:text-white">
              ErekaBose
            </span>
            <div>
              <div class=" flex flex-row items-center">
                <BeatLoader
                  cssOverride={override}
                  size={25}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  color="#0ea5e9"
                />
                <BeatLoader
                  cssOverride={override}
                  size={25}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  color="#0ea5e9"
                />
              </div>
            </div>
          </div>
        )}

        {/* {error && (
          <div class="flex h-[calc(100vh-80px)] items-center justify-center p-5 w-full bg-white">
            <div class="text-center">
              <div class="inline-flex rounded-full bg-sky-100 p-4">
                <div class="rounded-full stroke-sky-600 bg-sky-200 p-4">
                  <svg
                    class="w-16 h-16"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 8H6.01M6 16H6.01M6 12H18C20.2091 12 22 10.2091 22 8C22 5.79086 20.2091 4 18 4H6C3.79086 4 2 5.79086 2 8C2 10.2091 3.79086 12 6 12ZM6 12C3.79086 12 2 13.7909 2 16C2 18.2091 3.79086 20 6 20H14"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 16L22 21M22 16L17 21"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              </div>
              <h1 class="mt-5 text-[36px] font-bold text-slate-800 lg:text-[50px]">
                {error}
              </h1>
              <p class="text-slate-600 mt-5 lg:text-lg">
                Oops something went wrong. Try to use a valid link <br /> feel
                free to contact us if the problem presists.
              </p>

              <Link
                to="/trend"
                className="rounded-xl border-0 mt-8 bg-sky-500 px-6 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none"
              >
                Return to ErekaBose Home Page
              </Link>
            </div>
          </div>
        )} */}
      </div>
      <ToastContainer />
    </>
  );
}
