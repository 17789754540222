import React, { useEffect } from "react";
import { useState, CSSProperties } from "react";
import { BeatLoader } from "react-spinners";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function LoadingPage() {
  return (
    <div className="login-popup fixed  bg-gray-100 bg-opacity-50 top-0 left-0 w-full h-full z-[55] flex  items-center justify-center">
      <section className="min-h-screen flex flex-col box-border justify-center items-center">
        <div class="relative hidden flex flex-row items-center justify-center md:mt-2">
          <img
            src="img/ibloteclogo.png"
            class="h-10 md:h-16 w-10 md:w-16"
            alt=""
          />
          <a
            class="text-4xl md:text-4xl font-bold leading-tight  opacity-70"
            href="/"
          >
            <span class="text-olive-500">IBlo</span>
            <span class="text-yellow-500">Tec</span>
          </a>
        </div>
        <div class="ml-4 flex flex-row items-center">
          <BeatLoader
            cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
            color="#0ea5e9"
          />
          <BeatLoader
            cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
            color="#0ea5e9"
          />
        </div>
      </section>
    </div>
  );
}

export default LoadingPage;
