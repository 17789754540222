import React from 'react'
import SavedPost from '../components/SavedPost'

export default function SavedPage() {
  return (
    <>
    <SavedPost />
    </>
  )
}
