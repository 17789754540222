export const WaitUser = [
    {
      id: 1,
      name: "BUK-C BOY",
      cat: "Musician",
      photo: "https://source.unsplash.com/150x150/?portrait?1",
      comment: 12,
      likes: 250,
      status : false
        
    },
    {
        id: 2,
        name: "BUK-C BOY",
        cat: "Musician",
        photo: "https://source.unsplash.com/150x150/?portrait?2",
        comment: 12,
        likes: 250,
        status : true
    },
    {
        id: 3,
        name: "BUK-C BOY",
        cat: "Musician",
        photo: "https://source.unsplash.com/150x150/?portrait?3",
        comment: 12,
        likes: 250,
        status : false
    },
    {
        id: 4,
        name: "BUK-C BOY",
        cat: "Musician",
        photo: "https://source.unsplash.com/150x150/?portrait?4",
        comment: 12,
        likes: 250,
        status : false
          
    },
    {
        id: 5,
        name: "BUK-C BOY",
        cat: "Musician",
        photo: "https://source.unsplash.com/150x150/?portrait?5",
        comment: 12,
        likes: 250,
        status : true
    },
    {
        id: 6,
        name: "BUK-C BOY",
        cat: "Musician",
        photo: "https://source.unsplash.com/150x150/?portrait?6",
        comment: 12,
        likes: 250,
        status : false
    }
  ];

  export const waitDetail={
    id: 1,
    name: "BUK-C BOY",
    cat: "Musician",
    photo: "https://source.unsplash.com/150x150/?portrait?1",
    comment: 12,
    likes: 250,
    status : false
  }
  