import Member from "./Member";
import { useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { SugesContext, AdsContext } from "../../../context";
function WhoHave() {
  const who = useContext(AdsContext);
  const [page, setPage] = useState(1);
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  useEffect(() => {
    setloading(true);
    who.fetchWatchData(page, id);
    setPage(page + 1);
    setloading(false);
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      // Check if user has scrolled to the bottom of the page

      if (page < who.totalPages) {
        if (
          window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 50
        ) {
          setloading(true);
          who.fetchWatchData(page, id);
          setPage(page + 1); // Fetch next page when end is reached
          setloading(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [who]); // Add 'data' as a dependency to reattach event listener when data changes

  return (
    <>
      <div className="Supported mb-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
        <button
          type="button"
          className="flex w-full items-center justify-between rounded-t-xl border border-b-0 border-gray-200 bg-sky-50 p-5 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800"
        >
          <span>Who Watch ?</span>
        </button>
        <div>
          <div className="border border-b-0 border-gray-200 p-5 font-light dark:border-gray-700 dark:bg-gray-900">
            <div className="flex w-full flex-col space-y-4">
              <div className="grid grid-cols-2 gap-4">
                {who.whowatch?.map((have) => {
                  return <Member key={have.id} have={have} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default WhoHave;
