import React from 'react'

export default function SentMessage(props) {
  const msg = props.msg
  return (
    <>
        <div className="col-start-2 col-end-13 p-3 rounded-lg">
              <div className="flex items-center justify-start flex-row-reverse">
                <div
                    className="flex items-center justify-center h-10 w-10 rounded-full bg-sky-500 flex-shrink-0"
                >
                  <img src={msg.sender} className='rounded-full' />
                </div>
                <div
                    className="relative mr-3 text-sm bg-sky-100 py-2 px-4 shadow rounded-xl"
                >
                  <div>{msg.sms} </div>
                </div>
              </div>
            </div>
    </>
  )
}
