import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// CSS Modules, react-datepicker-cssmodules.css//
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import Select from "react-select";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Api from "../Api/Api";
import LoadingPage from "../LoadingPage.js/LoadingPage";

const validationSchema = Yup.object().shape({
  Account: Yup.string()
    .oneOf(["Publisher", "Supporter"], "Please select a gender")
    .required("Account Type is required"),
  Gender: Yup.string()
    .oneOf(["M", "F"], "Please select a gender")
    .required("Gender is required"),
  Date_Birth: Yup.date()
    .max(new Date(), "You can't select a future date")
    .required("Date of birth is required"),
  Phone_Number: Yup.string()
    .matches(/^\+(?:[0-9]●?){6,14}[0-9]$/, "Please enter a valid phone number")
    .required("Phone number is required"),
  Interest: Yup.string()
    .min(1, "Please select at least one Job or Interest")
    .required("Please select at least one Job or Interest"),
});

const initialValues = {
  Account: "",
  Gender: "",
  Phone_Number: "",
  Interest: "",
  Date_Birth: "",
};

function Payment_Info({ formData, setFormData, setPage }) {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [options, setoptions] = useState([{}]);
  const getAllInterest = () => {
    Api.get("/api/interest/getall").then((response) => {
      {
        const datas = [];
        response.data.map((item) => {
          const newRow = { value: item.title, label: item.title };
          datas.push(newRow);
        });
        setoptions(datas);
      }
    });
    //console.log(options);
  };
  useEffect(() => {
    getAllInterest();
  }, []);

  const [value, setValue] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const handleChange = (option) => {
    setSelectedOption(option);
    setFormData({ ...formData, Interest: option.value });
  };

  //submit Sign Up  handler to send a request to the node backend
  const onSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    try {
      setFormData({
        ...formData,
        Account: values.Account,
        Date_Birth: values.Date_Birth,
        Gender: values.Gender,
        Phone_Number: values.Phone_Number,
        Interest: values.Interest,
      });
      console.log(formData);
      if (formData) {
        const data = {
          username: formData.username,
          email: formData.email,
          password_hash: formData.password,
          gender: formData.Gender,
          date_of_birth: formData.Date_Birth,
          type: formData.Account,
          job: formData.Interest,
          phone: formData.Phone_Number,
          admin: "false",
        };
        // make a post request with the user data
        const response = await Api.post("/api/user/newuser/", data);
        // const formDatas = new FormData();
        // formDatas.append("username", formData.username);
        // formDatas.append("email", formData.email);
        // formDatas.append("password_hash", formData.password);
        // formDatas.append("gender", formData.Gender);
        // formDatas.append("date_of_birth", formData.Date_Birth);
        // formDatas.append("type", formData.Account);
        // formDatas.append("job", formData.Interest);
        // formDatas.append("phone", formData.Phone_Number);
        // formDatas.append("admin", false);

        // const response = await Api.post("/api/user/newuser/", formData, {
        //   headers: {
        //     "Content-Type": "multipart/form-data",
        //   },
        // });
        //make a post request with the user data
        // Api.post("/api/user/newuser/", data)
        //   .then((response) => {
        //     console.log("Status Code : ", response.status);
        //   })
        //   .catch((error) => {
        //     console.log("In error");
        //     console.log(error);
        //     alert("SignUp Unsuccessful :(");
        //   });
      }
      const handleClose = () => {
        toast.dismiss();
      };
      setLoading(false);
      // Afficher le message de succès dans un toast
      toast.success("Successful registration ! 🎉", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClick: handleClose, // Utilisation de la référence à la fonction handleClose
      });

      setPage((currPage) => currPage + 1);
      // const timer = setTimeout(() => {
      //   setLoading(false);
      // }, 1500);
      // return () => clearTimeout(timer);
    } catch (error) {
      console.error(error);
      const handleClose = () => {
        toast.dismiss();
      };
      if (error.response) {
        setLoading(false);
        // Le serveur a renvoyé une réponse avec un code d'erreur
        const errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClick: handleClose, // Utilisation de la référence à la fonction handleClose
        }); // Afficher le message d'erreur du serveur dans un toast
      }
    }

    setSubmitting(false);
  };

  return (
    <>
      {loading && <LoadingPage />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="flex  flex-col">
              <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
                <div class="flex flex-row space-x-2">
                  <div className="w-full">
                    <label
                      htmlFor="username"
                      className="text-left mb-2 ml-3 block font-medium text-gray-700 dark:text-white"
                    >
                      Date Of Birth
                    </label>
                    <DatePicker
                      selected={
                        values.Date_Birth ? values.Date_Birth : startDate
                      }
                      onChange={(date) => setFieldValue("Date_Birth", date)}
                      className="w-full h-12 items-center rounded-xl border border-gray-200 bg-white text-leftsm font-medium text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white sm:flex"
                    />

                    <ErrorMessage
                      name="Date_Birth"
                      component="div"
                      className="text-left mt-2 text-red-500 text-xs"
                    />
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="username"
                      className="text-left mb-2 ml-3 block font-medium text-gray-700 dark:text-white"
                    >
                      Gender
                    </label>
                    <ul class="w-full items-center rounded-lg border border-gray-200 bg-white text-leftsm font-medium text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white sm:flex flex-row">
                      <li class="w-full border-b border-gray-200 dark:border-gray-600 sm:border-b-0 sm:border-r">
                        <div class="flex items-center pl-3">
                          <Field
                            id="horizontal-list-radio-license"
                            type="radio"
                            name="Gender"
                            value="M"
                            class="h-4 w-4 border-gray-300 bg-gray-100 text-leftblue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700"
                          />
                          <label
                            for="horizontal-list-radio-license"
                            class="ml-2 w-full py-3 text-leftsm font-medium text-gray-900 dark:text-gray-300"
                          >
                            M{" "}
                          </label>
                        </div>
                      </li>
                      <li class="w-full dark:border-gray-600">
                        <div class="flex items-center pl-3">
                          <Field
                            id="horizontal-list-radio-passport"
                            type="radio"
                            name="Gender"
                            value="F"
                            class="h-4 w-4 border-gray-300 bg-gray-100 text-leftblue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700"
                          />
                          <label
                            for="horizontal-list-radio-passport"
                            class="ml-2 w-full py-3 text-leftsm font-medium text-gray-900 dark:text-gray-300"
                          >
                            F{" "}
                          </label>
                        </div>
                      </li>
                    </ul>
                    <ErrorMessage
                      name="Gender"
                      component="div"
                      className="text-left mt-2 text-red-500 text-xs"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="username"
                    className="text-left mb-2 ml-3 block font-medium text-gray-700 dark:text-white"
                  >
                    Job or Interest
                  </label>
                  <Select
                    options={options}
                    value={selectedOption}
                    onChange={(option) => {
                      setSelectedOption(option);
                      setFieldValue("Interest", option.value);
                    }}
                  />
                  <ErrorMessage
                    name="Interest"
                    component="div"
                    className="text-left mt-2 text-red-500 text-xs"
                  />
                </div>
                <div>
                  <label
                    htmlFor="Phone Number"
                    className="mb-2 ml-3 block text-left font-medium text-gray-700 dark:text-white"
                  >
                    Phone Number
                  </label>
                  <div className="mb-0 flex items-center rounded-xl border bg-white shadow-md">
                    <span className="px-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-6 w-6 text-gray-700"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                        ></path>
                      </svg>
                    </span>
                    <Field
                      className="h-12 w-full border-0 border-white focus:outline-none"
                      type="text"
                      name="Phone_Number"
                      placeholder="+................"
                    />
                  </div>
                  <ErrorMessage
                    name="Phone_Number"
                    component="div"
                    className="text-left mt-2 text-red-500 text-xs"
                  />
                </div>
                <div>
                  <label
                    htmlFor="username"
                    className="text-left mb-2 ml-3 block font-medium text-gray-700 dark:text-white"
                  >
                    Type of Account
                  </label>

                  <ul class="w-full items-center rounded-lg border border-gray-200 bg-white text-leftsm font-medium text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white sm:flex">
                    <li class="w-full border-b border-gray-200 dark:border-gray-600 sm:border-b-0 sm:border-r">
                      <div class="flex items-center pl-3">
                        <Field
                          id="horizontal-list-radio-license"
                          type="radio"
                          name="Account"
                          value="Publisher"
                          class="h-4 w-4 border-gray-300 bg-gray-100 text-leftblue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700"
                        />
                        <label
                          for="horizontal-list-radio-license"
                          class="ml-2 w-full py-3 text-leftsm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Publisher{" "}
                        </label>
                      </div>
                    </li>
                    <li class="w-full dark:border-gray-600">
                      <div class="flex items-center pl-3">
                        <Field
                          id="horizontal-list-radio-license"
                          type="radio"
                          name="Account"
                          value="Supporter"
                          class="h-4 w-4 border-gray-300 bg-gray-100 text-leftblue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700"
                        />
                        <label
                          for="horizontal-list-radio-passport"
                          class="ml-2 w-full py-3 text-leftsm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Supporter{" "}
                        </label>
                      </div>
                    </li>
                  </ul>
                  <ErrorMessage
                    name="Account"
                    component="div"
                    className="text-left mt-2 text-red-500 text-xs"
                  />
                </div>
              </div>
              <div className="mb-5 mt-5 flex w-full items-center justify-between">
                <button
                  className="px-auto mx-2 w-20 rounded-xl border-0 bg-sky-500 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none"
                  onClick={() => {
                    setPage((currPage) => currPage - 1);
                  }}
                >
                  Back
                </button>
                <button
                  className="px-auto mx-2 w-20 rounded-xl border-0 bg-sky-500 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Creating..." : "Finish"}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ToastContainer />
    </>
  );
}

export default Payment_Info;
