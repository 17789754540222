import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Api from "../Api/Api";
import LoadingPage from "../LoadingPage.js/LoadingPage";
import { GlobalFunctionContext } from "../../context";

const validationSchema = Yup.object().shape({
  message: Yup.string().required("Please Enter your message"),
  rating: Yup.string().required("Please Click on One of the Starts"),
});

const initialValues = {
  message: "",
  rating: "",
};

export default function ReportPopUp(props) {
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Password_show, setPasswordShow] = useState(false);
  const [Error, SetError] = useState(null);
  const [rating, setRating] = useState(0);
  const handleClick = (value) => {
    setRating(value);
  };
  const Global = useContext(GlobalFunctionContext);
  const handleClose = () => {
    toast.dismiss();
  };
  const onSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    const data = {
      user_id: localStorage.getItem("userid"),
      message: values.message,
      rating: values.rating,
    };
    Api.post("api/addfeedback", data)
      .then((response) => {
        toast.success("Feedback Sended", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClick: handleClose, // Utilisation de la référence à la fonction handleClose
        });
        setLoading(false);
        setTimeout(function () {
          Global.setSupportPop(false);
        }, 2000);
        console.log(response.data);
      })
      .catch((error) => {
        // Handle errors
        if (error.response) {
          setLoading(false);
          // Le serveur a renvoyé une réponse avec un code d'erreur
          const errorMessage = error.response.data.error;
          SetError(errorMessage);
          toast.error(errorMessage, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            onClick: handleClose, // Utilisation de la référence à la fonction handleClose
          }); // Affiche le message d'erreur dans le toast
        } else if (error.request) {
          // La requête a été faite mais aucune réponse n'a été reçue
          console.log(error.request);
        } else {
          // Une erreur s'est produite lors de la configuration de la requête
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      {loading && <LoadingPage />}
      <div
        className="login-popup fixed inset-0 z-40 flex flex-col items-center justify-center"
        style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <div class="h-auto w-auto rounded-3xl   text-center shadow-lg sm:text-left">
                <div class="w-full relative -mb-5 flex items-end justify-end">
                  <div
                    onClick={() => {
                      Global.setSupportPop(false);
                    }}
                    class="close-signup-popup  h-8 w-8 rounded-full bg-sky-500 hover:bg-sky-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="h-8 w-8 text-white"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                </div>
                <div class="flex w-full max-w-2xl flex-col rounded-3xl border bg-white p-8">
                  <div class="w-full flex justify-center items-center">
                    <img
                      src="./images/Logo.png"
                      class="h-12 w-12 scale-110"
                      alt="Logos"
                    />
                  </div>

                  <h2 class="title-fon mb-1 w-full text-center text-lg font-light text-sky-900">
                    Feedback/Support
                  </h2>
                  <p class="mb-5 leading-relaxed hidden text-gray-600">
                    How Have You Experience With ErekaBose ?
                  </p>
                  <div class="flex flex-col items-center py-6 space-y-3">
                    <span class="text-md text-gray-600">
                      How Have You Experience With Our ErekaBose ?
                    </span>
                    <div className="flex space-x-3">
                      {[1, 2, 3, 4, 5].map((value) => (
                        <svg
                          key={value}
                          className={`w-12 h-12 ${
                            value <= rating
                              ? "text-yellow-500"
                              : "text-gray-500"
                          }`}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          onClick={() => {
                            handleClick(value);
                            setFieldValue("rating", value);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      ))}
                    </div>
                    <div className="flex flex-row items-center justify-center">
                      <div
                        className={` ${
                          rating !== 0
                            ? "bg-gray-50 text-2xl font-bold  rounded-2xl px-3 py-1.5 text-yellow-500"
                            : "bg-gray-50 text-2xl font-bold  rounded-2xl px-3 py-1.5 text-gray-500"
                        }`}
                      >
                        {rating === 5
                          ? "Excellent"
                          : rating === 4
                          ? "Good"
                          : rating === 3
                          ? "Fair"
                          : rating === 2
                          ? "Poor"
                          : rating === 1
                          ? "Bad"
                          : "Unrated"}
                      </div>
                    </div>
                  </div>

                  <div class="mb-4 hidden">
                    <label for="email" class="text-sm leading-7 text-gray-600">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      class="w-full rounded border border-gray-300 bg-white py-1 px-3 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-sky-500 focus:ring-2 focus:ring-sky-200"
                    />
                  </div>
                  <div class="mb-4">
                    <label
                      for="message"
                      class="text-sm leading-7 text-gray-600"
                    >
                      Message
                    </label>
                    <Field
                      as="textarea"
                      id="message"
                      rows="4"
                      name="message"
                      placeholder="Write Here Your Message"
                      class="h-32 w-full resize-none rounded border border-gray-300 bg-white py-1 px-3 text-base leading-6 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-sky-500 focus:ring-2 focus:ring-sky-200"
                    />
                    <ErrorMessage
                      name="message"
                      component="div"
                      className="text-left mt-2 text-red-500 text-xs"
                    />
                    <ErrorMessage
                      name="rating"
                      component="div"
                      className="text-left mt-2 text-red-500 text-xs"
                    />
                  </div>
                  <button class="rounded-xl border-0 bg-sky-500 py-2 px-6 text-lg text-white hover:bg-sky-600 focus:outline-none">
                    Send
                  </button>
                  <p class="mt-3 text-xs text-gray-500">
                    Feel free to connect with us on social media platforms.
                  </p>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ToastContainer />
    </>
  );
}
