import React, { useContext, useRef, useState } from "react";
import { PricingContext } from "../../context";
import { SocialContext } from "../../context";
import Social from "../Allposts/Social";
import { useNavigate } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import SwiperCore, { Autoplay, Pagination } from "swiper";
SwiperCore.use([Autoplay, Pagination]);
function SocialNetwork() {
  const social = useContext(SocialContext);
  const swiper = useSwiper();
  const value = useContext(PricingContext);
  let i = 0;
  const navigate = useNavigate();
  return (
    <>
      <section className="bg-white  dark:bg-gray-900">
        <div className="max-w-screen-xl px-4  lg:pt-8  mx-auto lg:pb-16">
          <div className=" z-10 mx-10 overflow-hidden w-auto h-auto">
            <Swiper
              spaceBetween={13}
              slidesPerView={6}
              autoplay={{ delay: 2000, disableOnInteraction: false }}
              loop={true}
              className="tab-btns w-auto h-auto "
              id="swiper-wrapper-66d7ef5548866805"
              aria-live="polite"
            >
              <div className="swiper-wrapper">
                {value.Pricing?.map((Pricing) => {
                  return (
                    <>
                      <SwiperSlide
                        data-tab-btn={Pricing.id}
                        key={Pricing.id}
                        className="swiper-slide tab  w-auto flex flex-row items-center rounded-xl py-1 pr-1 h-auto swiper-slide-active"
                        role="group"
                        aria-label="1 / 21"
                        style={{
                          width: 228.667 + "px",
                          marginRight: 15 + "px",
                        }}
                      >
                        <div className="flex flex-row items-center justify-center rounded-xl bg-gray-200 px-3 py-4">
                          <img
                            className="h-12 w-12 "
                            src={`images/${Pricing.file}`}
                          />
                          <div className="text-sm text-gray-700">
                            {Pricing.platform}
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  );
                })}
              </div>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}
export default SocialNetwork;
