import React from "react";
import PostToShare from "./PostToShare";

function SettingComponent() {
  return (
    <>
      <PostToShare />
    </>
  );
}
export default SettingComponent;
