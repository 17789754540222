import React, { useContext } from "react";
import { PricingContext } from "../../context";
import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Api from "../Api/Api";
function AllCompanieBuyComponent() {
  const history = useNavigate();
  const value = useContext(PricingContext);
  const { category_id } = useParams();
  useEffect(() => {
    value.AllCompanies(category_id);
  }, []);

  const [search, setSearch] = useState("");
  const handleSearch = (e) => {
    setSearch(e);
  };
  console.log(value);
  const user_id = localStorage.getItem("userid");
  const AddPreference = async (company_id) => {
    if (localStorage.getItem("userid")) {
      try {
        const response = await Api.post("/api/news/view", {
          company_id,
          user_id,
        });
        console.log(response.data);
        history(`/pricing/companies/${company_id}`);
        if (response.data) {
          history(`/pricing/companies/${company_id}`);
        }
        // Handle success response
      } catch (error) {
        console.error(error);
        // Handle error response
      }
    }
  };

  return (
    <>
      <div className="My_Contribution">
        <div className="mb-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
          <div className="flex w-full flex-row items-center justify-center rounded-t-xl border border-b-0 border-gray-200 bg-gray-50 p-3 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800">
            <div className="flex w-full items-center justify-center space-x-4">
              <div className="relative">
                <input
                  type="search"
                  onKeyDown={(e) => handleSearch(search)}
                  onChange={(e) => handleSearch(e.target.value)}
                  id="password"
                  className="w-full rounded-xl border-2 border-gray-200 py-2 pl-3 pr-10 text-gray-600 transition-colors hover:border-gray-300 focus:border-sky-500 focus:outline-none"
                  placeholder="Search..."
                  value={search}
                />
                <button className="leading-0 absolute top-2 right-2 block h-7 items-center justify-center rounded-xl bg-sky-600 px-3 text-center text-xl text-white transition-colors hover:text-gray-300 focus:outline-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </button>
              </div>
              <div>
                <ul className="inline-flex items-center -space-x-px">
                  <li>
                    <Link
                      to="#"
                      className="ml-0 block rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      <span className="sr-only">Previous</span>
                      <svg
                        aria-hidden="true"
                        className="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="block rounded-r-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      <span className="sr-only">Next</span>
                      <svg
                        aria-hidden="true"
                        className="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="border border-b-0 border-gray-200 p-2 font-light dark:border-gray-700 dark:bg-gray-900">
            <div className="w-full space-y-4">
              <div className="relative h-full overflow-hidden">
                <div className="-mx-3 flex h-full flex-col divide-y overflow-y-auto overflow-x-hidden">
                  <div className="today mb-2">
                    <div class="px-4">
                      <div class="py-1">
                        <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                          <div class="min-h-[110px] flex items-center flex-col min-w-full shadow-md rounded-xl border overflow-hidden p-3">
                            {value.Companies?.map((order) => {
                              return (
                                <>
                                  <div className="flex items-center justify-between h-full w-full ">
                                    <div className="flex items-start space-x-2">
                                      {order.user.profile_picture ? (
                                        <img
                                          className="mx-1 h-9 w-9 flex-shrink-0 rounded-full object-cover"
                                          src={`http://backend.novadiscova.server.cisabu.bi/uploads/PhotoUser/${order.user.profile_picture}`}
                                          alt={""}
                                        />
                                      ) : (
                                        <div className="mx-1 h-[60px] w-[60px] flex-shrink-0 flex items-center justify-center rounded-full bg-sky-50 border-sky-300 border object-cover">
                                          <span class="text-4xl text-sky-500 uppercase font-medium">
                                            {order.user.username?.substring(
                                              0,
                                              1
                                            )}
                                          </span>
                                        </div>
                                      )}
                                      <div className=" mt-2 space-y-2 flex flex-col items-start justify-start">
                                        <h2 className="text-lg text-sky-500 uppercase font-semibold leading-none">
                                          {order.user.username}
                                        </h2>
                                        <h2 className="text-sm text-sky-500   leading-none">
                                          {order.user.job}
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex   flex-col  justify-center ">
                                    <div class="flex flex-row space-x-3 px-2">
                                      <div class="flex flex-row space-x-3 items-center justify-center">
                                        <div class="flex flex-row space-x-2 text-yellow-500 items-center justify-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            class="w-6 h-6"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                            ></path>
                                          </svg>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            class="w-6 h-6"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                            ></path>
                                          </svg>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            class="w-6 h-6"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                            ></path>
                                          </svg>
                                        </div>
                                        <span class="text-lg text-gray-600 font-semibold ">
                                          {order.companie.number_Order}
                                        </span>
                                      </div>
                                      <div class="bg-gray-100 py-2 px-3 flex flex-row rounded-xl">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="currentColor"
                                          class="w-6 h-6"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                                          ></path>
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                                          ></path>
                                        </svg>
                                        <span class="rounded-r-xl text-base text-gray-700">
                                          {order.companie.location}
                                        </span>
                                      </div>
                                    </div>
                                    <p className="p-3">
                                      {order.companie.description.length >= 200
                                        ? order.companie.description.substring(
                                            0,
                                            200
                                          ) + "...."
                                        : order.companie.description}
                                    </p>
                                  </div>

                                  <div className="flex   flex-row items-center justify-between w-full mt-2 ">
                                    <h2 className="flex  flex-row items-center text-xl font-semibold text-gray-500 bg-gray-100 rounded-xl px-3 py-2">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="mr-2 h-6 w-6"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                                        ></path>
                                      </svg>

                                      <h2 className="text-sm text-gray-500 uppercase font-semibold leading-none">
                                        {order.platform_services.length}{" "}
                                        Services Availables
                                      </h2>
                                    </h2>
                                    <div className="">
                                      <div className="font-display text-center text-sm font-semibold text-gray-500 flex items-center justify-center">
                                        <Link to={`/pricing/companies/${order.user.id}`}
                                          class="text-white bg-sky-500 hover:bg-sky-800 focus:ring-4 focus:ring-sky-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5  dark:bg-sky-600 dark:hover:bg-sky-700 focus:outline-none dark:focus:ring-sky-800"
                                        >
                                          View/Contact
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllCompanieBuyComponent;
