import React from 'react'

export default function NotifItem(props) {
  props = props.notif
  return (
    <>
        <div className=" flex flex-row items-center justify-start px-4">
          <div className="mt-2 hover:shadow-md cursor:pointer flex w-full rounded-lg bg-gray-50 p-3">
              <div className="flex h-8 w-8 items-center justify-center rounded-full border border-gray-200 focus:outline-none">
                <img src={props.photo} className='rounded-full' alt="icon" />
              </div>
              <div className="pl-3">
                <p tabindex="0" className="text-sm leading-none focus:outline-none">
                  <span className="text-indigo-700">{props.user}</span> favourited an 
                  <span className="text-indigo-700">item</span>
                </p>
                <p tabindex="0" className="pt-1 text-xs leading-3 text-gray-500 focus:outline-none">2 hours ago</p>
              </div>
            </div>
        </div>
    </>
  )
}
