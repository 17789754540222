import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Api from "../Api/Api";
import LoadingPage from "../LoadingPage.js/LoadingPage";
import { GlobalFunctionContext } from "../../context";

export default function AdsPicturePop(props) {
  const Global = useContext(GlobalFunctionContext);
  const [loading, setLoading] = useState(false);
  const [showmore, SetShow] = useState(false);
  const TimeAgo = (dateString) => {
    const currentDate = new Date();
    const pastDate = new Date(dateString);

    const timeDifference = currentDate.getTime() - pastDate.getTime();

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return years === 1 ? "1 year ago" : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? "1 month ago" : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1 min ago" : `${minutes} min ago`;
    } else {
      return seconds === 1 ? "Now" : "Now";
    }
  };
  return (
    <>
      {loading && <LoadingPage />}
      <div
        className="login-popup fixed inset-0 z-40 flex flex-col items-center justify-center"
        style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
      >
        <div className="flex min-w-[640px]    md:max-w-[720px]  flex-row items-center justify-center ">
          <div className="mb-4  rounded-3xl  ">
            <div className="items min-h-48 flex items-start justify-start w-full">
              <div className="flex flex-row w-full">
                <div className="min-w-[640px]    md:max-w-[720px] bg-gray-50 text-gray-900 border border-gray-300 rounded-3xl shadow-md ">
                  <div className="flex items-center justify-between p-3">
                    <div className="flex items-center space-x-2">
                      <img
                        src={`http://backend.novadiscova.server.cisabu.bi/uploads/Ads/${Global.Ads.file}`}
                        alt=""
                        className="border border-sky-300 h-16 w-16 rounded-full object-cover object-center shadow-sm"
                      />
                      <div className="-space-y-1">
                        <h2 className="text-lg text-sky-600 font-semibold leading-none">
                          {Global.Ads.User.username}
                        </h2>
                        <span className="text-coolGray-400 inline-block text-sm leading-none">
                          {" "}
                          {Global.Ads.User.job}{" "}
                        </span>
                      </div>
                    </div>

                    <div className="w-auto ml-10 bg-gray-200 rounded-lg flex flex-row space-x-2 ">
                      <span className=" font-semibold italic">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </span>
                      <span className="w-auto rounded-lg bg-sky-500 px-2 text-white">
                        {TimeAgo(Global.Ads.createdAt)}
                      </span>
                    </div>

                    <button
                      title="Open options"
                      type="button"
                      className="-mt-5"
                    >
                      <div
                        onClick={() => Global.setAdsPop(false)}
                        className="close-login-popup  h-10 w-10 rounded-full bg-sky-500 opacity-80 hover:bg-sky-700"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="h-10 w-10 text-white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          ></path>
                        </svg>
                      </div>
                    </button>
                  </div>

                  <div className="flex w-full items-start justify-between flex-col px-3 text-sm">
                    <div className="description w-full   bg-gray-100 px-2 py-2 rounded-3xl text-gray-700 ">
                      <div
                        className={
                          showmore
                            ? "description_text  overflow-hidden "
                            : "description_text  overflow-hidden  h-10"
                        }
                      >
                        <p className="px-3 pt-1 text-lg ">
                          {Global.Ads.description}{" "}
                        </p>
                      </div>
                      {Global.Ads.description.length >= 20 ? (
                        <button
                          type="button"
                          onClick={() => SetShow(!showmore)}
                          className="text-gray-900 cursor-auto font-extralight  bg-gray-300 rounded-lg px-1 mt-2"
                        >
                          {!showmore ? "ShowMore" : "ShowLess"}
                        </button>
                      ) : null}
                    </div>
                  </div>

                  <div className="mt-5">
                    {Global.Ads.file && (
                      <div className="relative  w-full  overflow-hidden">
                        <div className="h-32 md:h-[520px] ">
                          <img
                            src={`http://backend.novadiscova.server.cisabu.bi/uploads/Ads/${Global.Ads.file}`}
                            alt="product 1"
                            className="object-contain  md:min-w-[289px] h-full w-full rounded-3xl"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
