export const EventsData = [
    {
      id: 1,
      name: "BUK-C BOY",
      cat: "Musician",
      desc: "This Week,We Will Drop a new Song Title: Sona Produced by:Mocco",
      photo: "https://source.unsplash.com/150x150/?portrait?1",
      sups: 250,
      date: "3 march 2022 7:21:44",
      dure: 5     
    },
    {
        id: 2,
        name: "BUK-C BOY",
        cat: "Musician",
        desc: "This Week,We Will Drop a new Song Title: Sona Produced by:Mocco",
        photo: "https://source.unsplash.com/150x150/?portrait?2",
        sups: 100,
        date: "3 march 2023 5:21:10",
        dure: 2
    },
    {
        id: 3,
        name: "BUK-C BOY",
        cat: "Musician",
        desc: "This Week,We Will Drop a new Song Title: Sona Produced by:Mocco",
        photo: "https://source.unsplash.com/150x150/?portrait?3",
        sups: 250,
        date: "3 march 2015 18:21:20",
        dure: 4
    },
    {
        id: 4,
        name: "BUK-C BOY",
        cat: "Musician",
        desc: "This Week,We Will Drop a new Song Title: Sona Produced by:Mocco",
        photo: "https://source.unsplash.com/150x150/?portrait?4",
        sups: 250,
        date: "3 march 2015 20:21:44",
        dure: 1
          
    },
    {
        id: 5,
        name: "BUK-C BOY",
        cat: "Musician",
        desc: "This Week,We Will Drop a new Song Title: Sona Produced by:Mocco",
        photo: "https://source.unsplash.com/150x150/?portrait?5",
        sups: 250,
        date: "3 march 2020 20:21:00",
        dure: 5
    },
    {
        id: 6,
        name: "BUK-C BOY",
        cat: "Musician",
        desc: "This Week,We Will Drop a new Song Title: Sona Produced by:Mocco",
        photo: "https://source.unsplash.com/150x150/?portrait?6",
        sups: 250,
        date: "3 march 2023 17:21:44",
        dure: 2
    }
  ];
  export const detailEvent={
    id: 1,
    name: "BUK-C BOY",
    cat: "Musician",
    desc: "This Week,We Will Drop a new Song Title: Sona Produced by:Mocco",
    photo: "https://source.unsplash.com/150x150/?portrait?1",
    sups: 250,
    date: "3 march 2022 7:21:44",
    dure: 5
      
  }