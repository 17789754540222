import React from "react";
import AdsDetail from "../components/MyAds/AdsDetail/AdsDetail";

function MyAdsPage() {
  return (
    <>
      <AdsDetail />
    </>
  );
}
export default MyAdsPage;
