const countries = [
  {
    name: "Burundi",
    states: [
      { name: "Bubanza" },
      { name: "Bujumbura Mairie" },
      { name: "Bujumbura Rural" },
      { name: "Bururi" },
      { name: "Cankuzo" },
      { name: "Cibitoke" },
      { name: "Gitega" },
      { name: "Karuzi" },
      { name: "Kayanza" },
      { name: "Kirundo" },
      { name: "Makamba" },
      { name: "Muramvya" },
      { name: "Muyinga" },
      { name: "Mwaro" },
      { name: "Ngozi" },
      { name: "Rumonge" },
      { name: "Rutana" },
      { name: "Ruyigi" },
    ],
  },
  {
    name: "Kenya",
    states: [
      { name: "Nairobi" },
      { name: "Mombasa" },
      { name: "Kisumu" },
      { name: "Nakuru" },
      { name: "Eldoret" },
      { name: "Nyeri" },
      { name: "Meru" },
      { name: "Machakos" },
      { name: "Thika" },
      { name: "Kisii" },
      { name: "Malindi" },
      { name: "Naivasha" },
      { name: "Kitale" },
      { name: "Kakamega" },
      { name: "Lamu" },
      { name: "Nanyuki" },
    ],
  },
  {
    name: "Tanzania",
    states: [
      { name: "Dar es Salaam" },
      { name: "Arusha" },
      { name: "Dodoma" },
      { name: "Mwanza" },
      { name: "Mbeya" },
      { name: "Morogoro" },
      { name: "Tanga" },
      { name: "Kilimanjaro" },
      { name: "Zanzibar City" },
      { name: "Iringa" },
      { name: "Shinyanga" },
      { name: "Moshi" },
      { name: "Tabora" },
      { name: "Kigoma" },
      { name: "Mtwara" },
      { name: "Singida" },
      { name: "Morogoro" },
      { name: "Rukwa" },
    ],
  },
  {
    name: "Uganda",
    states: [
      { name: "Kampala" },
      { name: "Gulu" },
      { name: "Lira" },
      { name: "Mbarara" },
      { name: "Jinja" },
      { name: "Bwizibwera" },
      { name: "Mbale" },
      { name: "Mukono" },
      { name: "Kasese" },
      { name: "Masaka" },
      { name: "Entebbe" },
      { name: "Njeru" },
      { name: "Arua" },
      { name: "Kira" },
      { name: "Nakasongola" },
      { name: "Soroti" },
      { name: "Lugazi" },
      { name: "Fort Portal" },
    ],
  },
];

export default countries;
