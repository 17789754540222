import React from "react";
import PostInfos from "./PostInfos";
import TargetOfWork from "./TargetOfWork";
import PostToShare from "./PostToShare";
import SendFeedBack from "./SendFeedBack";
import WhoHave from "./WhoHave";
function PostDetail() {
  return (
    <>
      <PostInfos />
      <WhoHave />
    </>
  );
}
export default PostDetail;
