export const MessageDatas = [
    {
      id: 1,
      sender: "https://source.unsplash.com/150x150/?portrait?1",
      receiver: "https://source.unsplash.com/150x150/?portrait?2",
      sms: "Skate ipsum dolor sit amet, alley oop vert mute-air Colby Carter flail 180 berm.",
      received: true,
      date: "3 march 2023 17:21:44"        
    },
    {
      id: 2,
      sender: "https://source.unsplash.com/150x150/?portrait?1",
      receiver: "https://source.unsplash.com/150x150/?portrait?2",
      sms: "Skate ipsum dolor sit amet, alley oop vert mute-air Colby Carter flail 180 berm.",
      received: true,
      date: "3 march 2023 17:21:44" 
    },
    {
      id: 3,
      sender: "https://source.unsplash.com/150x150/?portrait?2",
      receiver: "https://source.unsplash.com/150x150/?portrait?1",
      sms: "Hey How are you today?",
      received: false,
      date: "3 march 2023 17:21:44" 
    },
    {
      id: 4,
      sender: "https://source.unsplash.com/150x150/?portrait?1",
      receiver: "https://source.unsplash.com/150x150/?portrait?2",
      sms: "Skate ipsum dolor sit amet, alley oop vert mute-air Colby Carter flail 180 berm.",
      received: true,
      date: "3 march 2023 17:21:44" 
          
    },
    {
      id: 5,
      sender: "https://source.unsplash.com/150x150/?portrait?2",
      receiver: "https://source.unsplash.com/150x150/?portrait?1",
      sms: "Skate ipsum dolor sit amet, alley oop vert mute-air Colby Carter flail 180 berm.",
      received: false,
      date: "3 march 2023 17:21:44" 
    },
    {
      id: 6,
      sender: "https://source.unsplash.com/150x150/?portrait?1",
      receiver: "https://source.unsplash.com/150x150/?portrait?2",
      sms: "Skate ipsum dolor sit amet, alley oop vert mute-air Colby Carter flail 180 berm.",
      received: true,
      date: "3 march 2023 17:21:44" 
    }
  ];
  