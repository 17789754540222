import React from 'react'

export default function PayPoup(props) {
  return (
    <div>
        <div className="payment-popup fixed inset-0 z-40 flex items-center justify-center" style={{backgroundColor: "rgba(0,0,0,0.3)"}}>
  <div className=" min-w-screen z-40 flex min-h-screen items-center justify-center ">
  <div className="mx-auto w-full rounded-lg bg-white p-5 text-gray-700 shadow-lg" style={{maxWidth: 700+"px"}}>
    <div className="flex w-full pt-1 mb-3 ">
      <img src="images/Logo.png" className="mx-auto  flex h-10 w-10 items-center justify-center overflow-hidden rounded-full  text-white shadow-lg"/>
      <div onClick={()=>props.handlePopuppay(false)} className="close-payment-popup h-8 w-8 rounded-full bg-sky-500 hover:bg-sky-700">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-8 w-8 text-white">
          <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
    </div>
    <div className="mb-3">
      <h1 className="text-center text-xl font-bold text-gray-600">Your Total Balance</h1>
    </div>

    <div className="mb-3">
      <div className="mx-auto w-1/2">
        <div className="group relative">
          <div className="absolute -inset-1 rounded-lg bg-gradient-to-r from-sky-600 to-sky-600 opacity-25 blur transition duration-1000 group-hover:opacity-100 group-hover:duration-200"></div>
          <div className="items-top relative flex justify-start space-x-6 rounded-lg bg-white px-7 py-6 leading-none ring-1 ring-gray-900/5">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="absolute top-3 h-6 w-6 text-sky-600" >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>

            <span className="text-2xl font-bold text-sky-500">140000fbu</span>
          </div>
        </div>
      </div>
    </div>

    <div className="">
      <h2 className="font-display  w-full xl:text-bold rounded-b-3xl bg-sky-50 py-2 px-3  text-2xl font-semibold text-sky-900 ">Payment</h2>
      <div className="mt-5">
        <form>
          <div className="grid grid-cols-2 gap-3 ">
          <div>
            <div className="text-sm font-bold tracking-wide text-gray-700">Amount of Money</div>
            <input className="w-full border-b border-gray-300 py-2 text-lg focus:border-sky-500 focus:outline-none" type="" placeholder="Entrer the amount" />
          </div>
          <div className="">
            <div className="flex items-center justify-between">
              <div className="text-sm font-bold tracking-wide text-gray-700">Reason </div>
            </div>
            <input className="w-full border-b border-gray-300 py-2 text-lg focus:border-sky-500 focus:outline-none" type="" placeholder="Enter The Reason" />
          </div>
          <div className="">
            <div className="flex items-center justify-between">
              <div className="text-sm font-bold tracking-wide text-gray-700">Password</div>
            </div>
            <input className="w-full border-b border-gray-300 py-2 text-lg focus:border-sky-500 focus:outline-none" type="" placeholder="Enter your password" />
          </div>
          <div className="">
            <div className="flex items-center justify-between">
              <div className="text-sm font-bold tracking-wide text-gray-700">Secret Word</div>
            </div>
            <input className="w-full border-b border-gray-300 py-2 text-lg focus:border-sky-500 focus:outline-none" type="" placeholder="Enter your Secret Word" />
          </div>
          </div>
          <div className="mt-10 mb-5">
            <button className="font-display focus:shadow-outline w-full rounded-full bg-sky-500 p-4 font-semibold tracking-wide text-gray-100 shadow-lg hover:bg-sky-600 focus:outline-none" onclick="finish('login');">Send Request</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</div>
    </div>
  )
}
