import React from "react";
import My_NeedComponent from "../components/My_Needs/My_NeedComponent";

function My_Needs() {
  return (
    <>
      <My_NeedComponent />
    </>
  );
}
export default My_Needs;
