import React from "react";
import My_HaveComponent from "../components/My__Haves/My_HaveComponent";

function My_Haves() {
  return (
    <>
      <My_HaveComponent />
    </>
  );
}
export default My_Haves;
