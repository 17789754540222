import React, { useContext } from "react";
import { useEffect } from "react";
import { useState, CSSProperties } from "react";
import { BeatLoader } from "react-spinners";
import Pub from "./Pub";
import { PostContext } from "../../context";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

export default function Pubs() {
  const posts = useContext(PostContext);
  console.log(posts);
  const [page, setPage] = useState(1);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    setloading(true);
    posts.fetchData(page);
    setPage(page + 1);
    setloading(false);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      // Check if user has scrolled to the bottom of the page

      if (page < posts.totalPages) {
        if (
          window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 50
        ) {
          console.log("pass");
          setloading(true);
          posts.fetchData(page);
          setPage(page + 1); // Fetch next page when end is reached
          setloading(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [posts]); // Add 'data' as a dependency to reattach event listener when data changes

  return (
    <>
      <div className="w-full  space-y-4 flex flex-col">
        <div className="grid grid-cols-2 mb-6 gap-5 p-2">
          {posts.posts?.map((post) => {
            return <Pub key={post.id} post={post} />;
          })}
        </div>
        {/* <div className="mt-5 flex w-full items-center justify-center">
          <button
            onClick={fetch}
            type="button"
            className="mr-2 mb-2 w-48 rounded-lg border border-gray-200  py-2.5 px-5 text-sm font-medium  bg-gray-100 text-sky-600 hover:text-sky-800 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
          >
            Load more
          </button>
        </div> */}
        {loading && (
          <div class="w-full flex items-center justify-center">
            {" "}
            <div class="ml-4 mt-5 w-80 flex flex-row items-center">
              <BeatLoader
                cssOverride={override}
                size={25}
                aria-label="Loading Spinner"
                data-testid="loader"
                color="#0ea5e9"
              />
              <BeatLoader
                cssOverride={override}
                size={25}
                aria-label="Loading Spinner"
                data-testid="loader"
                color="#0ea5e9"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
