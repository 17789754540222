import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingPage from "../LoadingPage.js/LoadingPage";
import Api from "../Api/Api";
const validationSchema = Yup.object().shape({
  platform_category: Yup.string().required("Select At least One Resssource"),
});

const initialValues = {
  platform_category: "",
};

function Service_Type_Info({ formData, setFormData, setPage }) {
  const [loading, setLoading] = useState(false);
  const [service, setService] = useState();
  const getAllService = () => {
    setLoading(true);
    const social = "social";
    Api.get(`/api/platforms/mediaservice/${social}`).then((response) => {
      {
        const datas = response.data;
        setService(response.data);
        setLoading(false);
      }
    });
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setFormData({
      ...formData,
      platform_category: values.platform_category,
    });
    setPage((currPage) => currPage + 1);
    setSubmitting(false);
  };

  const checkuser = () => {
    if (localStorage.getItem("userid")) {
      // Variable is set in localStorage
      const user_id = localStorage.getItem("userid");
      Api.get(`/api/platforms/usermedia/${user_id}`).then((response) => {
        {
          const datas = response.data;
          console.log(response.data);
          if (datas != null) {
            setFormData({
              ...formData,
              alreadyCompany: true,
              platform_category: datas.platform_category_id,
              platform_id: datas.Platforms_category.platform_id,
            });
            setPage((currPage) => currPage + 1);
          } else {
            getAllService();
          }
        }
      });
    }
  };
  useEffect(() => {
    checkuser();
    getAllService();
  }, []);

  const [Space, setSpace] = useState([]);

  function FetchEl(event) {
    const button = event.target;
    const parentElement = button.parentNode;

    // Loop through each button child
    const buttons = parentElement.getElementsByTagName("button");
    for (let i = 0; i < buttons.length; i++) {
      const childButton = buttons[i];
      childButton.classList.remove("border-sky-500");
    }

    if (event.target.classList.contains("border-sky-500")) {
      event.target.classList.remove("border-sky-500");
      setSpace("");
    } else {
      event.target.classList.add("border-sky-500");
      setSpace((current) => [event.target.innerHTML]);
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="flex w-full flex-row items-center justify-center">
              <div className="p-2 rounded-full border border-sky-500 text-sky-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="bi bi-postcard w-7 h-7"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2ZM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4Zm7.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7ZM2 5.5a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5ZM10.5 5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3ZM13 8h-2V6h2v2Z"
                  />
                </svg>
              </div>
            </div>
            <div className="flex  flex-col">
              <div className="w-full flex items-center justify-center">
                <p className="mb-6 max-w-2xl font-medium text-sky-500 dark:text-gray-400 md:text-lg lg:mb-8 lg:text-2xl">
                  Select One Ressource You Have ??
                </p>
              </div>
              <div className="item mx-5 px-5 h-auto w-auto bg-gray-50 rounded-xl shadow-md mb-10 py-5">
                <div className="grid grid-cols-3 gap-3">
                  {service?.map((service) => {
                    return (
                      <button
                        onClick={(e) => {
                          FetchEl(e);
                          setFieldValue("platform_category", service.id);
                          setFormData({
                            ...formData,
                            platform_id: service.platform_id,
                          });
                        }}
                        className="rounded-xl bg-gray-200 border-2 px-3 py-4 text-center text-sm text-gray-700 hover:scale-105 hover:duration-400 hover:ease-in-out"
                      >
                        {service.category}
                      </button>
                    );
                  })}
                </div>
                <div class="flex flex-row items-center justify-center">
                  {" "}
                  <ErrorMessage
                    name="platform_category"
                    component="div"
                    className="text-left mt-2 text-red-500 text-sm"
                  />
                </div>
              </div>

              <div className="mb-5 mt-5 flex w-full items-center justify-center">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-1/2 rounded-xl border-0 bg-sky-500 px-6 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none"
                >
                  {isSubmitting ? "Continue..." : "Continue"}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default Service_Type_Info;
