import React from "react";

import TvOnlineComponent from "../components/Buy/TvOnlineComponent";
function TvOnlineBuy() {
  return (
    <>
      <TvOnlineComponent />
    </>
  );
}
export default TvOnlineBuy;
