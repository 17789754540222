import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Api from "../Api/Api";
import LoadingPage from "../LoadingPage.js/LoadingPage";
import { GlobalFunctionContext } from "../../context";

const validationSchema = Yup.object().shape({
  message: Yup.string().required("Please Enter your message"),
});

const initialValues = {
  message: "",
};

export default function ReportPopUp(props) {
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Password_show, setPasswordShow] = useState(false);
  const [Error, SetError] = useState(null);
  const Global = useContext(GlobalFunctionContext);
  const handleClose = () => {
    toast.dismiss();
  };
  const onSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    const data = {
      user_id: localStorage.getItem("userid"),
      message: values.message,
    };
    Api.post("api/addreport", data)
      .then((response) => {
        toast.success("Report Sended", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClick: handleClose, // Utilisation de la référence à la fonction handleClose
        });
        setLoading(false);
        setTimeout(function () {
          Global.setReportPop(false);
        }, 2000);
        console.log(response.data);
      })
      .catch((error) => {
        // Handle errors
        if (error.response) {
          setLoading(false);
          // Le serveur a renvoyé une réponse avec un code d'erreur
          const errorMessage = error.response.data.error;
          SetError(errorMessage);
          toast.error(errorMessage, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            onClick: handleClose, // Utilisation de la référence à la fonction handleClose
          }); // Affiche le message d'erreur dans le toast
        } else if (error.request) {
          // La requête a été faite mais aucune réponse n'a été reçue
          console.log(error.request);
        } else {
          // Une erreur s'est produite lors de la configuration de la requête
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  return (
    <>
      {loading && <LoadingPage />}
      <div
        className="login-popup fixed inset-0 z-40 flex flex-col items-center justify-center"
        style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
      >
        <div className="relative -mb-5 flex w-[320px] md:w-[600px] items-end justify-end">
          <div
            onClick={() => {
              Global.setReportPop(false);
            }}
            className="close-login-popup h-8 w-8 rounded-full bg-sky-500 opacity-80 hover:bg-sky-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-8 w-8 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </div>
        </div>
        <div className="flex  w-[320px] h-[450px] md:w-[600px]  flex-row items-center justify-center rounded-3xl bg-white text-center shadow-lg sm:text-left">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <div class="flex w-[350px] max-w-xl flex-col rounded-lg  p-8">
                  <div class="w-full flex justify-center items-center">
                    <img
                      src="./images/Logo.png"
                      class="h-12 w-12 scale-110"
                      alt="Logos"
                    />
                  </div>

                  <h2 class=" mb-5 w-full text-center text-2xl font-light text-sky-600">
                    Report a Problem
                  </h2>

                  <div class="mb-6 w-full font-medium flex flex-col justify-center items-center">
                    <p class=" leading-relaxed text-sm text-gray-600">
                      Thank You For Reporting Us.
                    </p>
                    <p class=" leading-relaxed text-sm  text-gray-600">
                      We Will Correct The Problem As Soon
                    </p>
                    <p class=" leading-relaxed text-sm  text-gray-600">
                      as Possible
                    </p>
                  </div>
                  <div class="mb-4">
                    <label
                      for="message"
                      class="text-sm leading-7 text-gray-600"
                    >
                      Message
                    </label>
                    <Field
                      as="textarea"
                      id="message"
                      rows="4"
                      name="message"
                      class="h-24 w-full resize-none text-sm  rounded border border-gray-300 bg-white py-1 px-3  leading-6 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-sky-500 focus:ring-2 focus:ring-sky-200"
                      placeholder="Write Here Your Problem"
                    />
                    <ErrorMessage
                      name="message"
                      component="div"
                      className="text-left mt-2 text-red-500 text-xs"
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    class="rounded-xl border-0 bg-sky-500 py-2 px-6 text-lg text-white hover:bg-sky-600 focus:outline-none"
                  >
                    {isSubmitting ? "Sending ..." : "Send"}
                  </button>
                  <p class="mt-3 text-xs text-gray-500">
                    Feel free to connect with us on social media platforms.
                  </p>
                </div>
              </Form>
            )}
          </Formik>

          <div class=" w-1/2 h-auto flex flex-col">
            <img src="./images/support.png" class="w-[250px] h-auto" />
            <p class="mt-3 text-xs text-center text-sky-500">
              Our social media platforms.
            </p>
            <div class="flex items-center  mt-2 justify-center space-x-4  ">
              <a
                rel="noopener noreferrer"
                href="#"
                class="rounded-lg p-2 text-white hover:bg-sky-200"
              >
                <img
                  src="https://img.icons8.com/ios/256/facebook-new.png"
                  class="h-6 w-6"
                />
              </a>
              <a
                rel="noopener noreferrer"
                href="#"
                aria-label="Dribble"
                class="rounded-md p-2 hover:bg-sky-200"
              >
                <img
                  src="https://img.icons8.com/ios/256/twitter.png"
                  class="h-6 w-6"
                />
              </a>
              <a
                rel="noopener noreferrer"
                href="#"
                aria-label="Twitter"
                class="rounded-md p-2 hover:bg-sky-200 dark:text-gray-100 hover:dark:text-violet-400"
              >
                <img
                  src="https://img.icons8.com/ios/256/instagram-new.png"
                  class="h-6 w-6"
                />
              </a>
              <a
                rel="noopener noreferrer"
                href="#"
                aria-label="Email"
                class="rounded-md p-2 hover:bg-sky-200 dark:text-gray-100 hover:dark:text-violet-400"
              >
                <img
                  src="https://img.icons8.com/ios/256/tiktok.png"
                  class="h-6 w-6"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
