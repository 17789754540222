const UnitialState = {
  Carts: [],
};
export const CartsReducer = (State = UnitialState, action) => {
  switch (action.type) {
    case "Add_Cart":
      const { items, quantity } = action.payload;
      const itemIndex = State.Carts.findIndex(
        (item) => item.unique_id
        === items.unique_id
      );

      const temp = { ...items, quantity: quantity };
        return {
          ...State,
          Carts: [...State.Carts, temp],
        };

    
    case "Update_Cart":
      const { prod_items,prod_quantity } = action.payload;
      console.log(State.Carts)
      const prod_index = State.Carts.findIndex(
        (item) => item.unique_id
        === prod_items.unique_id

      );
      if (prod_index >= 0) {
        State.Carts[prod_index].quantity += prod_quantity;
      } 

    // eslint-disable-next-line no-fallthrough
    case "Remove_Cart":
      const data = State.Carts.filter((el) => el.unique_id !== action.payload);
      return {
        ...State,
        Carts: data,
      };

    // eslint-disable-next-line no-fallthrough
    case "Remove_One":
      const products = action.payload;
      console.log(State.Carts)
      const prod_index_found = State.Carts.findIndex(
        (item) => item.unique_id
        === products.unique_id
      );
      if (prod_index_found >= 0) {
        State.Carts[prod_index_found].quantity -= 1;
      }
    // eslint-disable-next-line no-fallthrough
    default:
      return State;
  }
};
