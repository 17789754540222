import React from 'react'
import AllPubs from './AllPubs'

function PubsBox() {
  return (
      <>
        <AllPubs />
    </>
  )
}
export default PubsBox