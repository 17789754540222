import React, { useContext } from "react";
import Other_Images from "./Other_Images";
import "./soft.css"
import * as Yup from "yup";
import { toast } from "react-toastify";
import { ADD, DELETE, REMOVE, UPDATE } from "../../Redux/Action/Action";
import { useDispatch, useSelector } from "react-redux";
import { Accordion } from "flowbite-react";
import { PricingContext } from "../../context";
import { GlobalFunctionContext } from "../../context";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "flowbite-react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
// Import Swiper styles
import "swiper/css";



const validationSchema = Yup.object().shape({
  Phone_Number: Yup.string()
    .matches(/^\+(?:[0-9]●?){6,14}[0-9]$/, "Please enter a valid phone number")
    .required("Phone number is required"),
});

const initialValues = {
  Phone_Number: "",
};

function Quick_OrderComponent() {
  const getData = useSelector((state) => state.CartsReducer.Carts);
  console.log(getData)
  const swiper = useSwiper();
  const value = useContext(PricingContext);
  //quick_ordering/:company_name/:company_id/:menu_name/:menu_id/:table_id
  //test_link : localhost:3000/quick_ordering/Word_Beach/0/Menu_VIP/1/7
  const { company_name, company_id, menu_name, menu_id, table_id } = useParams();
  console.log(company_name, company_id, menu_name, menu_id, table_id)
  useEffect(() => {
    value.handleMenu_Products(menu_id, table_id, company_id);
    value.handleMenu_Portions(menu_id);
    value.handleMenu_Speciales(menu_id);
    value.handleTake_Away_Prices(menu_id);

  }, []);

  console.log("Menu_Products");
  console.log(value);
  const Global = useContext(GlobalFunctionContext);
  const [openAccordion, setOpenAccordion] = React.useState(null);

  const toggleAccordion = (groupName) => {
    setOpenAccordion(openAccordion === groupName ? null : groupName);
  };

  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
    switch (tabNumber) {
      case 1:
        console.log('Tab 1 clicked');
        // Add your specific logic for Tab 1 here
        break;
      case 2:
        console.log('Tab 2 clicked');
        // Add your specific logic for Tab 2 here
        break;
      case 3:
        console.log('Tab 3 clicked');
        // Add your specific logic for Tab 3 here
        break;
    }
  };

  const [activePage, setActivePage] = useState(3);
  const handlePageClick = (PageNumber) => {
    setActivePage(PageNumber);
    switch (PageNumber) {
      case 1:
        console.log('Page 1 clicked');
        // Add your specific logic for Tab 1 here
        break;
      case 2:
        console.log('Page 2 clicked');
        // Add your specific logic for Tab 2 here
        break;
      case 3:
        console.log('Page 3 clicked');
        // Add your specific logic for Tab 3 here
        break;
    }
  };

  const [activePayment, setActivePayment] = useState(1);
  const handlePaymentClick = (PaymentNumber) => {
    setActivePayment(PaymentNumber);
    switch (PaymentNumber) {
      case 1:
        console.log('Page 1 clicked');
        // Add your specific logic for Tab 1 here
        break;
      case 2:
        console.log('Page 2 clicked');
        // Add your specific logic for Tab 2 here
        break;
      case 3:
        console.log('Page 3 clicked');
        // Add your specific logic for Tab 3 here
        break;
    }
  };

  const [price, setprice] = useState(null)
  const [take, settake] = useState(0)
  const [is_take, set_is_take] = useState(false)
  const estimate_price = (price) => {
    {
      let temp_price=0;
      value.Take_Away_Prices?.map((item, index) => {
        console.log(item)
        if (price >= item.start_price && price >= item.start_price) {
          temp_price = price + item.pay_price;
          setprice(temp_price)
          settake(item.pay_price)
          return true
        }
      })
    }
  }

  const return_estimate_price = (price) => {
    {
      let temp_price=0;
      value.Take_Away_Prices?.map((item, index) => {
        console.log(item)
        if (price >= item.start_price && price >= item.start_price) {
          return item.pay_price;
        }
      })
    }
  }
  const total = () => {
    let prices = 0;
    getData.map((e, index) => {
      console.log(e)
      if (e.product.selectedRadio) {
        if (e.product.selectedRadio.length > 0) {
          prices = (Number(e.product.selectedRadio.suggested_price) * e.quantity).toFixed(2) + Number(prices);
          console.log("prices", e.product.selectedRadio.suggested_price)
        }
      }
      else {
        prices = ((Number(e.product.price) * e.quantity) + Number(prices));
        console.log("prices--", prices)
      }

      if (e.product.checkedAdditionalPrices) {
        if (e.product.checkedAdditionalPrices.length > 0) {
          e.product.checkedAdditionalPrices.map((additional) => {
            if (additional.additional_price) {
              prices = (Number(prices) + Number(additional.additional_price))
              console.log("prices", additional.additional_price)
            }
          })
        }
      }
    });
    //console.log(prices)
    if (is_take) {
      estimate_price(prices)
    }
    else {
      setprice(prices);
      settake(return_estimate_price(prices))
    }
    //setprice(Math.floor(prices).toLocaleString("fr-FR"));
  };
  const dispatch = useDispatch();

  const dlt = (id) => {
    dispatch(DELETE(id));
  };
  function handleDelete(e) {
    dlt(e)
  }

  function remove(e) {
    dispatch(REMOVE(e));
  }

  function send(e) {
    dispatch(UPDATE(e, 1));
  }

  const total_sum = (additional_sum) => {
    let total = 0
    additional_sum.map((additional) => {
      if (additional.additional_price) {
        total = (parseFloat(total) + parseFloat(additional.additional_price))
      }
    })

    return total;
  }

  useEffect(() => {
    total();
  }, [total, getData]);

  //submit Sign Up  handler to send a request to the node backend
  const onSubmit = async (values, { setSubmitting }) => {

    try {


    } catch (error) {

    }

    setSubmitting(false);
  };
  return (
    <>

      <section className="sidebar relative py-[20px]  z-10 bg-white px-5 dark:bg-gray-900">
        <div className="w-full flex flex-col items-center justify-center">
          <div className=" flex flex-col items-center justify-center w-[680px]">
            <div className="w-full px-6 mx-auto">
              <div
                className="relative flex items-center p-0 mt-6 overflow-hidden bg-center bg-cover min-h-75  rounded-2xl border border-sky-300  shadow-inner"
                style={{
                  "background-image":
                    "url" + (`http://backend.novadiscova.server.cisabu.bi/uploads/PhotoUser/${value.Menu_Products?.Company.back_profile}`),
                  "background-position-y": "50%",
                }}
              ></div>
              <div className="relative flex flex-col flex-auto min-w-0 p-4 mx-6 -mt-16 overflow-hidden break-words  shadow-blur rounded-2xl bg-white/80 bg-clip-border backdrop-blur-2xl backdrop-saturate-200 border border-sky-300 bg-white shadow-inner ">
                <div className="flex flex-wrap -mx-3">
                  <div className="flex-none w-auto max-w-full px-3">
                    <div className="text-base ease-soft-in-out h-18.5 w-18.5 relative inline-flex items-center justify-center rounded-xl text-white transition-all duration-200">
                      {value.Menu_Products?.Company ? (
                        <img
                          className="mx-1 h-18.5 w-18.5 w-full  flex-shrink-0 shadow-soft-sm rounded-xl object-cover"
                          src={`http://backend.novadiscova.server.cisabu.bi/uploads/PhotoUser/${value.Menu_Products.Company.front_profile}`}
                          alt={""}
                        />
                      ) : (
                        <div className="mx-1 h-18.5 w-18.5  flex-shrink-0 flex items-center justify-center shadow-soft-sm rounded-xl bg-sky-50 border-sky-300 border object-cover">
                          <span className="text-sky-500 uppercase font-medium">
                            {value.Menu_Products?.Company.name?.substring(0, 1)}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex-none w-auto max-w-full px-3 my-auto">
                    <div className="h-full">
                      <h5 className="mb-1 font-bold text-2xl ">
                        {value.Menu_Products?.Company.name}
                      </h5>

                      <div className="flex flex-row space-x-1 text-yellow-500 items-start justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div className="bg-gray-100 py-2  flex flex-row rounded-xl">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                        ></path>
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                        ></path>
                      </svg>
                      <span className="rounded-r-xl text-base text-gray-700">
                        {value.Menu_Products?.Company.location}
                      </span>
                    </div>

                  </div>
                  <div className="w-full max-w-full px-3 mx-auto mt-4 sm:my-auto sm:mr-0 md:w-1/2 md:flex-none lg:w-4/12">
                    <div className="relative right-0">
                      <ul
                        className="relative flex flex-wrap p-1 list-none bg-transparent rounded-xl"
                        nav-pills
                        role="tablist"
                      >
                        <li className="z-30 flex-auto text-center">
                          <a
                            className="z-30 block w-full p-3 bg-sky-500 px-0 py-1 mb-0 transition-all border-0 rounded-lg ease-soft-in-out  text-white"
                            nav-link
                            href="javascript:;"
                            role="tab"
                            aria-selected="false"

                          >
                            <span className="text-xl font-bold ">
                              Table {value.Menu_Products?.Table.numero}
                            </span>
                          </a>
                        </li>
                      </ul>
                      <ul
                        className="relative flex flex-wrap items-center justify-center p-1 list-none bg-transparent rounded-xl"
                        nav-pills
                        role="tablist"
                      >
                        <li className="z-30 flex-auto text-center">
                          {getData.length > 0 ? (
                            <button onClick={() => handlePageClick(1)} className=" mt-2 p-1 text-sm text-center   text-gray-700 rounded-full md:mt-2 md:ml-4 relative items-center ">
                              <span className=" absolute z-10 hover:scale-110 right-3 top-2 bg-sy-500  w-[20px] h-[20px] rounded-[500%] flex justify-center items-center text-white  bg-yellow-800 text-[10px]">
                                {getData.length}
                              </span>
                              <div
                                className={`relative hover:scale-110 cursor-pointer text-[2em] bi bi-cart3  p-2`}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6 text-sky-500"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                                  />
                                </svg>
                              </div>
                            </button>)
                            : (
                              <button onClick={() => handlePageClick(1)} className=" mt-2 p-1 text-sm text-center   text-gray-700 rounded-full md:mt-2 md:ml-4 relative items-center ">

                                <div

                                  className={`relative hover:scale-110 cursor-pointer text-[2em] bi bi-cart3  p-2`}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6 text-sky-500"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                                    />
                                  </svg>
                                </div>
                              </button>)
                          }

                        </li>

                        <li className="z-30 flex-auto text-center">
                          <button onClick={() => handlePageClick(2)}
                            className="z-30 block w-full border border-sky-100 px-0 py-1 mb-0 transition-all border-0 rounded-lg ease-soft-in-out  text-white"
                            nav-link
                            href="javascript:;"
                            role="tab"
                            aria-selected="false"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-6 h-6 text-sky-500"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                              />
                            </svg>
                          </button>
                        </li>

                        <li className="z-30 flex-auto text-center">
                          <button onClick={() => handlePageClick(3)}
                            className="z-30 block w-full border border-sky-100 px-0 py-1 mb-0 transition-all border-0 rounded-lg ease-soft-in-out  text-white"
                            nav-link
                            href="javascript:;"
                            role="tab"
                            aria-selected="false"
                          >

                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-sky-500" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                              <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                            </svg>

                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {activePage === 3 && <div id="Menu" className="rounded-lg p-4 dark:border-gray-700">
              <div className="item w-128  card text-grey-darkest flex h-auto rounded-xl border bg-white shadow-inner">
                <div className="flex w-full flex-col">
                  <div className="flex-1 p-4 pb-0">
                    <div className="flex items-center justify-between p-3">
                      <div className="flex items-center justify-center">
                        <h2 className="flex flex-row items-center text-xl font-semibold text-gray-500">
                          Menu
                        </h2>
                      </div>

                      <div className="flex items-center justify-center">
                        <h2 className="flex flex-row items-center text-xl font-semibold text-gray-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="mr-2 h-6 w-7"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                            ></path>
                          </svg>
                          {"20"} items
                        </h2>
                      </div>

                      <button
                        title="Open options"
                        type="button"
                        onClick={() => "hello"}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 text-sky-500"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="rounded-md bg-gray-100 px-2 py-2"></div>
                  </div>
                </div>
              </div>

              <div className="py-5 z-20  overflow-hidden  w-[680px] ">
                <Swiper
                  spaceBetween={13}
                  slidesPerView={4}
                  className="tab-btns w-auto h-auto "
                  id="swiper-wrapper-66d7ef5548866805"
                  aria-live="polite"
                >
                  <div className="swiper-wrapper">
                    {value.Menu_Products?.Menu_Products?.map((Menu, index) => {
                      return (
                        <>
                          {Menu.Group_categories?.map((Menu_sous_cat, subIndex) => {
                            return (
                              <SwiperSlide key={subIndex}>
                                <div className="flex flex-row items-center justify-center rounded-md border border-gray-200 p-3">
                                  <span className="text-xl font-medium text-gray-700">
                                    {Menu_sous_cat.Group_categorie_name}
                                  </span>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </>
                      );
                    })}
                  </div>
                </Swiper>
              </div>
              <div className="my-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
                <button
                  type="button"
                  className="flex w-full items-center justify-between rounded-t-xl border border-b-0 border-gray-200 bg-sky-50 p-5 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800"
                >
                  <div className="flex flex-row space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 6h.008v.008H6V6Z"
                      />
                    </svg>

                    <span className="text-xl">Products</span>
                  </div>

                  <div className="inline-flex rounded-md shadow-sm">
                    <button
                      href="#"
                      className={`px-4 py-2 text-sm font-medium ${activeTab === 1 ? 'bg-gray-100 text-sky-700' : 'bg-white text-gray-900'} border border-gray-200 rounded-s-lg hover:bg-gray-100 focus:z-10 focus:ring-1 focus:ring-sky-100 focus:text-sky-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-skye-200 dark:focus:text-white`}
                      onClick={() => handleTabClick(1)}
                    >
                      Plats
                    </button>
                    <button
                      className={`px-4 py-2 text-sm font-medium ${activeTab === 3 ? 'bg-gray-100 text-sky-700' : 'bg-white text-gray-900'} border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-sky-700 focus:z-10 focus:ring-1 focus:ring-sky-100 focus:text-sky-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-sky-200 dark:focus:text-white`}
                      onClick={() => handleTabClick(3)}
                    >
                      Plat Speciales
                    </button>
                  </div>

                </button>

                <div className="flex w-full items-center justify-between mb-3 text-md border border-b-0 border-gray-200 rounded-b-3xl bg-white py-5">
                  <div className="w-full max-w-full px-3 lg-max:mt-6 ">
                    <div className="relative flex flex-col h-full min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                      {
                        activeTab === 1 && <>{value.Menu_Products?.Menu_Products?.map((Menu, index) => {
                          return (
                            <>
                              <div className={` ${openAccordion === Menu.Group_name ? 'border-b border-t shadow-xl bg-gray-50 rounded-2xl' : 'border-b'
                                }`}>
                                <button
                                  className="w-full flex justify-between items-center py-4 px-6 focus:outline-none"
                                  onClick={() => toggleAccordion(Menu.Group_name)}
                                >
                                  <div className="p-4 pb-0 mb-0  border-b-0 rounded-t-2xl">
                                    <h6 className="mb-3 text-xl">{Menu.Group_name}</h6>
                                  </div>
                                  <span className={`transition-transform duration-300 ${openAccordion === Menu.Group_name ? 'rotate-180' : ''}`}>
                                    <svg
                                      className="w-6 h-6"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M19 9l-7 7-7-7"
                                      />
                                    </svg>
                                  </span>
                                </button>
                                <div
                                  className={`overflow-hidden transition-max-height duration-300 ${openAccordion === Menu.Group_name ? 'max-h-screen ' : 'max-h-0'
                                    }`}
                                >
                                  <Accordion collapseAll>

                                    {Menu.Group_categories?.map((Menu_sous, subIndex) => {
                                      return (
                                        <Accordion.Panel>
                                          <Accordion.Title>{Menu_sous.Group_categorie_name}</Accordion.Title>

                                          <Accordion.Content><div className="flex-auto p-4">
                                            <ul className="flex flex-col pl-0 mb-0 rounded-lg">
                                              {Menu_sous.Products?.map((Product) => {
                                                return (
                                                  <React.Fragment key={Product.id}>
                                                    <li className="relative flex items-center py-2 px-3 mb-2 bg-white shadow-xl border rounded-2xl text-inherit">
                                                      <div className="inline-flex items-center justify-center h-36 w-36 mr-4 text-white transition-all duration-200 text-base ease-soft-in-out rounded-xl">
                                                        <img
                                                          src={`http://backend.novadiscova.server.cisabu.bi/uploads/Hospitality/${Product.photo}`}
                                                          alt="kal"
                                                          className="w-full shadow-soft-2xl rounded-xl"
                                                        />
                                                      </div>
                                                      <div className="flex flex-col items-start justify-center">
                                                        <span className="mb-1.5 leading-normal text-xl font-medium">
                                                          {Product.item_name}
                                                        </span>
                                                        <p className="mb-0 leading-tight text-md">
                                                          {Product.description}
                                                        </p>
                                                        <div className="mb-1.5 flex flex-row space-x-2 text-gray-300 items-center justify-center">
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            className="w-6 h-6"
                                                          >
                                                            <path
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                                            ></path>
                                                          </svg>
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            className="w-6 h-6"
                                                          >
                                                            <path
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                                            ></path>
                                                          </svg>
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            className="w-6 h-6"
                                                          >
                                                            <path
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                                            ></path>
                                                          </svg>
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            className="w-6 h-6"
                                                          >
                                                            <path
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                                            ></path>
                                                          </svg>
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            className="w-6 h-6"
                                                          >
                                                            <path
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                                            ></path>
                                                          </svg>
                                                        </div>
                                                        <div className="flex flex-row">
                                                          <span className="mb-1.5 leading-normal text-xl font-medium">
                                                            Fbu {Product.price}
                                                          </span>
                                                          <p className="ml-4 mb-0 leading-tight text-md bg-gray-100 rounded-xl p-2 border border-sky-300">
                                                            {Product.stock_quantity} availables
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div
                                                        onClick={() => {
                                                          Global.setAddtoCartPop(true, Product);
                                                        }}
                                                        className="inline-block py-2 px-4 mb-0 ml-auto mr-4 font-bold text-center uppercase align-middle transition-all border-0 rounded-xl shadow-none cursor-pointer leading-pro ease-soft-in hover:scale-102 hover:active:scale-102 active:opacity-85 bg-sky-500 text-white hover:text-sky-800 hover:shadow-none active:scale-100"
                                                      >
                                                        Add
                                                      </div>
                                                    </li>
                                                  </React.Fragment>
                                                );
                                              })}
                                            </ul>
                                          </div></Accordion.Content>
                                        </Accordion.Panel>
                                      );
                                    })}

                                  </Accordion>

                                  <div
                                    className={`overflow-hidden transition-max-height duration-300 ${openAccordion === Menu.Group_name ? 'max-h-screen ' : 'max-h-0'
                                      }`}
                                  >
                                    <Accordion collapseAll>


                                      <Accordion.Panel>
                                        <Accordion.Title>Portions</Accordion.Title>

                                        <Accordion.Content><div className="flex-auto p-4">
                                          <ul className="flex flex-col pl-0 mb-0 rounded-lg">
                                            {value?.Menu_Portions?.map((Product) => {
                                              return (
                                                <React.Fragment key={Product.Product.id}>
                                                  <li className="relative flex items-center py-2 px-3 mb-2 bg-white shadow-xl border rounded-2xl text-inherit">
                                                    <div className="inline-flex items-center justify-center h-36 w-36 mr-4 text-white transition-all duration-200 text-base ease-soft-in-out rounded-xl">
                                                      <img
                                                        src={`http://backend.novadiscova.server.cisabu.bi/uploads/Hospitality/${Product.Product.photo}`}
                                                        alt="kal"
                                                        className="w-full shadow-soft-2xl rounded-xl"
                                                      />
                                                    </div>
                                                    <div className="flex flex-col items-start justify-center">
                                                      <span className="mb-1.5 leading-normal text-xl font-medium">
                                                        {Product.Product.item_name}
                                                      </span>
                                                      <p className="mb-0 leading-tight text-md">
                                                        {Product.Product.description}
                                                      </p>
                                                      <div className="mb-1.5 flex flex-row space-x-2 text-gray-300 items-center justify-center">
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          fill="none"
                                                          viewBox="0 0 24 24"
                                                          strokeWidth="1.5"
                                                          stroke="currentColor"
                                                          className="w-6 h-6"
                                                        >
                                                          <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                                          ></path>
                                                        </svg>
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          fill="none"
                                                          viewBox="0 0 24 24"
                                                          strokeWidth="1.5"
                                                          stroke="currentColor"
                                                          className="w-6 h-6"
                                                        >
                                                          <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                                          ></path>
                                                        </svg>
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          fill="none"
                                                          viewBox="0 0 24 24"
                                                          strokeWidth="1.5"
                                                          stroke="currentColor"
                                                          className="w-6 h-6"
                                                        >
                                                          <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                                          ></path>
                                                        </svg>
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          fill="none"
                                                          viewBox="0 0 24 24"
                                                          strokeWidth="1.5"
                                                          stroke="currentColor"
                                                          className="w-6 h-6"
                                                        >
                                                          <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                                          ></path>
                                                        </svg>
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          fill="none"
                                                          viewBox="0 0 24 24"
                                                          strokeWidth="1.5"
                                                          stroke="currentColor"
                                                          className="w-6 h-6"
                                                        >
                                                          <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                                          ></path>
                                                        </svg>
                                                      </div>
                                                      <div className="flex flex-row">
                                                        <span className="mb-1.5 leading-normal text-xl font-medium">
                                                          Fbu {Product.Product.price}
                                                        </span>
                                                        <p className="ml-4 mb-0 leading-tight text-md bg-gray-100 rounded-xl p-2 border border-sky-300">
                                                          {Product.Product.stock_quantity} availables
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div
                                                      onClick={() => {
                                                        Global.setAddtoCartPop(true, Product.Product);
                                                      }}
                                                      className="inline-block py-2 px-4 mb-0 ml-auto mr-4 font-bold text-center uppercase align-middle transition-all border-0 rounded-xl shadow-none cursor-pointer leading-pro ease-soft-in hover:scale-102 hover:active:scale-102 active:opacity-85 bg-sky-500 text-white hover:text-sky-800 hover:shadow-none active:scale-100"
                                                    >
                                                      Add
                                                    </div>
                                                  </li>
                                                </React.Fragment>
                                              );
                                            })}
                                          </ul>
                                        </div></Accordion.Content>
                                      </Accordion.Panel>

                                    </Accordion>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}</>
                      }




                      {
                        activeTab === 3 && <>{value.Menu_Speciales
                          ?.Menu_Products?.map((Menu, index) => {
                            return (
                              <>
                                <div className={` ${openAccordion === Menu.Group_name ? 'border-b border-t shadow-xl bg-gray-50 rounded-2xl' : 'border-b'
                                  }`}>
                                  <button
                                    className="w-full flex justify-between items-center py-4 px-6 focus:outline-none"
                                    onClick={() => toggleAccordion(Menu.Group_name)}
                                  >
                                    <div className="p-4 pb-0 mb-0  border-b-0 rounded-t-2xl">
                                      <h6 className="mb-3 text-xl">plats-speciales {Menu.Group_name}</h6>
                                    </div>
                                    <span className={`transition-transform duration-300 ${openAccordion === Menu.Group_name ? 'rotate-180' : ''}`}>
                                      <svg
                                        className="w-6 h-6"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M19 9l-7 7-7-7"
                                        />
                                      </svg>
                                    </span>
                                  </button>
                                  <div
                                    className={`overflow-hidden transition-max-height duration-300 ${openAccordion === Menu.Group_name ? 'max-h-screen ' : 'max-h-0'
                                      }`}
                                  >
                                    <Accordion collapseAll>

                                      {Menu.Group_categories?.map((Menu_sous, subIndex) => {
                                        return (
                                          <Accordion.Panel>
                                            <Accordion.Title>{Menu_sous.Group_categorie_name}</Accordion.Title>

                                            <Accordion.Content><div className="flex-auto p-4">
                                              <ul className="flex flex-col pl-0 mb-0 rounded-lg">
                                                {Menu_sous.Products?.map((Product) => {
                                                  return (
                                                    <React.Fragment key={Product.Products.id}>
                                                      <li className="relative flex items-center py-2 px-3 mb-2 bg-white shadow-xl border rounded-2xl text-inherit">
                                                        <div className="inline-flex items-center justify-center h-36 w-36 mr-4 text-white transition-all duration-200 text-base ease-soft-in-out rounded-xl">
                                                          <img
                                                            src={`http://backend.novadiscova.server.cisabu.bi/uploads/Hospitality/${Product.Products.photo}`}
                                                            alt="kal"
                                                            className="w-full shadow-soft-2xl rounded-xl"
                                                          />
                                                        </div>
                                                        <div className="flex flex-col items-start justify-center">
                                                          <span className="mb-1.5 leading-normal text-xl font-medium">
                                                            {Product.Products.item_name}
                                                          </span>
                                                          <p className="mb-0 leading-tight text-md">
                                                            {Product.Products.description}
                                                          </p>
                                                          <div className="mb-1.5 flex flex-row space-x-2 text-gray-300 items-center justify-center">
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              fill="none"
                                                              viewBox="0 0 24 24"
                                                              strokeWidth="1.5"
                                                              stroke="currentColor"
                                                              className="w-6 h-6"
                                                            >
                                                              <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                                              ></path>
                                                            </svg>
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              fill="none"
                                                              viewBox="0 0 24 24"
                                                              strokeWidth="1.5"
                                                              stroke="currentColor"
                                                              className="w-6 h-6"
                                                            >
                                                              <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                                              ></path>
                                                            </svg>
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              fill="none"
                                                              viewBox="0 0 24 24"
                                                              strokeWidth="1.5"
                                                              stroke="currentColor"
                                                              className="w-6 h-6"
                                                            >
                                                              <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                                              ></path>
                                                            </svg>
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              fill="none"
                                                              viewBox="0 0 24 24"
                                                              strokeWidth="1.5"
                                                              stroke="currentColor"
                                                              className="w-6 h-6"
                                                            >
                                                              <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                                              ></path>
                                                            </svg>
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              fill="none"
                                                              viewBox="0 0 24 24"
                                                              strokeWidth="1.5"
                                                              stroke="currentColor"
                                                              className="w-6 h-6"
                                                            >
                                                              <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                                              ></path>
                                                            </svg>
                                                          </div>
                                                          <div className="flex flex-row">
                                                            <span className="mb-1.5 leading-normal text-xl font-medium">
                                                              Fbu {Product.Products.price}
                                                            </span>
                                                            <p className="ml-4 mb-0 leading-tight text-md bg-gray-100 rounded-xl p-2 border border-sky-300">
                                                              {Product.Products.stock_quantity} availables
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <div
                                                          // onClick={() => {
                                                          //   Global.setAddtoCartPop(true, Product.Products);
                                                          // }}
                                                          className="inline-block py-2  px-4 mb-0 ml-auto mr-4 font-bold text-center uppercase align-middle transition-all border-0 rounded-xl shadow-none cursor-pointer leading-pro ease-soft-in hover:scale-102 hover:active:scale-102 active:opacity-85  text-white  hover:shadow-none active:scale-100"
                                                        >
                                                          <div class="flex items-center">
                                                            <input id="link-checkbox" type="checkbox" value="" class="w-8 h-8 text-sky-600 bg-gray-100 border-gray-300 rounded focus:ring-sky-500 dark:focus:ring-sky-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                            <label for="link-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 hidden">I agree with the <a href="#" class="text-sky-600 dark:text-sky-500 hover:underline">terms and conditions</a>.</label>
                                                          </div>
                                                        </div>

                                                      </li>
                                                    </React.Fragment>
                                                  );
                                                })}
                                              </ul>
                                            </div></Accordion.Content>
                                          </Accordion.Panel>
                                        );
                                      })}

                                    </Accordion>
                                  </div>
                                </div>
                              </>
                            );
                          })}</>
                      }
                    </div>
                  </div>
                </div>
                <div class="custom-slant w-full mb-5 p-2" style={{ height: "182px;" }}>
                  <div class="hide-with-banner relative">
                    <a
                      href="https://material-tailwind.com?ref=tailwindcomponents"
                      target="_blank"
                      class="flex h-full w-full flex-row py-5"
                    >
                      <div class="z-10 w-1/2 flex-col items-start justify-center px-6 xl:px-8">
                        <p class="mb-5 font-sans text-2xl font-bold text-white">
                          Detendre-Vous un Peu Avec Nos Autres Services
                        </p>
                        <div class="mb-3">
                          <span class="h-8 rounded-lg bg-sky-500 px-4 py-3 font-sans text-xs font-bold text-white shadow-md">
                            Get Started
                          </span>
                        </div>
                      </div>
                      <div class="z-10 w-1/2 flex-col items-start justify-center px-6 xl:px-8">
                        <img
                          src="http://backend.novadiscova.server.cisabu.bi/uploads/Posts/4cb88535-14ab-492e-8b37-c8a7cb97e191.jpg"
                          class="h-32 w-full object-cover rounded-2xl border "
                        />
                      </div>
                      <div class="absolute  bg-gradient-to-br   from-sky-500 to-white border-sky-500 border bottom-0 left-0 h-full w-full overflow-hidden rounded-2xl object-cover"></div>
                      <img
                        src="images/Ads/background-layer.png"
                        class="absolute bottom-0 left-0  opacity-40 h-full w-full overflow-hidden rounded-2xl object-cover"
                      />
                    </a>
                  </div>
                </div>

                <div className="flex w-full items-center justify-between  text-md  rounded-b-3xl bg-white p-5">
                  <div className="flex   flex-col  justify-center ">
                    <div className="flex flex-row space-x-3 mb-3 items-start justify-start">
                      <span className="text-xl text-gray-600 font-semibold ">
                        More About Our Company
                      </span>
                    </div>
                    <div className="flex flex-col space-y-3 px-2  pt-4 mt-3 border-t border-gray-300/50 ">
                      <div className="hidden">
                        <div className="-m-1 flex flex-col sm:flex-wrap md:-m-2">
                          <div className="flex w-full  flex-wrap">
                            <div className="w-full p-1 md:p-2">
                              <img
                                alt="gallery"
                                className="block h-full w-full rounded-lg 2xl:rounded-2xl object-cover object-center"
                                src="https://www.tailwindtap.com/assets/components/gallery/image9.jpg"
                              />
                            </div>
                            <div className="w-1/2 p-1 md:p-2">
                              <img
                                alt="gallery"
                                className="block h-full w-full rounded-lg 2xl:rounded-2xl object-cover object-center"
                                src="https://www.tailwindtap.com/assets/components/gallery/image4.jpg"
                              />
                            </div>
                            <div className="w-1/2 p-1 md:p-2">
                              <img
                                alt="gallery"
                                className="block h-full w-full rounded-lg 2xl:rounded-2xl object-cover object-center"
                                src="https://www.tailwindtap.com/assets/components/gallery/image6.jpg"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <Other_Images />
                    </div>
                    <p className="p-3  text-gray-600  ">
                      {value.Menu_Products?.Company.description}
                    </p>



                    <div className="flex flex-row space-x-3 px-2  pt-4 mt-3 border-t border-gray-300/50 ">
                      <div className="flex flex-row space-x-3 items-center justify-center">
                        <div className="flex flex-row space-x-2 text-yellow-500 items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                            ></path>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                            ></path>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                            ></path>
                          </svg>
                        </div>
                        <span className="text-lg text-gray-600 font-semibold ">
                          + {value.Menu_Products?.Company.number_Order} Satisfied
                          clients
                        </span>
                      </div>
                    </div>

                    <div className="flex flex-col items-start  pt-4 pb-4 px-3 mt-3 border-t border-gray-300/50 ">
                      <div className="flex flex-row space-x-3 mb-4 items-start justify-start">
                        <span className="text-xl text-gray-600 font-semibold ">
                          Our Customers Reviews
                        </span>
                      </div>
                      <div className="flex flex-row items-center justify-between space-x-5">
                        <div className="text-xl text-gray-600    leading-7">
                          <h2 className="flex  flex-row items-center text-xl font-semibold text-gray-500 bg-gray-100 rounded-xl px-3 py-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="mr-2 h-6 w-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                              ></path>
                            </svg>
                            <h2 className="text-sm text-gray-500 uppercase font-semibold leading-none">
                              <p className="text-gray-600">
                                What do you think about our Business ?
                              </p>
                            </h2>
                          </h2>
                        </div>

                      </div>
                    </div>

                    <form className="w-full max-w-xl bg-white rounded-lg px-4 pt-2">
                      <div className="flex flex-wrap -mx-3 mb-6">
                        <h2 className="px-4 pt-3 pb-2 text-gray-800 text-lg">
                          Add a Review
                        </h2>
                        <div className="w-full md:w-full px-3 mb-2 mt-2">
                          <textarea
                            className="bg-gray-100 h-32 rounded border border-gray-400 leading-normal resize-none w-full h-20 py-2 px-3 font-medium placeholder-gray-700 transition-colors duration-200 ease-in-out focus:border-sky-500 focus:ring-2 focus:ring-sky-200"
                            name="message"
                            id="message"
                            rows="4"
                            placeholder="Write your review Here"
                            required
                          ></textarea>
                        </div>
                        <div className="w-full md:w-full flex items-start md:w-full px-3">
                          <div className="flex items-start w-1/2 text-gray-700 px-2 mr-auto">
                            <svg
                              fill="none"
                              className="w-5 h-5 text-gray-600 mr-1"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            <p className="text-xs md:text-sm pt-px">
                              Your review is important to us
                            </p>
                          </div>
                          <div className="-mr-1">
                            <button
                              type="submit"
                              className="bg-sky-500 text-white hover:bg-sky-700 font-medium py-1 px-4  rounded-lg tracking-wide mr-1 hover:bg-gray-100"
                            >
                              Post Review
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="flex flex-col gap-3  px-3 mt-4">
                      <div className="flex flex-col gap-4  shadow rounded-xl border border-gray-300  p-4">
                        <div className="flex justify justify-between">
                          <a
                            className=" flex transform items-center py-1.5 text-sm text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                            href="/profile"
                          >
                            <div className="mx-1 h-12 w-12 flex-shrink-0 flex items-center justify-center rounded-full bg-sky-50 border-sky-300 border object-cover">
                              <span className="text-sky-500 uppercase font-medium">
                                d
                              </span>
                            </div>
                            <div className="mx-1">
                              <h1 className="text-sm font-semibold text-gray-700 dark:text-gray-200">
                                Alice Banks
                              </h1>
                              <p className="text-sm text-gray-500 dark:text-gray-400">
                                Bujumbura,Burundi
                              </p>
                            </div>
                          </a>

                          <div className="flex p-1 gap-1 text-orange-300">
                            <ion-icon name="star"></ion-icon>
                            <ion-icon name="star"></ion-icon>
                            <ion-icon name="star"></ion-icon>
                            <ion-icon name="star"></ion-icon>
                            <ion-icon name="star-half"></ion-icon>
                          </div>

                          <div className="flex flex-row space-x-2 text-yellow-500 items-center justify-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                              ></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                              ></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                              ></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                              ></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                              ></path>
                            </svg>
                          </div>
                        </div>

                        <div>
                          Gorgeous design! Even more responsive than the
                          previous version. A pleasure to use!
                        </div>

                        <div className="flex justify-between">
                          <span>Feb 13, 2021</span>
                          <button className="p-1 px-2 border border-gray-400 hidden rounded-lg bg-opacity-60">
                            <ion-icon name="share-outline"></ion-icon>{" "}
                            useful
                          </button>
                        </div>
                      </div>

                      <div className="flex flex-col gap-4  shadow rounded-xl border border-gray-300  p-4">
                        <div className="flex justify justify-between">
                          <a
                            className=" flex transform items-center py-1.5 text-sm text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                            href="/profile"
                          >
                            <div className="mx-1 h-12 w-12 flex-shrink-0 flex items-center justify-center rounded-full bg-sky-50 border-sky-300 border object-cover">
                              <span className="text-sky-500 uppercase font-medium">
                                d
                              </span>
                            </div>
                            <div className="mx-1">
                              <h1 className="text-sm font-semibold text-gray-700 dark:text-gray-200">
                                Alice Banks
                              </h1>
                              <p className="text-sm text-gray-500 dark:text-gray-400">
                                Bujumbura,Burundi
                              </p>
                            </div>
                          </a>

                          <div className="flex p-1 gap-1 text-orange-300">
                            <ion-icon name="star"></ion-icon>
                            <ion-icon name="star"></ion-icon>
                            <ion-icon name="star"></ion-icon>
                            <ion-icon name="star"></ion-icon>
                            <ion-icon name="star-half"></ion-icon>
                          </div>

                          <div className="flex flex-row space-x-2 text-yellow-500 items-center justify-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                              ></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                              ></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                              ></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                              ></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                              ></path>
                            </svg>
                          </div>
                        </div>

                        <div>
                          Gorgeous design! Even more responsive than the
                          previous version. A pleasure to use!
                        </div>

                        <div className="flex justify-between">
                          <span>Feb 13, 2021</span>
                          <button className="p-1 px-2 border border-gray-400 hidden rounded-lg bg-opacity-60">
                            <ion-icon name="share-outline"></ion-icon>{" "}
                            useful
                          </button>
                        </div>
                      </div>

                      <div className="flex flex-col gap-4  shadow rounded-xl border border-gray-300  p-4">
                        <div className="flex justify justify-between">
                          <a
                            className=" flex transform items-center py-1.5 text-sm text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                            href="/profile"
                          >
                            <div className="mx-1 h-12 w-12 flex-shrink-0 flex items-center justify-center rounded-full bg-sky-50 border-sky-300 border object-cover">
                              <span className="text-sky-500 uppercase font-medium">
                                d
                              </span>
                            </div>
                            <div className="mx-1">
                              <h1 className="text-sm font-semibold text-gray-700 dark:text-gray-200">
                                Alice Banks
                              </h1>
                              <p className="text-sm text-gray-500 dark:text-gray-400">
                                Bujumbura,Burundi
                              </p>
                            </div>
                          </a>

                          <div className="flex p-1 gap-1 text-orange-300">
                            <ion-icon name="star"></ion-icon>
                            <ion-icon name="star"></ion-icon>
                            <ion-icon name="star"></ion-icon>
                            <ion-icon name="star"></ion-icon>
                            <ion-icon name="star-half"></ion-icon>
                          </div>

                          <div className="flex flex-row space-x-2 text-yellow-500 items-center justify-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                              ></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                              ></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                              ></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                              ></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                              ></path>
                            </svg>
                          </div>
                        </div>

                        <div>
                          Gorgeous design! Even more responsive than the
                          previous version. A pleasure to use!
                        </div>

                        <div className="flex justify-between">
                          <span>Feb 13, 2021</span>
                          <button className="p-1 px-2 border border-gray-400 hidden rounded-lg bg-opacity-60">
                            <ion-icon name="share-outline"></ion-icon>{" "}
                            useful
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
                <button
                  type="button"
                  className="flex w-full items-center justify-between rounded-t-xl border border-b-0 border-gray-200 bg-sky-50 p-5 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800"
                >
                  <div className="flex flex-row space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z"
                      />
                    </svg>

                    <span className="text-xl">Our Location</span>
                  </div>
                </button>
                <div className="flex w-full items-center justify-center  text-md border border-b-0 border-gray-200 rounded-b-3xl bg-white p-5">
                  <div className=" border border-sky-300 shadow rounded-2xl overflow-hidden">
                    {" "}
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15931.280151019238!2d29.3954391!3d-3.3940662!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19c182b87903ccc1%3A0xbacb4e867f382ad3!2sCampus%20Universitaire%20de%20Kiriri!5e0!3m2!1sfr!2sbi!4v1703529817729!5m2!1sfr!2sbi"
                      width="520"
                      height="450"
                      style={{ border: "0" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>

            </div>}

            {activePage === 1 && <div id="Carts" className="rounded-lg p-4 dark:border-gray-700">
              <div className="item w-128  card text-grey-darkest flex h-auto rounded-xl border bg-white shadow-inner">
                <div className="flex w-full flex-col">
                  <div className="flex-1 p-4 pb-0">
                    <div className="flex items-center justify-between p-3">
                      <div className="flex items-center justify-center">
                        <h2 className="flex flex-row items-center text-xl font-semibold text-gray-500">
                          Carts - Products
                        </h2>
                      </div>

                      <button
                        title="Open options"
                        type="button"
                        onClick={() => "hello"}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 text-sky-500"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="rounded-md bg-gray-100 px-2 py-2"></div>
                  </div>
                </div>
              </div>
              {getData.length > 0 ? (
                <div class="bg-white dark:bg-box-dark rounded-10 my-4  ">
                  <div class="grid grid-cols-12 sm:gap-[25px] gap-y-[25px]">
                    <div class="col-span-12 2xl:col-span-12 item w-128  card text-grey-darkest flex h-auto rounded-xl border bg-white shadow-inner">
                      <div class="max-h-[455px] pb-[15px] scrollbar overflow-y-auto">
                        <table class="min-w-full text-sm font-light text-start whitespace-nowrap product-container">
                          <thead>
                            <tr>
                              <th class="sticky top-0 capitalize bg-regularBG dark:bg-box-dark-up px-[25px] py-4 text-start text-light dark:text-title-dark text-[14px] font-medium border-none before:hidden rounded-s-[4px]">
                                Product</th>
                              <th class="sticky top-0 capitalize bg-regularBG dark:bg-box-dark-up px-4 py-4 text-light dark:text-title-dark text-[14px] font-medium border-none before:hidden">
                                Price</th>
                              <th class="sticky top-0 capitalize bg-regularBG dark:bg-box-dark-up px-4 py-4 text-light dark:text-title-dark text-[14px] font-medium border-none before:hidden">
                                Quantity</th>
                              <th class="sticky top-0 capitalize bg-regularBG dark:bg-box-dark-up px-4 py-4 text-light dark:text-title-dark text-[14px] font-medium border-none before:hidden">
                                total</th>
                              <th class="sticky top-0 capitalize bg-regularBG dark:bg-box-dark-up px-[25px] py-2.5 text-end text-light dark:text-title-dark text-[12px] font-medium border-none before:hidden rounded-e-[4px]">
                              </th>
                            </tr>
                          </thead>
                          <tbody class="bg-white dark:bg-box-dark">
                            {getData.map((e, index) => {
                              return (
                                <>
                                  <tr class="group productItems border-b border-gray-300">
                                    <td class="ps-[25px] pe-4 py-2.5 text-start last:text-end group-hover:bg-transparent border-none before:hidden rounded-s-[4px]">
                                      <div class="flex items-center gap-x-[20px] gap-y-[10px]">
                                        <figure class="min-w-[80px] w-[80px] h-[80px] flex items-center justify-center bg-regular dark:bg-box-dark-up rounded-4 p-[5px]">
                                          <img class="object-cover placeholder-image" src={`http://backend.novadiscova.server.cisabu.bi/uploads/Hospitality/${e.product.photo}`} alt="boss chair" />
                                        </figure>
                                        <figcaption>
                                          <h6 class="capitalize mt-3 mb-[8px] leading-[21px] text-dark dark:text-title-dark text-[15px] font-medium">
                                            {e.product.item_name}</h6>
                                          {e.Multiple_pricings?.length ||
                                            e.checkedAdditionalPrices?.length ? <> <ul class="grid items-center gap-[13px] mb-0 capitalize">
                                              <li>
                                                <span class=" bg-gray-100 p-1 px-2 rounded-md text-dark dark:text-title-dark me-[5px] text-[14px] font-medium">{e.selectedRadio.condition_text}</span>
                                              </li>
                                              {e.checkedAdditionalPrices.length > 0 ? <>
                                                {e.checkedAdditionalPrices.map((additional) => {
                                                  return (
                                                    <>     <li class="bg-gray-100 rounded-md p-1 px-2">
                                                      <span class="text-body dark:text-subtitle-dark text-[13px]">{additional.question_text
                                                      }</span>:<span class="ml-2 text-body dark:text-subtitle-dark text-[13px]">{additional.
                                                        additional_price
                                                      } FBU</span>
                                                    </li>
                                                    </>
                                                  );
                                                })}</>
                                                : null
                                              }
                                            </ul></> : <></>}
                                          {
                                            e.Recommandations &&
                                            <p class="mt-2">
                                              <span class="text-body dark:text-subtitle-dark text-[13px]">{e.Recommandations}</span>
                                            </p>
                                          }
                                        </figcaption>
                                      </div>
                                    </td>
                                    <td class="px-4 py-2.5 font-normal text-center capitalize text-[14px] text-light dark:text-subtitle-dark border-none group-hover:bg-transparent">
                                      {e.selectedRadio?.suggested_price ?
                                        <>{e.selectedRadio.suggested_price}</>
                                        :
                                        <>{e.product.price}</>
                                      }
                                      <span class="ml-1">FBU</span>
                                    </td>
                                    <td class="px-4 py-2.5 font-normal text-center capitalize text-[14px] text-dark dark:text-title-dark border-none group-hover:bg-transparent">
                                      <div class="flex items-center justify-center">
                                        <button onClick={
                                          e.quantity <= 1
                                            ? () => dlt(e.id)
                                            : () => remove(e)
                                        } type="button" class="bg-gray-200 productItemsDown cursor-pointer transition-[0.3s] hover:text-primary hover:bg-primary/10 w-[38px] h-[38px] flex items-center justify-center text-[16px] text-body dark:text-subtitle-dark bg-regular dark:bg-box-dark-up border-1 border-regular dark:border-box-dark-up rounded-[10px]">
                                          <span>-</span>
                                        </button>
                                        <input type="number" min="1" value={e.quantity} class="productItemsNumber bg-transparent 2xl:ps-[12px] w-[50px] text-[14px] font-medium text-dark dark:text-title-dark placeholder:text-dark dark:placeholder:text-title-dark border-none shadow-none appearance-none outline-none text-center" />
                                        <button onClick={() => send(e)} type="button" class="bg-gray-200 productItemsUp cursor-pointer transition-[0.3s] hover:text-primary hover:bg-primary/10 w-[38px] h-[38px] flex items-center justify-center text-[16px] text-body dark:text-subtitle-dark bg-regular dark:bg-box-dark-up border-1 border-regular dark:border-box-dark-up rounded-[10px]">
                                          <span>+</span>
                                        </button>
                                      </div>
                                    </td>
                                    <td class="ps-4 pe-[25px] py-2.5 font-normal text-center capitalize text-[14px] text-primary border-none group-hover:bg-transparent rounded-e-[4px]">
                                      {e.selectedRadio?.suggested_price ?
                                        <>{(e.selectedRadio.suggested_price * e.quantity + total_sum(e.checkedAdditionalPrices)).toFixed(2)}</>
                                        :
                                        <>{e.product.price * e.quantity}</>
                                      }
                                      <span class="ml-1">FBU</span>
                                    </td>
                                    <td class="px-4 py-2.5 font-normal text-center capitalize border-none group-hover:bg-transparent">
                                      <div class="flex items-center justify-center">
                                        <button type="button" class="w-[38px] h-[38px] flex items-center text-light-extra hover:bg-danger/10 justify-center text-[18px] cursor-pointer remove-event-wrapper uil uil-trash-alt hover:text-danger rounded-full">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            className="h-5 w-5 cursor-pointer duration-150 hover:text-red-500"
                                            onClick={() => handleDelete(e.unique_id)}
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M6 18L18 6M6 6l12 12"
                                            />
                                          </svg>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}

                            <tr>
                              <td colspan="1" class="ps-[25px] pe-4 py-2.5">
                                <div class="flex flex-row justify-between">
                                  <p class="flex items-center space-x-4 mt-3 mb-[18px] text-lg text-gray-500"><span class="font-medium text-body dark:text-subtitle-dark">Subtotal :</span><span class="font-medium text-dark dark:text-title-dark">{price}<span class="ml-1">Fbu</span></span>  </p>
                                </div>
                              </td>

                              <td colspan={take?"2":"1"}> <div class=" border border-gray-100  rounded-lg dark:border-gray-600">
                                <div class="flex items-center ps-3">
                                  <input
                                    type="checkbox" checked={is_take} onChange={() => is_take ? set_is_take(false) : set_is_take(true)} value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                  <div class="w-full flex flex-row justify-between items-center">
                                    <label for="vue-checkbox" class="  py-3 ms-2 text-md font-medium text-sky-900 dark:text-gray-300">Take Away</label>
                                    <label for="vue-checkbox" class="  py-3 mr-4 text-md font-bold text-sky-900 dark:text-gray-300">{take} {take && <span class="ml-1">Fbu</span>}</label>
                                  </div>
                                </div>
                              </div></td>
                            </tr>
                            <tr>
                              <td colspan="4" class="ps-[25px] pe-4 py-2.5">
                                <div class="flex items-center gap-[15px] mt-[15px] mb-[35px]">
                                  <input type="text" id="product-name" class="w-[180px] bg-regularBG rounded-6 border-regular border-1 text-[15px] dark:bg-box-dark-up dark:border-box-dark-up px-[20px] py-[12px] min-h-[44px] outline-none placeholder:text-[#A0A0A0] text-body dark:text-subtitle-dark focus:ring-primary focus:border-primary" placeholder="Coupon Code" autocomplete="off" />
                                  <button type="button" class="group text-[14px] border-normal bg-sky-500  rounded-lg font-semibold text-white dark:text-title-dark btn-outlined h-[44px] dark:border-box-dark-up sm:px-[20px] px-[15px] rounded-6 flex items-center gap-[5px] leading-[22px] hover:text-white hover:bg-primary-hbr bg-primary transition duration-300" data-te-ripple-init="" data-te-ripple-color="light">
                                    Apply Coupon
                                  </button>
                                  <button type="button" class="group text-[14px] border-normal bg-sky-500  rounded-lg font-semibold text-white dark:text-title-dark btn-outlined h-[44px] dark:border-box-dark-up sm:px-[20px] px-[15px] rounded-6 flex items-center gap-[5px] leading-[22px] hover:text-white hover:bg-primary-hbr bg-primary transition duration-300" data-te-ripple-init="" data-te-ripple-color="light">
                                    Continuer
                                  </button>
                                </div>
                              </td>



                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="col-span-12 2xl:col-span-12  text-grey-darkest  h-auto rounded-xl border bg-white shadow-inner">
                      <div class="bg-regularBG dark:bg-box-dark-up sm:p-[25px] p-[15px] rounded-10">
                        <h1 class="mb-6 text-xl font-semibold text-dark dark:text-title-dark">Info - Payment </h1>
                        <div class="bg-white dark:bg-box-dark p-[25px] shadow-[0_10px_30px_rgba(10,10,10,0.06)] rounded-10">
                          <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                          >
                            {({ isSubmitting, values, setFieldValue }) => (
                              <Form>
                                <div class="mx-auto  py-5">
                                  <ul class="w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-xl dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                    <li className={`w-full border-b border-gray-200 rounded-lg dark:border-gray-600 ${activePayment === 1 ? 'bg-sky-100' : ''} `}
                                    >


                                      <div class="flex items-center ps-3">
                                        <input id="" type="radio" value=""
                                          onClick={() => handlePaymentClick(1)} name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                        <label for="list-radio-license" class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">   <div class="w-full flex flex-row justify-between items-center">
                                          <div class="flex-none">
                                            <img
                                              className="h-12  rounded-xl"
                                              src="../../../../../images/payment/lumicash.png"
                                            />
                                          </div>

                                          {activePayment === 1 &&
                                            <div>

                                              <div className="mb-0 flex items-center  mr-3 rounded-md border bg-white shadow-md"
                                              >
                                                <span className="px-3">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="h-4 w-4 text-gray-700"
                                                  >
                                                    <path
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                                                    ></path>
                                                  </svg>
                                                </span>
                                                <Field
                                                  className="h-8 w-full border-0 border-white focus:outline-none"
                                                  type="text"
                                                  name="Phone_Number"
                                                  placeholder="votre numéro"
                                                />
                                              </div>
                                              <ErrorMessage
                                                name="Phone_Number"
                                                component="div"
                                                className="text-left mt-2 text-red-500 text-xs"
                                              />
                                            </div>}
                                        </div> </label>
                                      </div>
                                    </li>
                                    <li className={`w-full border-b border-gray-200 rounded-lg dark:border-gray-600 ${activePayment === 2 ? 'bg-sky-100' : ''} `}
                                    >
                                      <div class="flex items-center ps-3">
                                        <input id="" type="radio" value=""
                                          onClick={() => handlePaymentClick(2)} name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                        <label for="list-radio-license" class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">   <div class="w-full flex flex-row justify-between items-center">
                                          <div class="flex-none">
                                            <img
                                              className="h-12  rounded-xl"
                                              src="../../../../../images/payment/ibbm+.png"
                                            />
                                          </div>
                                          {activePayment === 2 && <div>
                                            <div className="mb-0 flex items-center  mr-3 rounded-md border bg-white shadow-md">
                                              <span className="px-3">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  fill="none"
                                                  viewBox="0 0 24 24"
                                                  strokeWidth="1.5"
                                                  stroke="currentColor"
                                                  className="h-4 w-4 text-gray-700"
                                                >
                                                  <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                                                  ></path>
                                                </svg>
                                              </span>
                                              <Field
                                                className="h-8 w-full border-0 border-white focus:outline-none"
                                                type="text"
                                                name="Phone_Number"
                                                placeholder="votre numéro"
                                              />
                                            </div>
                                            <ErrorMessage
                                              name="Phone_Number"
                                              component="div"
                                              className="text-left mt-2 text-red-500 text-xs"
                                            />
                                          </div>}
                                        </div> </label>
                                      </div>
                                    </li>
                                    <li className={`w-full border-b border-gray-200 rounded-lg dark:border-gray-600 ${activePayment === 3 ? 'bg-sky-100' : ''} `}
                                    >
                                      <div class="flex items-center ps-3">
                                        <input id="" type="radio" value=""
                                          onClick={() => handlePaymentClick(3)} name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                        <label for="list-radio-license" class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">   <div class="w-full flex flex-row justify-between items-center">
                                          <div class="flex-none">
                                            <img
                                              className="h-12  rounded-xl"
                                              src="../../../../../images/payment/ecocash.png"
                                            />
                                          </div>
                                          {activePayment === 3 &&
                                            <div>
                                              <div className="mb-0 flex items-center  mr-3 rounded-md border bg-white shadow-md">
                                                <span className="px-3">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="h-4 w-4 text-gray-700"
                                                  >
                                                    <path
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                                                    ></path>
                                                  </svg>
                                                </span>
                                                <Field
                                                  className="h-8 w-full border-0 border-white focus:outline-none"
                                                  type="text"
                                                  name="Phone_Number"
                                                  placeholder="votre numéro"
                                                />
                                              </div>
                                              <ErrorMessage
                                                name="Phone_Number"
                                                component="div"
                                                className="text-left mt-2 text-red-500 text-xs"
                                              />
                                            </div>}
                                        </div> </label>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <hr className="mt-3" />

                                <div class="ps-[25px] pe-4 py-2.5">
                                  <div class="flex justify-between items-center gap-[15px] mt-[15px] mb-[15px]">

                                    <p class="flex items-center space-x-4 mt-3 mb-[18px] text-lg text-gray-500"><span class="font-medium text-body dark:text-subtitle-dark">Montant Total :</span><span class="font-medium text-dark dark:text-title-dark">{price}<span class="ml-1">Fbu</span></span>  </p>
                                    <button type="submit"
                                      disabled={isSubmitting} class="group text-[14px] border-normal bg-sky-500  rounded-lg font-semibold text-white dark:text-title-dark btn-outlined h-[44px] dark:border-box-dark-up sm:px-[20px] px-[15px] rounded-6 flex items-center gap-[5px] leading-[22px] hover:text-white hover:bg-primary-hbr bg-primary transition duration-300" data-te-ripple-init="" data-te-ripple-color="light">
                                      {isSubmitting ? "Requesting..." : "Continue"}
                                    </button>
                                  </div>
                                </div>

                                <hr className="mt-3" />

                                <div class="ps-[25px] pe-4 py-2.5">
                                  <div class="flex items-center gap-[15px] mt-[15px] mb-[35px]">
                                    <input type="text" id="product-name" class="w-[180px] bg-regularBG rounded-6 border-regular border-1 text-[15px] dark:bg-box-dark-up dark:border-box-dark-up px-[20px] py-[12px] min-h-[44px] outline-none placeholder:text-[#A0A0A0] text-body dark:text-subtitle-dark focus:ring-primary focus:border-primary" placeholder="Ecrivez le code ici" autocomplete="off" />
                                    <button type="button" class="group text-[14px] border-normal bg-sky-500  rounded-lg font-semibold text-white dark:text-title-dark btn-outlined h-[44px] dark:border-box-dark-up sm:px-[20px] px-[15px] rounded-6 flex items-center gap-[5px] leading-[22px] hover:text-white hover:bg-primary-hbr bg-primary transition duration-300" data-te-ripple-init="" data-te-ripple-color="light">
                                      OTP
                                    </button>
                                    <button type="button" class="group text-[14px] border-normal bg-sky-500  rounded-lg font-semibold text-white dark:text-title-dark btn-outlined h-[44px] dark:border-box-dark-up sm:px-[20px] px-[15px] rounded-6 flex items-center gap-[5px] leading-[22px] hover:text-white hover:bg-primary-hbr bg-primary transition duration-300" data-te-ripple-init="" data-te-ripple-color="light">
                                      Passer la Commander
                                    </button>
                                  </div>
                                </div>

                              </Form>
                            )}
                          </Formik>

                        </div>
                      </div>
                    </div>
                    <div class="col-span-12 2xl:col-span-12  text-grey-darkest  h-auto rounded-xl border bg-white shadow-inner">
                      <div class="bg-regularBG dark:bg-box-dark-up sm:p-[25px] p-[15px] rounded-10">
                        <h1 class="mb-6 text-xl font-semibold text-dark dark:text-title-dark">Order Summary</h1>
                        <div class="bg-white dark:bg-box-dark p-[25px] shadow-[0_10px_30px_rgba(10,10,10,0.06)] rounded-10">
                          <ul class="mb-0">
                            <li class="flex items-center justify-between mb-[18px]"><span class="font-medium text-body dark:text-subtitle-dark">Subtotal :</span><span class="font-medium text-dark dark:text-title-dark">{price}<span class="ml-1">Fbu</span></span>  </li>
                            <li class="flex items-center justify-between mb-[18px]"><span class="font-medium text-body dark:text-subtitle-dark">Discount :</span><span class="font-medium text-dark dark:text-title-dark">0<span class="ml-1">Fbu</span></span></li>
                            <li class="flex items-center justify-between mb-[18px]"><span class="font-medium text-body dark:text-subtitle-dark">Shipping Charge :</span><span class="font-medium text-dark dark:text-title-dark">0<span class="ml-1">Fbu</span></span></li>
                          </ul>
                          <select data-te-select-init data-te-class-select-input="py-[10px] px-[20px] w-full capitalize [&~span]:top-[15px] [&~span]:w-[15px] [&~span]:h-[15px] [&~span]:text-body dark:[&~span]:text-white text-light dark:text-subtitle-dark border-normal dark:border-box-dark-up border-1 rounded-4 dark:bg-box-dark-up outline-none ltr:[&~span]:right-[0.75rem] rtl:[&~span]:left-[0.75rem] rtl:[&~span]:right-auto" data-te-class-notch-leading="!border-0 !shadow-none group-data-[te-input-focused]:shadow-none group-data-[te-input-focused]:border-none" data-te-class-notch-middle="!border-0 !shadow-none !outline-none" data-te-class-notch-trailing="!border-0 !shadow-none !outline-none">
                            <option value="all">Select Coupon</option>
                            <option value="2">SAVE10NOW</option>
                            <option value="3">WEBDEAL20</option>
                            <option value="4">CODE50OFF</option>
                            <option value="5">DESIGN15</option>
                            <option value="6">TECHSALE25</option>
                          </select>
                          <label for="promoCode" class="text-body dark:text-subtitle-dark text-[15px] w-full relative mt-[20px] block">Promo
                            Code</label>
                          <div class="flex items-center gap-[15px] mt-[15px] mb-[35px]">
                            <input type="text" id="promoCode" class="w-[180px] bg-regularBG rounded-6 border-regular border-1 text-[15px] dark:bg-box-dark-up dark:border-box-dark-up px-[20px] py-[12px] h-[44px] outline-none placeholder:text-[#A0A0A0] text-body dark:text-subtitle-dark focus:ring-primary focus:border-primary" placeholder="Promo Code" autocomplete="off" />
                            <button type="button" class="group text-[14px] border-success border-1 font-semibold text-success btn-outlined h-[44px] sm:px-[20px] px-[15px] rounded-6 flex items-center gap-[5px] leading-[22px] hover:text-white hover:bg-success transition duration-300" data-te-ripple-init="" data-te-ripple-color="light">
                              Apply
                            </button>
                          </div>
                          <h5 class="inline-flex items-center justify-between w-full mb-[15px]"><span class="text-base font-semibold text-dark dark:text-title-dark">Total : </span><span class="text-lg font-semibold text-primary">{price}<span class="ml-1">Fbu</span></span></h5>
                          <button type="button" class="group w-full text-[14px] border-normal font-semibold text-white dark:text-title-dark btn-outlined h-[44px] dark:border-box-dark-up sm:px-[20px] px-[15px] rounded-6 flex items-center justify-center gap-[5px] leading-[22px] hover:text-white hover:bg-primary-hbr bg-primary transition duration-300 capitalize" data-te-ripple-init="" data-te-ripple-color="light">
                            process to checkout
                            <i class="uil uil-arrow-right text-[16px]"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              ) : (<>
                <div class="flex  items-center justify-center p-5 w-full bg-white rounded-2xl  my-4 shadow-lg border">
                  <div class="text-center">
                    <div class="inline-flex rounded-full bg-sky-100 p-4">
                      <div class="rounded-full stroke-sky-600 bg-sky-200 p-4">
                        <svg
                          class="w-16 h-16"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 8H6.01M6 16H6.01M6 12H18C20.2091 12 22 10.2091 22 8C22 5.79086 20.2091 4 18 4H6C3.79086 4 2 5.79086 2 8C2 10.2091 3.79086 12 6 12ZM6 12C3.79086 12 2 13.7909 2 16C2 18.2091 3.79086 20 6 20H14"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M17 16L22 21M22 16L17 21"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <h1 class="mt-5 text-2xl font-bold text-slate-500 ">
                      No Products in Carts
                    </h1>
                    <p class="text-slate-600 mt-5 lg:text-lg">
                      Please Add Products <br /> Star
                      Offering Services or Products
                    </p>{" "}
                    <div className="my-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
                      <button onClick={() => handlePageClick(3)}
                        className="mt-5 rounded-xl border-0 bg-sky-500 px-6 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none"
                      >
                        Add Product
                      </button>
                    </div>
                  </div>
                </div></>)
              }
              <div className="my-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
                <button
                  type="button"
                  className="flex w-full items-center justify-between rounded-t-xl border border-b-0 border-gray-200 bg-sky-50 p-5 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800"
                >
                  <div className="flex flex-row space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z"
                      />
                    </svg>

                    <span className="text-xl">Our Location</span>
                  </div>
                </button>
                <div className="flex w-full items-center justify-center  text-md border border-b-0 border-gray-200 rounded-b-3xl bg-white p-5">
                  <div className=" border border-sky-300 shadow rounded-2xl overflow-hidden">
                    {" "}
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15931.280151019238!2d29.3954391!3d-3.3940662!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19c182b87903ccc1%3A0xbacb4e867f382ad3!2sCampus%20Universitaire%20de%20Kiriri!5e0!3m2!1sfr!2sbi!4v1703529817729!5m2!1sfr!2sbi"
                      width="520"
                      height="450"
                      style={{ border: "0" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>}

            {activePage === 2 && <div id="Orders" className="rounded-lg p-4 dark:border-gray-700">
              <div className="item w-128  card text-grey-darkest flex h-auto rounded-xl border bg-white shadow-inner">
                <div className="flex w-full flex-col">
                  <div className="flex-1 p-4 pb-0">
                    <div className="flex items-center justify-between p-3">
                      <div className="flex items-center justify-center">
                        <h2 className="flex flex-row items-center text-xl font-semibold text-gray-500">
                          Menu
                        </h2>
                      </div>

                      <div className="flex items-center justify-center">
                        <h2 className="flex flex-row items-center text-xl font-semibold text-gray-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="mr-2 h-6 w-7"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                            ></path>
                          </svg>
                          {"20"} items
                        </h2>
                      </div>

                      <button
                        title="Open options"
                        type="button"
                        onClick={() => "hello"}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 text-sky-500"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="rounded-md bg-gray-100 px-2 py-2"></div>
                  </div>
                </div>
              </div>
              {getData.length > 0 && (
                <div class="bg-white dark:bg-box-dark rounded-10 my-4  ">
                  <div class="grid grid-cols-12 sm:gap-[25px] gap-y-[25px]">
                    <div class="col-span-12 2xl:col-span-12 item w-128  card text-grey-darkest flex h-auto rounded-xl border bg-white shadow-inner">
                      <div class="max-h-[455px] pb-[15px] scrollbar overflow-y-auto">
                        <table class="min-w-full text-sm font-light text-start whitespace-nowrap product-container">
                          <thead>
                            <tr>
                              <th class="sticky top-0 capitalize bg-regularBG dark:bg-box-dark-up px-[25px] py-4 text-start text-light dark:text-title-dark text-[14px] font-medium border-none before:hidden rounded-s-[4px]">
                                Product</th>
                              <th class="sticky top-0 capitalize bg-regularBG dark:bg-box-dark-up px-4 py-4 text-light dark:text-title-dark text-[14px] font-medium border-none before:hidden">
                                Price</th>
                              <th class="sticky top-0 capitalize bg-regularBG dark:bg-box-dark-up px-4 py-4 text-light dark:text-title-dark text-[14px] font-medium border-none before:hidden">
                                Quantity</th>
                              <th class="sticky top-0 capitalize bg-regularBG dark:bg-box-dark-up px-4 py-4 text-light dark:text-title-dark text-[14px] font-medium border-none before:hidden">
                                total</th>
                              <th class="sticky top-0 capitalize bg-regularBG dark:bg-box-dark-up px-[25px] py-2.5 text-end text-light dark:text-title-dark text-[12px] font-medium border-none before:hidden rounded-e-[4px]">
                              </th>
                            </tr>
                          </thead>
                          <tbody class="bg-white dark:bg-box-dark">
                            {getData.map((e) => {
                              return (
                                <>
                                  <tr class="group productItems border-b border-gray-300">
                                    <td class="ps-[25px] pe-4 py-2.5 text-start last:text-end group-hover:bg-transparent border-none before:hidden rounded-s-[4px]">
                                      <div class="flex items-center gap-x-[20px] gap-y-[10px]">
                                        <figure class="min-w-[80px] w-[80px] h-[80px] flex items-center justify-center bg-regular dark:bg-box-dark-up rounded-4 p-[5px]">
                                          <img class="object-cover placeholder-image" src={`http://backend.novadiscova.server.cisabu.bi/uploads/Hospitality/${e.product.photo}`} alt="boss chair" />
                                        </figure>
                                        <figcaption>
                                          <h6 class="capitalize mb-[8px] leading-[21px] text-dark dark:text-title-dark text-[15px] font-medium">
                                            {e.product.item_name}</h6>
                                          <ul class="flex items-center gap-[20px] mb-0 capitalize">
                                            <li>
                                              <span class=" bg-gray-100 p-1 px-2 rounded-md text-dark dark:text-title-dark me-[5px] text-[14px] font-medium">{e.selectedRadio.condition_text}</span>
                                            </li>
                                            {e.checkedAdditionalPrices.length > 0 ? <>
                                              {e.checkedAdditionalPrices.map((additional) => {
                                                return (
                                                  <>     <li class="bg-gray-100 p-1 px-2">
                                                    <span class="text-body dark:text-subtitle-dark text-[13px]">{additional.question_text
                                                    }</span>:<span class="ml-2 text-body dark:text-subtitle-dark text-[13px]">{additional.
                                                      additional_price
                                                    } FBU</span>
                                                  </li>
                                                  </>
                                                );
                                              })}</>
                                              : null
                                            }
                                          </ul >
                                          {
                                            e.Recommandations &&
                                            <p class="mt-2">
                                              <span class="text-body dark:text-subtitle-dark text-[13px]">{e.Recommandations}</span>
                                            </p>
                                          }
                                        </figcaption>
                                      </div>
                                    </td>
                                    <td class="px-4 py-2.5 font-normal text-center capitalize text-[14px] text-light dark:text-subtitle-dark border-none group-hover:bg-transparent">
                                      {e.selectedRadio.suggested_price
                                      } <span class="ml-1">FBU</span>
                                    </td>
                                    <td class="px-4 py-2.5 font-normal text-center capitalize text-[14px] text-dark dark:text-title-dark border-none group-hover:bg-transparent">
                                      <div class="flex items-center justify-center">
                                        <button type="button" class="bg-gray-200 productItemsDown cursor-pointer transition-[0.3s] hover:text-primary hover:bg-primary/10 w-[38px] h-[38px] flex items-center justify-center text-[16px] text-body dark:text-subtitle-dark bg-regular dark:bg-box-dark-up border-1 border-regular dark:border-box-dark-up rounded-[10px]">
                                          <span>-</span>
                                        </button>
                                        <input type="number" min="1" value="1" class="productItemsNumber bg-transparent 2xl:ps-[12px] w-[50px] text-[14px] font-medium text-dark dark:text-title-dark placeholder:text-dark dark:placeholder:text-title-dark border-none shadow-none appearance-none outline-none text-center" />
                                        <button type="button" class="bg-gray-200 productItemsUp cursor-pointer transition-[0.3s] hover:text-primary hover:bg-primary/10 w-[38px] h-[38px] flex items-center justify-center text-[16px] text-body dark:text-subtitle-dark bg-regular dark:bg-box-dark-up border-1 border-regular dark:border-box-dark-up rounded-[10px]">
                                          <span>+</span>
                                        </button>
                                      </div>
                                    </td>
                                    <td class="ps-4 pe-[25px] py-2.5 font-normal text-center capitalize text-[14px] text-primary border-none group-hover:bg-transparent rounded-e-[4px]">
                                      {(e.selectedRadio.suggested_price * e.quantity).toFixed(2)
                                      } <span class="ml-1">FBU</span>
                                    </td>
                                    <td class="px-4 py-2.5 font-normal text-center capitalize border-none group-hover:bg-transparent">
                                      <div class="flex items-center justify-center">
                                        <button type="button" class="w-[38px] h-[38px] flex items-center text-light-extra hover:bg-danger/10 justify-center text-[18px] cursor-pointer remove-event-wrapper uil uil-trash-alt hover:text-danger rounded-full">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="mr-2 h-6 w-7"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                                            ></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}

                            <tr>
                              <td colspan="5" class="ps-[25px] pe-4 py-2.5">
                                <div class="flex items-center gap-[15px] mt-[15px] mb-[35px]">
                                  <input type="text" id="product-name" class="w-[180px] bg-regularBG rounded-6 border-regular border-1 text-[15px] dark:bg-box-dark-up dark:border-box-dark-up px-[20px] py-[12px] min-h-[44px] outline-none placeholder:text-[#A0A0A0] text-body dark:text-subtitle-dark focus:ring-primary focus:border-primary" placeholder="Coupon Code" autocomplete="off" />
                                  <button type="button" class="group text-[14px] border-normal bg-sky-500  rounded-lg font-semibold text-white dark:text-title-dark btn-outlined h-[44px] dark:border-box-dark-up sm:px-[20px] px-[15px] rounded-6 flex items-center gap-[5px] leading-[22px] hover:text-white hover:bg-primary-hbr bg-primary transition duration-300" data-te-ripple-init="" data-te-ripple-color="light">
                                    Apply Coupon
                                  </button>
                                </div>
                              </td>
                              <td colspan="5" class="ps-[25px] pe-4 py-2.5">
                                <div class="flex items-center gap-[15px] mt-[15px] mb-[35px]">
                                  <input type="text" id="product-name" class="w-[180px] bg-regularBG rounded-6 border-regular border-1 text-[15px] dark:bg-box-dark-up dark:border-box-dark-up px-[20px] py-[12px] min-h-[44px] outline-none placeholder:text-[#A0A0A0] text-body dark:text-subtitle-dark focus:ring-primary focus:border-primary" placeholder="Coupon Code" autocomplete="off" />
                                  <button type="button" class="group text-[14px] border-normal bg-sky-500  rounded-lg font-semibold text-white dark:text-title-dark btn-outlined h-[44px] dark:border-box-dark-up sm:px-[20px] px-[15px] rounded-6 flex items-center gap-[5px] leading-[22px] hover:text-white hover:bg-primary-hbr bg-primary transition duration-300" data-te-ripple-init="" data-te-ripple-color="light">
                                    Apply Coupon
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="col-span-12 2xl:col-span-12  text-grey-darkest  h-auto rounded-xl border bg-white shadow-inner">
                      <div class="bg-regularBG dark:bg-box-dark-up sm:p-[25px] p-[15px] rounded-10">
                        <h1 class="mb-6 text-xl font-semibold text-dark dark:text-title-dark">Order Summary</h1>
                        <div class="bg-white dark:bg-box-dark p-[25px] shadow-[0_10px_30px_rgba(10,10,10,0.06)] rounded-10">
                          <ul class="mb-0">
                            <li class="flex items-center justify-between mb-[18px]"><span class="font-medium text-body dark:text-subtitle-dark">Subtotal :</span><span class="font-medium text-dark dark:text-title-dark">$488</span></li>
                            <li class="flex items-center justify-between mb-[18px]"><span class="font-medium text-body dark:text-subtitle-dark">Discount :</span><span class="font-medium text-dark dark:text-title-dark">$-20</span></li>
                            <li class="flex items-center justify-between mb-[18px]"><span class="font-medium text-body dark:text-subtitle-dark">Shipping Charge :</span><span class="font-medium text-dark dark:text-title-dark">$30</span></li>
                          </ul>
                          <select data-te-select-init data-te-class-select-input="py-[10px] px-[20px] w-full capitalize [&~span]:top-[15px] [&~span]:w-[15px] [&~span]:h-[15px] [&~span]:text-body dark:[&~span]:text-white text-light dark:text-subtitle-dark border-normal dark:border-box-dark-up border-1 rounded-4 dark:bg-box-dark-up outline-none ltr:[&~span]:right-[0.75rem] rtl:[&~span]:left-[0.75rem] rtl:[&~span]:right-auto" data-te-class-notch-leading="!border-0 !shadow-none group-data-[te-input-focused]:shadow-none group-data-[te-input-focused]:border-none" data-te-class-notch-middle="!border-0 !shadow-none !outline-none" data-te-class-notch-trailing="!border-0 !shadow-none !outline-none">
                            <option value="all">Select Coupon</option>
                            <option value="2">SAVE10NOW</option>
                            <option value="3">WEBDEAL20</option>
                            <option value="4">CODE50OFF</option>
                            <option value="5">DESIGN15</option>
                            <option value="6">TECHSALE25</option>
                          </select>
                          <label for="promoCode" class="text-body dark:text-subtitle-dark text-[15px] w-full relative mt-[20px] block">Promo
                            Code</label>
                          <div class="flex items-center gap-[15px] mt-[15px] mb-[35px]">
                            <input type="text" id="promoCode" class="w-[180px] bg-regularBG rounded-6 border-regular border-1 text-[15px] dark:bg-box-dark-up dark:border-box-dark-up px-[20px] py-[12px] h-[44px] outline-none placeholder:text-[#A0A0A0] text-body dark:text-subtitle-dark focus:ring-primary focus:border-primary" placeholder="Promo Code" autocomplete="off" />
                            <button type="button" class="group text-[14px] border-success border-1 font-semibold text-success btn-outlined h-[44px] sm:px-[20px] px-[15px] rounded-6 flex items-center gap-[5px] leading-[22px] hover:text-white hover:bg-success transition duration-300" data-te-ripple-init="" data-te-ripple-color="light">
                              Apply
                            </button>
                          </div>
                          <h5 class="inline-flex items-center justify-between w-full mb-[15px]"><span class="text-base font-semibold text-dark dark:text-title-dark">Total : </span><span class="text-lg font-semibold text-primary">$498</span></h5>
                          <button type="button" class="group w-full text-[14px] border-normal font-semibold text-white dark:text-title-dark btn-outlined h-[44px] dark:border-box-dark-up sm:px-[20px] px-[15px] rounded-6 flex items-center justify-center gap-[5px] leading-[22px] hover:text-white hover:bg-primary-hbr bg-primary transition duration-300 capitalize" data-te-ripple-init="" data-te-ripple-color="light">
                            process to checkout
                            <i class="uil uil-arrow-right text-[16px]"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              )
              }
              <div className="my-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
                <button
                  type="button"
                  className="flex w-full items-center justify-between rounded-t-xl border border-b-0 border-gray-200 bg-sky-50 p-5 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800"
                >
                  <div className="flex flex-row space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z"
                      />
                    </svg>

                    <span className="text-xl">Our Location</span>
                  </div>
                </button>
                <div className="flex w-full items-center justify-center  text-md border border-b-0 border-gray-200 rounded-b-3xl bg-white p-5">
                  <div className=" border border-sky-300 shadow rounded-2xl overflow-hidden">
                    {" "}
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15931.280151019238!2d29.3954391!3d-3.3940662!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19c182b87903ccc1%3A0xbacb4e867f382ad3!2sCampus%20Universitaire%20de%20Kiriri!5e0!3m2!1sfr!2sbi!4v1703529817729!5m2!1sfr!2sbi"
                      width="520"
                      height="450"
                      style={{ border: "0" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>}
            {" "}
          </div>{" "}
        </div>
      </section>
    </>
  );
}

export default Quick_OrderComponent;
