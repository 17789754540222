import React from "react";

import AllWebsiteBuyComponent from "../components/Buy/AllWebsiteBuyComponent";
function AllWebsiteBuy() {
  return (
    <>
      <AllWebsiteBuyComponent />
    </>
  );
}
export default AllWebsiteBuy;
