import React, { useContext } from "react";
import Helper from "./Helper";
import { HelpContext } from "../../context";
import { PostContext } from "../../context";
import { Link } from "react-router-dom";
import Api from "../Api/Api";

function Pub(props) {
  const post = props.post;
  const TimeAgo = (dateString) => {
    const currentDate = new Date();
    const pastDate = new Date(dateString);

    const timeDifference = currentDate.getTime() - pastDate.getTime();

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return years === 1 ? "1 year ago" : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? "1 month ago" : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1 min ago" : `${minutes} min ago`;
    } else {
      return seconds === 1 ? "Now" : "Now";
    }
  };

  const TimeLeft = (dateString) => {
    const currentDate = new Date();
    const futureDate = new Date(dateString);

    const timeDifference = futureDate.getTime() - currentDate.getTime();

    if (timeDifference <= 0) {
      return "Expired";
    }

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return years === 1 ? "1 year left" : `${years} years left`;
    } else if (months > 0) {
      return months === 1 ? "1 month left" : `${months} months left`;
    } else if (days > 0) {
      return days === 1 ? "1 day left" : `${days} days left`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour left" : `${hours} hours left`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1 min left" : `${minutes} min left`;
    } else {
      return seconds === 1 ? "1 sec left" : `${seconds} sec left`;
    }
  };
  const user_id = localStorage.getItem("userid");
  const AddLike = async (post_id) => {
    if (localStorage.getItem("userid")) {
      try {
        const response = await Api.post("/api/likes", { post_id, user_id });
        // console.log(response.data);
        // Handle success response
      } catch (error) {
        // console.error(error);
        // Handle error response
      }
    }
  };

  const isUserLiked = (Ads_likes, user_id) => {
    Ads_likes.map((like) => {
      if (like.user_id == user_id) {
        return true;
      }
    });

    return false;
  };

  const help = useContext(HelpContext);
  const pub = useContext(PostContext);
  return (
    <>
      <div className="items min-h-48 flex items-center justify-center">
        <custom-card3>
          <div className="bg-gray-100 text-gray-900 border border-gray-300 rounded-lg shadow-md ">
            <div className="flex items-center justify-between p-3">
              <div className="flex items-center space-x-2">
                <img
                  src={`http://backend.novadiscova.server.cisabu.bi/uploads/Ads/${post.file}`}
                  alt=""
                  className="bg-coolGray-500 border-coolGray-700 h-8 w-8 rounded-full object-cover object-center shadow-sm"
                />
                <div className="-space-y-1">
                  <h2 className="text-sm font-semibold leading-none">
                    {post.User.username}
                  </h2>
                  <span className="text-coolGray-400 inline-block text-xs leading-none">
                    {post.User.job}
                  </span>
                </div>
              </div>
              <div className=" shadow flex flex-row items-center  justify-center space-x-1 bg-sky-500 rounded-2xl px-3 py-2">
                <Link
                  to={`/editads/${post.id}`}
                  className=" text-sm text-white"
                >
                  Edit Ads
                </Link>
              </div>
            </div>

            <div className="flex flex-col w-full items-start justify-start px-7 text-sm">
              <p className="px-3 text-base text-gray-700">
                {post.description}
                {/* {post.description.length >= 20
                  ? post.description.substring(0, 20) + "...."
                  : post.description} */}
              </p>
              <div class="w-full flex flex-row justify-end items-end">
                <div className="w-auto rounded-t-lg  bg-sky-500 px-3 text-white">
                  {" "}
                  {TimeAgo(post.createdAt)}
                </div>
              </div>
            </div>
            <div className="">
              <Link to={`/MyAds/${post.id}`}>
                <div className="relative  w-full  overflow-hidden">
                  <div className="h-32 md:h-[291px] ">
                    <img
                      src={`http://backend.novadiscova.server.cisabu.bi/uploads/Ads/${post.file}`}
                      alt="product 1"
                      className="object-cover border border-sky-300 md:min-w-[289px] h-full w-full rounded-2xl"
                    />
                  </div>
                </div>
              </Link>
            </div>

            <div className="p-3">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <button
                    type="button"
                    title="Like post"
                    className="flex items-center justify-center"
                  >
                    <>
                      <div className=" shadow flex flex-row items-center  justify-center space-x-1 bg-sky-200 rounded-2xl px-3 py-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="h-5 w-5 text-sky-500"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                        <span className="text-xs text-sky-600 font-medium">
                          {!post.Ads_likes
                            ? "0"
                            : post.Ads_likes.length > 1000
                            ? `${(post.Ads_likes.length / 1000).toFixed(1)}K`
                            : post.Ads_likes.length}{" "}
                          users
                        </span>
                      </div>
                    </>
                  </button>
                </div>
                <button
                  type="button"
                  title="Like post"
                  className="flex items-center justify-center"
                >
                  <>
                    <div
                      className={
                        TimeLeft(post.end_date) == "Expired"
                          ? " shadow flex flex-row items-center  justify-center space-x-1 bg-red-200 rounded-2xl px-3 py-2"
                          : " shadow flex flex-row items-center  justify-center space-x-1 bg-sky-200 rounded-2xl px-3 py-2"
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className={
                          TimeLeft(post.end_date) == "Expired"
                            ? "h-5 w-5 text-red-500"
                            : "h-5 w-5 text-sky-500"
                        }
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>

                      <span
                        className={
                          TimeLeft(post.end_date) == "Expired"
                            ? "text-xs text-red-600 font-medium"
                            : "text-xs text-sky-600 font-medium"
                        }
                      >
                        {TimeLeft(post.end_date)}
                      </span>
                    </div>
                  </>
                </button>
              </div>
            </div>
          </div>
        </custom-card3>
      </div>
    </>
  );
}
export default Pub;
