import React, { useContext, useEffect, useState } from "react";
import Api from "../Api/Api";
function Member({ need, ValidateUser, issend, setsend }) {
  const user_id = localStorage.getItem("userid");
  const [Error, SetError] = useState(null);
  const Validate = (need_id) => {
    ValidateUser(need_id);
  };
  const TimeAgo = (dateString) => {
    const currentDate = new Date();
    const pastDate = new Date(dateString);

    const timeDifference = currentDate.getTime() - pastDate.getTime();

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return years === 1 ? "1 year ago" : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? "1 month ago" : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1 min ago" : `${minutes} min ago`;
    } else {
      return seconds === 1 ? "Now" : "Now";
    }
  };
  return (
    <>
      <div className="flex w-auto flex-col items-center justify-center rounded-3xl bg-gray-50 border  p-5">
        <div className="w-auto flex flex-col items-center justify-center">
          <div className="flex flex-row space-x-3">
            <div>
              {need.profile_picture ? (
                <img
                  className="w-28 h-auto mx-auto aspect-square rounded-full border-2 border-gray-300 dark:bg-gray-500"
                  src={`http://backend.novadiscova.server.cisabu.bi/uploads/PhotoUser/${need.profile_picture}`}
                  alt={""}
                />
              ) : (
                <div className=" h-16 w-16 flex-shrink-0 flex items-center justify-center rounded-full bg-sky-50 border-sky-300 border object-cover">
                  <span class="text-sky-500 text-3xl uppercase font-medium">
                    {need.username?.substring(0, 1)}
                  </span>
                </div>
              )}
            </div>
            <div className="space-y-1">
              <h2 className="text-lg uppercase font-semibold text-sky-500">
                {need.username}
              </h2>
              <p className="text-xl text-sky-700 dark:text-gray-400">
                {need.job}
              </p>
            </div>
          </div>
          <div className="space-y-4 divide-y mt-4 divide-sky-700 px-3 text-center">
            <div className="flex items-center justify-center space-x-4 rounded-3xl bg-gray-50 pt-2">
              <button
                onClick={() => {
                  Validate(need.id);
                }}
                className="text-white bg-sky-500 font-medium rounded-2xl text-lg px-5 py-2.5 text-center mr-2 mb-2 opacity-80"
              >
                {issend ? "Validate...." : "Validate"}
              </button>
            </div>
            <div className="flex items-center justify-center pt-2">
              <div className="flex items-center justify-center hidden space-x-2">
                <div className="-space-y-1">
                  <span className="text-gray-500 inline-block text-sm font-semibold leading-none">
                    Alerted At
                  </span>
                </div>
              </div>
              <div className="w-auto  bg-gray-200 rounded-lg border-sky-500 opacity-70 shadow border flex flex-row space-x-1 ">
                <span className="flex items-center justify-center pl-1 font-semibold italic">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-4 h-4 text-gray-800"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
                <span className="w-auto rounded-lg text-sm bg-sky-500 px-2 text-white">
                  {TimeAgo(need.createdAt)}
                </span>
              </div>
            </div>
          </div>
        </div>
        {Error ? (
          <label
            htmlFor="username"
            className="text-left  ml-3 block font-medium text-green-500 dark:text-white"
          >
            {Error}
          </label>
        ) : null}
      </div>
    </>
  );
}
export default Member;
