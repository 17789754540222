import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TargetContext } from "../../../context";
import Target from "./Target";
function TargetOfWork() {
  const { id } = useParams();
  var tags = useContext(TargetContext);
  const target = useContext(TargetContext);
  tags = tags.tags;
  useEffect(() => {
    target.handleTags(id);
  }, []);
  return (
    <>
      {tags ? (
        <div className="mb-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
          <button
            type="button"
            className="flex w-full items-center justify-between rounded-t-xl border border-b-0 border-gray-200 bg-sky-100 p-5 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800"
          >
            <span>Target Of Our Work</span>
          </button>
          <div>
            <div className="border border-b-0 border-gray-200 p-5 font-light dark:border-gray-700 dark:bg-gray-900">
              <div className="flex w-full flex-col space-y-4">
                {tags.length > 1 ? (
                  tags.map((tag) => {
                    return <Target key={tag.id} tag={tag} />;
                  })
                ) : (
                  <Target key={tags.id} tag={tags} />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
export default TargetOfWork;
