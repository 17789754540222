export const MySmsDatas = [
    {
      id: 1,
      name: "UI Art Design",
      photo: "https://source.unsplash.com/150x150/?portrait?6",
      sms: "Skate ipsum dolor sit amet, alley oop vert mute-air Colby Carter flail 180 berm.",
      active: true
        
    },
    {
        id: 2,
        name: "UI Art Design",
        photo: "https://source.unsplash.com/150x150/?portrait?5",
        sms: "Skate ipsum dolor sit amet, alley oop vert mute-air Colby Carter flail 180 berm.",
        active: false
    },
    {
        id: 3,
        name: "UI Art Design",
        photo: "https://source.unsplash.com/150x150/?portrait?1",
        sms: "Skate ipsum dolor sit amet, alley oop vert mute-air Colby Carter flail 180 berm.",
        active: true
    },
    {
        id: 4,
        name: "BUK-C BOY",
        photo: "https://source.unsplash.com/150x150/?portrait?2",
        sms: "Skate ipsum dolor sit amet, alley oop vert mute-air Colby Carter flail 180 berm.",
        active: true
          
    },
    {
        id: 5,
        name: "BUK-C BOY",
        photo: "https://source.unsplash.com/150x150/?portrait?3",
        sms: "Skate ipsum dolor sit amet, alley oop vert mute-air Colby Carter flail 180 berm.",
        active: false
    },
    {
        id: 6,
        name: "UI Art Design",
        photo: "https://source.unsplash.com/150x150/?portrait?4",
        sms: "Skate ipsum dolor sit amet, alley oop vert mute-air Colby Carter flail 180 berm.",
        active: true
    }
  ];

  export const MysmsDetail = {
    id: 1,
    name: "BUK-C BOY",
    photo: "https://source.unsplash.com/150x150/?portrait?2",
    sms: "Skate ipsum dolor sit amet, alley oop vert mute-air Colby Carter flail 180 berm.",
    active: true
      
}
  