import React from "react";

import AllRadioBuyComponent from "../components/Buy/AllRadioBuyComponent";
function AllRadioBuy() {
  return (
    <>
      <AllRadioBuyComponent />
    </>
  );
}
export default AllRadioBuy;
