import React, {useContext} from 'react'
import {WaitUContext} from '../../context'
export default function WaitUser(props) {
    const wt = props.wt
    const wtU = useContext(WaitUContext)
  return (
    <>
        <div className="items  flex items-center justify-center  " style={{cursor: "auto"}}>
      <custom-card3>
        <div className="bg-gray-100 text-gray-900 rounded-lg shadow-md w-64 ">
          <div className="flex items-center justify-between p-3" style={{cursor: "auto"}}>
            <div className="flex items-center space-x-2" style={{cursor: "auto"}}>
              <img src={wt.photo} alt="" className="bg-coolGray-500 border-coolGray-700 h-8 w-8 rounded-full object-cover object-center shadow-sm" style={{cursor: "auto"}}/>
              <div className="-space-y-1" style={{cursor: "auto"}}>
                <h2 className="text-sm font-semibold leading-none" style={{cursor: "auto"}}>{wt.name}</h2>
                <span className="text-coolGray-400 inline-block text-xs leading-none" style={{cursor: "auto"}}>{wt.cat}</span>
              </div>
            </div>
            <button title="Open options" type="button">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="h-5 w-5 fill-current" style={{cursor: "auto"}}>
                <path d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"></path>
                <path d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"></path>
                <path d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"></path>
              </svg>
            </button>
          </div>
          <div className="p-3" style={{cursor: "auto"}}>
            <div className=" flex items-center justify-between" style={{cursor: "auto"}}>
              <div className="flex items-center space-x-3">
                
                <button type="button" title="Add a comment" className="flex items-center justify-center">
                  
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="h-5 w-5 fill-current" viewBox="0 0 16 16">
  <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"></path>
</svg>


                    <span className="px-2">{wt.comment}K</span>
                </button>
                <button type="button" title="Like post" className="flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"  className="h-5 w-5 fill-current" >
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
</svg>
                  <span className="px-2">{wt.likes}K</span>
                </button>
                
               
              </div>
              <button type="button" title="Bookmark post" className="flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="h-5 w-5 fill-current" viewBox="0 0 16 16">
                <path d="M3.75 0a1 1 0 0 0-.8.4L.1 4.2a.5.5 0 0 0-.1.3V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4.5a.5.5 0 0 0-.1-.3L13.05.4a1 1 0 0 0-.8-.4h-8.5ZM8.5 4h6l.5.667V5H1v-.333L1.5 4h6V1h1v3ZM8 7.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z"></path>
              </svg>
              </button>
            </div>
            <div className="flex flex-wrap items-center justify-center pt-3 pb-1" style={{cursor: "auto"}}>
              
              <div className="flex items-center justify-between space-x-2">
              {wt.status ? 
                <button onClick={()=>wtU.handleStatus(wt.id)} type="button" className="py-2.5 px-5 mr-2 mb-2 text-sm text-green-500 font-medium focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-sky-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Accepted</button>
                : 
                <button onClick={()=>wtU.handleStatus(wt.id)} type="button" className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-sky-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Accept</button>
              }
                <button onClick={()=>wtU.removeUser(wt.id)} type="button" className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-red-600 focus:outline-none bg-white rounded-lg border border-r-200 hover:bg-red-500 hover:text-sky-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Delete</button>
            </div>
            </div>
          </div>
        </div>
      </custom-card3>
    </div>
    </>
  )
}
