import React, { useContext, useState } from "react";
import Api from "../Api/Api";
import { Link, useNavigate } from "react-router-dom";
import { GlobalFunctionContext } from "../../context";
function Event(props) {
  const history = useNavigate();
  const Global = useContext(GlobalFunctionContext);
  const evt = props.evt;
  console.log(props.evt);
  var date = new Date(evt.createdAt);
  const eDate = date.toString("YYYY-MM-dd").split(" ");
  const eTime = eDate[4].split(":");
  const [issend, setsend] = useState(false);
  const [Error, SetError] = useState(null);
  const TimeAgo = (dateString) => {
    const currentDate = new Date();
    const pastDate = new Date(dateString);

    const timeDifference = currentDate.getTime() - pastDate.getTime();

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return years === 1 ? "1 year ago" : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? "1 month ago" : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1 min ago" : `${minutes} min ago`;
    } else {
      return seconds === 1 ? "Now" : "Now";
    }
  };

  const user_id = localStorage.getItem("userid");
  const Order = async (have_id) => {
    setsend(true);
    if (localStorage.getItem("userid")) {
      try {
        const response = await Api.post("/api/Order/Have", {
          have_id,
          user_id,
        });
        console.log(response.data);
        setsend(false);
        Global.setUserPop(true);

        // Handle success response
      } catch (error) {
        console.error(error);
        if (error.response) {
          const errorMessage = error.response.data.error;
          SetError(errorMessage);
          setTimeout(() => {
            SetError(null);
          }, 3000);
        }
        setsend(false);
        // Handle error response
      }
    }
  };

  const AddPreference = async (haves_id) => {
    if (localStorage.getItem("userid")) {
      try {
        const response = await Api.post("/api/haves/view", {
          haves_id,
          user_id,
        });
        console.log(response.data);

        if (response.data) {
          history(`/who/havepost/${haves_id}`);
        }
        // Handle success response
      } catch (error) {
        console.error(error);
        // Handle error response
      }
    }
  };
  return (
    <>
      <div className=" item sm:w-[600px] card text-grey-darkest flex h-auto ">
        <div
          className="sm:flex hidden h-full py-3  flex-col items-start justify-start  mr-4"
          alt="Date"
        >
          {evt.User?.profile_picture ? (
            <img
              className="bg-coolGray-500 border-coolGray-700 h-12 w-12 rounded-full object-cover object-center shadow-sm"
              src={`http://backend.novadiscova.server.cisabu.bi/uploads/Haves_Needs/${evt.file}`}
              alt={evt.User.username}
            />
          ) : (
            <div className="mx-1 h-12 w-12 flex-shrink-0 flex items-center justify-center rounded-full bg-sky-50 border-sky-300 border object-cover">
              <span class="text-sky-500 uppercase font-medium">
                {evt.User.username?.substring(0, 1)}
              </span>
            </div>
          )}
        </div>
        <div className="flex w-full  flex-col rounded-xl bg-white border shadow-md">
          <div className="flex-1 px-3 pb-0">
            <div className="flex items-center justify-between pt-3">
              <div className="flex items-center space-x-2">
                <div className="-space-y-1">
                  <h2 className="text-sm font-semibold leading-none">
                    {evt.User.username}
                  </h2>
                  <span className="text-coolGray-400 inline-block text-xs font-semibold leading-none">
                    {evt.User.job}
                  </span>
                </div>
              </div>
              <div className="w-auto ml-10 bg-gray-200 rounded-lg border-sky-500 opacity-70 shadow border flex flex-row space-x-1 ">
                <span className="flex items-center justify-center pl-1 font-semibold italic">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-4 h-4 text-gray-800"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
                <span className="w-auto rounded-lg text-sm bg-sky-500 px-2 text-white">
                  {TimeAgo(evt.createdAt)}
                </span>
              </div>
            </div>

            <div className="my-4 flex flex-row justify-between ">
              <div className="flex flex-row space-x-3">
                <div class="bg-gray-100 py-2 px-3 flex flex-row rounded-xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                    />
                  </svg>

                  <span className="rounded-r-xl text-base text-gray-700">
                    {evt.location}
                  </span>
                </div>
                <div class="bg-gray-100 py-2 px-3 flex flex-row rounded-xl">
                  <span className="rounded-r-xl text-base text-gray-700">
                    {evt.Category.title}
                  </span>
                </div>
              </div>
              <div class="bg-gray-100 py-2 px-3 flex flex-row rounded-xl">
                <span className="rounded-r-xl text-lg font-bold text-gray-700">
                  {evt.price}fbu
                </span>
              </div>
            </div>
            <div className="my-4">
              <span className="rounded-r-xl text-base text-gray-700">
                {evt.description}
              </span>
            </div>

            <div className="mb-4">
              <button onClick={() => AddPreference(evt.id)}  className="relative  w-full">
                <div className="relative  w-full  overflow-hidden">
                  <div className="h-32 md:h-[291px] ">
                    <img
                      src={`http://backend.novadiscova.server.cisabu.bi/uploads/Haves_Needs/${evt.file}`}
                      alt="product 1"
                      className="object-cover border border-sky-300 md:min-w-[289px] h-full w-full rounded-2xl"
                    />
                  </div>
                </div>
              </button>
            </div>

            <div className="mt-1 mb-3 flex items-center justify-between">
              <div className="flex flex-row items-center  justify-center space-x-1">
                <div className="flex flex-row items-center  justify-center space-x-1 bg-sky-500 rounded-2xl px-3 py-2">
                  <button
                    onClick={() => {
                      Global.setPop(evt.User);
                      Order(evt.id);
                    }}
                    className="pr-2 text-md text-white"
                  >
                    {issend ? "Order Now..." : "Order Now"}
                  </button>
                </div>
                {Error ? (
                  <label
                    htmlFor="username"
                    className="text-left  ml-3 block font-medium text-green-500 dark:text-white"
                  >
                    {Error}
                  </label>
                ) : null}
              </div>
              <div className="flex flex-row space-x-3 items-center justify-center">
                <div className="flex flex-row space-x-1 text-yellow-500 items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                    />
                  </svg>
                </div>

                <span class="text-lg text-gray-600 font-semibold ">
                  {evt.Haves_orders.length} Order
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Event;
