import React from 'react'
import MyMessages from '../components/Messages/MyMessages'

export default function MessagesPage() {
  return (
    <>
      <MyMessages />  
    </>
  )
}
