import { faL } from "@fortawesome/free-solid-svg-icons";
import React, { Component } from "react";
import { StepDatas } from "./Models/StepWorkDatas";
import { reviewsData } from "./Models/ReviewDatas";
import { PostData } from "./Models/PostDatas";
import { SocialData } from "./Models/SocialDatas";
import { HelpData } from "./Models/HelpersDatas";
import { TargetDatas } from "./Models/TargetDatas";
import { NotifyDatas } from "./Models/NotifyDatas";
import { SupsDatas } from "./Models/SupsDatas";
import { SugesDada } from "./Models/SugesDadas";
import { EventsData, detailEvent } from "./Models/EventDatas";
import { WaitUser } from "./Models/WaitUserDatas";
import { MySmsDatas, MysmsDetail } from "./Models/MySmsDatas";
import { MessageDatas } from "./Models/MessageDatas";
import { PricingData } from "./Models/PricingData";
import { PostTabData } from "./Models/PostTabsDatas";
import LoadingPage from "./components/LoadingPage.js/LoadingPage";
import Api from "./components/Api/Api";

const StepContext = React.createContext();
const AdsContext = React.createContext();
const ReviewContext = React.createContext();
const PostContext = React.createContext();
const NewsContext = React.createContext();
const HavesContext = React.createContext();
const NeedsContext = React.createContext();
const PostOrdersContext = React.createContext();
const SocialContext = React.createContext();
const HelpContext = React.createContext();
const TargetContext = React.createContext();
const NotifyContext = React.createContext();
const SupsContext = React.createContext();
const SugesContext = React.createContext();
const EventContext = React.createContext();
const WaitUContext = React.createContext();
const MySmsContext = React.createContext();
const SmsContext = React.createContext();
const PricingContext = React.createContext();
const PostTabContext = React.createContext();
const GlobalFunctionContext = React.createContext();
let newnotif = 0;
class StepProvider extends Component {
  componentDidMount() {
    this.setSteps();
  }
  //set Steps function
  setSteps = () => {
    let tempsetSteps = [];
    StepDatas.forEach((item) => {
      const singleItem = { ...item };
      tempsetSteps = [...tempsetSteps, singleItem];
    });
    this.setState(() => {
      return { steps: tempsetSteps };
    });
  };

  render() {
    return (
      <StepContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </StepContext.Provider>
    );
  }
}

class GlobalFunctionProvider extends Component {
  state = {
    UserPop: false,
    AdsPop: false,
    Ads: {},
    Pop: {},
    LocationPop: false,
    Location: {},
    ProductstoCart: {},
    ReportPop: false,
    SupportPop: false,
    AddtoCartPop: false,
  };
  setReportPop = (newValue) => {
    this.setState({ ReportPop: newValue });
  };
  setSupportPop = (newValue) => {
    this.setState({ SupportPop: newValue });
  };
  setUserPop = (newValue) => {
    this.setState({ UserPop: newValue });
  };
  setPop = (newValue) => {
    this.setState({ Pop: newValue });
  };

  setAdsPop = (newValue) => {
    this.setState({ AdsPop: newValue });
  };

  setAds = (newValue) => {
    this.setState({ Ads: newValue });
  };
  setLocationPop = (newValue) => {
    this.setState({ LocationPop: newValue });
  };

  setAddtoCartPop = (newValue,Product) => {
        this.setState({ AddtoCartPop: newValue,ProductstoCart:Product });
  };

  setLocation = (newValue) => {
    this.setState({ Location: newValue });
  };

  render() {
    return (
      <GlobalFunctionContext.Provider
        value={{
          ReportPop: this.state.ReportPop,
          UserPop: this.state.UserPop,
          setUserPop: this.setUserPop,
          AdsPop: this.state.AdsPop,
          setAdsPop: this.setAdsPop,
          Ads: this.state.Ads,
          setAds: this.setAds,
          Pop: this.state.Pop,
          setPop: this.setPop,
          Location: this.state.Location,
          setLocation: this.setLocation,
          LocationPop: this.state.LocationPop,
          setLocationPop: this.setLocationPop,
          setReportPop: this.setReportPop,
          SupportPop: this.state.SupportPop,
          setSupportPop: this.setSupportPop,
          setAddtoCartPop :this.setAddtoCartPop,
          AddtoCartPop:this.state.AddtoCartPop,
          ProductstoCart:this.state.ProductstoCart
        }}
      >
        {this.props.children}
      </GlobalFunctionContext.Provider>
    );
  }
}

class PostTabProvider extends Component {
  componentDidMount() {}

  handleLinks = async (id) => {
    try {
      const response = await Api.get(`/api/Links/${id}`);
      const tempsetTabData = response.data;
      this.setState(() => {
        return { TabsData: tempsetTabData };
      });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <PostTabContext.Provider
        value={{ ...this.state, handleLinks: this.handleLinks }}
      >
        {this.props.children}
      </PostTabContext.Provider>
    );
  }
}

class ReviewProvider extends Component {
  //set Reviews function
  componentDidMount() {
    this.setReviews();
  }
  setReviews = () => {
    let tempsetReviews = [];
    reviewsData.forEach((item) => {
      const singleItem = { ...item };
      tempsetReviews = [...tempsetReviews, singleItem];
    });
    this.setState(() => {
      return { reviews: tempsetReviews };
    });
  };

  render() {
    return (
      <ReviewContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </ReviewContext.Provider>
    );
  }
}

class PostsProvider extends Component {
  state = {
    posts: [],
    totalPages: null,
  };
  componentDidMount() {
    // this.setPosts();
  }

  setPosts = async () => {
    try {
      const response = await Api.get("/api/posts");
      const { products, totalPages } = response.data;
      const posts = products;
      this.setState(() => {
        return { posts };
      });
    } catch (error) {
      console.error(error);
    }
  };

  fetchData = async (page) => {
    try {
      const response = await Api.get(`/api/posts?page=${page}`);
      const { products, totalPages } = response.data;
      const posts = products;
      this.setState((prevState) => {
        const updatedPosts = [...prevState.posts, ...products];
        return { posts: updatedPosts, totalPages };
      });
    } catch (error) {
      console.error(error);
    }
  };

  fetchMyPostData = async (page, user_id) => {
    console.log("Pages ", page);
    try {
      const response = await Api.get(
        `/api/myPosts?page=${page}&user_id=${user_id}`
      );
      const { products, totalPages } = response.data;
      console.log("totalPages", totalPages);
      const posts = products;
      this.setState((prevState) => {
        const updatedPosts = [...prevState.posts, ...products];
        return { posts: updatedPosts, totalPages };
      });
    } catch (error) {
      console.error(error);
    }
  };

  getItem = (id) => {
    const post = this.state.posts.find((item) => item.id == id);
    return post;
  };

  handleDetail = async (id) => {
    try {
      const response = await Api.get(`/api/post/${id}`);
      const post = response.data;
      this.setState(() => {
        return { detailProduct: post };
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleClear = () => {
    this.setState(() => {
      return { posts: [] };
    });
  };

  render() {
    return (
      <PostContext.Provider
        value={{
          ...this.state,
          handleDetail: this.handleDetail,
          handleClear: this.handleClear,
          fetchData: this.fetchData,
          fetchMyPostData: this.fetchMyPostData,
        }}
      >
        {this.props.children}
      </PostContext.Provider>
    );
  }
}

class NewsProvider extends Component {
  state = {
    Related_Articles: [],
    Articles: [],
    totalPages: null,
  };
  componentDidMount() {
    // this.setPosts();
  }

  setPosts = async () => {
    try {
      const response = await Api.get("/api/posts");
      const { products, totalPages } = response.data;
      const posts = products;
      this.setState(() => {
        return { posts };
      });
    } catch (error) {
      console.error(error);
    }
  };

  fetchData = async (page) => {
    try {
      const response = await Api.get(`/api/News?page=${page}`);
      const { Articles, totalPages } = response.data;

      const Articles_items = Articles;
      this.setState((prevState) => {
        const updatedPosts = [...prevState.Articles, ...Articles_items];
        return { Articles: updatedPosts, totalPages };
      });
    } catch (error) {
      console.error(error);
    }
  };
  fetchRelatedData = async (page, id) => {
    try {
      const response = await Api.get(`/api/Related/News?page=${page}&id=${id}`);
      const { Articles, totalPages } = response.data;
      console.log("related", response.data);
      const Articles_items = Articles;
      this.setState((prevState) => {
        const updatedPosts = [...prevState.Related_Articles, ...Articles_items];
        return { Related_Articles: updatedPosts, totalPages };
      });
    } catch (error) {
      console.error(error);
    }
  };

  fetchMyPostData = async (page, user_id) => {
    console.log("Pages ", page);
    try {
      const response = await Api.get(
        `/api/myPosts?page=${page}&user_id=${user_id}`
      );
      const { products, totalPages } = response.data;
      console.log("totalPages", totalPages);
      const posts = products;
      this.setState((prevState) => {
        const updatedPosts = [...prevState.posts, ...products];
        return { posts: updatedPosts, totalPages };
      });
    } catch (error) {
      console.error(error);
    }
  };

  getItem = (id) => {
    const post = this.state.posts.find((item) => item.id == id);
    return post;
  };

  handleDetail = async (id) => {
    try {
      const response = await Api.get(`/api/News/${id}`);
      const News = response.data;
      this.setState(() => {
        return { detailNews: News };
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleClear = () => {
    this.setState(() => {
      return { posts: [] };
    });
  };

  render() {
    return (
      <NewsContext.Provider
        value={{
          ...this.state,
          handleDetail: this.handleDetail,
          handleClear: this.handleClear,
          fetchData: this.fetchData,
          fetchRelatedData: this.fetchRelatedData,
          fetchMyPostData: this.fetchMyPostData,
        }}
      >
        {this.props.children}
      </NewsContext.Provider>
    );
  }
}

class AdsProvider extends Component {
  state = {
    posts: [],
    totalPages: null,
    whowatch: [],
  };
  componentDidMount() {
    this.fetchData();
  }

  setPosts = async () => {
    try {
      const response = await Api.get("/api/posts");
      const { products, totalPages } = response.data;
      const posts = products;
      this.setState(() => {
        return { posts };
      });
    } catch (error) {
      console.error(error);
    }
  };

  fetchData = async (page) => {
    console.log("Pages ", page);
    try {
      const response = await Api.get(`/api/Ads?page=${page}`);
      const { products, totalPages } = response.data;
      console.log("totalPages", totalPages);
      const posts = products;
      this.setState((prevState) => {
        const updatedPosts = [...prevState.posts, ...products];
        return { posts: updatedPosts, totalPages };
      });
    } catch (error) {
      console.error(error);
    }
  };

  fetchMyPostData = async (page, user_id) => {
    console.log("Pages ", page);
    try {
      const response = await Api.get(
        `/api/myAds?page=${page}&user_id=${user_id}`
      );
      const { products, totalPages } = response.data;
      console.log("totalPages", totalPages);
      const posts = products;
      this.setState((prevState) => {
        const updatedPosts = [...prevState.posts, ...products];
        return { posts: updatedPosts, totalPages };
      });
    } catch (error) {
      console.error(error);
    }
  };

  getItem = (id) => {
    const post = this.state.posts.find((item) => item.id == id);
    return post;
  };

  handleDetail = async (id) => {
    try {
      const response = await Api.get(`/api/ads/${id}`);
      const post = response.data;
      this.setState(() => {
        return { detailProduct: post };
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleClear = () => {
    this.setState(() => {
      return { posts: [] };
    });
  };

  fetchWatchData = async (page, ads_id) => {
    try {
      const response = await Api.get(
        `/api/whowatch?page=${page}&ads_id=${ads_id}`
      );
      const { users, totalPages } = response.data;
      console.log(response.data);
      this.setState((prevState) => {
        const updatedwhowatch = [...prevState.whowatch, ...users];
        return { whowatch: updatedwhowatch, totalPages };
      });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <AdsContext.Provider
        value={{
          ...this.state,
          handleDetail: this.handleDetail,
          handleClear: this.handleClear,
          fetchData: this.fetchData,
          fetchMyPostData: this.fetchMyPostData,
          fetchWatchData: this.fetchWatchData,
        }}
      >
        {this.props.children}
      </AdsContext.Provider>
    );
  }
}

class HavesProvider extends Component {
  state = {
    haves: [],
    myhaves: [],
    whohaves: [],
    totalPages: null,
  };

  fetchhavesData = async (page) => {
    try {
      const response = await Api.get(`/api/haves?page=${page}`);
      const { products, totalPages } = response.data;
      this.setState((prevState) => {
        const updatedhaves = [...prevState.haves, ...products];
        return { haves: updatedhaves, totalPages };
      });
    } catch (error) {
      console.error(error);
    }
  };

  fetchmyhavesData = async (page, user_id) => {
    try {
      const response = await Api.get(
        `/api/myhaves?page=${page}&user_id=${user_id}`
      );
      const { products, totalPages } = response.data;
      this.setState((prevState) => {
        const updatedhaves = [...prevState.myhaves, ...products];
        return { myhaves: updatedhaves, totalPages };
      });
    } catch (error) {
      console.error(error);
    }
  };

  fetchWhoHaveDatassss = async (page, user_id, have_id) => {
    try {
      const response = await Api.get(
        `/api/whohave?page=${page}&user_id=${user_id}&have_id=${have_id}`
      );
      const { products, totalPages } = response.data;
      this.setState((prevState) => {
        const updatedwhohaves = [...prevState.whohaves, ...products];
        return { whohaves: updatedwhohaves, totalPages };
      });
    } catch (error) {
      console.error(error);
    }
  };

  fetchWhoHaveData = async (page, have_id) => {
    try {
      const response = await Api.get(
        `/api/whohave?page=${page}&have_id=${have_id}`
      );
      const { users, totalPages } = response.data;
      this.setState((prevState) => {
        const updatedwhohaves = [...prevState.whohaves, ...users];
        return { whohaves: updatedwhohaves, totalPages };
      });
    } catch (error) {
      console.error(error);
    }
  };

  getItem = (id) => {
    const post = this.state.posts.find((item) => item.id == id);
    return post;
  };

  handleDetail = async (id) => {
    try {
      const response = await Api.get(`/api/Have/${id}`);
      const have = response.data;
      this.setState(() => {
        return { detailHave: have };
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleClear = () => {
    this.setState(() => {
      return { posts: [] };
    });
  };
  render() {
    return (
      <HavesContext.Provider
        value={{
          ...this.state,
          handleDetail: this.handleDetail,
          handleClear: this.handleClear,
          fetchhavesData: this.fetchhavesData,
          fetchWhoHaveData: this.fetchWhoHaveData,
          fetchmyhavesData: this.fetchmyhavesData,
        }}
      >
        {this.props.children}
      </HavesContext.Provider>
    );
  }
}

class NeedsProvider extends Component {
  state = {
    needs: [],
    myneeds: [],
    totalPages: null,
    whoneeds: [],
  };

  fetchneedsData = async (page) => {
    try {
      const response = await Api.get(`/api/needs?page=${page}`);
      const { products, totalPages } = response.data;
      this.setState((prevState) => {
        const updatedhaves = [...prevState.needs, ...products];
        return { haves: updatedhaves, totalPages };
      });
    } catch (error) {
      console.error(error);
    }
  };

  fetchmyneedsData = async (page, user_id) => {
    try {
      const response = await Api.get(
        `/api/myneeds?page=${page}&user_id=${user_id}`
      );
      const { products, totalPages } = response.data;
      this.setState((prevState) => {
        const updatedhaves = [...prevState.myneeds, ...products];
        return { myneeds: updatedhaves, totalPages };
      });
    } catch (error) {
      console.error(error);
    }
  };

  fetchWhoNeedData = async (page, need_id) => {
    try {
      const response = await Api.get(
        `/api/whoneed?page=${page}&need_id=${need_id}`
      );
      const { users, totalPages } = response.data;
      this.setState((prevState) => {
        const updatedwhohaves = [...prevState.whoneeds, ...users];
        return { whoneeds: updatedwhohaves, totalPages };
      });
    } catch (error) {
      console.error(error);
    }
  };

  getItem = (id) => {
    const post = this.state.posts.find((item) => item.id == id);
    return post;
  };

  handleDetail = async (id) => {
    try {
      const response = await Api.get(`/api/Need/${id}`);
      const need = response.data;
      this.setState(() => {
        return { detailNeed: need };
      });
    } catch (error) {
      console.error(error);
    }
  };
  handleClear = () => {
    this.setState(() => {
      return { posts: [] };
    });
  };
  render() {
    return (
      <NeedsContext.Provider
        value={{
          ...this.state,
          handleDetail: this.handleDetail,
          handleClear: this.handleClear,
          fetchneedsData: this.fetchneedsData,
          fetchmyneedsData: this.fetchmyneedsData,
          fetchWhoNeedData: this.fetchWhoNeedData,
        }}
      >
        {this.props.children}
      </NeedsContext.Provider>
    );
  }
}

class PostsOrdersProvider extends Component {
  //set Posts function
  componentDidMount() {}

  handleAllOrders = async (id) => {
    try {
      const response = await Api.get(`/api/postorders/user/${id}`);
      const { products, totalPages } = response.data;
      const orders = products;
      this.setState(() => {
        return { orders };
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleOrder = async (id) => {
    try {
      const response = await Api.get(`/api/order/post/${id}`);
      const order = response.data;
      this.setState(() => {
        return { detailorder: order };
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleClear = () => {
    this.setState(() => {
      return { posts: [] };
    });
  };

  render() {
    return (
      <PostOrdersContext.Provider
        value={{
          ...this.state,
          handleOrder: this.handleOrder,
          handleClear: this.handleClear,
          handleAllOrders: this.handleAllOrders,
        }}
      >
        {this.props.children}
      </PostOrdersContext.Provider>
    );
  }
}

class PricingProvider extends Component {
  //set Pricings function
  componentDidMount() {
    this.handleAllPricing();
  }
  handleAllPricing = async () => {
    try {
      const response = await Api.get("/api/platforms/pricing");
      const tempsetPricing = response.data;
      this.setState(() => {
        return { Pricing: tempsetPricing };
      });
    } catch (error) {
      console.error(error);
    }
  };

  AllCompaniesPricing = async (id) => {
    try {
      const response = await Api.get(`/api/platforms/pricing/companies/${id}`);
      const tempsetPricing = response.data;
      this.setState(() => {
        return { CompanyPricing: tempsetPricing };
      });
    } catch (error) {
      console.error(error);
    }
  };

  AllCompanies = async () => {
    try {
      const response = await Api.get(`/api/platforms/companies`);
      const tempsetPricing = response.data;
      this.setState(() => {
        return { Companies: tempsetPricing };
      });
    } catch (error) {
      console.error(error);
    }
  };

  getItem = (Platform) => {
    const PriceArray = this.state.Pricing.find(
      (item) => item.Platform == Platform
    );
    return PriceArray;
  };

  handleDetail = async (id) => {
    try {
      const response = await Api.get(`/api/platforms/pricing/${id}`);
      const Price = response.data;
      this.setState(() => {
        return { detailBuy: Price };
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleUserService = async (id) => {
    try {
      const response = await Api.get(`/api/company/pricing/${id}`);
      const Price = response.data;
      this.setState(() => {
        return { UserService: Price };
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleMenu_Products = async (menu_id,table_id,company_id) => {
    try {
      const response = await Api.post(`/api/menu_products/bymenu_id/`,{
        "menu_id": menu_id,
        "table_id": table_id,
        "companie_id": company_id
      });
      const Menu_Products = response.data;
      this.setState(() => {
        return { Menu_Products: Menu_Products };
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleMenu_Portions = async (menu_id) => {
    try {
      const response = await Api.post(`/api/product_options/byoption/portion_status`,{
        "menu_id": menu_id,
      });
      const Menu_Products = response.data;
      this.setState(() => {
        return { Menu_Portions: Menu_Products };
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleMenu_Speciales = async (menu_id) => {
    try {
      const response = await Api.post(`api/product_options/byoption/plat_speciale_status`,{
        "menu_id": menu_id,
      });
      const Menu_Products = response.data;
      this.setState(() => {
        return { Menu_Speciales: Menu_Products };
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleTake_Away_Prices = async () => {
    try {
      const response = await Api.get(`api/take-away-price/getall`);
      const Take_Away_Prices = response.data;
      this.setState(() => {
        return { Take_Away_Prices: Take_Away_Prices };
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleServiceDetail = async (service_id, user_id) => {
    try {
      const response = await Api.get(
        `/api/pricing/myservice?service_id=${service_id}&user_id=${user_id}`
      );

      const Price = response.data[0].platform_service;
      Price.file = response.data[0].file;
      this.setState(() => {
        return { ServiceDetail: Price };
      });
    } catch (error) {
      console.log(error);
      console.error(error);
    }
  };

  handleClear = () => {
    this.setState(() => {
      return { posts: [] };
    });
  };

  render() {
    return (
      <PricingContext.Provider
        value={{
          ...this.state,
          AllCompaniesPricing: this.AllCompaniesPricing,
          handleDetail: this.handleDetail,
          handleAllPricing: this.handleAllPricing,
          handleClear: this.handleClear,
          handleUserService: this.handleUserService,
          handleServiceDetail: this.handleServiceDetail,
          handleMenu_Products:this.handleMenu_Products,
          AllCompanies: this.AllCompanies,
          handleMenu_Speciales:this.handleMenu_Speciales,
          handleMenu_Portions:this.handleMenu_Portions,
          handleTake_Away_Prices:this.handleTake_Away_Prices
        }}
      >
        {this.props.children}
      </PricingContext.Provider>
    );
  }
}

class SocialProvider extends Component {
  //set Social function
  componentDidMount() {
    this.setSocial();
  }
  setSocial = () => {
    let tempsetSocial = [];
    SocialData.forEach((item) => {
      const singleItem = { ...item };
      tempsetSocial = [...tempsetSocial, singleItem];
    });
    this.setState(() => {
      return { socials: tempsetSocial };
    });
  };

  render() {
    return (
      <SocialContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </SocialContext.Provider>
    );
  }
}

class HelpsProvider extends Component {
  //set Helps function
  componentDidMount() {
    this.setHelps();
  }
  setHelps = () => {
    let tempsetHelps = [];
    HelpData.forEach((item) => {
      const singleItem = { ...item };
      tempsetHelps = [...tempsetHelps, singleItem];
    });
    this.setState(() => {
      return { helps: tempsetHelps };
    });
  };

  render() {
    return (
      <HelpContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </HelpContext.Provider>
    );
  }
}

class TargetProvider extends Component {
  //set Target function
  componentDidMount() {}
  handleTags = async (id) => {
    try {
      const response = await Api.get(`/api/Target/${id}`);
      const tempsetTags = response.data;
      this.setState(() => {
        return { tags: tempsetTags };
      });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <TargetContext.Provider
        value={{ ...this.state, handleTags: this.handleTags }}
      >
        {this.props.children}
      </TargetContext.Provider>
    );
  }
}

class NotifyProvider extends Component {
  //set Target function
  componentDidMount() {
    this.setNotifs();
  }
  setNotifs = () => {
    let tempsetNotifs = [];
    NotifyDatas.forEach((item) => {
      if (!item.readed) {
        newnotif++;
      }
      const singleItem = { ...item };
      tempsetNotifs = [...tempsetNotifs, singleItem];
    });
    this.setState(() => {
      return {
        notifs: tempsetNotifs,
        notreaded: newnotif,
      };
    });
  };
  handleSearch = (e) => {
    let tempsetNotifs = [];
    if (e) {
      NotifyDatas.forEach((item) => {
        if (item.body.indexOf(e) != -1) {
          const singleItem = { ...item };
          tempsetNotifs = [...tempsetNotifs, singleItem];
        }
      });
      this.setState(() => {
        return {
          notifs: tempsetNotifs,
        };
      });
    } else {
      this.setNotifs();
    }
  };

  render() {
    return (
      <NotifyContext.Provider
        value={{ ...this.state, handleSearch: this.handleSearch }}
      >
        {this.props.children}
      </NotifyContext.Provider>
    );
  }
}

class SupsProvider extends Component {
  state = {
    users: [],
    totalPages: null,
  };
  //set Suppoter function
  componentDidMount() {}

  fetchData = async (page) => {
    try {
      const response = await Api.get(`/api/users?page=${page}`);
      const { products, totalPages, totalProducts } = response.data;
      const totalUsers = totalProducts;
      const users = products;
      this.setState(() => {
        return { users: users, totalPages, totalUsers };
      });
    } catch (error) {
      console.error(error);
    }
  };

  fetchUser = async (page, username) => {
    try {
      console.log("Username", username);
      const response = await Api.get(
        `/api/finduser?page=${page}&username=${username}`
      );
      const { products, totalPages, totalProducts } = response.data;
      const users = products;
      const totalUsers = totalProducts;
      this.setState(() => {
        return { users: users, totalPages, totalUsers };
      });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <SupsContext.Provider
        value={{
          ...this.state,
          fetchData: this.fetchData,
          fetchUser: this.fetchUser,
        }}
      >
        {this.props.children}
      </SupsContext.Provider>
    );
  }
}

class SugesProvider extends Component {
  //set Suggestion Eveents function
  componentDidMount() {
    this.setSuges();
  }
  setSuges = () => {
    let tempsetSuges = [];
    SugesDada.forEach((item) => {
      const singleItem = { ...item };
      tempsetSuges = [...tempsetSuges, singleItem];
    });
    this.setState(() => {
      return { suges: tempsetSuges };
    });
  };

  render() {
    return (
      <SugesContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </SugesContext.Provider>
    );
  }
}

class EventProvider extends Component {
  //set Suggestion Eveents function
  componentDidMount() {
    this.setEvts();
  }
  setEvts = () => {
    let tempsetEvts = [];
    EventsData.forEach((item) => {
      const singleItem = { ...item };
      tempsetEvts = [...tempsetEvts, singleItem];
    });
    this.setState(() => {
      return {
        evts: tempsetEvts,
        detailEvent: detailEvent,
      };
    });
  };

  getItem = (id) => {
    const evt = this.state.evts.find((item) => item.id == id);
    return evt;
  };

  handleEvent = (id) => {
    const evt = this.getItem(id);
    this.setState(() => {
      return { detailEvent: evt };
    });
  };
  render() {
    return (
      <EventContext.Provider
        value={{ ...this.state, handleEvent: this.handleEvent }}
      >
        {this.props.children}
      </EventContext.Provider>
    );
  }
}

class WaitUProvider extends Component {
  //set Eveents WaitLists function
  componentDidMount() {
    this.setWaits();
  }
  setWaits = () => {
    let tempsetWaits = [];
    WaitUser.forEach((item) => {
      const singleItem = { ...item };
      tempsetWaits = [...tempsetWaits, singleItem];
    });
    this.setState(() => {
      return { wts: tempsetWaits };
    });
  };

  handleStatus = (id) => {
    let tempWtList = [...this.state.wts];
    const selectedUser = tempWtList.find((item) => item.id === id);
    const index = tempWtList.indexOf(selectedUser);
    const user = tempWtList[index];
    if (user.status) user.status = false;
    else user.status = true;
    this.setState(() => {
      return { wts: [...tempWtList] };
    });
  };

  removeUser = (id) => {
    let tempWtList = [...this.state.wts];
    const selectedUser = tempWtList.find((item) => item.id === id);
    const index = tempWtList.indexOf(selectedUser);
    if (index !== -1) tempWtList.splice(index, 1);
    this.setState(() => {
      return { wts: [...tempWtList] };
    });
  };
  render() {
    return (
      <WaitUContext.Provider
        value={{
          ...this.state,
          handleStatus: this.handleStatus,
          removeUser: this.removeUser,
        }}
      >
        {this.props.children}
      </WaitUContext.Provider>
    );
  }
}

class MySmsProvider extends Component {
  //set My Messages  function
  componentDidMount() {
    this.setSms();
  }
  setSms = () => {
    let tempsetSms = [];
    MySmsDatas.forEach((item) => {
      const singleItem = { ...item };
      tempsetSms = [...tempsetSms, singleItem];
    });
    this.setState(() => {
      return {
        sms: tempsetSms,
        smsDetail: MysmsDetail,
      };
    });
  };

  getItem = (id) => {
    const item = this.state.sms.find((item) => item.id == id);
    return item;
  };

  handleSmsDetail = (id) => {
    const item = this.getItem(id);
    this.setState(() => {
      return { smsDetail: item };
    });
  };
  render() {
    return (
      <MySmsContext.Provider
        value={{ ...this.state, handleSmsDetail: this.handleSmsDetail }}
      >
        {this.props.children}
      </MySmsContext.Provider>
    );
  }
}

class SmsProvider extends Component {
  //set Messages function
  componentDidMount() {
    this.setMsgs();
  }
  setMsgs = () => {
    let tempsetMsgs = [];
    MessageDatas.forEach((item) => {
      const singleItem = { ...item };
      tempsetMsgs = [...tempsetMsgs, singleItem];
    });
    this.setState(() => {
      return { msgs: tempsetMsgs };
    });
  };

  render() {
    return (
      <SmsContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </SmsContext.Provider>
    );
  }
}
export {
  StepProvider,
  StepContext,
  SmsProvider,
  SmsContext,
  MySmsProvider,
  MySmsContext,
  WaitUProvider,
  WaitUContext,
  EventProvider,
  EventContext,
  SugesProvider,
  SugesContext,
  SupsProvider,
  SupsContext,
  NotifyProvider,
  NotifyContext,
  HelpsProvider,
  HelpContext,
  TargetProvider,
  TargetContext,
  ReviewContext,
  ReviewProvider,
  PostsProvider,
  PostContext,
  NewsProvider,
  NewsContext,
  SocialProvider,
  SocialContext,
  PricingProvider,
  PricingContext,
  PostTabContext,
  PostTabProvider,
  PostsOrdersProvider,
  PostOrdersContext,
  GlobalFunctionProvider,
  GlobalFunctionContext,
  HavesProvider,
  HavesContext,
  NeedsProvider,
  NeedsContext,
  AdsContext,
  AdsProvider,
};
