import React from "react";
import { Link } from "react-router-dom";
function HeroBlock() {
  return (
    <>
      <section className="bg-white  dark:bg-gray-900 z-0">
        <div className="grid max-w-screen-xl px-4 pt-10 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-10">
          <div className="mr-auto  place-self-center items-center flex justify-center flex-col lg:col-span-7 z-20 pt-20">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl text-sky-500 dark:text-white ">
              Maximaze Your Audience
              <br />
              Visibility and Shares
            </h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              This Platform will help you to share your post among fans.
              <br />
              You will get more crucial Benefit By Using It.
              <br />
              <i>ErekaBose Have Come to handle Your This Problem</i>
            </p>
            <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <Link
                to="/trend"
                className="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center text-gray-900 border border-gray-200 rounded-lg sm:w-auto hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              >
                Get Stated Now{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 "
                >
                  <path
                    strokeLinecap="round"
                    d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </Link>
            </div>
          </div>
          <div className="hidden  lg:mt-0 lg:col-span-5 z-10 lg:flex">
            <img
              src="images/herohome.png"
              className="absolute top-4 right-32 z-0 w-auto scale-100"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroBlock;
