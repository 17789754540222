import React ,{useContext} from 'react'
import AllWaitLists from './AllWaitLists'
import WaitListDetail from './WaitListDetail'
import {EventContext} from '../../context'

export default function WaitListBox() {
  var evt = useContext(EventContext)
  evt = evt.detailEvent
  return (
    <>
            <div type="button" className="flex w-full items-center justify-between rounded-t-xl p-1 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800">
                    <span>My Released Event</span>
                </div>
                <div className="mb-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
                    {/* <!-- component --> */}
                <div className="flex h-screen flex-row text-gray-800 antialiased">
                <div className="flex w-auto flex-shrink-0 flex-row bg-gray-100 rounded-xl p-4">
                <AllWaitLists />
                </div>
                {evt ? <WaitListDetail evt={evt} />: null}
                    </div>
            </div>
    </>
  )
}
