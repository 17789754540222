import React from 'react'
function WhyUs() {
  return (
    <>
      <section className="z-0  mt-16 px-16 bg-gray-100 dark:bg-gray-900">
  <div className="mx-auto flex flex-col lg:grid max-w-screen-xl px-4 pb-8 pt-10 lg:grid-cols-12 lg:gap-8 lg:py-16 lg:pt-10 xl:gap-0">

    <div className="z-0   mr-auto lg:col-span-5 lg:mt-0 flex  ">
      <div className="grid grid-cols-2 gap-10  text-gray-500 dark:text-gray-400 lg:grid-cols-2">
          <div className="item relative  shadow-md flex    break-words bg-sky-100  shadow-soft-xl rounded-2xl bg-clip-border">
              <div className="flex items-center w-full justify-center p-4">
                <div className="flex flex-col justify-between -mx-3">
                   <div className="px-3 w-full text-center basis-1/3">
                    <div className="inline-block w-14 h-12 text-center rounded-bl-3xl rounded-tr-xl bg-gradient-to-tl from-sky-700 to-sky-500">
                      <div className="flex items-center justify-center w-full h-full">
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-white" fill="currentColor"  viewBox="0 0 16 16">
                      <path d="M3.1.7a.5.5 0 0 1 .4-.2h9a.5.5 0 0 1 .4.2l2.976 3.974c.149.185.156.45.01.644L8.4 15.3a.5.5 0 0 1-.8 0L.1 5.3a.5.5 0 0 1 0-.6l3-4zm11.386 3.785-1.806-2.41-.776 2.413 2.582-.003zm-3.633.004.961-2.989H4.186l.963 2.995 5.704-.006zM5.47 5.495 8 13.366l2.532-7.876-5.062.005zm-1.371-.999-.78-2.422-1.818 2.425 2.598-.003zM1.499 5.5l5.113 6.817-2.192-6.82L1.5 5.5zm7.889 6.817 5.123-6.83-2.928.002-2.195 6.828z"/>
</svg>
</div>

                    </div>
                  </div>
                  <div className="flex-none  text-center max-w-full px-3">
                    <div>
                      <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">Great Quality</p>
                      <p className="text-xs mt-2">
                      The quality of our ErekaBose services will pleasantly surprise you.
                      </p>
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
            <div className="item relative  shadow-md flex    break-words bg-sky-100  shadow-soft-xl rounded-2xl bg-clip-border">
              <div className="flex items-center w-full justify-center p-4">
                <div className="flex flex-col justify-between -mx-3">
                   <div className="px-3 w-full text-center basis-1/3">
                    <div className="inline-block w-14 h-12 text-center rounded-bl-3xl rounded-tr-xl bg-gradient-to-tl from-sky-700 to-sky-500">
                      <div className="flex items-center justify-center w-full h-full">
<svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-white" fill="currentColor"  viewBox="0 0 16 16">
  <path d="M8 4.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7Zm3.5.878c1.482-1.42 4.795 1.392 0 4.622-4.795-3.23-1.482-6.043 0-4.622ZM2.5 5a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3Zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3Zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3Z"/>
  <path fillRule="evenodd" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H2Z"/>
</svg>
</div>

                    </div>
                  </div>
                  <div className="flex-none  text-center max-w-full px-3">
                    <div>
                      <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">Trusted Based</p>
                      <p className="text-xs mt-2">
                      ErekaBose is Based On Trust.We Ensure You're Happy and Fine EveryTime
                      </p>
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
            <div className="item relative  shadow-md flex    break-words bg-sky-100  shadow-soft-xl rounded-2xl bg-clip-border">
              <div className="flex items-center w-full justify-center p-4">
                <div className="flex flex-col justify-between -mx-3">
                   <div className="px-3 w-full text-center basis-1/3">
                    <div className="inline-block w-14 h-12 text-center rounded-bl-3xl rounded-tr-xl bg-gradient-to-tl from-sky-700 to-sky-500">
                      <div className="flex items-center justify-center w-full h-full">
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-white" fill="currentColor"  viewBox="0 0 16 16">
                      <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z"/>
</svg>
</div>

                    </div>
                  </div>
                  <div className="flex-none  text-center max-w-full px-3">
                    <div>
                      <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">24/7 Support</p>
                      <p className="text-xs mt-2">
                       Our Support Agents  checks your orders, will reply in seconds
                      </p>
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
            <div className="item relative  shadow-md flex    break-words bg-sky-100  shadow-soft-xl rounded-2xl bg-clip-border">
              <div className="flex items-center w-full justify-center p-4">
                <div className="flex flex-col justify-between -mx-3">
                   <div className="px-3 w-full text-center basis-1/3">
                    <div className="inline-block w-14 h-12 text-center rounded-bl-3xl rounded-tr-xl bg-gradient-to-tl from-sky-700 to-sky-500">
                      <div className="flex items-center justify-center w-full h-full">
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-white" fill="currentColor"  viewBox="0 0 16 16">
                      <path d="M11.176 14.429c-2.665 0-4.826-1.8-4.826-4.018 0-2.22 2.159-4.02 4.824-4.02S16 8.191 16 10.411c0 1.21-.65 2.301-1.666 3.036a.324.324 0 0 0-.12.366l.218.81a.616.616 0 0 1 .029.117.166.166 0 0 1-.162.162.177.177 0 0 1-.092-.03l-1.057-.61a.519.519 0 0 0-.256-.074.509.509 0 0 0-.142.021 5.668 5.668 0 0 1-1.576.22ZM9.064 9.542a.647.647 0 1 0 .557-1 .645.645 0 0 0-.646.647.615.615 0 0 0 .09.353Zm3.232.001a.646.646 0 1 0 .546-1 .645.645 0 0 0-.644.644.627.627 0 0 0 .098.356Z"/>
  <path d="M0 6.826c0 1.455.781 2.765 2.001 3.656a.385.385 0 0 1 .143.439l-.161.6-.1.373a.499.499 0 0 0-.032.14.192.192 0 0 0 .193.193c.039 0 .077-.01.111-.029l1.268-.733a.622.622 0 0 1 .308-.088c.058 0 .116.009.171.025a6.83 6.83 0 0 0 1.625.26 4.45 4.45 0 0 1-.177-1.251c0-2.936 2.785-5.02 5.824-5.02.05 0 .1 0 .15.002C10.587 3.429 8.392 2 5.796 2 2.596 2 0 4.16 0 6.826Zm4.632-1.555a.77.77 0 1 1-1.54 0 .77.77 0 0 1 1.54 0Zm3.875 0a.77.77 0 1 1-1.54 0 .77.77 0 0 1 1.54 0Z"/>
</svg>
</div>

                    </div>
                  </div>
                  <div className="flex-none  text-center max-w-full px-3">
                    <div>
                      <p className="mb-0 mt-4 font-sans font-bold leading-normal text-base">Social Easy</p>
                      <p className="text-xs mt-2">
                      Get Informed,Discovers,Chats With Others.
                      With Specific Goals.
                      </p>
                    </div>
                  </div>
                
                </div>
              </div>
            </div>

        </div>
        </div>

    <div className="z-20  flex flex-col lg:order-last order-first mb-11 items-center justify-center place-self-center pt-8 lg:pt-12 lg:col-span-7">
      <h1 className="mb-4 max-w-2xl text-4xl font-extrabold leading-none tracking-tight text-sky-500 dark:text-white md:text-5xl xl:text-6xl">Why  Us<br />EREKA BOSE ?</h1>
      <p className="mb-6 max-w-2xl font-light text-gray-500 dark:text-gray-400 md:text-lg lg:mb-8 lg:text-xl">Here at ErekaBose, we strive for customer service  <br />as well as top quality performance.<br /><i>You are 100% protected by using our  ErekaBOse.</i></p>
      <div className="space-y-4 sm:flex sm:space-x-4 sm:space-y-0">
        <a href="#" className="inline-flex w-full items-center justify-center rounded-lg border border-sky-500 hover:bg-sky-100 px-5 py-3 text-center text-sm font-medium text-sky-600 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-800 sm:w-auto"
          >Sign Up Now</a>
      </div>
    </div>
  </div>
</section>
      </>
  )
}

export default WhyUs