import React from "react";
import NewsComponent from "../components/News/NewsComponent";

function News() {
  return (
    <>
      <NewsComponent />
    </>
  );
}
export default News;
