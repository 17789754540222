import React from 'react'
import PubOwner from '../components/Profile/PubOwner'

export default function PageOwner() {
  return (
    <>
    <PubOwner />
    </>
  )
}
