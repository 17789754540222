import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalFunctionContext } from "../../context";
import Api from "../Api/Api";
import Member from "./Member";
import { SugesContext, NeedsContext } from "../../context";
function Event(props) {
  const evt = props.evt;
  var date = new Date(evt.createdAt);
  const eDate = date.toString("YYYY-MM-dd").split(" ");
  const eTime = eDate[4].split(":");
  const Global = useContext(GlobalFunctionContext);
  const TimeAgo = (dateString) => {
    const currentDate = new Date();
    const pastDate = new Date(dateString);

    const timeDifference = currentDate.getTime() - pastDate.getTime();

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return years === 1 ? "1 year ago" : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? "1 month ago" : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1 min ago" : `${minutes} min ago`;
    } else {
      return seconds === 1 ? "Now" : "Now";
    }
  };

  const [issend, setsend] = useState(false);
  const [issendout, setsendout] = useState(false);
  const [Error, SetError] = useState(null);
  const [ErrorOutside, SetErrorOutside] = useState(null);
  const [fetchSeller, SetfetchSeller] = useState(false);

  const user_id = localStorage.getItem("userid");
  const handleChildData = (data) => {
    // Handle the data received from the child component
    console.log("Data received from child:", data);
  };
  const ValidateUser = async (need_id) => {
    setsend(true);
    if (localStorage.getItem("userid")) {
      try {
        const response = await Api.post("/api/Order/Need/Update", {
          need_id,
          user_id,
        });
        console.log(response.data);
        // Handle success response
        setsend(false);
      } catch (error) {
        setsend(false);
        console.error(error);
        if (error.response) {
          const errorMessage = error.response.data.error;
          SetError(errorMessage);
          setTimeout(() => {
            SetError(null);
          }, 3000);
        }
        setsend(false);
        // Handle error response
      }
    }
  };

  const ValidateOutsideUser = async (need_id) => {
    setsendout(true);
    try {
      const response = await Api.post("/api/Order/Need/OutsideUpdate", {
        need_id,
      });
      console.log(response.data);
      // Handle success response
      setsendout(false);
    } catch (error) {
      setsendout(false);
      console.error(error);
      if (error.response) {
        const errorMessage = error.response.data.error;
        SetErrorOutside(errorMessage);
        setTimeout(() => {
          SetErrorOutside(null);
        }, 3000);
      }
      setsendout(false);
      // Handle error response
    }
  };

  const Fetch = (need_id) => {
    SetfetchSeller(true);
    setloading(true);
    who.fetchWhoNeedData(page, need_id);
    setPage(page + 1);
    setloading(false);
  };

  const who = useContext(NeedsContext);
  const [page, setPage] = useState(1);
  const [loading, setloading] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     // Check if user has scrolled to the bottom of the page

  //     if (page < who.totalPages) {
  //       if (
  //         window.innerHeight + document.documentElement.scrollTop >=
  //         document.documentElement.offsetHeight - 50
  //       ) {
  //         setloading(true);
  //         who.fetchWhoHaveData(page, need_id);
  //         setPage(page + 1); // Fetch next page when end is reached
  //         setloading(false);
  //       }
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [who]);
  console.log(who);
  return (
    <>
      <div className=" item sm:w-[600px] card text-grey-darkest flex h-auto ">
        <div
          className="sm:flex hidden h-full py-3  flex-col items-start justify-start  mr-4"
          alt="Date"
        >
          {evt.User?.profile_picture ? (
            <img
              className="bg-coolGray-500 border-coolGray-700 h-12 w-12 rounded-full object-cover object-center shadow-sm"
              src={`http://backend.novadiscova.server.cisabu.bi/uploads/Haves_Needs/${evt.file}`}
              alt={evt.User.username}
            />
          ) : (
            <div className="mx-1 h-12 w-12 flex-shrink-0 flex items-center justify-center rounded-full bg-sky-50 border-sky-300 border object-cover">
              <span class="text-sky-500 uppercase font-medium">
                {evt.User.username?.substring(0, 1)}
              </span>
            </div>
          )}
        </div>
        <div className="flex flex-col space-y-3 ">
          <div className="flex w-full  flex-col rounded-xl bg-white border shadow-md">
            <div className="flex-1 px-3 pb-0">
              <div className="flex items-center justify-between pt-3">
                <div className="flex items-center space-x-2">
                  <div className="-space-y-1">
                    <h2 className="text-sm font-semibold leading-none">
                      {evt.User.username}
                    </h2>
                    <span className="text-coolGray-400 inline-block text-xs font-semibold leading-none">
                      {evt.User.job}
                    </span>
                  </div>
                </div>

                <div className="w-auto ml-10 bg-gray-200 rounded-lg border-sky-500 opacity-70 shadow border flex flex-row space-x-1 ">
                  <span className="flex items-center justify-center pl-1 font-semibold italic">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-4 h-4 text-gray-800"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </span>
                  <span className="w-auto rounded-lg text-sm bg-sky-500 px-2 text-white">
                    {TimeAgo(evt.createdAt)}
                  </span>
                </div>
              </div>
              <div className="my-4 flex flex-row justify-between ">
                <div className="flex flex-row space-x-3">
                  <div class="bg-gray-100 py-2 px-3 flex flex-row rounded-xl">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                      />
                    </svg>

                    <span className="rounded-r-xl text-base text-gray-700">
                      {evt.location}
                    </span>
                  </div>
                  <div class="bg-gray-100 py-2 px-3 flex flex-row rounded-xl">
                    <span className="rounded-r-xl text-base text-gray-700">
                      {evt.Category.title}
                    </span>
                  </div>
                </div>
                <div class="bg-gray-100 py-2 px-3 flex flex-row rounded-xl">
                  <span className="rounded-r-xl text-lg font-bold text-gray-700">
                    {evt.price}fbu
                  </span>
                </div>
              </div>
              <div className="mt-4 mb-2 ">
                <span className="rounded-r-xl text-base text-gray-700">
                  {evt.description}
                </span>
              </div>

              <div className="mb-4">
                <Link to={`/who/myneeds/${evt.id}`}>
                  <div className="relative  w-full  overflow-hidden">
                    <div className="h-32 md:h-[291px] ">
                      <img
                        src={`http://backend.novadiscova.server.cisabu.bi/uploads/Haves_Needs/${evt.file}`}
                        alt="product 1"
                        className="object-cover border border-sky-300 md:min-w-[289px] h-full w-full rounded-2xl"
                      />
                    </div>
                  </div>
                </Link>
              </div>

              <div className="mt-1 mb-3 flex items-center justify-between">
                <div className="flex flex-col items-center  justify-center space-x-1">
                  <div className="flex flex-row items-center  justify-center space-x-1 bg-sky-500 rounded-2xl px-3 py-2">
                    <button
                      onClick={() => {
                        Fetch(evt.id);
                      }}
                      className="pr-2 text-md text-white"
                    >
                      {issend ? "Validate Order..." : "Validate Order"}
                    </button>
                  </div>
                  {Error ? (
                    <label
                      htmlFor="username"
                      className="text-left  ml-3 block font-medium text-green-500 dark:text-white"
                    >
                      {Error}
                    </label>
                  ) : null}
                </div>

                {evt.status == false ? (
                  <button class="py-3 px-3 text-md leading-none border-red-200 border text-red-700 bg-red-100 rounded-3xl focus:outline-none">
                    Order Status: Processing
                  </button>
                ) : (
                  <button class="py-3 px-3 text-md leading-none border-green-200 border text-green-700 bg-green-100 rounded-3xl focus:outline-none">
                    Order Status: Completed
                  </button>
                )}
                <div className="flex flex-row space-x-1 items-center justify-center">
                  <span class="text-lg text-gray-600 font-semibold ">
                    {evt.Needs_orders.length} Seller Alerts
                  </span>
                </div>
              </div>
            </div>
          </div>

          {fetchSeller ? (
            <>
              <div className="flex w-full  flex-col rounded-xl bg-white border shadow-md">
                <div className="Supported mb-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
                  <button
                    type="button"
                    className="flex w-full items-center justify-between rounded-t-xl border border-b-0 border-gray-200 bg-sky-100 p-5 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800"
                  >
                    <span>Which Seller You Buyed From ?</span>
                    <div
                      onClick={() => SetfetchSeller(false)}
                      className="close-login-popup h-8 w-8 rounded-full bg-sky-500 opacity-80 hover:bg-sky-700"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-8 w-8 text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                    </div>
                  </button>

                  <div>
                    <div className="border border-b-0 border-gray-200 p-5 font-light dark:border-gray-700 dark:bg-gray-900">
                      <div className="flex w-full flex-col space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                          {who.whoneeds?.map((need) => {
                            return (
                              <Member
                                key={need.id}
                                need={need}
                                ValidateUser={ValidateUser}
                                issend={issend}
                                setsend={setsend}
                              />
                            );
                          })}
                        </div>
                        <div className="flex flex-col items-center justify-center space-x-4 rounded-3xl bg-gray-50 border border-sky-500 pt-4 p-2">
                          <div className="text-center">
                            If You've Buyed From Not Ereka Bose Seller.
                            <br />
                            Validate Order Here{" "}
                          </div>
                          <button
                            onClick={() => {
                              ValidateOutsideUser(evt.id);
                            }}
                            className="text-white mt-3 bg-sky-500 font-medium rounded-2xl text-lg px-5 py-2.5 text-center mr-2 mb-2 opacity-80"
                          >
                            {issendout ? "Validate...." : "Validate"}
                          </button>
                        </div>
                        {Error ? (
                          <label
                            htmlFor="username"
                            className="text-left  ml-3 block font-medium text-green-500 dark:text-white"
                          >
                            {Error}
                          </label>
                        ) : null}
                        {ErrorOutside ? (
                          <label
                            htmlFor="username"
                            className="text-left  ml-3 block font-medium text-green-500 dark:text-white"
                          >
                            {ErrorOutside}
                          </label>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
export default Event;
