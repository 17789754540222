import React from "react";
import Pubs from "./Pubs";
function AllPubs() {
  return (
    <>
      <div className="All_Post mb-3">
        <div className="flex w-full items-center justify-between rounded-t-xl p-1 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800">
          <span>Post</span>
          <hr className="h-px ml-4 w-full my-4 bg-gray-200 border-0 dark:bg-gray-700" />
        </div>
        <div className="mb-4 flex h-auto w-full flex-col rounded-3xl ">
          <div className="  font-light dark:border-gray-700 dark:bg-gray-900">
            <Pubs />
          </div>
        </div>
      </div>
    </>
  );
}
export default AllPubs;
