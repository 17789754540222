import React from 'react'
import Contact from './ContactUs/Contact'

function HomeFooter() {
  return (
    <div>
        <Contact />
    </div>
  )
}
export default HomeFooter