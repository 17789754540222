import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { NewsContext } from "../../../context";
import { HelpContext } from "../../../context";
import Helper from "../Helper";
import ShareMedia from "./ShareMedia";
import Api from "../../Api/Api";
function PostInfos() {
  var post = useContext(NewsContext);
  const pub = useContext(NewsContext);
  const { id } = useParams();
  const help = useContext(HelpContext);
  post = post.detailNews;
  console.log("news", post);
  const [showmore, SetShow] = useState(true);
  useEffect(() => {
    pub.handleDetail(id);
  }, []);
  const TimeAgo = (dateString) => {
    const currentDate = new Date();
    const pastDate = new Date(dateString);
    const timeDifference = currentDate.getTime() - pastDate.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return years === 1 ? "1 year ago" : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? "1 month ago" : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1 min ago" : `${minutes} min ago`;
    } else {
      return seconds === 1 ? "Now" : "Now";
    }
  };

  const user_id = localStorage.getItem("userid");
  const AddLike = async (post_id) => {
    if (localStorage.getItem("userid")) {
      try {
        const response = await Api.post("/api/likes", { post_id, user_id });
        console.log(response.data);
        // Handle success response
      } catch (error) {
        console.error(error);
        // Handle error response
      }
    }
  };

  const isUserLiked = (Article_likes, user_id) => {
    Article_likes.map((like) => {
      if (like.user_id == user_id) {
        return true;
      }
    });

    return false;
  };
  return (
    <>
      {post ? (
        <div className="mb-4  rounded-3xl  ">
          <div className="items min-h-48 flex items-start justify-start w-full">
            <div className="flex flex-row w-full">
              <div className="bg-gray-50 text-gray-900 border border-gray-300 rounded-lg shadow-md w-full">
                <div className="">
                  <img
                    src={`http://backend.novadiscova.server.cisabu.bi/uploads/News/${post.file}`}
                    alt=""
                    className="border-sky-300   w-full rounded-lg border object-cover object-center"
                  />
                </div>
                <div className="flex flex-col w-full items-start justify-start px-2 text-sm mt-2">
                  <div class="w-full flex flex-row justify-between items-start space-x-3">
                    <div className="w-auto rounded-lg p-1 text-md shadow border border-sky-200 bg-sky-100 px-3 mt text-gray-700">
                      Politics
                    </div>
                    <div className=" rounded-lg p-1 flex flex-row shadow border border-sky-200 bg-sky-100 px-3 mt text-white">
                      <img src={post.Country.url} class="w-6 rounded-xl" />
                      <span class="ml-2 text-base text-gray-700">
                        {post.Country.name}
                      </span>
                    </div>
                  </div>
                  <div className="px-3 text-2xl mt-3 font-bold text-gray-700">
                    {post.title.length >= 120
                      ? post.title.substring(0, 120) + "...."
                      : post.title}
                  </div>

                  <div class="w-full flex flex-row justify-between items-start">
                    <div className="w-auto  px-3 py-1 text-base text-gray-500">
                      {" "}
                      {TimeAgo(post.createdAt)}
                    </div>
                  </div>
                </div>

                <div className="flex mt-2 w-full items-start justify-between flex-col px-3 text-sm">
                  <div className="description w-full   bg-gray-100 px-2 py-2 rounded-3xl text-gray-700 ">
                    <div
                      className={
                        showmore
                          ? "description_text  overflow-hidden "
                          : "description_text  overflow-hidden  h-10"
                      }
                    >
                      <p className="px-3 pt-1 text-lg ">{post.description} </p>
                    </div>
                    {post.description.length >= 20 ? (
                      <button
                        type="button"
                        onClick={() => SetShow(!showmore)}
                        className="text-gray-900 cursor-auto font-extralight  bg-gray-300 rounded-lg px-1 mt-2"
                      >
                        {!showmore ? "ShowMore" : "ShowLess"}
                      </button>
                    ) : null}
                  </div>
                </div>
                <div className="flex items-center justify-between p-3">
                  <div className="flex items-center space-x-2">
                    <img
                      src={`http://backend.novadiscova.server.cisabu.bi/uploads/News/${post.file}`}
                      alt=""
                      className="border border-sky-300 h-10 w-10 rounded-full object-cover object-center shadow-sm"
                    />
                    <div className="">
                      <h2 className="text-lg text-sky-600 font-semibold leading-none">
                        {post.Author.username}
                      </h2>
                    </div>
                  </div>

                  {/* <button
                    type="button"
                    title="Like post"
                    onClick={() => AddLike(post.id)}
                    className="flex items-center justify-center"
                  >
                    {isUserLiked(post.Article_likes, user_id) ? (
                      <>
                        <span class="">
                          <svg
                            aria-label="like"
                            class=""
                            color="rgb(255, 48, 64)"
                            fill="rgb(255, 48, 64)"
                            height="24"
                            role="img"
                            viewBox="0 0 48 48"
                            width="24"
                          >
                            <title>like</title>
                            <path d="M34.6 3.1c-4.5 0-7.9 1.8-10.6 5.6-2.7-3.7-6.1-5.5-10.6-5.5C6 3.1 0 9.6 0 17.6c0 7.3 5.4 12 10.6 16.5.6.5 1.3 1.1 1.9 1.7l2.3 2c4.4 3.9 6.6 5.9 7.6 6.5.5.3 1.1.5 1.6.5s1.1-.2 1.6-.5c1-.6 2.8-2.2 7.8-6.8l2-1.8c.7-.6 1.3-1.2 2-1.7C42.7 29.6 48 25 48 17.6c0-8-6-14.5-13.4-14.5z"></path>
                          </svg>
                        </span>
                      </>
                    ) : (
                      <>
                        {" "}
                        <span class="">
                          <svg
                            aria-label="Unlike"
                            class=""
                            color="rgb(255, 48, 64)"
                            fill=" rgb(140 140 140)"
                            height="24"
                            role="img"
                            viewBox="0 0 48 48"
                            width="24"
                          >
                            <title>Unlike</title>
                            <path d="M34.6 3.1c-4.5 0-7.9 1.8-10.6 5.6-2.7-3.7-6.1-5.5-10.6-5.5C6 3.1 0 9.6 0 17.6c0 7.3 5.4 12 10.6 16.5.6.5 1.3 1.1 1.9 1.7l2.3 2c4.4 3.9 6.6 5.9 7.6 6.5.5.3 1.1.5 1.6.5s1.1-.2 1.6-.5c1-.6 2.8-2.2 7.8-6.8l2-1.8c.7-.6 1.3-1.2 2-1.7C42.7 29.6 48 25 48 17.6c0-8-6-14.5-13.4-14.5z"></path>
                          </svg>
                        </span>
                      </>
                    )}
                    <span className="px-2">
                      <span>
                        {!post.Article_likes
                          ? "0"
                          : post.Article_likes.length > 1000
                          ? `${(post.Article_likes.length / 1000).toFixed(1)}K`
                          : post.Article_likes.length}
                      </span>
                      <span> likes </span>
                    </span>
                  </button> */}
                  <div className="flex flex-row  items-center space-x-2">
                    <button
                      type="button"
                      title="Like post"
                      onClick={() => AddLike(post.id)}
                      className="flex items-center justify-center"
                    >
                      {isUserLiked(post.Article_likes, user_id) ? (
                        <>
                          <span class="">
                            <svg
                              aria-label="like"
                              class=""
                              color="rgb(255, 48, 64)"
                              fill="rgb(255, 48, 64)"
                              height="24"
                              role="img"
                              viewBox="0 0 48 48"
                              width="24"
                            >
                              <title>like</title>
                              <path d="M34.6 3.1c-4.5 0-7.9 1.8-10.6 5.6-2.7-3.7-6.1-5.5-10.6-5.5C6 3.1 0 9.6 0 17.6c0 7.3 5.4 12 10.6 16.5.6.5 1.3 1.1 1.9 1.7l2.3 2c4.4 3.9 6.6 5.9 7.6 6.5.5.3 1.1.5 1.6.5s1.1-.2 1.6-.5c1-.6 2.8-2.2 7.8-6.8l2-1.8c.7-.6 1.3-1.2 2-1.7C42.7 29.6 48 25 48 17.6c0-8-6-14.5-13.4-14.5z"></path>
                            </svg>
                          </span>
                        </>
                      ) : (
                        <>
                          {" "}
                          <span class="">
                            <svg
                              aria-label="Unlike"
                              class=""
                              color="rgb(255, 48, 64)"
                              fill=" rgb(140 140 140)"
                              height="24"
                              role="img"
                              viewBox="0 0 48 48"
                              width="24"
                            >
                              <title>Unlike</title>
                              <path d="M34.6 3.1c-4.5 0-7.9 1.8-10.6 5.6-2.7-3.7-6.1-5.5-10.6-5.5C6 3.1 0 9.6 0 17.6c0 7.3 5.4 12 10.6 16.5.6.5 1.3 1.1 1.9 1.7l2.3 2c4.4 3.9 6.6 5.9 7.6 6.5.5.3 1.1.5 1.6.5s1.1-.2 1.6-.5c1-.6 2.8-2.2 7.8-6.8l2-1.8c.7-.6 1.3-1.2 2-1.7C42.7 29.6 48 25 48 17.6c0-8-6-14.5-13.4-14.5z"></path>
                            </svg>
                          </span>
                        </>
                      )}
                      <span className="px-2">
                        <span>
                          {!post.Article_likes
                            ? "0"
                            : post.Article_likes.length > 1000
                            ? `${(post.Article_likes.length / 1000).toFixed(
                                1
                              )}K`
                            : post.Article_likes.length}
                        </span>
                        <span> likes </span>
                      </span>
                    </button>
                    <button title="Open options" type="button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        height="24"
                        width="24"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
                        />
                      </svg>
                    </button>
                    <button title="Open options" type="button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="h-5 w-5 fill-current"
                      >
                        <path d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"></path>
                        <path d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"></path>
                        <path d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
export default PostInfos;
