import { Scrollbars } from "react-custom-scrollbars-2";
import React, { useContext } from "react";
import { GlobalFunctionContext } from "../../context";
import { Outlet, useLocation } from "react-router-dom";
import { useState } from "react";
import Navbar from "../Navbars/Navbar";
import Footer from "../Footer/Footer";
import UserAside from "../AsideBar/UserAside";
import WhoUserAside from "../AsideBar/WhoUserAside";
import ListingUserAside from "../AsideBar/ListingUserAside";
import NewsUserAside from "../AsideBar/NewsUserAside";
import RightUserAside from "../AsideBar/RightUserAside";
import Search from "../Search/Search";
import { matchPath } from "react-router";
import WhoRightUserAside from "../AsideBar/WhoRightUserAside";
import UserProfilePopUp from "../PopUp/UserProfilePopUp";
import AdsPicturePop from "../PopUp/AdsPicturePop";
import LocationPopUp from "../PopUp/LocationPopUp";
import ReportPopUp from "../Report/ReportPopUp";
import FeedBackPopUp from "../FeedBack/FeedBackPopUp";
const Layout_notlogged = () => {
  let isHome = false;
  let isMessage = false;
  let isPricing = false;
  let isSharing = false;
  let isWho = false;
  let isListing = false;
  let isNews = false;
  let isQuickOrdering = false;
  const pattern = "/pricing/:platform";
  const { pathname } = useLocation();
  let Share = /^\/share.*$/;
  let QuickOrdering = /^\/quick_ordering.*$/;
  let Who = /^\/who\/.*$/;
  let Listing = /^\/Companies.*$/;
  let News = /^\/News.*$/;
  console.log("Quick_Ordering", isQuickOrdering);
  if (Share.test(pathname)) isSharing = true;
  if (QuickOrdering.test(pathname)) isQuickOrdering = true;
  if (Who.test(pathname)) {
    isWho = true;
  } else {
    isWho = false;
  }
  if (Listing.test(pathname)) {
    isListing = true;
  } else {
    isListing = false;
  }
  if (News.test(pathname)) {
    isNews = true;
  } else {
    isNews = false;
  }
  if (pathname == "/") isHome = true;
  if (pathname == "/messages") isMessage = true;
  if (matchPath(pathname, pattern)) isPricing = true;
  const [SearchState, SetSearch] = useState(false);
  const openSearch = (pop) => {
    if (pop) SetSearch(true);
    else SetSearch(false);
  };

  const Global = useContext(GlobalFunctionContext);

  return (
    <>
      {Global.SupportPop ? <FeedBackPopUp /> : null}
      {Global.ReportPop ? <ReportPopUp /> : null}
      {Global.UserPop ? <UserProfilePopUp /> : null}
      {Global.AdsPop ? <AdsPicturePop /> : null}
      {Global.LocationPop ? <LocationPopUp /> : null}
      {isSharing || isQuickOrdering  ? (
        <>
          <Outlet />
        </>
      ) :
      
      
      (
        <>
          {" "}
          <Navbar pathname={pathname} openSearch={openSearch} />
          <section className="sidebar relative top-20 z-10 bg-white px-5 dark:bg-gray-900">
            <div className="w-full">
              <button
                data-drawer-target="separator-sidebar"
                data-drawer-toggle="separator-sidebar"
                aria-controls="separator-sidebar"
                type="button"
                className="mt-2 ml-3 hidden items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="h-6 w-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              {isWho ? (
                <WhoUserAside pathname={pathname} />
              ) : isListing ? (
                <ListingUserAside pathname={pathname} />
              ) : isNews ? (
                <NewsUserAside pathname={pathname} />
              ) : (
                <UserAside pathname={pathname} />
              )}
              {isHome ? (
                <>
                  <Outlet />
                  <Footer pathname={pathname} />
                </>
              ) : (
                <div
                  className={`pl-4 sm:ml-64  flex flex-col items-center justify-center ${
                    isMessage || isPricing ? "w-auto" : "w-[680px]"
                  }`}
                >
                  {SearchState ? (
                    <Search
                      searchwidth={
                        isMessage || isPricing ? "w-2/3" : "w-[680px]"
                      }
                      openSearch={openSearch}
                    />
                  ) : null}
                  <div
                    className={`${
                      isMessage || isPricing ? "w-auto" : "w-[650px]"
                    }`}
                  >
                    <div className="rounded-lg p-4 dark:border-gray-700">
                      <Outlet />
                      <RightUserAside pathname={pathname} />
                      {isWho ? (
                        <WhoRightUserAside />
                      ) : (
                        <RightUserAside pathname={pathname} />
                      )}
                      {!isMessage ? <Footer pathname={pathname} /> : null}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Layout_notlogged;
