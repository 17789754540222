import React,{useState} from 'react'
import AllFooter from './AllFooter';
import HomeFooter from './HomeFooter';

function Footer(props) {
  let isHome = false
  let isWho = false
  if (props.pathname == '/') isHome = true
  if(props.pathname=="/who") isWho=true
    return( 
      <>
      {isHome ?
          <HomeFooter /> :
        <AllFooter />}
        
    </>
    )
}
export default Footer