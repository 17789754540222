import React from "react";

import WebsiteBuyComponent from "../components/Buy/WebsiteBuyComponent";
function WebsiteBuy() {
  return (
    <>
      <WebsiteBuyComponent />
    </>
  );
}
export default WebsiteBuy;
