import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// CSS Modules, react-datepicker-cssmodules.css//
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import Select from "react-select";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Api from "../Api/Api";
import LoadingPage from "../LoadingPage.js/LoadingPage";
import { country } from "./CountryList";
import "./tab.css";
const handleClose = () => {
  toast.dismiss();
};

const PasswordvalidationSchema = Yup.object().shape({
  Oldpassword: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character"
    )
    .required("Please Enter your old  password"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character"
    )
    .required("Please Enter your password"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please confirm your password"),
});

const PasswordinitialValues = {
  Oldpassword: "",
  password: "",
  confirmPassword: "",
};

const SecretvalidationSchema = Yup.object().shape({
  OldSecret: Yup.string()
    .min(8, "Secret Code must be at least 8 characters")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Secret Code must contain at least one special character"
    )
    .required("Please Enter your old  Secret Code"),
  Secret: Yup.string()
    .min(8, "Secret must be at least 8 characters")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Secret code must contain at least one special character"
    ),
  confirmSecret: Yup.string().oneOf(
    [Yup.ref("Secret"), null],
    "Secret Code must match"
  ),
  Number_Code: Yup.string().required("Your Number Or Code is required"),
  Payment_Method: Yup.string()
    .min(1, "Please select at least one Payment Method")
    .required("Please select at least one Payment Method"),
});
const SecretinitialValues = {
  OldSecret: "",
  Secret: "",
  confirmSecret: "",
  Number_Code: "",
  Payment_Method: " ",
};

function PostToShare({ formData, setFormData, setPage }) {
  const [paymentoptions, setpaymentoptions] = useState([
    {
      value: "Lumicash",
      label: "Lumicash",
    },
    {
      value: "EcoCash",
      label: "EcoCash",
    },
    {
      value: "Binance",
      label: "Binance",
    },
    {
      value: "PayPal",
      label: "PayPal",
    },
  ]);
  const [selectedPaymentOption, setSelectedpaymentOption] = useState(null);
  const handlePaymentChange = (option) => {
    setSelectedOption(option);
    setFormData({ ...formData, Interest: option.value });
  };
  const [photo, SetPhoto] = useState(null);
  const [Password_show, setPasswordShow] = useState(false);
  const [User, SetUser] = useState({});
  const [Actual, SetActual] = useState(1);
  const [EditProfile, SetEditProfile] = useState(false);
  const [EditPassword, SetEditPassword] = useState(false);
  const [EditSecret, SetEditSecret] = useState(false);
  const [ChangeSecret, SetChangeSecret] = useState(false);
  const [Error, SetError] = useState(null);
  const [Space, setSpace] = useState([]);
  const [Message, SetMessage] = useState(null);
  const ToggleTab = (index) => {
    SetActual(index);
  };
  const id = localStorage.getItem("userid");
  useEffect(() => {
    Api.get(`/api/user/${id}`)
      .then((response) => {
        SetUser(response.data);
        setSelectedOption({
          value: response.data.job,
          label: response.data.job,
        });
        selectedCountryOption({
          value: response.data.country,
          label: response.data.country,
        });
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const fetchUserInterest = async (page, username) => {
    try {
      const id = localStorage.getItem("userid");
      const response = await Api.get(`/api/user_interest/${id}`);
      {
        if (response.data.length > 0) {
          const data = response.data;
          setSpace(data);
          // console.log(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchUserInterest();
  }, []);
  const [checkboxes, setCheckboxes] = useState([]);

  const handleCheckboxChange = (event, checkboxId) => {
    const { checked } = event.target;
    setCheckboxes((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) =>
        checkbox.id === checkboxId ? { ...checkbox, status: checked } : checkbox
      )
    );
  };
  useEffect(() => {
    const fetchnotifData = async () => {
      try {
        // const user_id = 123; // Replace with the actual user ID
        // const response = await axios.get(`/notif_settings/getall/${user_id}`);
        const user_id = localStorage.getItem("userid");
        const response = await Api.get(`/api/notif_settings/getall/${user_id}`);
        // console.log(response);
        if (response.data.data.length > 0) {
          //   console.log("notif_settings");
          //   console.log(response);
          const jsonData = response.data.data.map((obj) => ({
            id: obj.notif_id,
            title: obj.Notif_type.title,
            status: true,
          }));
          if (response.data.datas.length > 0) {
            response.data.datas.map((obj) => {
              jsonData.push({
                id: obj.id,
                title: obj.title,
                status: false,
              });
            });
            setCheckboxes(jsonData);
          } else {
            setCheckboxes(jsonData);
          }
        } else {
          const responses = await Api.get("/api/notif_type/getall");
          const jsonData = responses.data.map((obj) => ({
            id: obj.id,
            title: obj.title,
            status: true,
          }));
          // console.log("notif_type");
          setCheckboxes(jsonData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchnotifData();
  }, []);
  const [loading, setLoading] = useState(false);
  const [options, setoptions] = useState([{}]);
  const [countryoptions, setcountryoptions] = useState([{}]);
  const getAllInterest = () => {
    Api.get("/api/interest/getall").then((response) => {
      {
        const datas = [];
        response.data.map((item) => {
          const newRow = { value: item.title, label: item.title };
          datas.push(newRow);
        });
        setoptions(datas);
      }
    });
    //console.log(options);
  };

  const getAllCountry = () => {
    const datas = [];
    country.map((item) => {
      const newRow = { value: item, label: item };
      datas.push(newRow);
    });
    setcountryoptions(datas);
  };
  useEffect(() => {
    getAllInterest();
    getAllCountry();
  }, []);
  const [value, setValue] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  const handleChange = (option) => {
    setSelectedOption(option);
    setFormData({ ...formData, Interest: option.value });
  };
  const handleCountryChange = (option) => {
    setSelectedCountryOption(option);
    setFormData({ ...formData, Interest: option.value });
  };

  const onPasswordSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    //  console.log(values);
    const data = {
      user_id: localStorage.getItem("userid"),
      old_password: values.Oldpassword,
      new_password: values.password,
    };
    Api.post("api/editpassword", data)
      .then((response) => {
        toast.success("Password Changed With Success♨️", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClick: handleClose, // Utilisation de la référence à la fonction handleClose
        });
        setLoading(false);
        //  console.log(response.data);
        SetMessage(response.data.message);
      })
      .catch((error) => {
        // Handle errors
        if (error.response) {
          setLoading(false);
          // Le serveur a renvoyé une réponse avec un code d'erreur
          const errorMessage = error.response.data.error;
          SetError(errorMessage);
          toast.error(errorMessage, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            onClick: handleClose, // Utilisation de la référence à la fonction handleClose
          }); // Affiche le message d'erreur dans le toast
        } else if (error.request) {
          // La requête a été faite mais aucune réponse n'a été reçue
          console.log(error.request);
        } else {
          // Une erreur s'est produite lors de la configuration de la requête
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onSecretSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    //console.log(values);
    const data = {
      user_id: localStorage.getItem("userid"),
      number_code: values.Number_Code,
      old_secret: values.OldSecret,
      new_secret: values.Secret,
      payment_method: values.Payment_Method,
    };
    Api.post("api/editsecret", data)
      .then((response) => {
        toast.success("Secret Code Changed With Success♨️", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClick: handleClose, // Utilisation de la référence à la fonction handleClose
        });
        setLoading(false);
        // console.log(response.data);
        SetMessage(response.data.message);
      })
      .catch((error) => {
        // Handle errors
        if (error.response) {
          setLoading(false);
          // Le serveur a renvoyé une réponse avec un code d'erreur
          const errorMessage = error.response.data.error;
          SetError(errorMessage);
          toast.error(errorMessage, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            onClick: handleClose, // Utilisation de la référence à la fonction handleClose
          }); // Affiche le message d'erreur dans le toast
        } else if (error.request) {
          // La requête a été faite mais aucune réponse n'a été reçue
          console.log(error.request);
        } else {
          // Une erreur s'est produite lors de la configuration de la requête
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onProfileSubmit = async (values, { setSubmitting }) => {
    const user_id = localStorage.getItem("userid");
    console.log(values);
    try {
      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("username", values.username);
      formData.append("email", values.email);
      formData.append("Bio", values.Bio);
      formData.append("Interest", values.Interest);
      formData.append("Phone_Number", values.Phone_Number);
      formData.append("photo", File);
      formData.append("date_of_birth", values.Date_Birth);
      const response = await Api.post("/api/user/updateuser", formData);

      const handleClose = () => {
        toast.dismiss();
      };
      // Afficher le message de succès dans un toast
      toast.success("Successful registration ! 🎉", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClick: handleClose, // Utilisation de la référence à la fonction handleClose
      });
    } catch (error) {
      console.error(error);
      const handleClose = () => {
        toast.dismiss();
      };
      if (error.response) {
        // Le serveur a renvoyé une réponse avec un code d'erreur
        const errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClick: handleClose, // Utilisation de la référence à la fonction handleClose
        }); // Afficher le message d'erreur du serveur dans un toast
      }
    }

    setSubmitting(false);
  };

  const onNotifSubmit = () => {
    setLoading(true);

    const filteredData = checkboxes.filter((item) => item.status === true);
    console.log(filteredData);
    const data = {
      user_id: localStorage.getItem("userid"),
      notif: filteredData,
    };
    Api.post("/api/notif_settings/update", data)
      .then((response) => {
        toast.success("Notification Setting Changed With Success♨️", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClick: handleClose, // Utilisation de la référence à la fonction handleClose
        });

        console.log(response.data);
        SetMessage(response.data.message);
      })
      .catch((error) => {
        // Handle errors
        if (error.response) {
          setLoading(false);
          // Le serveur a renvoyé une réponse avec un code d'erreur
          const errorMessage = error.response.data.error;
          SetError(errorMessage);
          toast.error(errorMessage, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            onClick: handleClose, // Utilisation de la référence à la fonction handleClose
          }); // Affiche le message d'erreur dans le toast
        } else if (error.request) {
          // La requête a été faite mais aucune réponse n'a été reçue
          console.log(error.request);
        } else {
          // Une erreur s'est produite lors de la configuration de la requête
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Please Enter your username"),
    email: Yup.string()
      .email("Invalid email")
      .required("Please Enter your email"),
    Bio: Yup.string().required("Please Enter your Bio"),

    Date_Birth: Yup.date()
      .max(new Date(), "You can't select a future date")
      .required("Date of birth is required"),
    Phone_Number: Yup.string()
      .matches(
        /^\+(?:[0-9]●?){6,14}[0-9]$/,
        "Please enter a valid phone number"
      )
      .required("Phone number is required"),
    Interest: Yup.string()
      .min(1, "Please select at least one Job or Interest")
      .required("Please select at least one Job or Interest"),
    Interest: Yup.string()
      .min(1, "Please select at least one Country")
      .required("Please select at least one  Country"),
  });

  const initialValues = {
    email: User?.email,
    username: User?.username,
    Phone_Number: User?.phone,
    Interest: User?.job,
    Date_Birth: new Date(User?.date_of_birth),
    Bio: User?.bio,
    Country: User?.country,
  };
  const [startDate, setStartDate] = useState(null);
  const [File, setFile] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    const Url = URL.createObjectURL(file);
    SetPhoto(Url);
  };
  return (
    <>
      <div className="Post">
        <div
          type="button"
          className="flex w-full items-center justify-between rounded-t-xl p-1 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800"
        >
          <span>Setting</span>
        </div>

        <div class="mb-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
          <div class="flex w-full flex-col items-center justify-center rounded-t-xl border border-b-0 border-gray-200  p-5 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800">
            <div class="tab-btns w-auto h-auto  flex flex-row space-x-8 justify-between items-center text-gray-500 dark:text-gray-400">
              <div
                onClick={() => ToggleTab(1)}
                className={
                  Actual == 1
                    ? "tab active-btn w-auto  h-auto flex flex-row items-center justify-center px-3 py-3 rounded-xl bg-gray-100  "
                    : "tab w-auto   h-auto flex flex-row items-center justify-center px-3 py-3 rounded-xl bg-gray-100  "
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>

                <button class="text-base text-gray-700">Account</button>
              </div>
              <div
                onClick={() => ToggleTab(2)}
                className={
                  Actual == 2
                    ? "tab active-btn w-auto  h-auto flex flex-row items-center justify-center px-3 py-3 rounded-xl bg-gray-100  "
                    : "tab w-auto   h-auto flex flex-row items-center justify-center px-3 py-3 rounded-xl bg-gray-100  "
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <button class="text-base text-gray-700">Payment</button>
              </div>
              <div
                key={1}
                onClick={() => ToggleTab(3)}
                className={
                  Actual == 3
                    ? "tab active-btn w-auto  h-auto flex flex-row items-center justify-center px-3 py-3 rounded-xl bg-gray-100  "
                    : "tab w-auto   h-auto flex flex-row items-center justify-center px-3 py-3 rounded-xl bg-gray-100  "
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                  />
                </svg>

                <button class="text-base text-gray-700">Notifications</button>
              </div>
            </div>
          </div>

          <div
            id="basic"
            className={Actual == 1 ? "tab-content active" : "tab-content"}
          >
            <div class="border border-b-0 border-gray-200 p-2 font-light dark:border-gray-700 dark:bg-gray-900">
              <div class="w-full space-y-4">
                <div class="relative h-full overflow-hidden">
                  <div class="-mx-3 flex h-full flex-col divide-y overflow-y-auto overflow-x-hidden">
                    <div class="today mb-10">
                      <div class="relative flex flex-col items-center justify-center rounded-lg">
                        <div class="mt-3 mb-3 ml-5">
                          {EditProfile ? (
                            <div class="text-lg font-semibold  text-gray-400">
                              Edit Profile
                            </div>
                          ) : EditPassword ? (
                            <div class="text-lg font-semibold  text-gray-400">
                              Edit Password
                            </div>
                          ) : (
                            <div class="text-lg font-semibold  text-gray-400">
                              Account Info
                            </div>
                          )}
                        </div>
                        {EditProfile ? (
                          <>
                            <div class="w-full max-w-full xl:w-9/12 flex flex-row items-center justify-center space-x-4">
                              <div class=" flex h-[118px] w-[118px] items-center justify-center rounded-full border-2 border-sky-400 bg-sky-400">
                                {photo ? (
                                  <img
                                    class="h-full w-full rounded-full object-cover"
                                    src={photo}
                                    alt=""
                                  />
                                ) : User.profile_picture ? (
                                  <img
                                    className="mx-1  h-[118px] w-[118px] flex-shrink-0 rounded-full object-cover"
                                    src={`http://backend.novadiscova.server.cisabu.bi/uploads/PhotoUser/${User.profile_picture}`}
                                    alt={User.username}
                                  />
                                ) : (
                                  <div className="mx-1 h-[118px] w-[118px] flex-shrink-0 flex items-center justify-center rounded-full bg-sky-50 border-sky-300 border object-cover">
                                    <span class="text-sky-500 uppercase font-medium">
                                      {User.username?.substring(0, 1)}
                                    </span>
                                  </div>
                                )}
                              </div>

                              <div class=" flex flex-col items-center">
                                <div className="">
                                  <div class=" flex w-[px] items-center  ">
                                    <button
                                      type="submit"
                                      class="rounded-2xl border-0 bg-sky-500 px-6 py-2 text-xs text-white hover:bg-sky-600 focus:outline-none"
                                    >
                                      Upload New
                                    </button>
                                    <input
                                      type="file"
                                      name="file"
                                      accept="image/*"
                                      class="absolute w-32 overflow-hidden px-4 opacity-0"
                                      onChange={(event) =>
                                        handleFileChange(event)
                                      }
                                    />
                                  </div>
                                </div>
                                <button
                                  onClick={() => {
                                    SetPhoto(null);
                                    setFile(null);
                                  }}
                                  class="mt-3  rounded-xl border px-6 py-2 text-xs text-red-600 shadow-lg focus:outline-none"
                                >
                                  Remove
                                </button>
                              </div>
                            </div>

                            <div></div>
                          </>
                        ) : !EditPassword ? (
                          <div class="w-full max-w-full xl:w-9/12 flex flex-row items-center justify-center space-x-4">
                            <div class=" flex h-[120px] w-[120px] items-center justify-center rounded-full border-2 border-sky-400 bg-sky-400">
                              <img
                                class="h-full w-full rounded-full hidden"
                                src="https://i.ibb.co/6YbS9ff/avatar11.png"
                                alt=""
                              />

                              {User.profile_picture ? (
                                <img
                                  className="mx-1  h-[118px] w-[118px] flex-shrink-0 rounded-full object-cover"
                                  src={`http://backend.novadiscova.server.cisabu.bi/uploads/PhotoUser/${User.profile_picture}`}
                                  alt={User.username}
                                />
                              ) : (
                                <div className="mx-1 h-9 w-9 flex-shrink-0 flex items-center justify-center rounded-full bg-sky-50 border-sky-300 border object-cover">
                                  <span class="text-sky-500 uppercase font-medium">
                                    {User.username?.substring(0, 1)}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}
                        <div className="flex flex-col  items-center w-full ">
                          {loading && <LoadingPage />}

                          {EditProfile ? (
                            <Formik
                              initialValues={initialValues}
                              validationSchema={validationSchema}
                              onSubmit={onProfileSubmit}
                            >
                              {({ isSubmitting, values, setFieldValue }) => (
                                <Form className="flex flex-col  items-center w-full ">
                                  {" "}
                                  <>
                                    <div class="w-full max-w-full px-3 mt-6 xl:w-12/12 mb-4 border rounded-2xl ">
                                      <div class="relative flex flex-col h-full min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                                        <div class="p-4 pb-0 mb-0 bg-white border-b-0 rounded-t-2xl">
                                          <div class="flex flex-row flex-wrap justify-between -mx-3">
                                            <div class="flex items-center px-3 shrink-0 md:max-w-11/12 md:flex-none">
                                              <div class="text-lg font-semibold  text-gray-400">
                                                Account Info
                                              </div>
                                            </div>
                                            <button
                                              onClick={() =>
                                                SetEditProfile(!EditProfile)
                                              }
                                              class="mt-3 flex flex-row items-center justify-center space-x-1  rounded-xl border px-6 py-2 text-base bg-red-500 hover:bg-red-600 text-white shadow-lg focus:outline-none"
                                            >
                                              <a
                                                href="javascript:;"
                                                data-target="tooltip_trigger"
                                                data-placement="top"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  fill="none"
                                                  viewBox="0 0 24 24"
                                                  stroke-width="1.5"
                                                  stroke="currentColor"
                                                  class="w-4 h-4"
                                                >
                                                  <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M6 18L18 6M6 6l12 12"
                                                  />
                                                </svg>
                                              </a>
                                              <span>Cancel</span>
                                            </button>
                                          </div>
                                        </div>
                                        <form className="p-4">
                                          <div class="personal_info flex flex-col">
                                            <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
                                              <div>
                                                <label
                                                  htmlFor="username"
                                                  className="text-left mb-2 ml-3 block font-medium text-gray-700 dark:text-white"
                                                >
                                                  Username
                                                </label>
                                                <div className="mb-0 flex items-center rounded border bg-white shadow-md">
                                                  <span className="px-3">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      strokeWidth="1.5"
                                                      stroke="currentColor"
                                                      className="h-5 w-5 text-gray-700"
                                                    >
                                                      <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                                                      ></path>
                                                    </svg>
                                                  </span>
                                                  <Field
                                                    className="h-12 w-full border-0 border-white focus:outline-none"
                                                    type="text"
                                                    name="username"
                                                    id="username"
                                                    placeholder="Username"
                                                  />
                                                </div>
                                                <ErrorMessage
                                                  name="username"
                                                  component="div"
                                                  className="text-left mt-2 text-red-500 text-xs"
                                                />
                                              </div>
                                              <div>
                                                <label
                                                  htmlFor="username"
                                                  className="text-left mb-2 ml-3 block font-medium text-gray-700 dark:text-white"
                                                >
                                                  Email
                                                </label>
                                                <div className="mb-0 flex items-center rounded border bg-gray-100 shadow-md">
                                                  <span className="px-3">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      strokeWidth="1.5"
                                                      stroke="currentColor"
                                                      className="h-5 w-5 text-gray-700"
                                                    >
                                                      <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                                                      ></path>
                                                    </svg>
                                                  </span>
                                                  <Field
                                                    className="h-12 w-full border-0 border-white   bg-gray-100 focus:outline-none"
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email"
                                                    disabled
                                                  />
                                                </div>
                                                <ErrorMessage
                                                  name="email"
                                                  component="div"
                                                  className="text-left mt-2 text-red-500 text-xs"
                                                />
                                              </div>

                                              <div class="col-span-2">
                                                <label
                                                  htmlFor="username"
                                                  className="text-left mb-2 ml-3 block font-medium text-gray-700 dark:text-white"
                                                >
                                                  Bio
                                                </label>
                                                <div className="mb-0 flex items-center rounded border bg-white shadow-md">
                                                  <Field
                                                    as="textarea"
                                                    id="message"
                                                    rows="4"
                                                    name="Bio"
                                                    className="p-2.5 w-full border-0 border-white focus:outline-none rounded-lg  focus:ring-sky-500 focus:border-sky-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-sky-500 dark:focus:border-sky-500"
                                                    placeholder="Write your Bio here..."
                                                  />
                                                </div>
                                                <ErrorMessage
                                                  name="Bio"
                                                  component="div"
                                                  className="text-left mt-2 text-red-500 text-xs"
                                                />
                                              </div>
                                            </div>

                                            <div className="grid w-full grid-cols-1 mt-4 gap-4 md:grid-cols-2">
                                              <div class="flex flex-row space-x-2">
                                                <div className="w-full">
                                                  <label
                                                    htmlFor="username"
                                                    className="text-left mb-2 ml-3 block font-medium text-gray-700 dark:text-white"
                                                  >
                                                    Date Of Birth
                                                  </label>
                                                  <DatePicker
                                                    selected={
                                                      values.Date_Birth
                                                        ? values.Date_Birth
                                                        : startDate
                                                    }
                                                    onChange={(date) =>
                                                      setFieldValue(
                                                        "Date_Birth",
                                                        date
                                                      )
                                                    }
                                                    className="w-full h-12 items-center rounded-xl border border-gray-200 bg-white text-leftsm font-medium text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white sm:flex"
                                                  />

                                                  <ErrorMessage
                                                    name="Date_Birth"
                                                    component="div"
                                                    className="text-left mt-2 text-red-500 text-xs"
                                                  />
                                                </div>
                                              </div>
                                              <div>
                                                <label
                                                  htmlFor="username"
                                                  className="text-left mb-2 ml-3 block font-medium text-gray-700 dark:text-white"
                                                >
                                                  Job or Interest
                                                </label>
                                                <Select
                                                  options={options}
                                                  value={selectedOption}
                                                  onChange={(option) => {
                                                    setSelectedOption(option);
                                                    setFieldValue(
                                                      "Interest",
                                                      option.value
                                                    );
                                                  }}
                                                />
                                                <ErrorMessage
                                                  name="Interest"
                                                  component="div"
                                                  className="text-left mt-2 text-red-500 text-xs"
                                                />
                                              </div>
                                              <div>
                                                <label
                                                  htmlFor="Phone Number"
                                                  className="mb-2 ml-3 block text-left font-medium text-gray-700 dark:text-white"
                                                >
                                                  Phone Number
                                                </label>
                                                <div className="mb-0 flex items-center rounded-xl border bg-white shadow-md">
                                                  <span className="px-3">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      strokeWidth="1.5"
                                                      stroke="currentColor"
                                                      className="h-6 w-6 text-gray-700"
                                                    >
                                                      <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                                                      ></path>
                                                    </svg>
                                                  </span>
                                                  <Field
                                                    className="h-12 w-full border-0 border-white focus:outline-none"
                                                    type="text"
                                                    name="Phone_Number"
                                                    placeholder="+................"
                                                  />
                                                </div>
                                                <ErrorMessage
                                                  name="Phone_Number"
                                                  component="div"
                                                  className="text-left mt-2 text-red-500 text-xs"
                                                />
                                              </div>
                                              <div>
                                                <label
                                                  htmlFor="username"
                                                  className="text-left mb-2 ml-3 block font-medium text-gray-700 dark:text-white"
                                                >
                                                  Country
                                                </label>
                                                <Select
                                                  options={countryoptions}
                                                  value={selectedCountryOption}
                                                  onChange={(option) => {
                                                    setSelectedCountryOption(
                                                      option
                                                    );
                                                    setFieldValue(
                                                      "Country",
                                                      option.value
                                                    );
                                                  }}
                                                />
                                                <ErrorMessage
                                                  name="Country"
                                                  component="div"
                                                  className="text-left mt-2 text-red-500 text-xs"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                    <button
                                      class="w-auto rounded-xl border-0 bg-sky-500 px-6 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none"
                                      type="submit"
                                      disabled={isSubmitting}
                                    >
                                      {isSubmitting
                                        ? "Update..."
                                        : "Save Changes"}
                                    </button>
                                  </>
                                </Form>
                              )}
                            </Formik>
                          ) : EditPassword ? (
                            <>
                              <Formik
                                initialValues={PasswordinitialValues}
                                validationSchema={PasswordvalidationSchema}
                                onSubmit={onPasswordSubmit}
                              >
                                {({ isSubmitting, values, setFieldValue }) => (
                                  <Form className="flex flex-col  items-center w-full ">
                                    <div class="w-full max-w-full px-3 mt-6 xl:w-12/12 mb-4 border rounded-2xl ">
                                      <div class="relative flex flex-col h-full min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                                        <div class="p-4 pb-0 mb-0 bg-white border-b-0 rounded-t-2xl">
                                          <div class="flex flex-row flex-wrap justify-between -mx-3">
                                            <div class="flex items-center px-3 shrink-0 md:max-w-11/12 md:flex-none">
                                              <div class="text-lg font-semibold  text-gray-400">
                                                Edit Password
                                              </div>
                                            </div>
                                            <button
                                              onClick={() =>
                                                SetEditPassword(!EditPassword)
                                              }
                                              class="mt-3 flex flex-row items-center justify-center space-x-1  rounded-xl border px-6 py-2 text-base bg-red-500 hover:bg-red-600 text-white shadow-lg focus:outline-none"
                                            >
                                              <a
                                                href="javascript:;"
                                                data-target="tooltip_trigger"
                                                data-placement="top"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  fill="none"
                                                  viewBox="0 0 24 24"
                                                  stroke-width="1.5"
                                                  stroke="currentColor"
                                                  class="w-4 h-4"
                                                >
                                                  <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M6 18L18 6M6 6l12 12"
                                                  />
                                                </svg>
                                              </a>
                                              <span>Cancel</span>
                                            </button>
                                          </div>
                                        </div>
                                        <form className="p-4">
                                          <div class="personal_info flex flex-col">
                                            <div class="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
                                              <div class="col-span-2">
                                                <div class="w-1/2">
                                                  <label
                                                    htmlFor="username"
                                                    className="text-left mb-2 ml-3 block font-medium text-gray-700 dark:text-white"
                                                  >
                                                    OldPassword
                                                  </label>
                                                  <div className="mb-0 flex items-center rounded border bg-white shadow-md">
                                                    <span className="px-3">
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="1.5"
                                                        stroke="currentColor"
                                                        className="h-6 w-6 text-gray-700"
                                                      >
                                                        <path
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                                        ></path>
                                                      </svg>
                                                    </span>
                                                    <Field
                                                      className="h-12 w-full border-0 border-white focus:outline-none"
                                                      type={
                                                        Password_show
                                                          ? "text"
                                                          : "password"
                                                      }
                                                      name="Oldpassword"
                                                      placeholder="•••••••••"
                                                    />
                                                    <div class="">
                                                      {!Password_show ? (
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="16"
                                                          height="16"
                                                          fill="gray"
                                                          id="togglePassword"
                                                          className="bi bi-eye relative right-3  cursor-pointer z-20 opacity-500"
                                                          viewBox="0 0 16 16"
                                                          onClick={() =>
                                                            setPasswordShow(
                                                              !Password_show
                                                            )
                                                          }
                                                        >
                                                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path>
                                                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path>
                                                        </svg>
                                                      ) : (
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="16"
                                                          height="16"
                                                          fill="currentColor"
                                                          className="bi bi-eye-slash-fill relative right-3 -z-1  cursor-pointer "
                                                          id="mama"
                                                          viewBox="0 0 16 16"
                                                          onClick={() =>
                                                            setPasswordShow(
                                                              !Password_show
                                                            )
                                                          }
                                                        >
                                                          <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"></path>
                                                          <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"></path>
                                                        </svg>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <ErrorMessage
                                                    name="Oldpassword"
                                                    component="div"
                                                    className="text-left mt-2 text-red-500 text-xs"
                                                  />
                                                </div>
                                              </div>
                                              <div>
                                                <label
                                                  htmlFor="username"
                                                  className="text-left mb-2 ml-3 block font-medium text-gray-700 dark:text-white"
                                                >
                                                  New Password
                                                </label>
                                                <div className="mb-0 flex items-center rounded border bg-white shadow-md">
                                                  <span className="px-3">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      strokeWidth="1.5"
                                                      stroke="currentColor"
                                                      className="h-6 w-6 text-gray-700"
                                                    >
                                                      <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                                      ></path>
                                                    </svg>
                                                  </span>
                                                  <Field
                                                    className="h-12 w-full border-0 border-white focus:outline-none"
                                                    type={
                                                      Password_show
                                                        ? "text"
                                                        : "password"
                                                    }
                                                    name="password"
                                                    placeholder="•••••••••"
                                                  />
                                                  <div class="">
                                                    {!Password_show ? (
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="gray"
                                                        id="togglePassword"
                                                        className="bi bi-eye relative right-3  cursor-pointer z-20 opacity-500"
                                                        viewBox="0 0 16 16"
                                                        onClick={() =>
                                                          setPasswordShow(
                                                            !Password_show
                                                          )
                                                        }
                                                      >
                                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path>
                                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path>
                                                      </svg>
                                                    ) : (
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-eye-slash-fill relative right-3 -z-1  cursor-pointer "
                                                        id="mama"
                                                        viewBox="0 0 16 16"
                                                        onClick={() =>
                                                          setPasswordShow(
                                                            !Password_show
                                                          )
                                                        }
                                                      >
                                                        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"></path>
                                                        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"></path>
                                                      </svg>
                                                    )}
                                                  </div>
                                                </div>
                                                <ErrorMessage
                                                  name="password"
                                                  component="div"
                                                  className="text-left mt-2 text-red-500 text-xs"
                                                />
                                              </div>
                                              <div>
                                                <label
                                                  htmlFor="username"
                                                  className="text-left mb-2 ml-3 block font-medium text-gray-700 dark:text-white"
                                                >
                                                  Confirm New Password
                                                </label>
                                                <div className="mb-0 flex items-center rounded border bg-white shadow-md">
                                                  <span className="px-3">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      strokeWidth="1.5"
                                                      stroke="currentColor"
                                                      className="h-6 w-6 text-gray-700"
                                                    >
                                                      <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                                      ></path>
                                                    </svg>
                                                  </span>
                                                  <Field
                                                    className="h-12 w-full border-0 border-white focus:outline-none"
                                                    type={
                                                      Password_show
                                                        ? "text"
                                                        : "password"
                                                    }
                                                    name="confirmPassword"
                                                    placeholder="•••••••••"
                                                  />
                                                  <div class="">
                                                    {!Password_show ? (
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="gray"
                                                        id="togglePassword"
                                                        className="bi bi-eye relative right-3  cursor-pointer z-20 opacity-500"
                                                        viewBox="0 0 16 16"
                                                        onClick={() =>
                                                          setPasswordShow(
                                                            !Password_show
                                                          )
                                                        }
                                                      >
                                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path>
                                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path>
                                                      </svg>
                                                    ) : (
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-eye-slash-fill relative right-3 -z-1  cursor-pointer "
                                                        id="mama"
                                                        viewBox="0 0 16 16"
                                                        onClick={() =>
                                                          setPasswordShow(
                                                            !Password_show
                                                          )
                                                        }
                                                      >
                                                        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"></path>
                                                        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"></path>
                                                      </svg>
                                                    )}
                                                  </div>
                                                </div>
                                                <ErrorMessage
                                                  name="confirmPassword"
                                                  component="div"
                                                  className="text-left mt-2 text-red-500 text-xs"
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          {Error ? (
                                            <label
                                              htmlFor="username"
                                              className="text-left mb-2 ml-3 block font-medium text-red-500 dark:text-white"
                                            >
                                              {Error}
                                            </label>
                                          ) : null}

                                          {Message ? (
                                            <label
                                              htmlFor="username"
                                              className="text-left mb-2 ml-3 block font-medium text-green-500 dark:text-white"
                                            >
                                              {Message}
                                            </label>
                                          ) : null}
                                        </form>
                                      </div>
                                    </div>

                                    <button
                                      class="w-auto rounded-xl border-0 bg-sky-500 px-6 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none"
                                      type="submit"
                                      disabled={isSubmitting}
                                    >
                                      {isSubmitting
                                        ? "Update..."
                                        : "Save Changes"}
                                    </button>
                                  </Form>
                                )}
                              </Formik>
                            </>
                          ) : (
                            <>
                              {" "}
                              <div class="w-full max-w-full px-3 mt-6 xl:w-9/12 mb-4 border rounded-2xl ">
                                <div class="relative flex flex-col h-full min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                                  <div class="p-4 pb-0 mb-0 bg-white border-b-0 rounded-t-2xl">
                                    <div class="flex flex-row justify-between -mx-3">
                                      <div class="flex items-center px-3 shrink-0  md:flex-none">
                                        <h6 class="mb-0">Bio </h6>
                                      </div>
                                      <div class="flex flex-row space-x-2">
                                        <button
                                          onClick={() =>
                                            SetEditPassword(!EditPassword)
                                          }
                                          class="mt-3  flex flex-row space-x-1 items-center justify-center rounded-xl border px-6 py-2 text-xs bg-sky-500 hover:bg-sky-600 text-white shadow-lg focus:outline-none"
                                        >
                                          <a
                                            href="javascript:;"
                                            data-target="tooltip_trigger"
                                            data-placement="top"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                              fill="currentColor"
                                              class="w-4 h-4"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z"
                                                clip-rule="evenodd"
                                              />
                                            </svg>
                                          </a>
                                          <div
                                            data-target="tooltip"
                                            class="px-2 py-1 text-center text-white bg-black rounded-lg text-base hidden"
                                            role="tooltip"
                                            style={{
                                              position: " absolute",
                                              " inset": "auto auto 0px 0px",
                                              " margin": " 0px",
                                              transform:
                                                " translate(920px, 113px)",
                                            }}
                                            data-popper-placement="top"
                                          >
                                            {" "}
                                            Edit Password{" "}
                                            <div
                                              class="invisible absolute h-2 w-2 bg-inherit before:visible before:absolute before:h-2 before:w-2 before:rotate-45 before:bg-inherit before:content-['']"
                                              data-popper-arrow=""
                                              style={{
                                                position: "absolute",
                                                left: "0px",
                                                transform:
                                                  "translate(0px, 0px)",
                                              }}
                                            ></div>
                                          </div>{" "}
                                          Edit Password
                                        </button>
                                        <button
                                          onClick={() =>
                                            SetEditProfile(!EditProfile)
                                          }
                                          class="mt-3  rounded-xl border px-6 py-2 text-xs bg-sky-500 hover:bg-sky-600 text-white shadow-lg focus:outline-none"
                                        >
                                          <a
                                            href="javascript:;"
                                            data-target="tooltip_trigger"
                                            data-placement="top"
                                          >
                                            <i
                                              class="leading-normal fas fa-user-edit text-base text-white"
                                              aria-hidden="true"
                                            ></i>
                                          </a>
                                          <div
                                            data-target="tooltip"
                                            class="px-2 py-1 text-center text-white bg-black rounded-lg text-base hidden"
                                            role="tooltip"
                                            style={{
                                              position: " absolute",
                                              " inset": "auto auto 0px 0px",
                                              " margin": " 0px",
                                              transform:
                                                " translate(920px, 113px)",
                                            }}
                                            data-popper-placement="top"
                                          >
                                            {" "}
                                            Edit Profile{" "}
                                            <div
                                              class="invisible absolute h-2 w-2 bg-inherit before:visible before:absolute before:h-2 before:w-2 before:rotate-45 before:bg-inherit before:content-['']"
                                              data-popper-arrow=""
                                              style={{
                                                position: "absolute",
                                                left: "0px",
                                                transform:
                                                  "translate(0px, 0px)",
                                              }}
                                            ></div>
                                          </div>{" "}
                                          Edit Profile
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="flex-auto p-4">
                                    <p class="leading-normal text-base">
                                      {User.bio}.
                                    </p>
                                    <hr class="h-px my-6  bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent" />
                                    <div class="">
                                      {" "}
                                      <div class="flex flex-row space-x-16 items-center hidden   border-gray-100 relative  px-4 py-2 pt-0 pl-0 leading-normal bg-white border-0 rounded-t-lg text-base text-inherit">
                                        <div class=" ">Username :</div>
                                        <div class="font-semibold">
                                          {User.username}
                                        </div>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="currentColor"
                                          class="w-3 h-3"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                    <ul class="flex flex-col pl-0 mb-0 rounded-lg">
                                      <li class="relative block px-4 py-2 pt-0 pl-0 leading-normal bg-white border-0 rounded-t-lg text-base text-inherit">
                                        <strong class="text-slate-700">
                                          Username:
                                        </strong>{" "}
                                        &nbsp; {User.username}
                                      </li>

                                      <li class="relative block px-4 py-2 pl-0 leading-normal bg-white border-0 border-t-0 text-base text-inherit">
                                        <strong class="text-slate-700">
                                          Email:
                                        </strong>{" "}
                                        &nbsp; {User.email}
                                      </li>
                                      <li class="relative block px-4 py-2 pl-0 leading-normal bg-white border-0 border-t-0 text-base text-inherit">
                                        <strong class="text-slate-700">
                                          Gender:
                                        </strong>{" "}
                                        &nbsp; {User.gender}
                                      </li>
                                      <li class="relative block px-4 py-2 pl-0 leading-normal bg-white border-0 border-t-0 text-base text-inherit">
                                        <strong class="text-slate-700">
                                          Date of Birth:
                                        </strong>{" "}
                                        &nbsp;{User.date_of_birth}
                                      </li>

                                      <li class="relative block px-4 py-2 pl-0 leading-normal bg-white border-0 border-t-0 text-base text-inherit">
                                        <strong class="text-slate-700">
                                          Job:
                                        </strong>{" "}
                                        &nbsp; {User.job}
                                      </li>

                                      <li class="relative block px-4 py-2 pl-0 leading-normal bg-white border-0 border-t-0 text-base text-inherit">
                                        <strong class="text-slate-700">
                                          Interested With:
                                        </strong>{" "}
                                        &nbsp;{" "}
                                        {Space.map((item) => {
                                          return (
                                            <button
                                              type="button"
                                              class="rounded-2xl  bg-sky-100 px-4 py-2 m-2 "
                                            >
                                              {item.interest_title}
                                            </button>
                                          );
                                        })}
                                      </li>
                                      <li class="relative block px-4 py-2 pl-0 leading-normal bg-white border-0 border-t-0 text-base text-inherit">
                                        <strong class="text-slate-700">
                                          Account:
                                        </strong>{" "}
                                        &nbsp; {User.type}
                                      </li>
                                      <li class="relative block px-4 py-2 pb-0 pl-0 bg-white border-0 border-t-0 rounded-b-lg text-inherit">
                                        <strong class="leading-normal text-base text-slate-700">
                                          Country:
                                        </strong>{" "}
                                        &nbsp; {User.country}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <ToastContainer />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="basic"
            className={Actual == 2 ? "tab-content active" : "tab-content"}
          >
            <div class="border border-b-0 border-gray-200 p-2 font-light dark:border-gray-700 dark:bg-gray-900">
              <div class="w-full space-y-4">
                <div class="relative h-full overflow-hidden">
                  <div class="-mx-3 flex h-full flex-col divide-y overflow-y-auto overflow-x-hidden">
                    <div class="today mb-10">
                      <div class="relative flex flex-col items-center justify-center rounded-lg">
                        <div class="mt-3 mb-3 ml-5">
                          {EditSecret ? (
                            <div class="text-lg font-semibold  text-gray-400">
                              Change Secret Code
                            </div>
                          ) : (
                            <div class="text-lg font-semibold  text-gray-400">
                              Payment & Contact Info
                            </div>
                          )}
                        </div>
                        <div className="flex flex-col  items-center w-full ">
                          {loading && <LoadingPage />}
                          {EditSecret ? (
                            <>
                              <Formik
                                initialValues={SecretinitialValues}
                                validationSchema={SecretvalidationSchema}
                                onSubmit={onSecretSubmit}
                              >
                                {({ isSubmitting, values, setFieldValue }) => (
                                  <Form className="flex flex-col  items-center w-full ">
                                    <div class="w-full max-w-full px-3 mt-6 xl:w-12/12 mb-4 border rounded-2xl ">
                                      <div class="relative flex flex-col h-full min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                                        <div class="p-4 pb-0 mb-0 bg-white border-b-0 rounded-t-2xl">
                                          <div class="flex flex-row flex-wrap justify-between -mx-3">
                                            <div class="flex items-center px-3 shrink-0 md:max-w-11/12 md:flex-none">
                                              <div class="text-lg font-semibold  text-gray-400">
                                                Change Transaction Secret Code
                                              </div>
                                            </div>
                                            <button
                                              onClick={() =>
                                                SetEditSecret(!EditSecret)
                                              }
                                              class="mt-3 flex flex-row items-center justify-center space-x-1  rounded-xl border px-6 py-2 text-base bg-red-500 hover:bg-red-600 text-white shadow-lg focus:outline-none"
                                            >
                                              <a
                                                href="javascript:;"
                                                data-target="tooltip_trigger"
                                                data-placement="top"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  fill="none"
                                                  viewBox="0 0 24 24"
                                                  stroke-width="1.5"
                                                  stroke="currentColor"
                                                  class="w-4 h-4"
                                                >
                                                  <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M6 18L18 6M6 6l12 12"
                                                  />
                                                </svg>
                                              </a>
                                              <span>Cancel</span>
                                            </button>
                                          </div>
                                        </div>
                                        <form className="p-4">
                                          <div class="personal_info flex flex-col">
                                            <div class="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
                                              <div>
                                                <label
                                                  htmlFor="Phone Number"
                                                  className="mb-2 ml-3 block text-left font-medium text-gray-700 dark:text-white"
                                                >
                                                  Number/Code
                                                </label>
                                                <div className="mb-0 flex items-center rounded-xl border bg-white shadow-md">
                                                  <span className="px-3">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      strokeWidth="1.5"
                                                      stroke="currentColor"
                                                      className="h-6 w-6 text-gray-700"
                                                    >
                                                      <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                                                      ></path>
                                                    </svg>
                                                  </span>
                                                  <Field
                                                    className="h-12 w-full border-0 border-white focus:outline-none"
                                                    type="text"
                                                    name="Number_Code"
                                                    placeholder="Number/Code............."
                                                  />
                                                </div>
                                                <ErrorMessage
                                                  name="Number_Code"
                                                  component="div"
                                                  className="text-left mt-2 text-red-500 text-xs"
                                                />
                                              </div>
                                              <div>
                                                <label
                                                  htmlFor="username"
                                                  className="text-left mb-2 ml-3 block font-medium text-gray-700 dark:text-white"
                                                >
                                                  Withdraw Way
                                                </label>
                                                <Select
                                                  options={paymentoptions}
                                                  value={selectedPaymentOption}
                                                  onChange={(option) => {
                                                    setSelectedpaymentOption(
                                                      option
                                                    );
                                                    setFieldValue(
                                                      "Payment_Method",
                                                      option.value
                                                    );
                                                  }}
                                                />
                                                <ErrorMessage
                                                  name="Payment_Method"
                                                  component="div"
                                                  className="text-left mt-2 text-red-500 text-xs"
                                                />
                                              </div>

                                              <div class="">
                                                <div class="">
                                                  <label
                                                    htmlFor="username"
                                                    className="text-left mb-2 ml-3 block font-medium text-gray-700 dark:text-white"
                                                  >
                                                    OldSecret Code
                                                  </label>
                                                  <div className="mb-0 flex items-center rounded border bg-white shadow-md">
                                                    <span className="px-3">
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="1.5"
                                                        stroke="currentColor"
                                                        className="h-6 w-6 text-gray-700"
                                                      >
                                                        <path
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                                        ></path>
                                                      </svg>
                                                    </span>
                                                    <Field
                                                      className="h-12 w-full border-0 border-white focus:outline-none"
                                                      type={
                                                        Password_show
                                                          ? "text"
                                                          : "password"
                                                      }
                                                      name="OldSecret"
                                                      placeholder="•••••••••"
                                                    />
                                                    <div class="">
                                                      {!Password_show ? (
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="16"
                                                          height="16"
                                                          fill="gray"
                                                          id="togglePassword"
                                                          className="bi bi-eye relative right-3  cursor-pointer z-20 opacity-500"
                                                          viewBox="0 0 16 16"
                                                          onClick={() =>
                                                            setPasswordShow(
                                                              !Password_show
                                                            )
                                                          }
                                                        >
                                                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path>
                                                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path>
                                                        </svg>
                                                      ) : (
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="16"
                                                          height="16"
                                                          fill="currentColor"
                                                          className="bi bi-eye-slash-fill relative right-3 -z-1  cursor-pointer "
                                                          id="mama"
                                                          viewBox="0 0 16 16"
                                                          onClick={() =>
                                                            setPasswordShow(
                                                              !Password_show
                                                            )
                                                          }
                                                        >
                                                          <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"></path>
                                                          <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"></path>
                                                        </svg>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <ErrorMessage
                                                    name="OldSecret"
                                                    component="div"
                                                    className="text-left mt-2 text-red-500 text-xs"
                                                  />
                                                </div>
                                              </div>
                                              {ChangeSecret ? (
                                                <>
                                                  <div class=" flex flex-row items-end justify-start">
                                                    <button
                                                      onClick={() => {
                                                        setFieldValue(
                                                          "Secret",
                                                          ""
                                                        );
                                                        setFieldValue(
                                                          "confirmSecret",
                                                          ""
                                                        );
                                                        SetChangeSecret(
                                                          !ChangeSecret
                                                        );
                                                      }}
                                                      class=" flex flex-row items-center justify-center space-x-1  rounded-xl border px-6 py-2 text-base border-red-500 bg-gray-50 hover:bg-sky-100 text-red-500 shadow-lg focus:outline-none"
                                                    >
                                                      <a
                                                        href="javascript:;"
                                                        data-target="tooltip_trigger"
                                                        data-placement="top"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          fill="none"
                                                          viewBox="0 0 24 24"
                                                          stroke-width="1.5"
                                                          stroke="currentColor"
                                                          class="w-4 h-4"
                                                        >
                                                          <path
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            d="M6 18L18 6M6 6l12 12"
                                                          />
                                                        </svg>
                                                      </a>
                                                      <span>Cancel</span>
                                                    </button>
                                                  </div>
                                                  <div class="bg-gray-100 rounded-2xl col-span-2 flex flex-row w-full p-3 space-x-4">
                                                    <div>
                                                      <label
                                                        htmlFor="username"
                                                        className="text-left mb-2 ml-3 block font-medium text-gray-700 dark:text-white"
                                                      >
                                                        New Secret Code
                                                      </label>
                                                      <div className="mb-0 flex items-center rounded border bg-white shadow-md">
                                                        <span className="px-3">
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            className="h-6 w-6 text-gray-700"
                                                          >
                                                            <path
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                              d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                                            ></path>
                                                          </svg>
                                                        </span>
                                                        <Field
                                                          className="h-12 w-full border-0 border-white focus:outline-none"
                                                          type={
                                                            Password_show
                                                              ? "text"
                                                              : "password"
                                                          }
                                                          name="Secret"
                                                          placeholder="•••••••••"
                                                        />
                                                        <div class="">
                                                          {!Password_show ? (
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="16"
                                                              height="16"
                                                              fill="gray"
                                                              id="togglePassword"
                                                              className="bi bi-eye relative right-3  cursor-pointer z-20 opacity-500"
                                                              viewBox="0 0 16 16"
                                                              onClick={() =>
                                                                setPasswordShow(
                                                                  !Password_show
                                                                )
                                                              }
                                                            >
                                                              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path>
                                                              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path>
                                                            </svg>
                                                          ) : (
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="16"
                                                              height="16"
                                                              fill="currentColor"
                                                              className="bi bi-eye-slash-fill relative right-3 -z-1  cursor-pointer "
                                                              id="mama"
                                                              viewBox="0 0 16 16"
                                                              onClick={() =>
                                                                setPasswordShow(
                                                                  !Password_show
                                                                )
                                                              }
                                                            >
                                                              <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"></path>
                                                              <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"></path>
                                                            </svg>
                                                          )}
                                                        </div>
                                                      </div>
                                                      <ErrorMessage
                                                        name="Secret"
                                                        component="div"
                                                        className="text-left mt-2 text-red-500 text-xs"
                                                      />
                                                    </div>
                                                    <div>
                                                      <label
                                                        htmlFor="username"
                                                        className="text-left mb-2 ml-3 block font-medium text-gray-700 dark:text-white"
                                                      >
                                                        Confirm Secret Code
                                                      </label>
                                                      <div className="mb-0 flex items-center rounded border bg-white shadow-md">
                                                        <span className="px-3">
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            className="h-6 w-6 text-gray-700"
                                                          >
                                                            <path
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                              d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                                            ></path>
                                                          </svg>
                                                        </span>
                                                        <Field
                                                          className="h-12 w-full border-0 border-white focus:outline-none"
                                                          type={
                                                            Password_show
                                                              ? "text"
                                                              : "password"
                                                          }
                                                          name="confirmSecret"
                                                          placeholder="•••••••••"
                                                        />
                                                        <div class="">
                                                          {!Password_show ? (
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="16"
                                                              height="16"
                                                              fill="gray"
                                                              id="togglePassword"
                                                              className="bi bi-eye relative right-3  cursor-pointer z-20 opacity-500"
                                                              viewBox="0 0 16 16"
                                                              onClick={() =>
                                                                setPasswordShow(
                                                                  !Password_show
                                                                )
                                                              }
                                                            >
                                                              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path>
                                                              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path>
                                                            </svg>
                                                          ) : (
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="16"
                                                              height="16"
                                                              fill="currentColor"
                                                              className="bi bi-eye-slash-fill relative right-3 -z-1  cursor-pointer "
                                                              id="mama"
                                                              viewBox="0 0 16 16"
                                                              onClick={() =>
                                                                setPasswordShow(
                                                                  !Password_show
                                                                )
                                                              }
                                                            >
                                                              <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"></path>
                                                              <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"></path>
                                                            </svg>
                                                          )}
                                                        </div>
                                                      </div>
                                                      <ErrorMessage
                                                        name="confirmSecret"
                                                        component="div"
                                                        className="text-left mt-2 text-red-500 text-xs"
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <div class=" flex flex-row items-end justify-start">
                                                  <button
                                                    onClick={() =>
                                                      SetChangeSecret(
                                                        !ChangeSecret
                                                      )
                                                    }
                                                    class=" flex flex-row items-center justify-center space-x-1  rounded-xl border px-6 py-2 text-base border-sky-500 bg-gray-50 hover:bg-sky-100 text-sky-500 shadow-lg focus:outline-none"
                                                  >
                                                    <a
                                                      href="javascript:;"
                                                      data-target="tooltip_trigger"
                                                      data-placement="top"
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke-width="1.5"
                                                        stroke="currentColor"
                                                        class="w-4 h-4"
                                                      >
                                                        <path
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                          d="M6 18L18 6M6 6l12 12"
                                                        />
                                                      </svg>
                                                    </a>
                                                    <span>
                                                      Change Secret Code
                                                    </span>
                                                  </button>
                                                </div>
                                              )}
                                            </div>
                                          </div>

                                          {Error ? (
                                            <label
                                              htmlFor="username"
                                              className="text-left mb-2 ml-3 block font-medium text-red-500 dark:text-white"
                                            >
                                              {Error}
                                            </label>
                                          ) : null}

                                          {Message ? (
                                            <label
                                              htmlFor="username"
                                              className="text-left mb-2 ml-3 block font-medium text-green-500 dark:text-white"
                                            >
                                              {Message}
                                            </label>
                                          ) : null}
                                        </form>
                                      </div>
                                    </div>

                                    <button
                                      class="w-auto rounded-xl border-0 bg-sky-500 px-6 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none"
                                      type="submit"
                                      disabled={isSubmitting}
                                    >
                                      {isSubmitting
                                        ? "Update..."
                                        : "Save Changes"}
                                    </button>
                                  </Form>
                                )}
                              </Formik>
                            </>
                          ) : (
                            <>
                              {" "}
                              <div class="w-full max-w-full px-3 mt-6 xl:w-9/12 mb-4 border rounded-2xl ">
                                <div class="relative flex flex-col h-full min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                                  <div class="flex-auto p-4">
                                    <div class="">
                                      {" "}
                                      <div class="flex flex-row space-x-16 items-center hidden   border-gray-100 relative  px-4 py-2 pt-0 pl-0 leading-normal bg-white border-0 rounded-t-lg text-base text-inherit">
                                        <div class=" ">Username :</div>
                                        <div class="font-semibold">
                                          {User.username}
                                        </div>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="currentColor"
                                          class="w-3 h-3"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                    <ul class="flex flex-col pl-0 mb-0 rounded-lg">
                                      <li class="relative block px-4 py-2 pt-0 pl-0 leading-normal bg-white border-0 rounded-t-lg text-base text-inherit">
                                        <strong class="text-slate-700">
                                          Payment Number/Code:
                                        </strong>{" "}
                                        &nbsp; {User.username}
                                      </li>

                                      <li class="relative block px-4 py-2 pl-0 leading-normal bg-white border-0 border-t-0 text-base text-inherit">
                                        <strong class="text-slate-700">
                                          Secret Code:
                                        </strong>{" "}
                                        &nbsp; ################
                                      </li>
                                      <li class="relative block px-4 py-2 pl-0 leading-normal bg-white border-0 border-t-0 text-base text-inherit">
                                        <strong class="text-slate-700">
                                          Payment Method :
                                        </strong>{" "}
                                        &nbsp; Lumicash
                                      </li>
                                      <div className="w-full grid grid-cols-1 md:grid-cols-2 my-12 px-6 gap-5">
                                        <div className="flex  flex-col md:space-x-3 shadow border bg-white p-1 rounded-2xl">
                                          <div className="flex items-center justify-center">
                                            <div className="relative h-20 flex items-center justify-center    rounded-2xl">
                                              <img
                                                className="h-16 w-16 rounded-xl"
                                                src="../../../images/2.png"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flex  flex-col md:space-x-3 shadow border bg-white p-1 rounded-2xl">
                                          <div className="flex items-center justify-center">
                                            <div className="relative h-20 flex items-center justify-center  rounded-2xl">
                                              <img
                                                className="h-16 w-16 rounded-xl"
                                                src="../../../images/binance.png"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flex  flex-col md:space-x-3 shadow border bg-white p-1 rounded-2xl">
                                          <div className="flex items-center justify-center">
                                            <div className="relative h-20 flex items-center justify-center    rounded-2xl">
                                              <img
                                                className="h-16 w-16 rounded-xl"
                                                src="../../../images/EcoCash.png"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flex  flex-col md:space-x-3 shadow border bg-white p-1 rounded-2xl">
                                          <div className="flex items-center justify-center">
                                            <div className="relative h-20 flex items-center justify-center   rounded-2xl">
                                              <img
                                                className="h-16 w-16 rounded-xl"
                                                src="../../../images/1.png"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </ul>
                                  </div>
                                  <div class="p-4 pb-0 mb-0 bg-white border-b-0 rounded-t-2xl">
                                    <div class="flex flex-row justify-end -mx-3">
                                      <div class="flex h-12 flex-row space-x-2">
                                        <button
                                          onClick={() =>
                                            SetEditSecret(!EditSecret)
                                          }
                                          class="mt-3  flex flex-row space-x-1 items-center justify-center rounded-xl border px-6 py-2 text-xs bg-sky-500 hover:bg-sky-600 text-white shadow-lg focus:outline-none"
                                        >
                                          <a
                                            href="javascript:;"
                                            data-target="tooltip_trigger"
                                            data-placement="top"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                              fill="currentColor"
                                              class="w-4 h-4"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z"
                                                clip-rule="evenodd"
                                              />
                                            </svg>
                                          </a>
                                          <div
                                            data-target="tooltip"
                                            class="px-6 py-2 text-center text-white bg-black rounded-lg text-base hidden"
                                            role="tooltip"
                                            style={{
                                              position: " absolute",
                                              " inset": "auto auto 0px 0px",
                                              " margin": " 0px",
                                              transform:
                                                " translate(920px, 113px)",
                                            }}
                                            data-popper-placement="top"
                                          >
                                            {" "}
                                            Change Secret Code{" "}
                                            <div
                                              class="invisible absolute h-2 w-2 bg-inherit before:visible before:absolute before:h-2 before:w-2 before:rotate-45 before:bg-inherit before:content-['']"
                                              data-popper-arrow=""
                                              style={{
                                                position: "absolute",
                                                left: "0px",
                                                transform:
                                                  "translate(0px, 0px)",
                                              }}
                                            ></div>
                                          </div>{" "}
                                          Change Secret Code
                                        </button>
                                      </div>
                                    </div>
                                    <hr class="h-px my-6  bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent" />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <ToastContainer />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="basic"
            className={Actual == 3 ? "tab-content active" : "tab-content"}
          >
            <div class="border border-b-0 border-gray-200 p-2 font-light dark:border-gray-700 dark:bg-gray-900">
              <div class="w-full space-y-4">
                <div class="relative h-full overflow-hidden">
                  <div class="-mx-3 flex h-full flex-col divide-y overflow-y-auto overflow-x-hidden">
                    <div class="today mb-10">
                      <div class="relative flex flex-col items-center justify-center rounded-lg">
                        <div class="mt-3 mb-3 ml-5">
                          <div class="text-lg font-semibold  text-gray-400">
                            Notification Setting
                          </div>
                        </div>
                        <div className="flex flex-col  items-center w-full ">
                          {loading && <LoadingPage />}

                          <>
                            {" "}
                            <div className="flex flex-col  items-center w-full ">
                              <div class="w-full max-w-full px-3 mt-6 xl:w-9/12 mb-4 border rounded-2xl ">
                                <div class="relative flex flex-col h-full min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                                  <div class="relative flex flex-col h-full min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                                    <div class="p-4 hidden pb-0 mb-0 bg-white border-b-0 rounded-t-2xl">
                                      <h6 class="mb-0">Platform Settings</h6>
                                    </div>
                                    <div class="flex-auto p-4">
                                      <h6 class="font-bold leading-tight uppercase text-xs text-slate-500">
                                        Account Notification
                                      </h6>
                                      <div class="flex flex-col pl-0 mb-0 rounded-lg">
                                        {checkboxes.length > 0 &&
                                          checkboxes.map((checkboxe) => (
                                            <li class="relative block px-0 py-2 bg-white border-0 rounded-t-lg text-inherit">
                                              <div class="min-h-6 mb-0.5 block pl-0 flex flex-row">
                                                <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                  <input
                                                    type="checkbox"
                                                    checked={checkboxe.status}
                                                    onChange={(event) =>
                                                      handleCheckboxChange(
                                                        event,
                                                        checkboxe.id
                                                      )
                                                    }
                                                    class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                                                  />
                                                  <label
                                                    for="toggle"
                                                    class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                                                  ></label>
                                                </div>
                                                <label
                                                  for="follow"
                                                  class="w-4/5 mb-0 ml-4 overflow-hidden font-normal cursor-pointer select-none text-sm text-ellipsis whitespace-nowrap text-slate-500"
                                                >
                                                  {checkboxe.title}
                                                </label>
                                              </div>
                                            </li>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <button
                                class="w-auto rounded-xl border-0 bg-sky-500 px-6 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none"
                                onClick={onNotifSubmit}
                              >
                                {loading ? "Updating..." : "Save Changes"}
                              </button>
                            </div>
                          </>
                        </div>
                        <ToastContainer />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer position="bottom-center" />
    </>
  );
}
export default PostToShare;
