import React from 'react'
import UserAside from '../AsideBar/UserAside'
import ProfileBox from './ProfileBox'
import MyPosts from './MyPosts'
import MyEvent from './MyEvent'
import MySups from './MySups'

function PubOwner() {
  return (
    <>
          <ProfileBox />
          <MyPosts />
          <MyEvent />
          <MySups />
    </>
  )
}
export default PubOwner