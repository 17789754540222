import React from 'react'

function Event(props) {
    const evt = props.evt
    var date = new Date(evt.date);
    const eDate = date.toString('YYYY-MM-dd').split(' ')
    const eTime = eDate[4].split(':')
  return (
    <>                         
                <div className=" item sm:w-[600px] card text-grey-darkest flex h-44 rounded-xl border bg-white shadow-md">
                              <div className="sm:flex hidden h-full w-1/2 flex-col items-center justify-center rounded-l-xl bg-sky-600 to-sky-100 px-10" alt="Date">
                                <p className="text-5xl text-white">{eDate[2]}</p>
                                <p className="font-serif text-2xl text-white">{eDate[1]}</p>
                                <p></p>
                                <p className="text-3xl text-white">{eDate[3]}</p>
                              </div>
                              <div className="flex w-full  flex-col">
                                <div className="flex-1 px-4 pb-0">
                                  <div className="flex items-center justify-between p-3" >
                                    <div className="flex items-center space-x-2" >
                                      <img src={evt.photo} alt="" className="bg-coolGray-500 border-coolGray-700 h-8 w-8 rounded-full object-cover object-center shadow-sm" />
                                      <div className="-space-y-1" >
                                        <h2 className="text-sm font-semibold leading-none" >{evt.name}</h2>
                                        <span className="text-coolGray-400 inline-block text-xs leading-none" >{evt.cat}</span>
                                      </div>
                                    </div>
                                    <button title="Open options" type="button">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="h-5 w-5 fill-current" >
                                        <path d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"></path>
                                        <path d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"></path>
                                        <path d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"></path>
                                      </svg>
                                    </button>
                                  </div>
                                  <div className="rounded-md bg-gray-100 py-2 px-2">
                                    <span className="rounded-r-xl text-base text-gray-700">{evt.desc}<span className="ml-10 text-xs">{eDate[2]} {eDate[1]} {eDate[3]}</span> </span>
                                  </div>

                                  <div className="mt-1 flex items-center justify-between">
                                    <div className="flex flex-row items-center justify-center space-x-1">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                          </svg>

                          <div className="pr-2 text-sm text-gray-800">{eTime[0]}h-{eTime[1]}'-{eTime[2]}''</div>
                                    </div>
                                    <div className="flex flex-row items-center justify-center space-x-1">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-ubuntu" viewBox="0 0 16 16">
                            <path d="M2.273 9.53a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.547Zm9.467-4.984a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.546ZM7.4 13.108a5.535 5.535 0 0 1-3.775-2.88 3.273 3.273 0 0 1-1.944.24 7.4 7.4 0 0 0 5.328 4.465c.53.113 1.072.169 1.614.166a3.253 3.253 0 0 1-.666-1.9 5.639 5.639 0 0 1-.557-.091Zm3.828 2.285a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.546Zm3.163-3.108a7.436 7.436 0 0 0 .373-8.726 3.276 3.276 0 0 1-1.278 1.498 5.573 5.573 0 0 1-.183 5.535 3.26 3.26 0 0 1 1.088 1.693ZM2.098 3.998a3.28 3.28 0 0 1 1.897.486 5.544 5.544 0 0 1 4.464-2.388c.037-.67.277-1.313.69-1.843a7.472 7.472 0 0 0-7.051 3.745Z"></path>
                          </svg>

                          <div className="pr-2 text-sm text-gray-800">{evt.sups}</div>
                                    </div>
                                    
                                </div>
                                
                                <div className="relative bottom-3 flex items-center justify-center">
                                  
                                    <div className="bg-sky-600 w-auto rounded-full hover:bg-grey-light flex items-center justify-center px-3 py-1 text-base font-light  text-white transition">
                                  Join WaitList
                                
                            <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z">
                            <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z">
                          
                                </path></path></div>
                                </div>
                              </div>
                            </div>
                            </div>

    </>
  )
}
export default Event