import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Api from "../Api/Api";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import LoadingPage from "../LoadingPage.js/LoadingPage";

function Payment_Info({ formDatas, setFormDatas, setPage }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [PlatformData, SetPlatformData] = useState(null);
  const [ServiceData, SetServiceData] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleChange = (option) => {
    setSelectedOption(option);
    setFormDatas({ ...formDatas, Interest: option.value });
  };
  const [options, setoptions] = useState([{}]);
  const [serviceoptions, setserviceoptions] = useState([{}]);
  const getAllInterest = () => {
    setLoading(true);
    Api.get("/api/platforms/getall").then((response) => {
      {
        const datas = [];
        response.data.map((item) => {
          const newRow = { value: item.platform, label: item.platform };
          datas.push(newRow);
        });
        setoptions(datas);
        setSelectedPlatform(datas[0]);
        SetPlatformData(response.data);
      }
    });

    //console.log(options);
  };

  const getAllService = () => {
    Api.get("/api/platform_services/getall").then((response) => {
      {
        const datas = [];
        // const id = response.data[0].platform_id;
        //console.log();
        const result = response.data.filter(
          (item) => item.platform_id === response.data[0].platform_id
        );
        result.map((item) => {
          const newRow = { value: item.title, label: item.title };
          datas.push(newRow);
        });
        setserviceoptions(datas);
        setSelectedService(datas[0]);
        SetServiceData(response.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getAllInterest();
    getAllService();
  }, []);
  const history = useNavigate();
  const [parts, setParts] = useState([]);

  const handlePartTitleChange = (e, partIndex) => {
    const updatedParts = [...parts];
    updatedParts[partIndex].link = e.target.value;
    setParts(updatedParts);
  };

  const handleVideoTitleChange = (e, partIndex, videoIndex) => {
    const updatedParts = [...parts];
    updatedParts[partIndex].videos[videoIndex].quantity = e.target.value;
    setParts(updatedParts);
  };

  const handleVideoDescriptionChange = (e, partIndex, videoIndex) => {
    const updatedParts = [...parts];
    updatedParts[partIndex].videos[videoIndex].description = e.target.value;
    setParts(updatedParts);
  };

  const handleVideoFileChange = (e, partIndex, videoIndex) => {
    const updatedParts = [...parts];
    updatedParts[partIndex].videos[videoIndex].file = e.target.files[0];
    setParts(updatedParts);
  };

  const handleAddPart = () => {
    const result = PlatformData.find(
      (item) => item.platform === selectedPlatform.value
    );
    const datas = [];

    const service_result = ServiceData.filter(
      (item) => item.platform_id === result.id
    );

    service_result.map((item) => {
      const newRow = { value: item.title, label: item.title };
      datas.push(newRow);
    });

    setserviceoptions(datas);
    setSelectedService(datas[0]);
    console.log(result.id);
    setParts([
      ...parts,
      {
        id: result.id,
        platform: selectedPlatform.value,
        image: result.file,
        link: "",
        videos: [
          {
            title: selectedService.value,
            quantity: "",
            unitaire: service_result[0].price,
          },
        ],
      },
    ]);

    //console.log(parts);
  };

  const handleRemovePart = (partIndex) => {
    const updatedParts = [...parts];
    updatedParts.splice(partIndex, 1);
    setParts(updatedParts);
  };

  const handleAddVideo = (partIndex) => {
    const result = PlatformData.find(
      (item) => item.platform === selectedPlatform.value
    );

    const service_data = ServiceData.filter(
      (item) =>
        item.platform_id === result.id && item.title === selectedService.value
    );

    const service_result = service_data.filter(
      (item) => item.title === selectedService.value
    );
    /*  console.log(ServiceData);
    console.log(service_result);
    console.log(selectedService.value);
    console.log(service_result.price);
*/
    const updatedParts = [...parts];
    updatedParts[partIndex].videos.push({
      title: selectedService.value,
      quantity: "",
      unitaire: service_result[0].price,
    });
    setParts(updatedParts);
    // console.log(parts);
  };

  const handleRemoveVideo = (partIndex, videoIndex) => {
    const updatedParts = [...parts];
    updatedParts[partIndex].videos.splice(videoIndex, 1);
    setParts(updatedParts);
  };

  const handleUpload = () => {
    console.log(parts);
    if (validateForm()) {
      setLoading(true);
      const formData = new FormData();
      formData.append(`user_id`, formDatas.user_id);
      formData.append(`description`, formDatas.description);
      formData.append(`file`, formDatas.file);
      parts.forEach((part, partIndex) => {
        formData.append(`parts[${partIndex}][id]`, part.id);
        formData.append(`parts[${partIndex}][platform]`, part.platform);
        formData.append(`parts[${partIndex}][link]`, part.link);
        part.videos.forEach((video, videoIndex) => {
          console.log(video);
          formData.append(
            `parts[${partIndex}][videos][${videoIndex}][title]`,
            video.title
          );
          formData.append(
            `parts[${partIndex}][videos][${videoIndex}][quantity]`,
            video.quantity
          );
          formData.append(
            `parts[${partIndex}][videos][${videoIndex}][unitaire]`,
            video.unitaire
          );
        });
      });
      Api.post("api/post/addpost", formData)
        .then((response) => {
          // Handle successful upload
          setLoading(false);
          setPage((currPage) => currPage + 1);
          toast.success(response.data.message);
        })
        .catch((error) => {
          // Handle upload error
          toast.error(error.message);
          setLoading(false);
        });
    }
  };

  const validateForm = () => {
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];

      if (!part.link) {
        toast.error(`Please fill the Link For ${part.platform}.`);
        return false;
      }

      if (!part.id) {
        toast.error(`Please fill the Link For ${part.platform}.`);
        return false;
      }

      for (let j = 0; j < part.videos.length; j++) {
        const video = part.videos[j];

        if (!video.quantity) {
          toast.error(
            `Please fill Quantity of ${video.title} in  ${part.platform}.`
          );
          return false;
        }
      }
    }
    return true;
  };
  return (
    <>
      {loading && <LoadingPage />}
      <div className="flex w-full flex-row items-center justify-center">
        <div className="p-2 rounded-full border border-sky-500 text-sky-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-7 h-7"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495"
            />
          </svg>
        </div>
      </div>
      <div className="flex  flex-col">
        <div className="w-full flex items-center justify-center">
          <p className="mb-6 max-w-2xl font-medium text-sky-500 dark:text-gray-400 md:text-lg lg:mb-8 lg:text-xl">
            Which Platform & Targeted Services ?
          </p>
        </div>
        <div class="Services Add">
          <div class="py-3 mb-6 flex flex-row items-center justify-between bg-gray-100 rounded-2xl ">
            <label
              htmlFor="username"
              className="text-left mb-2  ml-3 block font-medium text-sky-500 dark:text-white md:text-lg  lg:text-xl"
            >
              Add Platform
            </label>
            <div class=" flex flex-row items-center">
              <div class="w-56">
                <Select
                  options={options}
                  value={selectedPlatform}
                  onChange={(option) => {
                    setSelectedPlatform(option);
                  }}
                />
              </div>
              <button
                onClick={handleAddPart}
                className="px-auto mx-2 w-20 rounded-xl border-0 bg-sky-500 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none"
              >
                Add
              </button>
            </div>
          </div>
          {parts
            ? parts.map((part, partIndex) => (
                <div
                  key={partIndex}
                  className="border border-gray-300 bg-slate   -50 rounded-2xl px-4 py-2 mb-4"
                >
                  <div className="w-full flex flex-col border border-gray-300 px-3 py-2 rounded-xl items-start mb- justify-between">
                    <div class="flex items-center w-full  justify-between p-3">
                      <div class="flex items-center space-x-2">
                        <button class="rounded-full border-2 border-gray-200 p-1">
                          <img
                            class="h-12 w-12 rounded-full"
                            src={`images/${part.image}`}
                            alt="logo"
                          />
                        </button>
                        <div class="flex items-center justify-center">
                          <h2 class="text-2xl font-semibold text-sky-500">
                            {part.platform}
                          </h2>
                        </div>
                      </div>

                      <button
                        className="bg-sky-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                        onClick={() => handleRemovePart(partIndex)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-trash3-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                        </svg>
                      </button>
                    </div>
                    <div class="flex flex-row w-full items-center space-x-2">
                      <label
                        className="block font-bold mb-2 text-xl font-semibold text-sky-500"
                        htmlFor={`partTitle-${partIndex}`}
                      >
                        Link
                      </label>
                      <input
                        className="border border-gray-300 rounded-lg px-4 py-2 w-full h-10 w-full  focus:outline-none "
                        type="text"
                        id={`partTitle-${partIndex}`}
                        value={part.link}
                        onChange={(e) => handlePartTitleChange(e, partIndex)}
                        placeholder="Paste Your Link Here"
                      />
                    </div>
                  </div>
                  <div class="my-6 p-2 flex flex-row items-center justify-between bg-gray-100 rounded-2xl space-x-3">
                    <label
                      htmlFor="username"
                      className="text-left mb-2  ml-3 block font-medium text-sky-500 dark:text-white md:text-lg  lg:text-xl"
                    >
                      Services
                    </label>
                    <div class=" flex flex-row items-center">
                      <div class="w-56">
                        <Select
                          options={serviceoptions}
                          value={selectedService}
                          onChange={(option) => {
                            setSelectedService(option);
                          }}
                        />
                      </div>
                      <button
                        onClick={() => handleAddVideo(partIndex)}
                        className="px-auto mx-2 w-20 rounded-xl border-0 bg-sky-500 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  {part.videos.map((video, videoIndex) => (
                    <div key={videoIndex} className="mb-4">
                      <fieldset className="border border-gray-400  p-[15px] rounded-xl">
                        <legend
                          align="center"
                          className="text-xl text-sky-500 font-bold mt-[2em] mb-2 text-center"
                        >
                          {video.title}
                        </legend>

                        <div class="flex flex-row w-full mb-2 mt-2 items-center space-x-2">
                          <label
                            className="block font-bold mb-2 text-xl font-semibold text-sky-500"
                            htmlFor={`videoTitle-${partIndex}-${videoIndex}`}
                          >
                            Quantity
                          </label>
                          <input
                            className="border border-gray-300 rounded-lg px-4 py-2 w-full h-10 w-full  focus:outline-none "
                            type="text"
                            id={`videoTitle-${partIndex}-${videoIndex}`}
                            value={video.quantity}
                            onChange={(e) =>
                              handleVideoTitleChange(e, partIndex, videoIndex)
                            }
                            placeholder="Your Quantity Here"
                          />
                          <button
                            title="Remove video"
                            className="bg-sky-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                            onClick={() =>
                              handleRemoveVideo(partIndex, videoIndex)
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-trash3-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                            </svg>
                          </button>
                        </div>
                        <div class="flex flex-row justify-between w-full mb-2 mt-2 items-center space-x-2">
                          <div class="flex flex-row">
                            <label
                              className="block font-bold mb-2 text-lg font-thin text-sky-500"
                              htmlFor={`videoTitle-${partIndex}-${videoIndex}`}
                            >
                              Pay Amount:
                            </label>
                            <label
                              className="block font-bold mb-2 text-lg font-thin text-sky-500"
                              htmlFor={`videoTitle-${partIndex}-${videoIndex}`}
                            >
                              {video.quantity && video.unitaire
                                ? (
                                    video.quantity *
                                    (video.unitaire / 100)
                                  ).toFixed(2)
                                : "0"}{" "}
                              fbu
                            </label>
                          </div>
                          <div class="flex flex-row bg-gray-100 rounded-lg">
                            <span class="font-semibold p-2 leading-6 text-base text-gray-600">
                              100<sub class="ml-1">Qtes</sub> {"="}{" "}
                              {video.unitaire}
                              <sub class="ml-1">fbu</sub>
                            </span>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  ))}
                </div>
              ))
            : null}
          <div className=" flex p-2 justify-between items-center">
            <ToastContainer position="bottom-center" />
          </div>
        </div>

        <div
          align="center"
          className="text-xl flex flex-row justify-between text-sky-500 bg-gray-100 rounded-xl font-bold my-4 p-3 text-center"
        >
          <span>Total Amount</span>
          <span>
            {parts
              ? parts.reduce((sum, item) => {
                  const videoSum = item.videos.reduce((videoSum, video) => {
                    const quantity = Number(video.quantity);
                    const unitaire = Number(video.unitaire);
                    const videoTotal = quantity * (unitaire / 100);
                    return videoSum + videoTotal;
                  }, 0);

                  return (sum + videoSum).toFixed(2);
                }, 0)
              : null}
            <span className="ml-1">Fbu</span>
          </span>
        </div>
        <div className="mb-5 mt-5 flex w-full items-center justify-between">
          <button
            className="px-auto mx-2 w-20 rounded-xl border-0 bg-sky-500 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none"
            onClick={() => {
              setPage((currPage) => currPage - 1);
            }}
          >
            Back
          </button>
          <button
            className="px-auto mx-2 w-20 rounded-xl border-0 bg-sky-500 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none"
            onClick={() => {
              handleUpload();
            }}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default Payment_Info;
