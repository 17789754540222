import React from "react";
import My_HavePostDetail from "../components/My__Haves/PostDetail/My_HavePostDetail";

function My_Needs() {
  return (
    <>
      <My_HavePostDetail />
    </>
  );
}
export default My_Needs;
