import React, { useState, useEffect } from "react";
import Personal_Info from "./Personal_Info";
import Success_Info from "./Success_Info";
import Payment_Info from "./Payment_Info";
export default function CreateNeed(props) {
  const locationString = localStorage.getItem("location");
  const [page, setPage] = useState(0);
  useEffect(() => {
    const getLocationFromLocalStorage = () => {
      const locationString = localStorage.getItem("location");
      if (locationString) {
        const locationplace = JSON.parse(locationString);
        const place = locationplace.Country + " ," + locationplace.State;
        setFormData({
          ...formData,
          location: place,
        });
      }
    };
    getLocationFromLocalStorage();
  }, []);
  const [formData, setFormData] = useState({
    user_id: localStorage.getItem("userid"),
    category_id: "",
    description: "",
    price: "",
    file: "",
    location: "",
  });

  const PageDisplay = () => {
    if (page === 0) {
      return (
        <Personal_Info
          formData={formData}
          setFormData={setFormData}
          setPage={setPage}
        />
      );
    } else if (page === 1) {
      return (
        <Payment_Info
          formDatas={formData}
          setFormDatas={setFormData}
          setPage={setPage}
        />
      );
    } else {
      return (
        <Success_Info
          formData={formData}
          setFormData={setFormData}
          opensignupPopup={props.opensignupPopup}
        />
      );
    }
  };
  return (
    <>
      <div className="All_Post mb-3">
        <div className="mb-4 flex h-auto w-full flex-col rounded-3xl ">
          <div className="  font-light dark:border-gray-700 dark:bg-gray-900">
            <div className="z-0   mr-auto lg:col-span-5 lg:mt-0 flex  ">
              <div className="w-full  rounded-3xl bg-white text-center shadow-lg h-auto sm:text-left  md:w-[600px]">
                <div className="flex max-w-xl flex-col rounded-lg p-8">
                  {PageDisplay()}
                  <div className="flex flex-col">
                    <div className="stepper flex w-full flex-row items-center justify-between space-x-5 px-8">
                      {page === 0 ? (
                        <>
                          <div className="step--1 h-2 w-full rounded-lg bg-sky-500"></div>
                          <div className="step--1 h-2 w-full rounded-lg bg-gray-100"></div>
                          <div className="step--1 h-2 w-full rounded-lg bg-gray-100"></div>
                        </>
                      ) : page == 1 ? (
                        <>
                          <div className="step--1 h-2 w-full rounded-lg bg-sky-500"></div>
                          <div className="step--1 h-2 w-full rounded-lg bg-sky-500"></div>
                          <div className="step--1 h-2 w-full rounded-lg bg-gray-100"></div>
                        </>
                      ) : (
                        <>
                          <div className="step--1 h-2 w-full rounded-lg bg-sky-500"></div>
                          <div className="step--1 h-2 w-full rounded-lg bg-sky-500"></div>
                          <div className="step--1 h-2 w-full rounded-lg bg-sky-500"></div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
