import React, { useState } from "react";
import PayPoup from "./PayPoup";

function ProfileBox() {
  const [popuppay, setPopuppay] = useState(false);
  const handlePopuppay = (pop) => {
    if (pop) setPopuppay(true);
    else setPopuppay(false);
  };
  return (
    <>
      <div className="mb-4 grid grid-cols-2  rounded-3xl mt-4 border-2 border-gray-200 ">
        <div className="row-span-2 flex items-center justify-center rounded-3xl border-r-2 border-gray-200 bg-gradient-to-t from-sky-100 to-sky-0 dark:bg-gray-800">
          <div className="m-2 mt-5 flex w-full flex-col justify-end p-2">
            <div className="image">
              <img
                src="https://source.unsplash.com/150x150/?portrait?3"
                alt=""
                className="w- mx-auto aspect-square rounded-full border-2 border-gray-300 dark:bg-gray-500"
              />
              <div className="w-full flex justify-center items-end ">
                <div className="absolute inline-flex h-8 w-8 items-center justify-center rounded-full border-2 border-white  bg-orange-500 text-xs font-bold text-white dark:border-gray-900">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-3 h-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                    />
                  </svg>
                  P
                </div>
              </div>
            </div>

            <div className="space-y-4 divide-y divide-sky-700 px-3 text-center">
              <div>
                <div className="my-3 space-y-1">
                  <h2 className="text-xl font-semibold text-sky-500">
                    BUK-C BOY
                  </h2>
                  <p className="px-5 text-base text-sky-700 dark:text-gray-400">
                    Singer-Musician
                  </p>
                </div>

                <div className="flex justify-center">
                  <button
                    onClick={() => handlePopuppay(true)}
                    type="button"
                    className="open-payment-popup mb-2 mt-2 flex rounded-full px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                    style={{ backgroundColor: "#1da1f2" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="mr-2 h-5 w-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                      />
                    </svg>
                    message
                  </button>
                </div>

                <div class="mt-6 mb-3 flex justify-between gap-4 md:!gap-14 px-5 text-sky-600">
                  <div class="flex flex-col items-center justify-center">
                    <h3 class="text-bluePrimary text-2xl font-bold">1700</h3>
                    <p class="text-lightSecondary text-sm font-normal">Posts</p>
                  </div>
                  <div class=" border border-sky-500 w-1 h-full"></div>
                  <div class="flex flex-col items-center justify-center">
                    <h3 class="text-bluePrimary text-2xl font-bold">9.7K</h3>
                    <p class="text-lightSecondary text-sm font-normal">
                      Spreads
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4 justify-between items-center h-full ">
          <div className="flex flex-col rounded-3xl mt-8  dark:bg-gray-800">
            <button
              type="button"
              className="flex w-full px-5 items-center justify-between  font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800"
            >
              <span className="w-full  text-left"># Bio</span>
            </button>
            <div className="flex justify-center space-x-4 px-2 pb-2 pt-2">
              <p className="px-5 text-xs text-gray-700 sm:text-base">
                "My name is Alex Drysdale and I am a Junior Web Developer
                htmlFor Oswald Technologies.
              </p>
            </div>
          </div>
          <div className="flex flex-col rounded-3xl mt-8  dark:bg-gray-800">
            <button
              type="button"
              className="flex w-full px-5 items-center justify-between  font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800"
            >
              <span className="w-full text-left"># Details</span>
            </button>
            <div className="flex justify-center space-x-4 px-2 pb-2 pt-2">
              <p className="px-5 text-xs text-gray-700 sm:text-base">
                "My name is Alex Drysdale and I am a Junior Web Developer
                htmlFor Oswald Technologies.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-4 grid grid-cols-2 hidden gap-4 rounded-3xl p-4 shadow-lg">
        <div className="row-span-2 flex items-center justify-center rounded-3xl bg-sky-50 dark:bg-gray-800">
          <div className="m-2 mt-5 flex w-full flex-col justify-end p-2">
            <div className="image">
              <img
                src="https://source.unsplash.com/150x150/?portrait?3"
                alt=""
                className="w- mx-auto aspect-square rounded-full border-2 border-gray-300 dark:bg-gray-500"
              />
              <div className="w-full flex justify-center items-end ">
                <div className="absolute inline-flex h-8 w-8 items-center justify-center rounded-full border-2 border-white  bg-orange-500 text-xs font-bold text-white dark:border-gray-900">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-3 h-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                    />
                  </svg>
                  P
                </div>
              </div>
            </div>

            <div className="space-y-4 divide-y divide-sky-700 px-3 text-center">
              <div>
                <div className="my-3 space-y-1">
                  <h2 className="text-xl font-semibold text-sky-500">
                    BUK-C BOY
                  </h2>
                  <p className="px-5 text-xs text-sky-700 dark:text-gray-400">
                    Singer-Musician
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <div className="my-2 flex flex-row space-y-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-6 w-6 text-gray-700"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                      />
                    </svg>
                    <h2 className="text-sm font-semibold text-sky-700">
                      +258869061028
                    </h2>
                  </div>
                  <div className="my-2 flex flex-row space-y-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-6 w-6 text-gray-700"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                      />
                    </svg>

                    <h2 className="text-sm font-semibold text-sky-700">
                      Rumonge, Burundi
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col rounded-3xl border-2 border-gray-100 dark:bg-gray-800">
            <button
              type="button"
              className="flex w-full items-center justify-between rounded-t-xl border border-b-0 border-gray-200 bg-sky-100 font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800"
            >
              <span className="w-full text-center">About Me</span>
            </button>
            <div className="flex justify-center space-x-4 px-2 pb-2 pt-2">
              <p className="px-5 text-xs text-gray-700 sm:text-base">
                "My name is Alex Drysdale and I am a Junior Web Developer
                htmlFor Oswald Technologies.
              </p>
            </div>
          </div>
          <div className="flex flex-col rounded-3xl border-2 border-gray-100 dark:bg-gray-800">
            <button
              type="button"
              className="flex w-full items-center justify-between rounded-t-xl border border-b-0 border-gray-200 bg-sky-100 font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800"
            >
              <span className="w-full text-center">Bio</span>
            </button>
            <div className="flex justify-center space-x-4 px-2 pb-2 pt-2">
              <p className="px-3 text-base text-gray-700 sm:text-base">
                I appreciate Everything. Help me htmlFor this another one
              </p>
            </div>
          </div>
        </div>
        <div className="to-sky-0 mx-auto w-auto rounded-3xl border-2 border-gray-200 bg-gradient-to-t from-sky-100 dark:bg-gray-900 dark:text-gray-100">
          <div className="mt-4 flex justify-between px-10">
            <div className="flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-12 w-12 text-sky-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                />
              </svg>
              <h1 className="text-sm font-semibold text-gray-600">
                Supporters
              </h1>
            </div>

            <div className="flex flex-col items-center">
              <span className="text-2xl font-bold text-sky-500">1200 sups</span>
              <div className="flex justify-center">
                <button
                  onClick={() => handlePopuppay(true)}
                  type="button"
                  className="open-payment-popup mb-2 mt-2 flex rounded-full px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                  style={{ backgroundColor: "#1da1f2" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="mr-2 h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  Add Post
                </button>
              </div>
            </div>
          </div>
          <div className="mt-4 flex w-full justify-between space-x-4 rounded-2xl bg-sky-200 p-3 dark:text-gray-400">
            <div className="flex flex-col items-center rounded-xl bg-gray-200 px-3 py-2">
              <button className="rounded-full border-2 border-gray-200 p-1">
                <img
                  className="h-12 w-12 rounded-full"
                  src="https://upload.wikimedia.org/wikipedia/commons/b/b8/2021_Facebook_icon.svg"
                />
              </button>
              <div className="text-lg text-gray-700">12%</div>
            </div>
            <div className="flex flex-col items-center rounded-xl bg-gray-200 px-3 py-2">
              <button className="rounded-full border-2 border-gray-200 p-1">
                <img
                  className="h-12 w-12 rounded-full"
                  src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                />
              </button>
              <div className="text-lg text-gray-700">30%</div>
            </div>
            <div className="flex flex-col items-center rounded-xl bg-gray-200 px-3 py-2">
              <button className="rounded-full border-2 border-gray-200 p-1">
                <img
                  className="h-12 w-12 rounded-full"
                  src="https://www.svgrepo.com/show/475689/twitter-color.svg"
                />
              </button>
              <div className="text-lg text-gray-700">35%</div>
            </div>
            <div className="flex flex-col items-center rounded-xl bg-gray-200 px-3 py-2">
              <button className="rounded-full border-2 border-gray-200 p-1">
                <img
                  className="h-12 w-12 rounded-full"
                  src="https://www.svgrepo.com/show/303156/tiktok-icon-white-1-logo.svg"
                />
              </button>
              <div className="text-lg text-gray-700">80%</div>
            </div>
          </div>
        </div>
      </div>
      {popuppay ? <PayPoup handlePopuppay={handlePopuppay} /> : null}
    </>
  );
}
export default ProfileBox;
