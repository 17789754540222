import React from 'react'
import WaitUsers from './WaitUsers'

function WaitListDetail(props) {
  const evt = props.evt
  var date = new Date(evt.date);
  const eDate = date.toString('YYYY-MM-dd').split(' ')
  const eTime = eDate[4].split(':')
  return (
    <>
        <div className="flex h-full w-full flex-col bg-white px-4 py-2">
    <div className="up_bar flex flex-row items-center rounded-2xl py-4 px-6 shadow">
      <div className="flex h-10 w-14 flex-shrink-0 flex-col items-center justify-center rounded-lg bg-sky-500 text-sm font-bold text-white">
        <p>{eDate[1]} {eDate[2]}</p>
        <p>{eDate[3]}</p>
      </div>
      <div className="ml-3 flex flex-col">
        <div className="text-sm font-semibold">{evt.name}</div>
        <div className="text-xs text-gray-500">
          <div className="flex items-center justify-between">
            <div className="flex flex-row items-center justify-center space-x-1">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>

              <div className="pr-2 text-sm text-gray-800">{eTime[0]}h-{eTime[1]}'-{eTime[2]}</div>
            </div>
            <div className="flex flex-row items-center justify-center space-x-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-ubuntu" viewBox="0 0 16 16">
                <path d="M2.273 9.53a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.547Zm9.467-4.984a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.546ZM7.4 13.108a5.535 5.535 0 0 1-3.775-2.88 3.273 3.273 0 0 1-1.944.24 7.4 7.4 0 0 0 5.328 4.465c.53.113 1.072.169 1.614.166a3.253 3.253 0 0 1-.666-1.9 5.639 5.639 0 0 1-.557-.091Zm3.828 2.285a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.546Zm3.163-3.108a7.436 7.436 0 0 0 .373-8.726 3.276 3.276 0 0 1-1.278 1.498 5.573 5.573 0 0 1-.183 5.535 3.26 3.26 0 0 1 1.088 1.693ZM2.098 3.998a3.28 3.28 0 0 1 1.897.486 5.544 5.544 0 0 1 4.464-2.388c.037-.67.277-1.313.69-1.843a7.472 7.472 0 0 0-7.051 3.745Z" />
              </svg>

              <div className="pr-2 text-sm text-gray-800">120k</div>
            </div>
          </div>
        </div>
      </div>
      <div className="ml-auto">
        <ul className="flex flex-row items-center space-x-2">
          <li>
            <a href="#" className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 text-gray-400 hover:bg-gray-200">
              <span>
                <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path>
                </svg>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div className="h-full overflow-hidden py-4">
      <div className="h-full overflow-y-auto">
        <div className="relative h-full overflow-hidden pt-2">
        <WaitUsers />
      </div>
      </div>
    </div>
  </div>
    </>
  )
}
export default WaitListDetail