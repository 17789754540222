import React from "react";
import UserAside from "../AsideBar/UserAside";
import PubsBox from "./PubsBox";
import "./topbar.css";
function NewsComponent() {
  return (
    <>
      <PubsBox />
    </>
  );
}
export default NewsComponent;
