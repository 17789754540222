import React from "react";
import PostInfos from "./PostInfos";
import WhoHave from "./WhoHave";
function WhoHavePostDetail() {
  return (
    <>
      <PostInfos />
    </>
  );
}
export default WhoHavePostDetail;
