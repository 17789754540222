import React from 'react'

function member(props) {
    props = props.sup
  return (
    <>
        <div className="flex w-auto flex-col items-center justify-center rounded-3xl bg-gray-50 p-5">
            <div className="w-auto">
              <img src={props.photo} alt="" className="w-28 h-auto mx-auto aspect-square rounded-full border-2 border-gray-300 dark:bg-gray-500" />
              <div className="space-y-4 divide-y divide-sky-700 px-3 text-center">
                <div>
                  <div className="my-3 space-y-1">
                    <h2 className="text-lg font-semibold text-sky-500">{props.name}</h2>
                    <p className="px-5 text-xl text-sky-700 dark:text-gray-400">{props.Cat}</p>
                  </div>
                </div>

                <div className="flex items-center justify-center space-x-4 rounded-3xl bg-gray-50 pt-2">
                  <button type="button" className="text-white bg-gradient-to-r from-sky-500 to-sky-700  font-medium rounded-2xl text-lg px-5 py-2.5 text-center mr-2 mb-2 opacity-80">Message</button>
                </div>
              </div>
            </div>
          </div>
    </>
  )
}
export default member