import React from 'react'

function Company() {
  return (
    <div className="w-full px-4 sm:w-1/2 lg:w-2/12 shadow-sm rounded-3xl">
        <div className="mb-10 w-full">
          <h4 className="text-dark mb-9 text-lg font-semibold">Our Company</h4>
          <ul>
            <li>
              <a href=""className="text-body-color hover:text-primary mb-2 inline-block text-base text-gray-900 leading-loose"> About ErekaBose </a>
            </li>
            <li>
              <a href=""className="text-body-color hover:text-primary mb-2 inline-block text-base text-gray-900 leading-loose"> Contact Us </a>
            </li>
            <li>
              <a href=""className="text-body-color hover:text-primary mb-2 inline-block text-base text-gray-900 leading-loose"> Advertise Your Product </a>
            </li>
            <li>
              <a href=""className="text-body-color hover:text-primary mb-2 inline-block text-base text-gray-900 leading-loose"> Setting & Privacy </a>
            </li>
          </ul>
        </div>
      </div>
  )
}
export default Company