import React, {useContext} from 'react'
import {SupsContext} from '../../context'
import Suppoter from '../Users/Suppoter'

function MySups() {
  const sups = useContext(SupsContext)
  return (
    <>
      <div className="Supported mb-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
  <button type="button" className="flex w-full items-center justify-between rounded-t-xl border border-b-0 border-gray-200 bg-sky-100 p-5 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800">
    <span>My Supporters</span>
  </button>
  <div>
    <div className="border border-b-0 border-gray-200 p-5 font-light dark:border-gray-700 dark:bg-gray-900">
      <div className="flex w-full flex-col space-y-4">
        <div className="grid grid-cols-3 gap-4">
        { sups.sups?.map(sup=>{
            return  <Suppoter key={sup.id} sup= {sup} />
          })
          }
           
        </div>
      </div>
    </div>
  </div>
</div>
    </>
  )
}
export default MySups