import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Api from "../Api/Api";
import LoadingPage from "../LoadingPage.js/LoadingPage";
import { GlobalFunctionContext } from "../../context";
import { Checkbox, Label } from "flowbite-react";
import { ADD } from "../../Redux/Action/Action";
import { useDispatch,useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";


const validationSchema = Yup.object().shape({
  message: Yup.string().required("Please Enter your message"),
});

const initialValues = {
  message: "",
};

export default function AddtoCartPop(props) {
   
  const getData = useSelector((state) => state.CartsReducer.Carts);
  console.log(getData)
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [checkedAdditionalPrices, setCheckedAdditionalPrices] = useState([]);

  const handleRadioChange = (pricing) => {
    setSelectedRadio(pricing);
  };

  const handleCheckboxChange = (question) => {
    if (checkedAdditionalPrices.includes(question)) {
      setCheckedAdditionalPrices(checkedAdditionalPrices.filter((item) => item !== question));
    } else {
      setCheckedAdditionalPrices([...checkedAdditionalPrices, question]);
    }
  };

  const calculateTotalPrice = () => {
    const selectedRadioPrice = parseFloat(selectedRadio?.suggested_price || '0');
    const totalAdditionalPrice = checkedAdditionalPrices.reduce((total, question) => {
      const additionalPrice = parseFloat(question.additional_price || '0');
      return total + additionalPrice;
    }, 0);

    return selectedRadioPrice + totalAdditionalPrice;
  };
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Recommandations,setRecommandation]=useState("");
  const [Password_show, setPasswordShow] = useState(false);
  const [Quantity, SetQuantity] = useState(1);
  const [Error, SetError] = useState(null);
  const Global = useContext(GlobalFunctionContext);
  console.log(Global.ProductstoCart)

  const handleClose = () => {
    toast.dismiss();
  };

  const handleRecomandation=(event)=>{
    setRecommandation(event.target.value)
  }

  const onSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    const data = {
      user_id: localStorage.getItem("userid"),
      message: values.message,
    };
    Api.post("api/addreport", data)
      .then((response) => {
        toast.success("Report Sended", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClick: handleClose, // Utilisation de la référence à la fonction handleClose
        });
        setLoading(false);
        setTimeout(function () {
          Global.setReportPop(false);
        }, 2000);
        console.log(response.data);
      })
      .catch((error) => {
        // Handle errors
        if (error.response) {
          setLoading(false);
          // Le serveur a renvoyé une réponse avec un code d'erreur
          const errorMessage = error.response.data.error;
          SetError(errorMessage);
          toast.error(errorMessage, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            onClick: handleClose, // Utilisation de la référence à la fonction handleClose
          }); // Affiche le message d'erreur dans le toast
        } else if (error.request) {
          // La requête a été faite mais aucune réponse n'a été reçue
          console.log(error.request);
        } else {
          // Une erreur s'est produite lors de la configuration de la requête
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });

  };

  const dispatch = useDispatch();

  function send(e, qty) {
    dispatch(ADD(e, qty));
    Global.setAddtoCartPop(false, {});
    toast.success(
      ` ${e.product.item_name} Ajouté  au panier`,
      {
        position: "top-center",
        autoClose:1000,
      }
    );
  }

  function AddtoCarts(product_order)
  {
      console.log(product_order)
      send(product_order, Quantity)
  }

  return (
    <>
      {loading && <LoadingPage />}
      <div
        className="login-popup fixed inset-0 z-40 flex flex-col items-center justify-center"
        style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
      >
        <div className="relative -mb-5 flex w-[480px]  md:w-[640px] items-end justify-end">
          <div
            onClick={() => {
              Global.setAddtoCartPop(false, {});
            }}
            className="close-login-popup h-8 w-8 rounded-full bg-sky-500 opacity-80 hover:bg-sky-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-8 w-8 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </div>
        </div>
        <div className="p-5 w-[480px] h-[450px] overflow-y-scroll md:w-[640px]  flex-row items-center justify-center rounded-3xl bg-white text-center shadow-lg sm:text-left">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <div class="flex  max-w-2xl flex-col rounded-lg  p-6">
                  <div class="w-full flex justify-center items-center">
                    <div class="border  border-sky-500 p-2 m-2 rounded-full">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-9 h-9 text-sky-500">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                      </svg>
                    </div>
                  </div>

                  <h2 class=" mb-3 w-full text-center text-2xl font-light text-sky-600">
                    Add to Cart
                  </h2>
                  <p class="text-center mb-2 text-gray-200 font-medium ">{Global.ProductstoCart.item_name}</p>
                  <div class="mt-1 mb-2 flex flex-row justify-between items-center rounded-xl bg-gray-200 p-2">
                    <h3 class="text-sm ml-1 text-gray-500 text-xl font-medium  mb-1">Quantité</h3>
                    <div class="flex border border-gray-300 text-gray-600 divide-x divide-gray-300 w-max">
                      <div
                        class="h-8 w-8 bg-white text-xl flex items-center justify-center cursor-pointer select-none"
                        onClick={() => {
                          if (Quantity > 0) {
                            SetQuantity(Quantity - 1);
                          }
                        }}
                      >
                        -
                      </div>
                      <div class="h-8 w-8 bg-white text-base flex items-center justify-center">
                        {Quantity}
                      </div>
                      <div
                        onClick={() => {
                          SetQuantity(Quantity + 1);
                        }}
                        class="h-8 w-8 bg-white text-xl flex items-center justify-center cursor-pointer select-none"
                      >
                        +
                      </div>
                    </div>

                  </div>

                  {
                    Global.ProductstoCart &&
                    <>
                      {Global.ProductstoCart?.Multiple_pricings.length>0 && <p class="font-medium text-left my-4 text-xl text-gray-500">Voulez-Vous ?</p>}
                      <ul class="w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                        {Global.ProductstoCart?.Multiple_pricings?.map((Pricings, index) => {
                          if (index == 0) {
                            return (
                              <>
                                <li key={`pricing-${index}`} class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                                  <div class="flex items-center ps-3">
                                    <input id={`radio-${index}`} type="radio" value="" checked={selectedRadio === Pricings}
                                      onChange={() => handleRadioChange(Pricings)} name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                    <label for="list-radio-license" class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">   <div class="w-full flex flex-row justify-between items-center">
                                      <label for="vue-checkbox" class="  py-1 ms-2 text-sm font-medium text-sky-900 dark:text-gray-300">{Pricings.condition_text} </label>
                                      <label for="vue-checkbox" class="  py-1 mr-4 text-sm font-bold text-sky-900 dark:text-gray-300">{Pricings.suggested_price} Fbu</label>
                                    </div> </label>
                                  </div>
                                </li>
                              </>
                            );
                          }
                          else {
                            return (
                              <>
                                <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                                  <div class="flex items-center ps-3">
                                    <input id={`radio-${index}`} type="radio" value="" checked={selectedRadio === Pricings}
                                      onChange={() => handleRadioChange(Pricings)} name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />  <label for="list-radio-license" class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">   <div class="w-full flex flex-row justify-between items-center">
                                        <label for="vue-checkbox" class="  py-1 ms-2 text-sm font-medium text-sky-900 dark:text-gray-300">{Pricings.condition_text} </label>
                                        <label for="vue-checkbox" class="  py-1 mr-4 text-sm font-bold text-sky-900 dark:text-gray-300">{Pricings.suggested_price} Fbu</label>
                                      </div> </label>
                                  </div>
                                </li>
                              </>
                            );
                          }
                        })}

                      </ul>

                      {Global.ProductstoCart.Product_questions.length>0 && <p class="font-medium text-left text-xl my-4 text-gray-500">Personnalisez votre commande ?</p>}
                      <ul class="w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                        {Global.ProductstoCart?.Product_questions?.map((Questions, index) => {
                          return (
                            <>
                              <li key={`question-${index}`} class="w-full border-b border-gray-200 rounded-lg dark:border-gray-600">
                                <div class="flex items-center ps-3">
                                  <input id={`checkbox-${index}`} checked={checkedAdditionalPrices.includes(Questions)}
                                    onChange={() => handleCheckboxChange(Questions)} type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                  <div class="w-full flex flex-row justify-between items-center">
                                    <label for="vue-checkbox" class="  py-3 ms-2 text-sm font-medium text-sky-900 dark:text-gray-300">{Questions.question_text} </label>
                                    <label for="vue-checkbox" class="  py-3 mr-4 text-sm font-bold text-sky-900 dark:text-gray-300">{Questions.additional_price} Fbu</label>

                                  </div>
                                </div>
                              </li>
                            </>
                          );
                        })}

                      </ul>

                    </>
                  }
                  <div class="mb-4 mt-2">
                    <p class="font-medium text-left my-4 text-gray-500">Vos Recommandations</p>
                    <textarea
                      id="message"
                      rows="2"
                      name="message"
                      class="h-24 w-full resize-none text-sm  rounded border border-gray-300 bg-white py-1 px-3  leading-6 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-sky-500 focus:ring-2 focus:ring-sky-200"
                      placeholder="Write Your Additionnal Recommandation Here.."
                      value={Recommandations}
                      onChange={handleRecomandation}
                    />
                    <ErrorMessage
                      name="message"
                      component="div"
                      className="text-left mt-2 text-red-500 text-xs"
                    />
                  </div>


                  <p class="mt-3 text-xs text-gray-500">
                    Feel free to Add Your own Recommandation.
                  </p>
                </div>
              </Form>
            )}
          </Formik>


        </div>
        <div className="relative -mt-14 flex w-[480px] bg-white shadow-lg rounded-b-3xl  md:w-[640px] items-center justify-center border-top border h-20">
          <div
            onClick={() => {
              let product=Global.ProductstoCart;
              // product.Group_category=""
              // product.Multiple_pricings=""
              // product.Product_questions=""
              if(Global.ProductstoCart?.Multiple_pricings.length>0 ||
                Global.ProductstoCart?.Product_questions.length>0)
                {
                  AddtoCarts({product,selectedRadio,checkedAdditionalPrices,Recommandations,unique_id:uuidv4()});
                }
                else
                {
                  AddtoCarts({product,selectedRadio:[],checkedAdditionalPrices:[],Recommandations,unique_id:uuidv4()});
                }
            }}
            className="close-login-popup h-14 w-auto rounded-xl bg-sky-500  hover:bg-sky-700 px-3 "
          >
            <div class="w-full flex flex-row justify-between items-center">
              <label for="vue-checkbox" class="  py-3 ms-2 text-sm font-extrabold text-lg text-white dark:text-gray-300">{Global.ProductstoCart?.Multiple_pricings.length>0 ||
                Global.ProductstoCart.Product_questions.length>0 ? <>{(calculateTotalPrice().toFixed(2) * Quantity).toFixed(2)}</> :<>{(Global.ProductstoCart.price * Quantity).toFixed(2)}</>} Fbu </label>
              <label for="vue-checkbox" class="py-3 mr-4 ml-1 pl-2 border-l border-gray-400 text-sm font-bold text-white text-lg dark:text-gray-300">Ajouter au Commande</label>
            </div>
          </div>

        </div>
      </div>
      
    </>
  );
}
