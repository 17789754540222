import React from "react";
function Success_Info({ formData, setFormData, opensignupPopup }) {
  return (
    <>
      <div className="Success flex  flex-col">
        <div className="to-sky-20 flex h-48 w-full flex-col items-center rounded-3xl border border-sky-200 bg-gradient-to-r from-sky-50 py-6 md:w-[400px]">
          <h1 className="mb-4 text-xl font-extrabold text-gray-900">
            <span className="bg-gradient-to-r from-sky-400 to-emerald-600 bg-clip-text text-transparent">
              Welcome !!
            </span>
          </h1>
          <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
            Dear,<span className="font-bold">{formData.username}</span>
          </p>
          <p className="inline-block px-5 pt-2 text-sm font-light text-gray-500 dark:text-gray-400">
            Your Account have been created succesfully.
          </p>
          <p className="text-sm font-light text-gray-500 dark:text-gray-400">
            'Start Growing and Earn With EREKABOSE'
          </p>
        </div>
        <div className="mb-5 mt-5 flex w-full items-center justify-center">
          <button
            className="w-1/2 rounded-xl border-0 bg-sky-500 px-6 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none"
            onClick={() => opensignupPopup(false)}
          >
            Start
          </button>
        </div>
      </div>
    </>
  );
}

export default Success_Info;
