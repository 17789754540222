import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Api from "../Api/Api";
import axios from "axios";
import LoadingPage from "../LoadingPage.js/LoadingPage";
import { GlobalFunctionContext } from "../../context";
import Select from "react-select";
import countries from "./countriesData";
const validationSchema = Yup.object().shape({
  Country: Yup.string()
    .min(1, "Please select at least one Country")
    .required("Please select at least one Country"),
  State: Yup.string()
    .min(1, "Please select at least one State")
    .required("Please select at least one State"),
});

const initialValues = {
  Country: "",
  State: "",
};
export default function LocationPopUp(props) {
  const Global = useContext(GlobalFunctionContext);
  const [loading, setLoading] = useState(false);
  const [showmore, SetShow] = useState(false);
  const TimeAgo = (dateString) => {
    const currentDate = new Date();
    const pastDate = new Date(dateString);

    const timeDifference = currentDate.getTime() - pastDate.getTime();

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return years === 1 ? "1 year ago" : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? "1 month ago" : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1 min ago" : `${minutes} min ago`;
    } else {
      return seconds === 1 ? "Now" : "Now";
    }
  };
  const [Countryoptions, setCountryoptions] = useState([{}]);
  const [Stateoptions, setStateoptions] = useState([{}]);
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  const [selectedStateOption, setSelectedStateOption] = useState(null);
  const history = useNavigate();
  const [Password_show, setPasswordShow] = useState(false);
  const [Error, SetError] = useState(null);
  const handleClose = () => {
    toast.dismiss();
  };
  useEffect(() => {
    const fetchData = () => {
      const data = countries.map((country) => ({
        value: country.name,
        label: country.name,
      }));
      setCountryoptions(data);
    };

    fetchData();
  }, []);

  const fetchStateData = (name) => {
    const country = countries.find((country) => country.name === name);

    if (country) {
      const stateOptions = country.states.map((state) => ({
        value: state.name,
        label: state.name,
      }));

      setStateoptions(stateOptions);
    } else {
      setStateoptions([]);
    }
  };

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const user_id = localStorage.getItem("userid");
    Global.setLocation({
      Country: values.Country,
      State: values.State,
      user_id: user_id,
    });
    const location = {
      Country: values.Country,
      State: values.State,
      user_id: user_id,
    };
    localStorage.setItem("location", JSON.stringify(location));
    setSubmitting(false);
    Global.setLocationPop(false);
  };
  return (
    <>
      {loading && <LoadingPage />}
      <div
        className="login-popup fixed inset-0 z-40 flex flex-col items-center justify-center"
        style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
      >
        <div className="relative -mb-5 flex w-[320px] md:w-[600px] items-end justify-end">
          <div
            onClick={() => Global.setLocationPop(false)}
            className="close-login-popup hidden h-8 w-8 rounded-full bg-sky-500 opacity-80 hover:bg-sky-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-8 w-8 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </div>
        </div>
        <div className="flex  w-[320px] h-auto md:w-[600px]  flex-row items-center justify-center rounded-3xl bg-white text-center shadow-lg sm:text-left">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <div className="flex w-[350px] max-w-xl flex-col rounded-lg p-8">
                  <div className="flex w-full items-center justify-center">
                    <img
                      src="./images/Logo.png"
                      className="h-12 w-12 scale-110"
                      alt="Logos"
                    />
                  </div>

                  <h2 className="mb-10 w-full text-center text-2xl font-light text-sky-600">
                    Select Your Location Address
                  </h2>
                  <div className="mb-4">
                    <div className="mx-auto w-full">
                      <div class="mb-4">
                        <label
                          htmlFor="username"
                          className="text-left mb-2 ml-3 block font-medium text-gray-700 dark:text-white"
                        >
                          Select Country
                        </label>
                        <Select
                          options={Countryoptions}
                          value={selectedCountryOption}
                          onChange={(option) => {
                            setSelectedCountryOption(option);
                            setFieldValue("Country", option.value);
                            fetchStateData(option.value);
                          }}
                        />
                        <ErrorMessage
                          name="Country"
                          component="div"
                          className="text-left mt-2 text-red-500 text-xs"
                        />
                      </div>
                      <div class="mb-4">
                        <label
                          htmlFor="username"
                          className="text-left mb-2 ml-3 block font-medium text-gray-700 dark:text-white"
                        >
                          Select State
                        </label>
                        <Select
                          options={Stateoptions}
                          value={selectedStateOption}
                          onChange={(option) => {
                            setSelectedStateOption(option);
                            setFieldValue("State", option.value);
                          }}
                        />
                        <ErrorMessage
                          name="State"
                          component="div"
                          className="text-left mt-2 text-red-500 text-xs"
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className="rounded-xl border-0 bg-sky-500 px-6 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Continue ..." : "Continue"}
                  </button>
                  <button
                    onClick={() => Global.setLocationPop(false)}
                    className="rounded-xl hidden border-0 mt-4 bg-gray-300 px-6 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none"
                  >
                    Continue Sans Lieu
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
