import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import Api from "../../Api/Api";
import LoadingPage from "../../LoadingPage.js/LoadingPage";
import { useParams } from "react-router-dom";
import { PostContext } from "../../../context";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import { PostTabContext } from "../../../context";
import "./tab.css";
import "./Success.css";

const validationSchema = Yup.object().shape({
  file: Yup.mixed().required("Image is required"),
});

const handleClose = () => {
  toast.dismiss();
};
const initialValues = {
  file: "",
};

function Payment() {
  const [loading, setLoading] = useState(false);
  const [photo, SetPhoto] = useState(null);
  const { id } = useParams();
  var post_id = id;
  const [Datas, setDatas] = useState({
    post_id: post_id,
  });

  const onSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    const data = {
      post_id: Datas.post_id,
      file: values.file,
    };
    try {
      const formData = new FormData();
      formData.append("post_id", Datas.post_id);
      formData.append("file", values.file);
      const response = await Api.post("/api/postproof", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(response.data);

      const handleClose = () => {
        toast.dismiss();
      };
      // Afficher le message de succès dans un toast
      toast.success("Sended Succesfull", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClick: handleClose, // Utilisation de la référence à la fonction handleClose
      });
      SetPhoto(null);
    } catch (error) {
      console.error(error);
      const handleClose = () => {
        toast.dismiss();
      };
      if (error.response) {
        // Le serveur a renvoyé une réponse avec un code d'erreur
        const errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClick: handleClose, // Utilisation de la référence à la fonction handleClose
        }); // Afficher le message d'erreur du serveur dans un toast
      }
    }

    setSubmitting(false);
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    setFieldValue("file", file);
    const Url = URL.createObjectURL(file);
    SetPhoto(Url);
  };

  return (
    <>
      <div className="my-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
        <button
          type="button"
          className="flex w-full items-center justify-between rounded-t-xl border border-b-0 border-gray-200 bg-sky-50 p-5 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800"
        >
          <span className="text-xl">Payment Methods</span>
        </button>
        <div>
          <div className="border border-b-0 hidden border-gray-200 font-light dark:border-gray-700 dark:bg-gray-900">
            <div className="grid w-full grid-cols-2 gap-4 px-4 md:grid-cols-2">
              <div className="item-row ml-5">
                <div
                  className="my-4 flex h-4/5 flex-col items-center justify-center rounded-lg bg-sky-50 px-1 py-4 text-sm text-sky-800 dark:bg-gray-800 dark:text-sky-400"
                  role="alert"
                >
                  <div className="icon mt-3 flex flex-row">
                    <svg
                      aria-hidden="true"
                      className="mb-3 mr-1 inline h-6 w-6 flex-shrink-0"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="font-thin text-base">PAYMENT WAY</span>
                  </div>
                  <div className="Notification ">
                    <ul className="list-disc font-medium">
                      <li>
                        Send 22,000/=BIF to <br></br>this number +25762393507
                      </li>
                      <li>Izina : WILONDJA Rahma</li>
                      <li>LUMICASH</li>
                    </ul>
                  </div>
                  <div className="Notification m-1 rounded-2xl bg-gray-50 border-sky-400 border shadow-sm justify-between p-2">
                    After paying send us your Transaction screenshot alongside
                    with your username,email,phone number and your sim card
                    names on this number on WhatsApp +255628334168.
                  </div>
                </div>
              </div>
              <div className="item-row">
                <div className="extraOutline bg-whtie m-auto w-max rounded-lg bg-white p-4">
                  <div className="file_upload relative rounded-lg border-2 border-dotted border-gray-300 p-5 ">
                    <svg
                      className="mx-auto mb-4 w-24 text-sky-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      ></path>
                    </svg>

                    <div className="input_field mx-auto flex w-max flex-col text-center">
                      <label>
                        <input
                          className="hidden w-36 cursor-pointer text-sm"
                          type="file"
                          multiple=""
                        />
                        <div className="rounded-xl border-0 bg-sky-500 px-6 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none">
                          Select
                        </div>
                      </label>

                      <div className="title uppercase text-sky-500">
                        or drop files here
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full items-center justify-start rounded-xl  px-2 py-1">
                    <span className="text-sky-600 mr-1 flex items-center justify-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-4 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                        ></path>
                      </svg>
                    </span>
                    <p className="text-center text-sm text-sky-500">
                      Transaction Screenshots Upload
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-3 my-12 px-6 gap-5">
            <div className="flex  flex-col md:space-x-3 shadow border bg-white px-2 py-4 rounded-2xl">
              <div className="flex items-center justify-center">
                <div className="relative h-20 flex items-center justify-center  p-3  rounded-2xl">
                  <img
                    className="h-16 w-16 rounded-xl"
                    src="../../../images/2.png"
                  />
                </div>
              </div>
              <div className="pt-2">
                <div className="flex flex-col items-start">
                  <h1 className="text-gray-800 text-sm font-semibold">
                    Name :
                  </h1>
                  <p className="text-sky-800 font-semibold text-sm">
                    NDACAYISABA Elyse
                  </p>
                </div>
                <hr className="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700   " />
                <div className="flex flex-col items-start">
                  <h1 className="text-gray-800 text-sm font-semibold">Code:</h1>
                  <p className="text-sky-800 font-semibold text-sm">50877</p>
                </div>
                <hr className="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700   " />
              </div>
            </div>
            <div className="flex  flex-col md:space-x-3 shadow border bg-white px-2 py-4 rounded-2xl">
              <div className="flex items-center justify-center">
                <div className="relative h-20 flex items-center justify-center  p-3  rounded-2xl">
                  <img
                    className="h-16 w-16 rounded-xl"
                    src="../../../images/binance.png"
                  />
                </div>
              </div>
              <div className="pt-2">
                <div className="flex flex-col items-start">
                  <h1 className="text-gray-800 text-sm font-semibold">
                    Name :
                  </h1>
                  <p className="text-sky-800 font-semibold text-sm">
                    NDACAYISABA Elyse
                  </p>
                </div>
                <hr className="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700   " />
                <div className="flex flex-col items-start">
                  <h1 className="text-gray-800 text-sm font-semibold">Code:</h1>
                  <p className="text-sky-800 font-semibold text-sm">50877</p>
                </div>
                <hr className="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700   " />
              </div>
            </div>
            <div className="flex  flex-col md:space-x-3 shadow border bg-white px-2 py-4 rounded-2xl">
              <div className="flex items-center justify-center">
                <div className="relative h-20 flex items-center justify-center  p-3  rounded-2xl">
                  <img
                    className="h-16 w-16 rounded-xl"
                    src="../../../images/EcoCash.png"
                  />
                </div>
              </div>
              <div className="pt-2">
                <div className="flex flex-col items-start">
                  <h1 className="text-gray-800 text-sm font-semibold">
                    Name :
                  </h1>
                  <p className="text-sky-800 font-semibold text-sm">
                    NDACAYISABA Elyse
                  </p>
                </div>
                <hr className="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700   " />
                <div className="flex flex-col items-start">
                  <h1 className="text-gray-800 text-sm font-semibold">Code:</h1>
                  <p className="text-sky-800 font-semibold text-sm">50877</p>
                </div>
                <hr className="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700   " />
              </div>
            </div>
            <div className="flex  flex-col md:space-x-3 shadow border bg-white px-2 py-4 rounded-2xl">
              <div className="flex items-center justify-center">
                <div className="relative h-20 flex items-center justify-center  p-3  rounded-2xl">
                  <img
                    className="h-16 w-16 rounded-xl"
                    src="../../../images/1.png"
                  />
                </div>
              </div>
              <div className="pt-2">
                <div className="flex flex-col items-start">
                  <h1 className="text-gray-800 text-sm font-semibold">
                    Name :
                  </h1>
                  <p className="text-sky-800 font-semibold text-sm">
                    NDACAYISABA Elyse
                  </p>
                </div>
                <hr className="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700   " />
                <div className="flex flex-col items-start">
                  <h1 className="text-gray-800 text-sm font-semibold">Code:</h1>
                  <p className="text-sky-800 font-semibold text-sm">50877</p>
                </div>
                <hr className="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700   " />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
        <button
          type="button"
          className="flex w-full items-center justify-between rounded-t-xl border border-b-0 border-gray-200 bg-sky-50 p-5 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800"
        >
          <span className="text-xl">Payment Proof Send</span>
        </button>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <div className="flex items-center justify-center mt-4">
                  {" "}
                  <p className="text-gray-500 my-5 text-center">
                    Send The Require Amount To Activate Your Post.
                    <br />
                    After paying send us your Transaction screenshot
                    <br /> As Soon as Our Verification Finished,
                    <br /> Your Post Will Be Activate.
                    <br />
                    Our Payment Is safe,Trusted
                  </p>
                </div>

                <div className="">
                  <div class=" flex w-[px] items-center justify-center  ">
                    <div
                      className={`group mt-4  w-48 relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md
                                              text-white bg-sky-600
                                             focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500`}
                    >
                      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M7.5 3.75H6A2.25 2.25 0 003.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0120.25 6v1.5m0 9V18A2.25 2.25 0 0118 20.25h-1.5m-9 0H6A2.25 2.25 0 013.75 18v-1.5M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      </span>
                      Upload Image
                    </div>
                    <input
                      type="file"
                      name="file"
                      accept="image/*"
                      class="absolute w-32 overflow-hidden px-4 opacity-0"
                      onChange={(event) =>
                        handleFileChange(event, setFieldValue)
                      }
                    />
                  </div>
                </div>
                <ErrorMessage
                  name="file"
                  component="div"
                  className="text-left mt-2 text-red-500 text-sm"
                />

                {photo && (
                  <div class="w-full flex items-center justify-center">
                    <div className="w-[15em] h-[15em] mt-8 overflow-hidden">
                      <img
                        src={photo}
                        alt=" "
                        className="w-full h-full border border-sky-300 object-cover rounded-2xl"
                      />
                    </div>
                  </div>
                )}

                <div className="mb-5 mt-5 flex w-full items-center justify-center">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-auto rounded-xl border-0 bg-sky-500 px-6 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none"
                  >
                    {isSubmitting ? "Send..." : "Send"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <ToastContainer position="bottom-center" />
    </>
  );
}

export default Payment;
