import React from "react";

import AllTvOnlineComponent from "../components/Buy/AllTvOnlineComponent";
function AllTvOnline() {
  return (
    <>
      <AllTvOnlineComponent />
    </>
  );
}
export default AllTvOnline;
