import React from "react";
import My_NeedPostDetail from "../components/My_Needs/PostDetail/My_NeedPostDetail";

function My_Needs() {
  return (
    <>
      <My_NeedPostDetail />
    </>
  );
}
export default My_Needs;
