import React, {useContext} from 'react'
import {WaitUContext} from '../../context'
import WaitUser from './WaitUser'
export default function WaitUsers() {
    const wts = useContext(WaitUContext)
  return (
    <>
        <div className="-mx-3 h-full flex-col items-start grid grid-cols-1 gap-7 divide-y overflow-y-auto overflow-x-hidden">
          {wts.wts?.map(wt=>{
            return <WaitUser key={wt.id} wt= {wt} />
          })}
        </div>
    </>
  )
}
