import React from "react";
import PostDetail from "../components/MyPosts/PostDetail/PostDetail";

function MyPostPage() {
  return (
    <>
      <PostDetail />
    </>
  );
}
export default MyPostPage;
