import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import Api from "../../Api/Api";
import LoadingPage from "../../LoadingPage.js/LoadingPage";
import { useParams } from "react-router-dom";
import { PostContext } from "../../../context";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import { PostTabContext } from "../../../context";
import "./tab.css";
import "./Success.css";

const validationSchema = Yup.object().shape({
  Service: Yup.string()
    .min(1, "Please select at least one Service")
    .required("Please select at least one Service"),
  file: Yup.mixed().required("Image is required"),
});

const handleClose = () => {
  toast.dismiss();
};
const initialValues = {
  Service: "",
  file: "",
};

function PostToShare() {
  const [loading, setLoading] = useState(false);
  const [photo, SetPhoto] = useState(null);
  const { id } = useParams();
  const PostTab = useContext(PostTabContext);
  const swiper = useSwiper();
  const [Actual, SetActual] = useState(1);
  const [isCopied, setIsCopied] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const userid = localStorage.getItem("userid");
  const [Datas, setDatas] = useState({
    post_id: null,
    user_id: null,
    platform_id: null,
  });

  const ToggleTab = (index) => {
    SetActual(index);
    getAllInterest(index);
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    const data = {
      post_id: Datas.post_id,
      user_id: Datas.user_id,
      platform_id: Datas.platform_id,
      service: values.Service,
      file: values.file,
    };
    try {
      const formData = new FormData();
      formData.append("post_id", Datas.post_id);
      formData.append("user_id", Datas.user_id);
      formData.append("platform_id", Datas.platform_id);
      formData.append("service", values.Service);
      formData.append("file", values.file);

      const response = await Api.post("/api/sharingproof", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(response.data);

      const handleClose = () => {
        toast.dismiss();
      };
      // Afficher le message de succès dans un toast
      toast.success("Sended Succesfull", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClick: handleClose, // Utilisation de la référence à la fonction handleClose
      });
      SetPhoto(null);
      setIsSent(true);
      setTimeout(() => setIsSent(false), 2000); // Reset the message after 2 seconds
    } catch (error) {
      console.error(error);
      const handleClose = () => {
        toast.dismiss();
      };
      if (error.response) {
        // Le serveur a renvoyé une réponse avec un code d'erreur
        const errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClick: handleClose, // Utilisation de la référence à la fonction handleClose
        }); // Afficher le message d'erreur du serveur dans un toast
      }
    }

    setSubmitting(false);
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    setFieldValue("file", file);
    const Url = URL.createObjectURL(file);
    SetPhoto(Url);
  };

  useEffect(() => {
    PostTab.handleLinks(id);
  }, []);

  useEffect(() => {
    SetActual(PostTab.TabsData ? PostTab.TabsData[0].platform_id : 0);
    getAllInterest(PostTab.TabsData ? PostTab.TabsData[0].platform_id : 0);
    setDatas((prevState) => ({
      ...prevState,
      post_id: PostTab.TabsData ? PostTab.TabsData[0].post_id : 0,
      platform_id: PostTab.TabsData ? PostTab.TabsData[0].platform_id : 0,
      user_id: userid,
    }));
  }, [PostTab]);

  const copyInputValue = (inputValue) => {
    navigator.clipboard
      .writeText(inputValue)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset the message after 2 seconds
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  const [options, setoptions] = useState([{}]);
  const getAllInterest = (id) => {
    Api.get(`/api/platform_services/${id}`).then((response) => {
      {
        const datas = [];
        response.data.map((item) => {
          const newRow = { value: item.title, label: item.title };
          datas.push(newRow);
        });
        setoptions(datas);
      }
    });
    //console.log(options);
  };

  const [value, setValue] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const handleChange = (option) => {
    setSelectedOption(option);
  };
  return (
    <>
      {PostTab.TabsData ? (
        <div className="Post">
          <div
            type="button"
            className="flex w-full items-center justify-between rounded-t-xl p-1 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800"
          >
            <span>Post to Share</span>
          </div>
          <div className=" mb-4  flex h-auto w-full flex-col rounded-3xl ">
            <div className="flex w-full items-center shadow-lg justify-center rounded-t-xl border  border-gray-200   font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800">
              <section className="bg-white w-[615px] dark:bg-gray-900">
                <div className="mx-auto overflow-hidden max-w-screen-xl   lg:py-3">
                  <div className="navigation_items w-[615px] absolute  z-0 opacity-50 hover:opacity-100 hover:duration-200  flex items-center justify-between ">
                    <button
                      onClick={() => swiper.slidePrev()}
                      className="swiper-prev bg-gray-100 rounded-r-lg h-16 p-1 flex items-center justify-center swiper-button-disabled"
                      tabIndex="-1"
                      role="button"
                      aria-label="Previous slide"
                      aria-controls="swiper-wrapper-66d7ef5548866805"
                      aria-disabled="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6 text-gray-600"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 19.5L8.25 12l7.5-7.5"
                        ></path>
                      </svg>
                    </button>
                    <button
                      onClick={() => swiper.slideNext()}
                      className="swiper-next bg-gray-100 rounded-l-lg h-16 p-1 flex items-center justify-center"
                      tabIndex="0"
                      role="button"
                      aria-label="Next slide"
                      aria-controls="swiper-wrapper-66d7ef5548866805"
                      aria-disabled="false"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6 text-gray-600"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M8.25 4.5l7.5 7.5-7.5 7.5"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div className=" z-20 mx-10 overflow-hidden w-auto h-auto">
                    <Swiper
                      spaceBetween={13}
                      slidesPerView={4}
                      className="tab-btns w-auto h-auto "
                      id="swiper-wrapper-66d7ef5548866805"
                      aria-live="polite"
                    >
                      <div className="swiper-wrapper">
                        {PostTab.TabsData?.map((tab) => {
                          return (
                            <>
                              <SwiperSlide
                                key={tab.platform.id}
                                onClick={() => ToggleTab(tab.platform.id)}
                                className={
                                  Actual == tab.platform.id
                                    ? "tab active-btn w-auto  rounded-xl bg-gray-200 py-1 pr-1 h-auto "
                                    : "tab w-auto  rounded-xl bg-gray-200 py-1 pr-1 h-auto "
                                }
                                role="group"
                                aria-label="1 / 9"
                                style={{
                                  width: "124.5px",
                                  "margin-right": " 15px",
                                }}
                              >
                                <div className="flex flex-row items-center">
                                  <div className=" p-1">
                                    <img
                                      className="h-12 w-12 "
                                      src={`../images/${tab.platform.file}`}
                                    />
                                  </div>
                                  <div className="text-sm text-gray-700">
                                    {tab.platform.platform}
                                  </div>
                                </div>
                              </SwiperSlide>
                            </>
                          );
                        })}
                      </div>
                    </Swiper>
                  </div>
                </div>
              </section>
            </div>
            <div className="container  shadow-lg rounded-b-xl border  border-gray-200">
              {PostTab.TabsData?.map((tab) => {
                return (
                  <>
                    <div
                      id="basic"
                      className={
                        Actual == tab.platform.id
                          ? "tab-content active"
                          : "tab-content"
                      }
                    >
                      <div className="hidden absolute z-50">
                        <button
                          type="button"
                          className="open-Upload-popup text-white w-auto bg-gray-400 hover:ease-in-out hover:duration-200 hover:bg-sky-500 focus:ring-4 focus:outline-none focus:ring-sky-300 font-medium rounded-b-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-sky-500 dark:hover:bg-sky-500 dark:focus:ring-sky-800"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-5 h-5 mr-2 -ml-1"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                            />
                          </svg>
                          <p className="">Upload</p>
                        </button>
                      </div>
                      <div className="border border-b-0 border-gray-200 font-light dark:border-gray-700 dark:bg-gray-900">
                        <div className="flex w-full flex-col space-y-4">
                          <div className="flex flex-col w-full items-center justify-center">
                            <div className="frame flex flex-row items-center justify-center space-x-3 p-7">
                              <div className="max-w-md w-full rounded-2xl space-y-4">
                                <div className="flex flex-col items-center  max-w-md rounded overflow-hidden  p-1">
                                  <div className="grid gap-6">
                                    <div className="col-span-12">
                                      <div className="mb-0 flex items-center rounded border bg-white shadow-md">
                                        <span className="px-3">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            className="fill-gray-500 h-5 w-5 "
                                          >
                                            <path d="M8.465 11.293c1.133-1.133 3.109-1.133 4.242 0l.707.707 1.414-1.414-.707-.707c-.943-.944-2.199-1.465-3.535-1.465s-2.592.521-3.535 1.465L4.929 12a5.008 5.008 0 0 0 0 7.071 4.983 4.983 0 0 0 3.535 1.462A4.982 4.982 0 0 0 12 19.071l.707-.707-1.414-1.414-.707.707a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.122-2.121z"></path>
                                            <path d="m12 4.929-.707.707 1.414 1.414.707-.707a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.122 2.121c-1.133 1.133-3.109 1.133-4.242 0L10.586 12l-1.414 1.414.707.707c.943.944 2.199 1.465 3.535 1.465s2.592-.521 3.535-1.465L19.071 12a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0z"></path>
                                          </svg>
                                        </span>
                                        <input
                                          className="h-12 w-full border-0 border-white focus:outline-none"
                                          type="text"
                                          name="link"
                                          value={`http://localhost:3000/share/${tab.ereka_link}`}
                                          readOnly
                                        />
                                      </div>
                                    </div>

                                    <div className="col-span-12 flex items-center justify-center">
                                      <div class="flex flex-col ">
                                        <div class=" flex w-[px] items-center justify-center  ">
                                          <button
                                            onClick={() => {
                                              copyInputValue(
                                                `http://localhost:3000/share/${tab.ereka_link}`
                                              );
                                            }}
                                            className={`group w-48 relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md ${
                                              !isCopied
                                                ? "text-white bg-sky-600"
                                                : "text-sky-500 bg-gray-200 "
                                            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500`}
                                          >
                                            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="w-5 h-5"
                                              >
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
                                                />
                                              </svg>
                                            </span>

                                            {!isCopied ? (
                                              <p>Copy Link</p>
                                            ) : (
                                              <p>Copied !!</p>
                                            )}
                                          </button>
                                        </div>
                                        <Formik
                                          initialValues={initialValues}
                                          validationSchema={validationSchema}
                                          onSubmit={onSubmit}
                                        >
                                          {({
                                            isSubmitting,
                                            values,
                                            setFieldValue,
                                          }) => (
                                            <Form>
                                              <div className="mt-8">
                                                <label
                                                  htmlFor="username"
                                                  className="text-left mb-2 ml-3 block font-medium text-gray-700 dark:text-white"
                                                >
                                                  Select {tab.platform.platform}{" "}
                                                  services
                                                </label>
                                                <Select
                                                  options={options}
                                                  value={selectedOption}
                                                  onChange={(option) => {
                                                    setSelectedOption(option);
                                                    setFieldValue(
                                                      "Service",
                                                      option.value
                                                    );
                                                  }}
                                                />
                                                <ErrorMessage
                                                  name="Service"
                                                  component="div"
                                                  className="text-left mt-2 text-red-500 text-sm"
                                                />
                                              </div>
                                              <div className="">
                                                <div class=" flex w-[px] items-center justify-center  ">
                                                  <div
                                                    className={`group mt-4  w-48 relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md
                                              text-white bg-sky-600
                                             focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500`}
                                                  >
                                                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke-width="1.5"
                                                        stroke="currentColor"
                                                        class="w-5 h-5"
                                                      >
                                                        <path
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                          d="M7.5 3.75H6A2.25 2.25 0 003.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0120.25 6v1.5m0 9V18A2.25 2.25 0 0118 20.25h-1.5m-9 0H6A2.25 2.25 0 013.75 18v-1.5M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                        />
                                                      </svg>
                                                    </span>
                                                    Upload Image
                                                  </div>
                                                  <input
                                                    type="file"
                                                    name="file"
                                                    accept="image/*"
                                                    class="absolute w-32 overflow-hidden px-4 opacity-0"
                                                    onChange={(event) =>
                                                      handleFileChange(
                                                        event,
                                                        setFieldValue
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <ErrorMessage
                                                name="file"
                                                component="div"
                                                className="text-left mt-2 text-red-500 text-sm"
                                              />

                                              {photo && (
                                                <div class="w-full flex items-center justify-center">
                                                  <div className="w-[15em] h-[15em] mt-8 overflow-hidden">
                                                    <img
                                                      src={photo}
                                                      alt=" "
                                                      className="w-full h-full border border-sky-300 object-cover rounded-2xl"
                                                    />
                                                  </div>
                                                </div>
                                              )}

                                              {isSent ? (
                                                <>
                                                  {" "}
                                                  <div class="success-animation mb-5">
                                                    <svg
                                                      class="checkmark"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 52 52"
                                                    >
                                                      <circle
                                                        class="checkmark__circle"
                                                        cx="26"
                                                        cy="26"
                                                        r="25"
                                                        fill="none"
                                                      />
                                                      <path
                                                        class="checkmark__check"
                                                        fill="none"
                                                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                                      />
                                                    </svg>
                                                  </div>
                                                </>
                                              ) : null}

                                              <div className="mb-5 mt-5 flex w-full items-center justify-center">
                                                <button
                                                  type="submit"
                                                  disabled={isSubmitting}
                                                  className="w-1/2 rounded-xl border-0 bg-sky-500 px-6 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none"
                                                >
                                                  {isSubmitting
                                                    ? "Send..."
                                                    : "Send"}
                                                </button>
                                              </div>
                                            </Form>
                                          )}
                                        </Formik>
                                      </div>
                                    </div>
                                    <div className="col-span-12">
                                      <div
                                        className="my-4 px-1 flex rounded-lg bg-sky-50 py-4 text-sm text-sky-800 dark:bg-gray-800 dark:text-sky-400"
                                        role="alert"
                                      >
                                        <svg
                                          aria-hidden="true"
                                          className="mr-3 inline h-5 w-5 flex-shrink-0"
                                          fill="currentColor"
                                          viewBox="0 0 20 20"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                            clipRule="evenodd"
                                          ></path>
                                        </svg>
                                        <span className="sr-only">Info</span>
                                        <div>
                                          <span className="font-medium">
                                            What Next !!
                                          </span>{" "}
                                          After Our Verification,We Will Depost
                                          to Your Account As son as Possible .
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
      <ToastContainer position="bottom-center" />
    </>
  );
}
export default PostToShare;
