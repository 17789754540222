import React from "react";

import MyMedia_ServiceComponent from "../components/My_MediaServices/MyMedia_ServiceComponent";
function MyMedia_Services() {
  return (
    <>
      <MyMedia_ServiceComponent />
    </>
  );
}
export default MyMedia_Services;
