import React from "react";
import SettingComponent from "../components/Setting/SettingComponent";

export default function SettingPage() {
  return (
    <>
      <SettingComponent />
    </>
  );
}
