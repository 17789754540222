import React from "react";

function Haves_NeedsNotification(props) {
  props = props.natif;
  return (
    <>
      <div className="relative flex flex-row items-center rounded-lg bg-gray-100 p-3 hover:bg-sky-100">
        <div className="absolute right-0 top-0 mr-4 mt-4 items-center mb-0 text-xs text-gray-500">
          {props.time} hours ago
        </div>
        <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-pink-500 font-bold text-pink-300">
          <img src={props.photo} className="rounded-full" />
        </div>
        <div className="ml-3 flex flex-grow flex-col">
          <div className="text-sm font-medium">{props.user}</div>
          <div className="w-40 truncate text-xs">{props.body}</div>
        </div>
        <div className="ml-2 mb-1 flex-shrink-0 self-end"></div>
      </div>
    </>
  );
}
export default Haves_NeedsNotification;
