import React, { useContext } from "react";
import Other_Images from "./Other_Images";
import { PricingContext } from "../../context";
import { GlobalFunctionContext } from "../../context";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
function CompanieBuyComponent() {
  const value = useContext(PricingContext);
  const { user_id } = useParams();
  useEffect(() => {
    value.handleUserService(user_id);
  }, []);
  console.log(value);
  const Global = useContext(GlobalFunctionContext);
  return (
    <>
      {value.UserService?.map((order) => {
        return (
          <>
            <div className="item w-128  card text-grey-darkest flex h-auto rounded-xl border bg-white shadow-inner">
              <div className="flex w-full flex-col">
                <div className="flex-1 p-4 pb-0">
                  <div className="flex items-center justify-between p-3">
                    <div className="flex items-center space-x-2">
                      <button
                        className="rounded-full border-2 border-gray-200 p-1"
                        onClick={() => "hello"}
                      >
                        {order.user.profile_picture ? (
                          <img
                            className="mx-1 h-9 w-9 flex-shrink-0 rounded-full object-cover"
                            src={`http://backend.novadiscova.server.cisabu.bi/uploads/PhotoUser/${order.user.profile_picture}`}
                            alt={""}
                          />
                        ) : (
                          <div className="mx-1 h-12 w-12 flex-shrink-0 flex items-center justify-center rounded-full bg-sky-50 border-sky-300 border object-cover">
                            <span class="text-sky-500 uppercase font-medium">
                              {order.user.username?.substring(0, 1)}
                            </span>
                          </div>
                        )}
                      </button>
                      <div className="flex items-center justify-center">
                        <h2 className="text-2xl font-semibold text-sky-500">
                          {order.user.username}
                        </h2>
                      </div>
                    </div>

                    <div className="flex items-center justify-center">
                      <h2 className="flex flex-row items-center text-xl font-semibold text-gray-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="mr-2 h-7 w-7"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                          ></path>
                        </svg>
                        {order.platform_services.length} Services Availables
                      </h2>
                    </div>

                    <button
                      title="Open options"
                      type="button"
                      onClick={() => "hello"}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="h-5 w-5 fill-current"
                      >
                        <path d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"></path>
                        <path d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"></path>
                        <path d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"></path>
                      </svg>
                    </button>
                  </div>
                  <div className="rounded-md bg-gray-100 px-2 py-2">
                    <span className="rounded-r-xl text-lg text-gray-700">
                      {" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
              <button
                type="button"
                className="flex w-full items-center justify-between rounded-t-xl border border-b-0 border-gray-200 bg-sky-50 p-5 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800"
              >
                <div class="flex flex-row space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"
                    />
                  </svg>
                  <span className="text-xl">About the Business</span>
                </div>
              </button>
              <div className="flex w-full items-center justify-between  text-md border border-b-0 border-gray-200 rounded-b-3xl bg-white p-5">
                <div className="flex   flex-col  justify-center ">
                  <p className="p-3 text-xl text-gray-600  ">
                    {order.companie.description}
                  </p>
                  <div class="flex flex-col space-y-3 px-2  pt-4 mt-3 border-t border-gray-300/50 ">
                    <div class="flex flex-row space-x-3 mb-3 items-start justify-start">
                      <span class="text-xl text-gray-600 font-semibold ">
                        Photos & Videos
                      </span>
                    </div>

                    <div className="hidden">
                      <div className="-m-1 flex flex-col sm:flex-wrap md:-m-2">
                        <div className="flex w-full  flex-wrap">
                          <div className="w-full p-1 md:p-2">
                            <img
                              alt="gallery"
                              className="block h-full w-full rounded-lg 2xl:rounded-2xl object-cover object-center"
                              src="https://www.tailwindtap.com/assets/components/gallery/image9.jpg"
                            />
                          </div>
                          <div className="w-1/2 p-1 md:p-2">
                            <img
                              alt="gallery"
                              className="block h-full w-full rounded-lg 2xl:rounded-2xl object-cover object-center"
                              src="https://www.tailwindtap.com/assets/components/gallery/image4.jpg"
                            />
                          </div>
                          <div className="w-1/2 p-1 md:p-2">
                            <img
                              alt="gallery"
                              className="block h-full w-full rounded-lg 2xl:rounded-2xl object-cover object-center"
                              src="https://www.tailwindtap.com/assets/components/gallery/image6.jpg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <Other_Images />
                  </div>
                  <div class="flex flex-col space-y-3 px-2  pt-4 mt-8 border-t border-gray-300/50 ">
                    <div class="flex flex-row space-x-3 mb-3 items-start justify-start">
                      <span class="text-xl text-gray-600 font-semibold ">
                        Services Offered
                      </span>
                    </div>
                    <ul class="mt-8 space-y-3 text-lg mb-8">
                      {order.platform_services?.map((service) => {
                        return (
                          <>
                            <li class="flex items-start lg:col-span-1">
                              <div class="flex-shrink-0">
                                <svg
                                  class="w-5 h-5 text-sky-600"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clip-rule="evenodd"
                                  ></path>
                                </svg>
                              </div>
                              <p class="ml-3 leading-5 text-gray-600">
                                {service.title}
                              </p>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>

                  <div class="flex flex-row space-x-3 px-2  pt-4 mt-3 border-t border-gray-300/50 ">
                    <div class="flex flex-row space-x-3 items-center justify-center">
                      <div class="flex flex-row space-x-2 text-yellow-500 items-center justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                          ></path>
                        </svg>
                      </div>
                      <span class="text-lg text-gray-600 font-semibold ">
                        + {order.companie.number_Order} Satisfied clients
                      </span>
                    </div>
                  </div>
                  <div class="flex flex-row space-x-3 px-2  pt-4 mt-3 border-t border-gray-300/50 ">
                    <div class="bg-gray-100 py-2 px-3 flex flex-row rounded-xl">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                        ></path>
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                        ></path>
                      </svg>
                      <span class="rounded-r-xl text-base text-gray-700">
                        {order.companie.location}
                      </span>
                    </div>
                  </div>

                  <div class="flex flex-col items-start  pt-4 pb-4 px-3 mt-3 border-t border-gray-300/50 ">
                    <div class="flex flex-row space-x-3 mb-4 items-start justify-start">
                      <span class="text-xl text-gray-600 font-semibold ">
                        Our Customers Reviews
                      </span>
                    </div>
                    <div class="flex flex-row items-center justify-between space-x-5">
                      <div class="text-xl text-gray-600    leading-7">
                        <h2 class="flex  flex-row items-center text-xl font-semibold text-gray-500 bg-gray-100 rounded-xl px-3 py-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="mr-2 h-6 w-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                            ></path>
                          </svg>
                          <h2 class="text-sm text-gray-500 uppercase font-semibold leading-none">
                            <p class="text-gray-600">
                              What do you think about our Business ?
                            </p>
                          </h2>
                        </h2>
                      </div>

                      <div class="">
                        <div class="font-display text-center text-base font-semibold text-gray-500 flex items-center justify-center">
                          <a
                            class="hover:text-white text-gray-800  bg-gray-200  hover:bg-sky-500 focus:ring-4 focus:ring-sky-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5  dark:bg-sky-600 dark:hover:bg-sky-700 focus:outline-none dark:focus:ring-sky-800"
                            href="/pricing/companies/22"
                          >
                            Write a Review
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <form class="w-full max-w-xl bg-white rounded-lg px-4 pt-2">
                    <div class="flex flex-wrap -mx-3 mb-6">
                      <h2 class="px-4 pt-3 pb-2 text-gray-800 text-lg">
                        Add a Review
                      </h2>
                      <div class="w-full md:w-full px-3 mb-2 mt-2">
                        <textarea
                          class="bg-gray-100 h-32 rounded border border-gray-400 leading-normal resize-none w-full h-20 py-2 px-3 font-medium placeholder-gray-700 transition-colors duration-200 ease-in-out focus:border-sky-500 focus:ring-2 focus:ring-sky-200"
                          name="message"
                          id="message"
                          rows="4"
                          placeholder="Write your review Here"
                          required
                        ></textarea>
                      </div>
                      <div class="w-full md:w-full flex items-start md:w-full px-3">
                        <div class="flex items-start w-1/2 text-gray-700 px-2 mr-auto">
                          <svg
                            fill="none"
                            class="w-5 h-5 text-gray-600 mr-1"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          <p class="text-xs md:text-sm pt-px">
                            Your review is important to us
                          </p>
                        </div>
                        <div class="-mr-1">
                          <button
                            type="submit"
                            class="bg-sky-500 text-white hover:bg-sky-700 font-medium py-1 px-4  rounded-lg tracking-wide mr-1 hover:bg-gray-100"
                          >
                            Post Review
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div class="flex flex-col gap-3  px-3 mt-4">
                    <div class="flex flex-col gap-4  shadow rounded-xl border border-gray-300  p-4">
                      <div class="flex justify justify-between">
                        <a
                          class=" flex transform items-center py-1.5 text-sm text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                          href="/profile"
                        >
                          <div class="mx-1 h-12 w-12 flex-shrink-0 flex items-center justify-center rounded-full bg-sky-50 border-sky-300 border object-cover">
                            <span class="text-sky-500 uppercase font-medium">
                              d
                            </span>
                          </div>
                          <div class="mx-1">
                            <h1 class="text-sm font-semibold text-gray-700 dark:text-gray-200">
                              Alice Banks
                            </h1>
                            <p class="text-sm text-gray-500 dark:text-gray-400">
                              Bujumbura,Burundi
                            </p>
                          </div>
                        </a>

                        <div class="flex p-1 gap-1 text-orange-300">
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star-half"></ion-icon>
                        </div>

                        <div class="flex flex-row space-x-2 text-yellow-500 items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                            ></path>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                            ></path>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                            ></path>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                            ></path>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                            ></path>
                          </svg>
                        </div>
                      </div>

                      <div>
                        Gorgeous design! Even more responsive than the previous
                        version. A pleasure to use!
                      </div>

                      <div class="flex justify-between">
                        <span>Feb 13, 2021</span>
                        <button class="p-1 px-2 border border-gray-400 hidden rounded-lg bg-opacity-60">
                          <ion-icon name="share-outline"></ion-icon> useful
                        </button>
                      </div>
                    </div>

                    <div class="flex flex-col gap-4  shadow rounded-xl border border-gray-300  p-4">
                      <div class="flex justify justify-between">
                        <a
                          class=" flex transform items-center py-1.5 text-sm text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                          href="/profile"
                        >
                          <div class="mx-1 h-12 w-12 flex-shrink-0 flex items-center justify-center rounded-full bg-sky-50 border-sky-300 border object-cover">
                            <span class="text-sky-500 uppercase font-medium">
                              d
                            </span>
                          </div>
                          <div class="mx-1">
                            <h1 class="text-sm font-semibold text-gray-700 dark:text-gray-200">
                              Alice Banks
                            </h1>
                            <p class="text-sm text-gray-500 dark:text-gray-400">
                              Bujumbura,Burundi
                            </p>
                          </div>
                        </a>

                        <div class="flex p-1 gap-1 text-orange-300">
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star-half"></ion-icon>
                        </div>

                        <div class="flex flex-row space-x-2 text-yellow-500 items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                            ></path>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                            ></path>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                            ></path>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                            ></path>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                            ></path>
                          </svg>
                        </div>
                      </div>

                      <div>
                        Gorgeous design! Even more responsive than the previous
                        version. A pleasure to use!
                      </div>

                      <div class="flex justify-between">
                        <span>Feb 13, 2021</span>
                        <button class="p-1 px-2 border border-gray-400 hidden rounded-lg bg-opacity-60">
                          <ion-icon name="share-outline"></ion-icon> useful
                        </button>
                      </div>
                    </div>

                    <div class="flex flex-col gap-4  shadow rounded-xl border border-gray-300  p-4">
                      <div class="flex justify justify-between">
                        <a
                          class=" flex transform items-center py-1.5 text-sm text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                          href="/profile"
                        >
                          <div class="mx-1 h-12 w-12 flex-shrink-0 flex items-center justify-center rounded-full bg-sky-50 border-sky-300 border object-cover">
                            <span class="text-sky-500 uppercase font-medium">
                              d
                            </span>
                          </div>
                          <div class="mx-1">
                            <h1 class="text-sm font-semibold text-gray-700 dark:text-gray-200">
                              Alice Banks
                            </h1>
                            <p class="text-sm text-gray-500 dark:text-gray-400">
                              Bujumbura,Burundi
                            </p>
                          </div>
                        </a>

                        <div class="flex p-1 gap-1 text-orange-300">
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star-half"></ion-icon>
                        </div>

                        <div class="flex flex-row space-x-2 text-yellow-500 items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                            ></path>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                            ></path>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                            ></path>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                            ></path>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                            ></path>
                          </svg>
                        </div>
                      </div>

                      <div>
                        Gorgeous design! Even more responsive than the previous
                        version. A pleasure to use!
                      </div>

                      <div class="flex justify-between">
                        <span>Feb 13, 2021</span>
                        <button class="p-1 px-2 border border-gray-400 hidden rounded-lg bg-opacity-60">
                          <ion-icon name="share-outline"></ion-icon> useful
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="my-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
              <button
                type="button"
                className="flex w-full items-center justify-between rounded-t-xl border border-b-0 border-gray-200 bg-sky-50 p-5 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800"
              >
                <div class="flex flex-row space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z"
                    />
                  </svg>

                  <span className="text-xl">Our Location</span>
                </div>
              </button>
              <div className="flex w-full items-center justify-center  text-md border border-b-0 border-gray-200 rounded-b-3xl bg-white p-5">
                <div class=" border border-sky-300 shadow rounded-2xl overflow-hidden">
                  {" "}
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15931.280151019238!2d29.3954391!3d-3.3940662!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19c182b87903ccc1%3A0xbacb4e867f382ad3!2sCampus%20Universitaire%20de%20Kiriri!5e0!3m2!1sfr!2sbi!4v1703529817729!5m2!1sfr!2sbi"
                    width="520"
                    height="450"
                    style={{ border: "0" }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="my-4 flex h-auto w-full flex-col rounded-3xl shadow-lg">
              <button
                type="button"
                className="flex w-full items-center justify-between rounded-t-xl border border-b-0 border-gray-200 bg-sky-50 p-5 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800"
              >
                <span className="text-xl">List Of Services</span>
              </button>
              <div>
                <div className="border border-b-0 border-gray-200 font-light dark:border-gray-700 dark:bg-gray-900">
                  <div className="mb-6 inline-flex w-full items-center justify-center">
                    <div className="absolute flex h-8 w-32 items-center justify-center rounded-xl border-2 border-gray-300 bg-gray-500 px-3 py-2 shadow">
                      <span className="font-thing w-auto rounded-r-xl text-lg text-white">
                        {" "}
                        Shares{" "}
                      </span>
                    </div>
                  </div>

                  <div className="my-4 grid w-full grid-cols-1 gap-x-4 gap-y-6 px-6 ">
                    {order.platform_services?.map((service) => {
                      return (
                        <>
                          <div className="item-row flex flex-col  justify-between text-sm border border-sky-200  tracking-wider  px-3 py-1  bg-sky-50 bg-opacity-50 text-sky-500 rounded-xl mb-4 ">
                            <div className="item-row flex flex-row mt-4 items-center justify-between ">
                              <div className="flex flex-row space-x-3 items-center justify-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  class="w-3 h-3 text-gray-600 hidden"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"
                                  />
                                </svg>
                                <span class="text-xl text-gray-600 font-semibold ">
                                  {service.title}
                                </span>
                              </div>
                              <div className="flex flex-col ">
                                {" "}
                                <label
                                  htmlFor="username"
                                  className="mb-2 ml-1 block text-base text-gray-700 dark:text-white"
                                >
                                  <span class="text-sm lg:text-2xl text-gray-600 ">
                                    {service.price}
                                    <sub>fbu </sub>
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="item-row flex flex-row mt-4 items-center justify-between ">
                              <div className="flex flex-row space-x-3 items-center justify-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  class="w-3 h-3 text-gray-600 hidden"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z"
                                  />
                                </svg>
                                <span class="text-lg text-gray-600 ">
                                  {service.description}
                                </span>
                              </div>
                            </div>
                            <div className="mb-4 mt-3">
                              <div className="relative  w-full  overflow-hidden">
                                <div className="h-32 md:h-[291px] ">
                                  <img
                                    src={`http://backend.novadiscova.server.cisabu.bi/uploads/MediaService/${service.file}`}
                                    alt="product 1"
                                    className="object-cover border border-sky-300 md:min-w-[289px] h-full w-full rounded-2xl"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item-row flex flex-row items-center justify-between ">
                              <div className="flex flex-row space-x-3 items-center justify-center">
                                <div className="flex flex-row space-x-2 text-yellow-500 items-center justify-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-6 h-6"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                    />
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-6 h-6"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                    />
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-6 h-6"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                    />
                                  </svg>
                                </div>

                                <span class="text-lg text-gray-600 font-semibold ">
                                  250 orders
                                </span>
                              </div>
                              <div className="font-display mt-3 text-center text-sm font-semibold text-gray-500 flex items-center justify-center">
                                <button
                                  onClick={() => {
                                    Global.setUserPop(true);
                                    const user = order.user;
                                    user.comp_type = "Company";
                                    Global.setPop(user);
                                  }}
                                  className="rounded-xl border-0 bg-sky-500 px-6 py-2 text-lg text-white hover:bg-sky-600"
                                >
                                  Order Now
                                </button>
                              </div>
                            </div>
                          </div>{" "}
                        </>
                      );
                    })}
                  </div>

                  <div className="my-6 inline-flex hidden w-full items-center justify-center">
                    <hr className="my-2 h-px w-full rounded border-0 bg-gray-200 dark:bg-gray-700" />
                    <div className="absolute flex h-8 w-32 items-center justify-center rounded-xl border-2 border-gray-300 bg-gray-500 px-3 py-2 shadow">
                      <span className="font-thing w-auto rounded-r-xl text-lg text-white">
                        Growth{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}

export default CompanieBuyComponent;
