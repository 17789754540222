import React from 'react'
import UserAside from '../AsideBar/UserAside'
import WaitListBox from './WaitListBox'

function EventWaitList() {
  return (
    <>
      <WaitListBox />
    </>
  )
}
export default EventWaitList