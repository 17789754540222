import React from "react";
import PostInfos from "./PostInfos";
import TargetOfWork from "./TargetOfWork";
import PostToShare from "./PostToShare";
import SendFeedBack from "./SendFeedBack";
import Pubs from "./Pubs";
function PostDetail() {
  return (
    <>
      <PostInfos />
      <Pubs />
      {/* <TargetOfWork />
      <PostToShare />
      <SendFeedBack /> */}
    </>
  );
}
export default PostDetail;
