import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { NotifyContext } from "../../context";
import { AdsContext } from "../../context";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import Top from "./Top";
SwiperCore.use([Autoplay, Pagination]);
function RightUserAside(props) {
  const posts = useContext(AdsContext);
  let pathname = props.pathname;
  let isHome = false;
  let isMessage = false;
  let isWho = false;
  if (pathname == "/") isHome = true;
  if (pathname == "/messages") isMessage = true;
  if (pathname == "/who") isWho = true;
  const notifs = useContext(NotifyContext);
  let notreaded = notifs.notreaded;
  return (
    <>
      {isHome || isMessage ? null : isWho ? null : (
        <aside
          id="separator-sidebar"
          className="z-60 fixed right-0 top-20 h-auto w-auto -translate-x-full transition-transform sm:translate-x-0"
          aria-label="Sidebar"
        >
          <div className="flex h-screen flex-col space-y-4 overflow-y-scroll hide-scroll rounded-l-3xl border-b border-l border-sky-500 bg-gray-50 px-3 py-4 dark:bg-gray-800">
            <div className="SideBar_item flex w-80 flex-shrink-0 flex-row rounded-3xl border bg-gray-100 p-4">
              <div className="-mr-4 flex h-full w-full flex-col pl-4 pr-4">
                <div className="flex flex-row items-center">
                  <div className="flex flex-row items-center">
                    <button className="flex h-7 w-7 items-center justify-center rounded-full bg-gray-200 text-gray-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-56 w-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                        />
                      </svg>
                    </button>
                    <div className="ml-1 text-sm font-semibold text-gray-700">
                      My Dashboard
                    </div>
                  </div>
                  <div className="ml-auto">
                    <button className="flex h-7 w-7 items-center justify-center rounded-full bg-gray-200 text-gray-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-4 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="relative mt-3 h-full overflow-hidden pt-2">
                  <div className="flex h-full flex-col space-y-4 overflow-y-auto">
                    <div className="dash_item flex flex-row items-center rounded-3xl border border-l-2 border-sky-500 bg-gradient-to-r from-sky-100 to-sky-50 p-1 shadow">
                      <div className="ml-1 flex h-7 w-7 flex-shrink-0 items-center justify-center rounded-full bg-sky-500 font-bold text-sky-300">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-gem"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.1.7a.5.5 0 0 1 .4-.2h9a.5.5 0 0 1 .4.2l2.976 3.974c.149.185.156.45.01.644L8.4 15.3a.5.5 0 0 1-.8 0L.1 5.3a.5.5 0 0 1 0-.6l3-4zm11.386 3.785-1.806-2.41-.776 2.413 2.582-.003zm-3.633.004.961-2.989H4.186l.963 2.995 5.704-.006zM5.47 5.495 8 13.366l2.532-7.876-5.062.005zm-1.371-.999-.78-2.422-1.818 2.425 2.598-.003zM1.499 5.5l5.113 6.817-2.192-6.82L1.5 5.5zm7.889 6.817 5.123-6.83-2.928.002-2.195 6.828z" />
                        </svg>
                      </div>
                      <div className="flex flex-grow flex-row items-center justify-between px-3">
                        <div className="flex items-center">
                          <div className="text-base font-medium text-sky-800">
                            Points
                          </div>
                        </div>
                        <div className="truncate text-base font-bold text-sky-600">
                          12000
                        </div>
                      </div>
                    </div>
                    <div className="dash_item flex flex-row items-center rounded-3xl border border-l-2 border-sky-500 bg-gradient-to-r from-sky-100 to-sky-50 p-1 shadow">
                      <div className="ml-1 flex h-7 w-7 flex-shrink-0 items-center justify-center rounded-full bg-sky-500 font-bold text-sky-300">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="h-5 w-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-grow flex-row items-center justify-between px-3">
                        <div className="flex items-center">
                          <div className="text-base font-medium text-sky-800">
                            Balance
                          </div>
                        </div>
                        <div className="truncate text-base font-bold text-sky-600">
                          100
                        </div>
                      </div>
                    </div>
                    <div className="dash_item flex flex-row items-center rounded-3xl border border-l-2 border-sky-500 bg-gradient-to-r from-sky-100 to-sky-50 p-1 shadow">
                      <div className="ml-1 flex h-7 w-7 flex-shrink-0 items-center justify-center rounded-full bg-sky-500 font-bold text-sky-300">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="h-5 w-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-grow flex-row items-center justify-between px-3">
                        <div className="flex items-center">
                          <div className="text-base font-medium text-sky-800">
                            NetProfit
                          </div>
                        </div>
                        <div className="truncate text-base font-bold text-sky-600">
                          12000
                        </div>
                      </div>
                    </div>

                    <div className="dash_item flex items-center justify-center p-1 shadow">
                      <div className="flex w-32 flex-row items-center rounded-3xl bg-sky-500">
                        <div className="ml-1 flex h-7 w-7 flex-shrink-0 items-center justify-center rounded-full bg-sky-500 font-bold text-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="h-5 w-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                            />
                          </svg>
                        </div>
                        <div className="flex flex-grow flex-row items-center justify-between px-2">
                          <div className="flex items-center">
                            <div className="text-base font-medium text-white">
                              Withdraw
                            </div>
                          </div>
                          <div className="flex items-center ml-10">
                            <div className="ml-1 flex h-7 w-7  flex-shrink-0 items-center justify-center rounded-full bg-sky-500 font-bold text-white">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="h-5 w-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center">
              <div className="flex flex-row items-center">
                <button className="flex h-7 w-7 items-center justify-center rounded-full bg-gray-200 text-gray-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-56 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                    />
                  </svg>
                </button>
                <div className="ml-1 text-sm font-semibold text-gray-700">
                  Sponsored Ads
                </div>
              </div>

              <hr className="mx-auto my-2 h-px w-36 rounded border-0 bg-gray-500 dark:bg-gray-700 md:my-10" />
            </div>

            <div className="SideBar_item flex h-[380px] w-80 flex-shrink-0 flex-row rounded-3xl border bg-gray-100 p-4 pt-8">
              <Swiper
                direction={"vertical"}
                pagination={{
                  clickable: true,
                }}
                autoplay={{ delay: 4000, disableOnInteraction: false }}
                loop={true}
                modules={[Pagination]}
                className="mySwiper"
              >
                {" "}
                {posts.posts?.slice(0, 4).map((post) => {
                  return (
                    <SwiperSlide>
                      <Top key={post.id} post={post} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="flex flex-row items-center">
              <div className="flex flex-row items-center">
                <button className="flex h-7 w-7 items-center justify-center rounded-full bg-gray-200 text-gray-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z"
                    />
                  </svg>
                </button>
                <div className="ml-1 text-sm font-semibold text-gray-700">
                  Trends Posts
                </div>
              </div>

              <hr className="mx-auto my-2 h-px w-36 rounded border-0 bg-gray-500 dark:bg-gray-700 md:my-10" />
            </div>

            <div className="SideBar_item flex h-auto  w-80 space-y-2 flex-shrink-0 flex-col rounded-3xl border bg-gray-100 p-4">
              <div className="item shadow-soft-xl w-full relative h-24 min-w-0 flex flex-col justify-center  break-words rounded-2xl bg-sky-100 bg-clip-border">
                <div className="px-4">
                  <div className="flex flex-row">
                    <div className=" px-3">
                      <div className="inline-block h-16 w-20 rounded-lg bg-gradient-to-tl from-sky-700 to-sky-500 text-center">
                        <div className="flex h-full w-full items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="h-6 w-12 text-white"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="w-2/3 max-w-full flex-none px-3">
                      <div>
                        <h2 className="text-sm font-semibold leading-none">
                          BUK-C BOY{" "}
                          <span className="text-sm font-bold leading-normal text-sky-500 ml-2">
                            {" "}
                            .12 Ago
                          </span>
                        </h2>
                        <p className="mt-1 text-base text-gray-700">
                          Toka Official Video
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item shadow-soft-xl w-full relative h-24 min-w-0 flex flex-col justify-center  break-words rounded-2xl bg-sky-100 bg-clip-border">
                <div className="px-4">
                  <div className="flex flex-row">
                    <div className=" px-3">
                      <div className="inline-block h-16 w-20 rounded-lg bg-gradient-to-tl from-sky-700 to-sky-500 text-center">
                        <div className="flex h-full w-full items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="h-6 w-12 text-white"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="w-2/3 max-w-full flex-none px-3">
                      <div>
                        <h2 className="text-sm font-semibold leading-none">
                          BUK-C BOY{" "}
                          <span className="text-sm font-bold leading-normal text-sky-500 ml-2">
                            {" "}
                            .12 Ago
                          </span>
                        </h2>
                        <p className="mt-1 text-base text-gray-700">
                          Toka Official Video
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center">
              <div className="flex flex-row items-center">
                <button className="flex h-7 w-7 items-center justify-center rounded-full bg-gray-200 text-gray-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z"
                    />
                  </svg>
                </button>
                <div className="ml-1 text-sm font-semibold text-gray-700">
                  My Contacts
                </div>
              </div>

              <hr className="mx-auto my-2 h-px w-36 rounded border-0 bg-gray-500 dark:bg-gray-700 md:my-10" />
            </div>

            <div className="SideBar_item flex h-auto w-80 flex-shrink-0 flex-row rounded-3xl border bg-gray-100 p-4">
              <ul className="w-full">
                <li className="mt-2 flex cursor-pointer rounded-2xl items-center justify-between  bg-white p-2 transition hover:shadow-lg">
                  <div className="ml-2 flex">
                    <img
                      src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
                      width="40"
                      height="40"
                      className="rounded-full"
                    />
                    <div className="ml-2 flex flex-col justify-center">
                      <span className="font-medium text-black">
                        Jessica Koel
                      </span>
                    </div>
                  </div>
                </li>
                <li className="mt-2 flex cursor-pointer rounded-2xl items-center justify-between  bg-white p-2 transition hover:shadow-lg">
                  <div className="ml-2 flex">
                    <img
                      src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
                      width="40"
                      height="40"
                      className="rounded-full"
                    />
                    <div className="ml-2 flex flex-col justify-center">
                      <span className="font-medium text-black">
                        Jessica Koel
                      </span>
                    </div>
                  </div>
                </li>
                <li className="mt-2 flex cursor-pointer rounded-2xl items-center justify-between  bg-white p-2 transition hover:shadow-lg">
                  <div className="ml-2 flex">
                    <img
                      src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
                      width="40"
                      height="40"
                      className="rounded-full"
                    />
                    <div className="ml-2 flex flex-col justify-center">
                      <span className="font-medium text-black">
                        Jessica Koel
                      </span>
                    </div>
                  </div>
                </li>
                <li className="mt-2 flex cursor-pointer rounded-2xl items-center justify-between  bg-white p-2 transition hover:shadow-lg">
                  <div className="ml-2 flex">
                    <img
                      src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
                      width="40"
                      height="40"
                      className="rounded-full"
                    />
                    <div className="ml-2 flex flex-col justify-center">
                      <span className="font-medium text-black">
                        Jessica Koel
                      </span>
                    </div>
                  </div>
                </li>
                <li className="mt-2 flex cursor-pointer rounded-2xl items-center justify-between  bg-white p-2 transition hover:shadow-lg">
                  <div className="ml-2 flex">
                    <img
                      src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
                      width="40"
                      height="40"
                      className="rounded-full"
                    />
                    <div className="ml-2 flex flex-col justify-center">
                      <span className="font-medium text-black">
                        Jessica Koel
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </aside>
      )}
    </>
  );
}
export default RightUserAside;
