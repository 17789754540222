import React from 'react'
import UserAside from '../AsideBar/UserAside'
import PubsBox from './PubsBox'

function AllPriceComponent() {
  return (
    <>
        <PubsBox />
    </>
  )
}
export default AllPriceComponent