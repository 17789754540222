import React,{useContext} from 'react'
import WaitList from './WaitList'
import {EventContext} from '../../context'
export default function WaitLists() {
    const evts = useContext(EventContext)
  return (
    <>
        <div className="relative h-full overflow-hidden pt-2">
        <div className="-mx-3 flex h-full flex-col divide-y overflow-y-auto overflow-x-hidden">
          <div className="mt-3 mb-3 ml-5">
          </div>
          {evts.evts?.map(evt=>{
            return <WaitList key={evt.id} evt={evt} />
          })}
          <div className="mt-3 mb-3 ml-5">
            <div className="text-xs font-semibold uppercase text-gray-400">Yersterday</div>
          </div>
          {evts.evts?.map(evt=>{
            return <WaitList key={evt.id} evt={evt} />
          })}

          <div className="mt-3 mb-3 ml-5">
            <div className="text-xs font-semibold uppercase text-gray-400">Last Week</div>
          </div>{evts.evts?.map(evt=>{
            return <WaitList key={evt.id} evt={evt} />
          })}
        </div>
      </div>
    </>
  )
}
