import React from "react";
import PostOrderDetail from "../components/Post_Orders/PostOrderDetail/PostOrderDetail";
function PostOrderDetailPage() {
  return (
    <>
      <PostOrderDetail />
    </>
  );
}
export default PostOrderDetailPage;
