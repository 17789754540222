import React from 'react'
import MyContribution from '../components/Contribution/MyContribution'

function ContributePage() {
  return (
    <>
        <MyContribution />
    </>
  )
}
export default ContributePage