import React from "react";

function Target(props) {
  const rang = (props.tag.level * 100) / props.tag.quantity;
  return (
    <>
      <div className="social-item flex flex-col">
        <div className="flex w-full justify-between">
          <div>
            <div className="flex flex-row items-center rounded-xl">
              <div className="rounded-full p-1">
                <img
                  className="h-8 w-8 rounded-full"
                  src={`../images/${props.tag.platform.file}`}
                />
              </div>
              <div className="text-base text-gray-700">
                {props.tag.platform.platform} {props.tag.service}
              </div>
            </div>
          </div>
          <div className="font-bold text-sky-500">
            {props.tag.level}/{props.tag.quantity}
          </div>
        </div>
        <div className="h-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-700">
          <div
            className="h-2.5 rounded-full bg-sky-600"
            style={{ width: rang + "%" }}
          ></div>
        </div>
      </div>
    </>
  );
}
export default Target;
