import React from "react";

import Quick_OrderComponent from "../components/Quick_Order/Quick_OrderComponent";
function Quick_Ordering() {
  return (
    <>
      <Quick_OrderComponent />
    </>
  );
}
export default Quick_Ordering;
