import React,{useContext} from 'react'
import StepWork from './StepWork'
import { StepContext } from '../../context'
function HowWork() {
 const value = useContext(StepContext)
  return (
    <>
    <section className="bg-gray-50 dark:bg-gray-800">
  <div className="py-2 px-4  lg:py-2  lg:px-6">
    <div className="inline-flex w-full items-center justify-center">
      <hr className="my-8 h-px w-96 rounded border-0 bg-gray-200 dark:bg-gray-700"/>
      <div className="absolute left-1/2 -translate-x-1/2 bg-gray-200 rounded-xl px-4 py-2 dark:bg-gray-900">
        <span className="text inline-block self-center whitespace-nowrap bg-gradient-to-r from-sky-500 to-sky-800 bg-clip-text text-2xl text-transparent font-light dark:text-white">How It Works ?</span>
      </div>
    </div>
    <div className="mx-auto px-4 py-8 sm:max-w-xl md:max-w-full md:px-24 lg:max-w-screen-xl lg:px-8 lg:py-8">

  <div className="row-gap-5 md:row-gap-5 relative mb-8 grid gap-5 sm:grid-cols-2 lg:grid-cols-3">
    <div className="absolute inset-0 flex items-center justify-center sm:hidden lg:flex">
      <div className="h-full w-px bg-gray-300 lg:h-px lg:w-full"></div>
    </div>
    {
    value.steps?.map(step=>{
              return <StepWork key={step.id} step={step} />
            })
          }
  </div>
</div>
  </div>
    </section>
    </>
  )
}
export default HowWork
