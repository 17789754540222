import React, { useState } from "react";
import NavbarLogged from "./NavbarLogged";
import NavbarUnlogged from "./NavbarUnlogged";

function Navbar(props) {
  const [logged, setLogged] = useState(true);
  return (
    <header className="fixed w-full z-30">
      {props.pathname == "/" ? (
        <NavbarUnlogged />
      ) : (
        <NavbarLogged pathname={props.pathname} openSearch={props.openSearch} />
      )}
    </header>
  );
}
export default Navbar;
