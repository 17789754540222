import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// CSS Modules, react-datepicker-cssmodules.css//
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import Select from "react-select";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Api from "../Api/Api";
import LoadingPage from "../LoadingPage.js/LoadingPage";
import { space } from "postcss/lib/list";

function HeroIndestries() {
  const [loading, setloading] = useState(false);
  const [sups, setsups] = useState(null);
  const [Space, setSpace] = useState([]);
  const [Error, SetError] = useState(null);
  const [Interest, SetInterest] = useState([]);
  const user_id = localStorage.getItem("userid");
  function FetchEl(e) {
    e.target.classList.toggle("border-sky-500");
    e.target.classList.contains("border-sky-500")
      ? setSpace((current) => [...current, e.target.innerHTML])
      : setSpace((current) => [
          ...current.filter((el) => el !== e.target.innerHTML),
        ]);
  }
  const handleClose = () => {
    toast.dismiss();
  };
  function FetchElAdd(e) {
    if (Space.length >= 5) {
      SetError("Only 5 Interest at Maximun ");
      setTimeout(() => {
        SetError(null);
      }, 3000);
    } else {
      !e.target.classList.contains("border-sky-500")
        ? setSpace((current) => [
            ...current,
            {
              id: e.target.id,
              title: e.target.innerHTML.replace("&amp;", "&"),
            },
          ])
        : setSpace((current) => [
            ...current.filter((el) => el !== e.target.innerHTML),
          ]);
    }
  }

  function FetchElRemove(text) {
    setSpace((current) => current.filter((el) => el.title !== text));
  }

  const validationSchema = Yup.object().shape({
    Interest: Yup.string()
      .min(1, "Please select at least one Job or Interest")
      .required("Please select at least one Job or Interest"),
  });

  const initialValues = {
    Interest: "",
  };

  const fetchData = async (page) => {
    try {
      console.log("fetchData");
      const response = await Api.get(`/api/interests?page=${page}`);
      const { products, totalPages, totalProducts } = response.data;
      const totalUsers = totalProducts;
      const interest = products;
      setsups({ sups: interest, totalPages, totalUsers });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUser = async (page, username) => {
    try {
      console.log("Username", username);
      console.log("fetchUser");
      const response = await Api.get(
        `/api/findinterest?page=${page}&username=${username}`
      );
      const { products, totalPages, totalProducts } = response.data;
      const interest = products;
      const totalUsers = totalProducts;
      setsups({ sups: interest, totalPages, totalUsers });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUserInterest = async (page, username) => {
    try {
      const id = localStorage.getItem("userid");
      const response = await Api.get(`/api/user_interest/${id}`);
      {
        if (response.data.length > 0) {
          const data = response.data.map((item) => ({
            id: item.interest_id,
            title: item.interest_title,
          }));
          console.log(data);
          setSpace(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchUserInterest();
  }, []);
  const [search, setSearch] = useState(null);
  const [debouncedSearch, setDebouncedSearch] = useState(null);
  const handleSearch = (username) => {
    if (page > 1) {
      setPage(1);
      setSearch(username);
    } else {
      setSearch(username);
    }
  };
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (debouncedSearch) {
      if (sups?.totalPages) {
        if (page <= sups?.totalPages) {
          console.log("pass");
          console.log("page", page);
          setloading(true);
          fetchUser(page, debouncedSearch);
          setloading(false);
        }
      } else {
        setloading(true);
        fetchUser(page, debouncedSearch);
        setloading(false);
      }
    } else {
      console.log("Else Debounce");
      if (sups?.totalPages) {
        if (page <= sups?.totalPages) {
          console.log("pass");
          console.log("page", page);
          setloading(true);
          fetchData(page);
          setloading(false);
        }
      } else {
        console.log("Else Total Pages");
        setloading(true);
        fetchData(page);
        setloading(false);
      }
    }
  }, [page, debouncedSearch]);

  useEffect(() => {
    const delay = 500; // Delay in milliseconds
    const debounceTimer = setTimeout(() => {
      setDebouncedSearch(search);
    }, delay);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [search]);
  // Add 'data' as a dependency to reattach event listener when data changes

  // Fonction pour passer à la page suivante
  const nextPage = () => {
    if (page < sups?.totalPages) {
      setPage(page + 1);
      console("next page", page);
    }
  };

  // Fonction pour passer à la page précédente
  const previousPage = () => {
    if (page > 1) {
      setPage(page - 1);
      console("next page", page);
    }
  };

  const Save = () => {
    console.log(Space);
    const data = {
      user_id: user_id,
      Interest: Space.map((item) => ({
        id: item.id,
        title: item.title.replace("&amp;", "&"),
      })),
    };
    setloading(true);
    Api.post("api/interest/update", data)
      .then((response) => {
        toast.success("Welcome back! You are now logged in! ♨️", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClick: handleClose, // Utilisation de la référence à la fonction handleClose
        });
        setloading(false);
        console.log(response.data);
      })
      .catch((error) => {
        // Handle errors
        if (error.response) {
          setloading(false);
          // Le serveur a renvoyé une réponse avec un code d'erreur
          const errorMessage = error.response.data.error;
          SetError(errorMessage);
          toast.error(errorMessage, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            onClick: handleClose, // Utilisation de la référence à la fonction handleClose
          }); // Affiche le message d'erreur dans le toast
        } else if (error.request) {
          // La requête a été faite mais aucune réponse n'a été reçue
          console.log(error.request);
        } else {
          // Une erreur s'est produite lors de la configuration de la requête
          console.log("Error", error.message);
        }
      })
      .finally(() => {});
  };
  return (
    <>
      <div classNameName="My_Contribution">
        <div className="flex h-auto w-full flex-col rounded-3xl">
          <div className="w-full">
            <div className="mx-auto max-w-screen-xl overflow-hidden pb-4 lg:pb-4">
              <div className=" z-10 mx-10 h-auto w-auto mb-7 overflow-hidden">
                <div className=" h-auto w-auto">
                  <div className="h-auto w-auto">
                    <div className="flex flex-col items-center justify-center rounded-xls ">
                      <div className="flex flex-row space-x-3">
                        <img
                          src="https://img.freepik.com/free-vector/characters-people-holding-multimedia-icons-illustration_53876-32615.jpg?w=740&t=st=1679943113~exp=1679943713~hmac=5859770380f9cd1f482e7c3172c092e51e6e59661747780c1af36d3946a1de95"
                          alt=" random imgee"
                          className="w-[150px] h-[150px]  object-cover object-center rounded-lg shadow-md"
                        />
                        <img
                          src="https://www.ceo-review.com/wp-content/webp-express/webp-images/uploads/2021/02/Media-production.jpg.webp"
                          alt=" random imgee"
                          className="w-[150px] h-[150px] object-cover object-center rounded-lg shadow-md"
                        />
                        <img
                          src="https://thumbs.dreamstime.com/b/mobile-entertainment-vector-illustration-music-application-dancing-people-online-radio-audio-playlist-concept-apps-services-182366620.jpg"
                          alt=" random imgee"
                          className="w-[150px] h-[150px]  object-cover object-center rounded-lg shadow-md"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item mx-5 px-5 h-auto w-auto bg-gray-50 rounded-xl shadow-md mb-10 py-5">
                <div className="Additional_info w-full text-center text-xl text-sky-500 font-medium">
                  What Interest You ?
                </div>
                <div className="Additional_info mb-9 w-full text-center text-sm font-thin text-sky-500">
                  Here is the list
                </div>
                <div className="mb-4 flex w-full flex-row items-center justify-center rounded-xl border border-gray-200 bg-gray-50 p-5 text-left font-medium text-sky-500 focus:ring-4 focus:ring-sky-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-sky-800">
                  <form className="w-full">
                    <div className="flex w-full items-center  justify-between ">
                      <div className="relative">
                        <input
                          type="search"
                          onChange={(e) => handleSearch(e.target.value)}
                          id="password"
                          className="w-full rounded-xl border-2 border-gray-200 py-2 pl-3 pr-10 text-gray-600 transition-colors hover:border-gray-300 focus:border-sky-500 focus:outline-none"
                          placeholder="Search..."
                          value={search}
                        />
                        <button className="leading-0 absolute top-2 right-2 block h-7 items-center justify-center rounded-xl bg-sky-600 px-3 text-center text-xl text-white transition-colors hover:text-gray-300 focus:outline-none">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="h-6 w-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                            ></path>
                          </svg>
                        </button>
                      </div>
                      <div class="flex flex-row space-x-2">
                        <ul className="inline-flex items-center -space-x-px">
                          <li>
                            <div
                              onClick={previousPage}
                              disabled={page === 1}
                              className="ml-0 block rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            >
                              <span className="sr-only">Previous</span>
                              <svg
                                aria-hidden="true"
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                  clip-rule="evenodd"
                                ></path>
                              </svg>
                            </div>
                          </li>
                          <li>
                            <div
                              onClick={nextPage}
                              disabled={page === sups?.totalPages}
                              className="block rounded-r-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            >
                              <span className="sr-only">Next</span>
                              <svg
                                aria-hidden="true"
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                  clip-rule="evenodd"
                                ></path>
                              </svg>
                            </div>
                          </li>
                        </ul>
                        <span class="block rounded-lg border border-gray-300 bg-gray-100 px-3 py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ">
                          <span>
                            <span class="ts">{page}</span>–
                            <span class="ts">{sups?.totalPages}</span>
                          </span>{" "}
                          sur <span class="ts">{sups?.totalUsers}</span>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
                {/* <div className="grid grid-cols-3 gap-3">
                  <button
                    onClick={(e) => {
                      FetchEl(e);
                    }}
                    className="rounded-xl bg-gray-200 border-2 px-3 py-4 text-center text-sm text-gray-700 hover:scale-105 hover:duration-400 hover:ease-in-out"
                  >
                    Music Video/Audio
                  </button>
                  <button
                    onClick={(e) => {
                      FetchEl(e);
                    }}
                    className="rounded-xl bg-gray-200 border-2  px-3 py-4 text-center text-sm text-gray-700 hover:scale-105 hover:duration-400 hover:ease-in-out"
                  >
                    TV/Radio Show
                  </button>
                  <button
                    onClick={(e) => {
                      FetchEl(e);
                    }}
                    className="rounded-xl bg-gray-200 border-2  px-3 py-4 text-center text-sm text-gray-700 hover:scale-105 hover:duration-400 hover:ease-in-out"
                  >
                    Movie/Film
                  </button>
                  <button
                    onClick={(e) => {
                      FetchEl(e);
                    }}
                    className="rounded-xl bg-gray-200 border-2  px-3 py-4 text-center text-sm text-gray-700 hover:scale-105 hover:duration-400 hover:ease-in-out"
                  >
                    Sports News/Video
                  </button>
                  <button
                    onClick={(e) => {
                      FetchEl(e);
                    }}
                    className="rounded-xl bg-gray-200 border-2  px-3 py-4 text-center text-sm text-gray-700 hover:scale-105 hover:duration-400 hover:ease-in-out"
                  >
                    Dance
                  </button>
                  <button
                    onClick={(e) => {
                      FetchEl(e);
                    }}
                    className="rounded-xl bg-gray-200 border-2  px-3 py-4 text-center text-sm text-gray-700 hover:scale-105 hover:duration-400 hover:ease-in-out"
                  >
                    Interviews
                  </button>
                  <button
                    onClick={(e) => {
                      FetchEl(e);
                    }}
                    className="rounded-xl bg-gray-200 border-2  px-3 py-4 text-center text-sm text-gray-700 hover:scale-105 hover:duration-400 hover:ease-in-out"
                  >
                    General News
                  </button>
                  <button
                    onClick={(e) => {
                      FetchEl(e);
                    }}
                    className="rounded-xl bg-gray-200 border-2   py-4 text-center text-sm text-gray-700 hover:scale-105 hover:duration-400 hover:ease-in-out"
                  >
                    Special Events ..Shows,Concerts,.
                  </button>
                  <button
                    onClick={(e) => {
                      FetchEl(e);
                    }}
                    className="rounded-xl bg-gray-200  border-2  px-3 py-4 text-center text-sm text-gray-700 hover:scale-105 hover:duration-400 hover:ease-in-out"
                  >
                    Communique
                  </button>
                  <button
                    onClick={(e) => {
                      FetchEl(e);
                    }}
                    className="rounded-xl bg-gray-200 border-2  px-3 py-4 text-center text-sm text-gray-700 hover:scale-105 hover:duration-400 hover:ease-in-out"
                  >
                    Art & Design
                  </button>
                  <button
                    onClick={(e) => {
                      FetchEl(e);
                    }}
                    className="rounded-xl bg-gray-200 border-2  px-3 py-4 text-center text-sm text-gray-700 hover:scale-105 hover:duration-400 hover:ease-in-out"
                  >
                    DJ & Remixes
                  </button>
                  <button
                    onClick={(e) => {
                      FetchEl(e);
                    }}
                    className="rounded-xl bg-gray-200 border-2  px-3 py-4 text-center text-sm text-gray-700 hover:scale-105 hover:duration-400 hover:ease-in-out"
                  >
                    Production/Producer
                  </button>
                  <button
                    onClick={(e) => {
                      FetchEl(e);
                    }}
                    className="rounded-xl bg-gray-200 border-2  px-3 py-4 text-center text-sm text-gray-700 hover:scale-105 hover:duration-400 hover:ease-in-out"
                  >
                    Photograph
                  </button>
                  <button
                    onClick={(e) => {
                      FetchEl(e);
                    }}
                    className="rounded-xl bg-gray-200 border-2  px-3 py-4 text-center text-sm text-gray-700 hover:scale-105 hover:duration-400 hover:ease-in-out"
                  >
                    Photo
                  </button>
                  <button
                    onClick={(e) => {
                      FetchEl(e);
                    }}
                    className="rounded-xl bg-gray-200 border-2  px-3 py-4 text-center text-sm text-gray-700 hover:scale-105 hover:duration-400 hover:ease-in-out"
                  >
                    Commerce/Merchandise
                  </button>
                </div> */}
                <div className="grid grid-cols-3 gap-3 ">
                  {sups?.sups?.map((interest) => {
                    return (
                      <>
                        <button
                          onClick={(e) => {
                            FetchElAdd(e);
                          }}
                          id={interest.id}
                          className="rounded-xl bg-gray-200 border-2 px-3 py-4 text-center text-sm text-gray-700 hover:scale-105 hover:duration-400 hover:ease-in-out"
                        >
                          {interest.title}
                        </button>
                      </>
                    );
                  })}
                </div>

                <div className="mt-5 flex w-full items-center justify-center">
                  <button
                    type="button"
                    className="mr-2 mb-2 w-16 rounded-lg border border-gray-200  py-2.5 px-5 text-sm flex items-center justify-center font-medium  bg-gray-100 text-sky-600 hover:text-sky-800 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </button>
                </div>

                {Space.length > 0 ? (
                  <div className="mt-5 flex w-full items-center justify-center">
                    <div className=" text-md font-thin text-sky-500 bg-sky-50 border border-sky-200 p-2 rounded-xl">
                      <div className="Additional_info mb-3 w-full text-center text-xl text-sky-500 font-medium">
                        I'm Interested With
                      </div>
                      <div className="grid grid-cols-4 gap-2 w-full ">
                        {Space.map((item) => {
                          return (
                            <button
                              onClick={(e) => {
                                FetchElRemove(e);
                              }}
                              type="button"
                              class="relative flex items-center justify-center  border-sky-500  p-3 text-sm font-medium text-center text-white border-2  bg-gray-200 rounded-lg"
                            >
                              <span id="text" class="text-gray-700 ">
                                {item.title}
                              </span>

                              <div
                                onClick={(e) => {
                                  const textContent =
                                    e.target.parentNode.querySelector(
                                      "#text"
                                    ).textContent;
                                  console.log(textContent);
                                  FetchElRemove(textContent);
                                }}
                                className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900"
                              >
                                x
                              </div>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : null}
                {Error ? (
                  <label
                    htmlFor="username"
                    className="text-center  ml-3 block font-medium text-red-500 dark:text-white"
                  >
                    {Error}
                  </label>
                ) : null}

                <div className="mt-5 flex w-full items-center justify-center">
                  <button
                    onClick={() => {
                      if (Space.length <= 1) {
                        SetError("Please Select At Least 2 Interests ");
                        setTimeout(() => {
                          SetError(null);
                        }, 3000);
                      } else {
                        Save();
                      }
                    }}
                    class="w-48  rounded-xl border-0 bg-sky-500 py-2.5 px-5 text-lg text-white font-medium hover:bg-sky-600 focus:outline-none"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default HeroIndestries;
