import React, { useContext } from "react";
import { PostContext } from "../../context";
import { SugesContext } from "../../context";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
// Import Swiper styles
import "swiper/css";

function Other_Images() {
  const suges = useContext(SugesContext);
  const swiper = useSwiper();
  return (
    <>
      <section className="bg-white h-48 dark:bg-gray-900">
        <div className="mx-auto overflow-hidden max-w-screen-xl h-48 pb-4 lg:pb-4">
          <div className="navigation_items w-[580px] absolute mt-12   z-0 opacity-50 hover:opacity-100 hover:duration-200  flex items-center justify-between ">
            <button
              onClick={() => swiper.slidePrev()}
              className="swiper-prev bg-gray-100 rounded-lg h-14 p-1 flex items-center justify-center swiper-button-disabled"
              tabIndex="-1"
              role="button"
              aria-label="Previous slide"
              aria-controls="swiper-wrapper-66d7ef5548866805"
              aria-disabled="true"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-gray-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                ></path>
              </svg>
            </button>
            <button
              onClick={() => swiper.slideNext()}
              className="swiper-next bg-gray-100 rounded-lg h-14 p-1 flex items-center justify-center"
              tabIndex="0"
              role="button"
              aria-label="Next slide"
              aria-controls="swiper-wrapper-66d7ef5548866805"
              aria-disabled="false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-gray-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                ></path>
              </svg>
            </button>
          </div>
          <div className=" z-20 mx-10 overflow-hidden  w-[500px] h-48">
            <Swiper
              spaceBetween={13}
              slidesPerView={3}
              className="tab-btns w-auto h-auto "
              id="swiper-wrapper-66d7ef5548866805"
              aria-live="polite"
            >
              <div className="swiper-wrapper">
                {suges.suges?.map((sug) => {
                  return (
                    <>
                      <SwiperSlide key={sug.id}>
                        <div className="">
                          <img
                            alt="gallery"
                            className="block h-36 w-full border border-sky-100 rounded-lg 2xl:rounded-2xl object-cover object-center"
                            src="https://www.tailwindtap.com/assets/components/gallery/image1.jpg"
                          />
                        </div>
                      </SwiperSlide>
                    </>
                  );
                })}
              </div>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}
export default Other_Images;
