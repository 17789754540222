import React from "react";
import "./Success.css";
import { Link } from "react-router-dom";
function Success_Info({ formData, setFormData, opensignupPopup }) {
  return (
    <>
      <div className="Success flex h-[400px]  flex-col items-center justify-center">
        <div className="to-sky-20 flex h-auto w-full flex-col items-center rounded-3xl border border-sky-200 bg-gradient-to-r from-sky-50 py-6 md:w-[400px]">
          <div class="success-animation mb-5">
            <svg
              class="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                class="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                class="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
          </div>

          <h1 className="mb-4 text-xl font-extrabold text-gray-900 dark:text-sky-500">
            <span className="bg-gradient-to-r from-sky-400 to-emerald-600 dark:bg-gradient-to-r dark:from-sky-400 dark:to-emerald-600   bg-clip-text dark:bg-clip-text dark:text-sky-500  text-transparent">
              Post Added !!
            </span>
          </h1>

          <p className="inline-block px-5 pt-2 text-md font-light text-gray-500 dark:text-gray-400">
            Dear User Activate Your Post By Paying The Amount
          </p>
          <p className="inline-block text-center px-5 pt-2 text-md font-light text-gray-500 dark:text-gray-400">
            Your Post Will Be Published after
            <br /> Payment Confirmation
          </p>
          <p className="text-sm font-light text-gray-500 dark:text-gray-400">
            {"Go To Profile->My Order->Click To Order"}
          </p>
        </div>
        <div className="mb-5 mt-5 flex w-full items-center justify-center">
          <button className="w-1/2 rounded-xl border-0 bg-sky-500 px-6 py-2 text-lg text-white hover:bg-sky-600 focus:outline-none">
            <Link to="/trend">Return Home</Link>
          </button>
        </div>
      </div>
    </>
  );
}

export default Success_Info;
