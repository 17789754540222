import React from 'react'

function StepWork(props) {

  return (
    <>
    <div className="transform  duration-300 hover:-translate-y-2">
      <a href="#">
        <iframe className="h-60 w-full rounded-t-lg"src="https://www.youtube.com/embed/jytmYCC5OtE"title="YouTube video player"frameBorder="0"allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowFullScreen></iframe>
      </a>
      <div className="rounded border border-sky-200 bg-white p-2">
        <div className="mb-2 flex items-center justify-between">
          <p className="text-lg font-semibold leading-5 text-sky-500">{props.title} </p>
          <p className="text-deep-purple-accent-400 flex h-6 w-6 items-center justify-center rounded bg-sky-500 font-bold text-white">{props.id} </p>
        </div>
        <p className="text-sm font-light text-gray-600">{props.info} </p>
      </div>
    </div>
    </>
  )
}
export default StepWork