import React from "react";

import LocalTvComponent from "../components/Buy/LocalTvComponent";
function LocalTvBuy() {
  return (
    <>
      <LocalTvComponent />
    </>
  );
}
export default LocalTvBuy;
