import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PricingContext } from "../../context";
import { Link } from "react-router-dom";
import { NotifyContext } from "../../context";
import { GlobalFunctionContext } from "../../context";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { AdsContext } from "../../context";
import Top from "./Top";
SwiperCore.use([Autoplay, Pagination]);

function WhoRightUserAside(props) {
  const posts = useContext(AdsContext);
  const swiper = useSwiper();
  const value = useContext(PricingContext);
  let i = 0;
  const navigate = useNavigate();
  let pathname = props.pathname;
  let isHome = false;
  let isMessage = false;
  let isWho = false;
  if (pathname == "/") isHome = true;
  if (pathname == "/messages") isMessage = true;
  if (pathname == "/who") isWho = false;
  const notifs = useContext(NotifyContext);
  let notreaded = notifs.notreaded;
  const Global = useContext(GlobalFunctionContext);
  return (
    <>
      {isHome || isMessage ? null : isWho ? null : (
        <aside
          id="separator-sidebar"
          className="z-60 fixed right-0 top-20 rounded-l-3xl border-b border-l py-4 border-sky-500 bg-gray-50 overflow-hidden h-auto w-auto -translate-x-full transition-transform sm:translate-x-0"
          aria-label="Sidebar"
        >
          <div className="flex h-screen flex-col space-y-4 overflow-y-scroll hide-scroll px-3  dark:bg-gray-800">
            <div className="flex flex-row items-center">
              <div className="flex flex-row items-center">
                <button className="flex h-7 w-7 items-center justify-center rounded-full bg-gray-200 text-gray-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-56 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                    />
                  </svg>
                </button>
                <div className="ml-1 text-sm font-semibold text-gray-700">
                  Sponsored Ads
                </div>
              </div>

              <hr className="mx-auto my-2 h-px w-36 rounded border-0 bg-gray-500 dark:bg-gray-700 md:my-10" />
            </div>

            <div className="SideBar_item flex h-[380px] w-80 flex-shrink-0 flex-row rounded-3xl border bg-gray-100 p-4">
              <Swiper
                direction={"vertical"}
                pagination={{
                  clickable: true,
                }}
                autoplay={{ delay: 4000, disableOnInteraction: false }}
                loop={true}
                modules={[Pagination]}
                className="mySwiper"
              >
                {" "}
                {posts.posts
                  ?.slice(0, Math.ceil(posts.posts?.length / 2))
                  .map((post) => {
                    return (
                      <SwiperSlide>
                        <Top key={post.id} post={post} />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>

            <div className="SideBar_item flex h-[380px] w-80 flex-shrink-0 flex-row rounded-3xl border bg-gray-100 p-4">
              <Swiper
                direction={"vertical"}
                pagination={{
                  clickable: true,
                }}
                autoplay={{ delay: 4000, disableOnInteraction: false }}
                loop={true}
                modules={[Pagination]}
                className="mySwiper"
              >
                {" "}
                {posts.posts
                  ?.slice(Math.ceil(posts.posts?.length / 2))
                  .map((post) => {
                    return (
                      <SwiperSlide>
                        <Top key={post.id} post={post} />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>

          <div className=" z-10  overflow-hidden w-80 ">
            {/* <Swiper
              direction={"vertical"}
              pagination={{
                clickable: true,
              }}
              spaceBetween={13}
              slidesPerView={3}
              autoplay={{ delay: 2000, disableOnInteraction: false }}
              loop={true}
              className="tab-btns w-80 h-auto "
              id="swiper-wrapper-66d7ef5548866805"
              aria-live="polite"
            >
              <div className="swiper-wrapper">
                {value.Pricing?.map((Pricing) => {
                  return (
                    <>
                      <SwiperSlide
                        data-tab-btn={Pricing.id}
                        key={Pricing.id}
                        className="swiper-slide tab  w-80 flex flex-row items-center rounded-xl py-1 pr-1 h-auto swiper-slide-active"
                        role="group"
                        aria-label="1 / 21"
                        style={{
                          width: 228.667 + "px",
                          marginRight: 15 + "px",
                        }}
                      >
                        <div className="mb-4 w-full">
                          <div className="rounded-lg border border-gray-200 bg-white active-btn px-4 py-5 text-left shadow dark:border-gray-700 dark:bg-gray-800">
                            <div className="flex flex-row rounded-xl bg-gray-200 px-2 py-2">
                              <button className="rounded-full border-2 border-gray-200 p-1">
                                <img
                                  className="h-12 w-12 rounded-full"
                                  src={Pricing.Logo}
                                  alt="check-mark"
                                />
                              </button>
                              <div className="flex items-center justify-center">
                                <h2 className="text-2xl font-semibold text-gray-500 ml-2">
                                  {Pricing.Platform}
                                </h2>
                              </div>
                            </div>
                            <div className="inline-flex mt-3 items-center justify-center w-full">
                              <hr className="w-64 h-px my-2 bg-gray-200 border-0 dark:bg-gray-700" />
                              <span className="absolute px-3 font-normal text-sm text-gray-600 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">
                                Sharing
                              </span>
                            </div>
                            <ul className="mb-6 flex flex-col pt-2">
                              <li className="mb-2.5 flex items-center">
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  className="mr-4 dark:hidden"
                                  alt="check-mark"
                                />
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  className="mr-4 hidden dark:block"
                                  alt="check-mark"
                                />
                                <p className="text-base w-full font-normal flex  flex-row items-center justify-between  text-gray-800 dark:text-white">
                                  <span>100 Status</span>{" "}
                                  <span className="font-semibold mr-4 leading-6 text-base text-gray-600">
                                    {Pricing.status}
                                    <sub className="ml-1">fbu</sub>
                                  </span>
                                </p>
                              </li>
                              <li className="mb-2.5 flex items-center">
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  className="mr-4 dark:hidden"
                                  alt="check-mark"
                                />
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  className="mr-4 hidden dark:block"
                                  alt="check-mark"
                                />
                                <p className="text-base w-full font-normal flex  flex-row items-center justify-between  text-gray-800 dark:text-white">
                                  <span>100 Posts</span>{" "}
                                  <span className="font-semibold mr-4 leading-6 text-base text-gray-600">
                                    {Pricing.Posts}
                                    <sub className="ml-1">fbu</sub>
                                  </span>
                                </p>
                              </li>
                              <li className="mb-2.5 flex items-center">
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  className="mr-4 dark:hidden"
                                  alt="check-mark"
                                />
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  className="mr-4 hidden dark:block"
                                  alt="check-mark"
                                />
                                <p className="text-base w-full font-normal flex  flex-row items-center justify-between  text-gray-800 dark:text-white">
                                  <span>100 Pages</span>{" "}
                                  <span className="font-semibold mr-4 leading-6 text-base text-gray-600">
                                    {Pricing.pages}
                                    <sub className="ml-1">fbu</sub>
                                  </span>
                                </p>
                              </li>
                              <li className="mb-2.5 flex items-center">
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  className="mr-4 dark:hidden"
                                  alt="check-mark"
                                />
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  className="mr-4 hidden dark:block"
                                  alt="check-mark"
                                />
                                <p className="text-base w-full font-normal flex  flex-row items-center justify-between  text-gray-800 dark:text-white">
                                  <span>100 Groups</span>{" "}
                                  <span className="font-semibold mr-4 leading-6 text-base text-gray-600">
                                    {Pricing.Groups}
                                    <sub className="ml-1">fbu</sub>
                                  </span>
                                </p>
                              </li>
                              <li className="mb-2.5 flex items-center">
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  className="mr-4 dark:hidden"
                                  alt="check-mark"
                                />
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  className="mr-4 hidden dark:block"
                                  alt="check-mark"
                                />
                                <p className="text-base w-full font-normal flex  flex-row items-center justify-between  text-gray-800 dark:text-white">
                                  <span>200 Direct Friends</span>{" "}
                                  <span className="font-semibold mr-4 leading-6 text-base text-gray-600">
                                    {Pricing.DirectFriends}
                                    <sub className="ml-1">fbu</sub>
                                  </span>
                                </p>
                              </li>
                              <li className="mb-2.5 flex items-center">
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  className="mr-4 dark:hidden"
                                  alt="check-mark"
                                />
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  className="mr-4 hidden dark:block"
                                  alt="check-mark"
                                />
                                <p className="text-base w-full font-normal flex  flex-row items-center justify-between  text-gray-800 dark:text-white">
                                  <span>100 Events</span>{" "}
                                  <span className="font-semibold mr-4 leading-6 text-base text-gray-600">
                                    {Pricing.Events}
                                    <sub className="ml-1">fbu</sub>
                                  </span>
                                </p>
                              </li>
                              <li className="mb-2.5 flex items-center">
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  className="mr-4 dark:hidden"
                                  alt="check-mark"
                                />
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  className="mr-4 hidden dark:block"
                                  alt="check-mark"
                                />
                                <p className="text-base w-full font-normal flex  flex-row items-center justify-between  text-gray-800 dark:text-white">
                                  <span>100 Lives Videos</span>{" "}
                                  <span className="font-semibold mr-4 leading-6 text-base text-gray-600">
                                    {Pricing.Lives_Videos}
                                    <sub className="ml-1">fbu</sub>
                                  </span>
                                </p>
                              </li>
                            </ul>
                            <p className="relative pl-3 text-base text-sky-700">
                              <span className="text-2xl font-semibold">
                                Cash
                              </span>
                              <sub className="text-lg font-light">fbu</sub>
                              <span className="text-lg font-light">/100</span>
                            </p>
                            <button
                              onClick={() => {
                                value.handleDetail(Pricing.Platform);
                                navigate(`pricing/${Pricing.Platform}`);
                              }}
                              className="mt-5 w-full rounded bg-gray-200 px-8 py-3 text-base font-semibold text-sky-700 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-sky-700 focus:ring-offset-2"
                            >
                              Subscribe
                            </button>
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  );
                })}
              </div>
            </Swiper> */}
          </div>
        </aside>
      )}
    </>
  );
}
export default WhoRightUserAside;
