export const reviewsData = [
    {
      id: 1,
      name: "KENZIE EDGAR",
      photo: "https://i.pravatar.cc/100?img=1",
      info: '"Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos sunt ratione dolor exercitationem minima quas itaque saepe quasi architecto vel! Accusantium, vero sint recusandae cum tempora nemo commodi soluta deleniti."'
    },
    {
        id: 2,
        name: "TOMMIE EWART.",
        photo: "https://i.pravatar.cc/100?img=2",
        info: '"Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos sunt ratione dolor exercitationem minima quas itaque saepe quasi architecto vel! Accusantium, vero sint recusandae cum tempora nemo commodi soluta deleniti."'
    },
    {
        id: 3,
        name: "NEVADA HERBERTSON.",
        photo: "101.jpg",
        info: '"Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos sunt ratione dolor exercitationem minima quas itaque saepe quasi architecto vel! Accusantium, vero sint recusandae cum tempora nemo commodi soluta deleniti."'
    }]
      ;
export const DetailStep = {
    id: 1,
    name: "KENZIE EDGAR",
    photo: "https://i.pravatar.cc/100?img=6",
    info: '"Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos sunt ratione dolor exercitationem minima quas itaque saepe quasi architecto vel! Accusantium, vero sint recusandae cum tempora nemo commodi soluta deleniti."'
};