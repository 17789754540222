import React from 'react'
import HeroIndestries from './HeroIndestries'

export default function IndestrieBox() {
  return (
    <>
                <HeroIndestries />
    </>
  )
}
