import React, {useContext} from 'react'
import Helper from './Helper'
import {HelpContext} from '../../context'
import {PostContext} from '../../context'
import { Link } from 'react-router-dom'

function Pub(props) {
    const post = props.post
    const help = useContext(HelpContext)
    const pub = useContext(PostContext)
  return (
    <>
    <div className="items min-h-48 flex items-center justify-center" >
      <custom-card3>
        <div className="bg-gray-100 text-gray-900 rounded-lg shadow-md ">
          <div className="flex items-center justify-between p-3" >
            <div className="flex items-center space-x-2" >
              <img src={post.photoUser} alt="" className="bg-coolGray-500 border-coolGray-700 h-8 w-8 rounded-full object-cover object-center shadow-sm"  />
              <div className="-space-y-1" >
                <h2 className="text-sm font-semibold leading-none" >{post.username}</h2>
                <span className="text-coolGray-400 inline-block text-xs leading-none" >{post.Categorie}</span>
              </div>
            </div>
            <button title="Open options" type="button">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="h-5 w-5 fill-current" >
                <path d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z"></path>
                <path d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z"></path>
                <path d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z"></path>
              </svg>
            </button>
          </div>

          <div className="flex w-full items-center justify-between px-7 text-sm">
            <p className="px-3 text-base text-gray-700">{post.title}</p>
            <div className="w-auto rounded-t-lg bg-sky-500 px-3 text-white"> {post.after} ago</div>
          </div>
          <div className="" onClick={()=>pub.handleDetail(post.id)}>
          <Link to="/pub">
            <img src={post.photoPost}  />
          </Link>
          </div>
          <div className="p-3" >
            <div className="flex items-center justify-between" >
              <div className="flex items-center space-x-3">
                <button type="button" title="Like post" className="flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="h-5 w-5 fill-current">
                    <path d="M453.122,79.012a128,128,0,0,0-181.087.068l-15.511,15.7L241.142,79.114l-.1-.1a128,128,0,0,0-181.02,0l-6.91,6.91a128,128,0,0,0,0,181.019L235.485,449.314l20.595,21.578.491-.492.533.533L276.4,450.574,460.032,266.94a128.147,128.147,0,0,0,0-181.019ZM437.4,244.313,256.571,425.146,75.738,244.313a96,96,0,0,1,0-135.764l6.911-6.91a96,96,0,0,1,135.713-.051l38.093,38.787,38.274-38.736a96,96,0,0,1,135.765,0l6.91,6.909A96.11,96.11,0,0,1,437.4,244.313Z"></path>
                  </svg>
                  <span className="px-2">{post.liked}K</span>
                </button>
                <button type="button" title="Add a comment" className="flex items-center justify-center">
                  
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="h-5 w-5 fill-current" viewBox="0 0 16 16">
                    <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"/>
                  </svg>
                    <span className="px-2">{post.listen}K</span>
                </button>
               
              </div>
              <button type="button" title="Bookmark post" className="flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="h-5 w-5 fill-current" viewBox="0 0 16 16">
                <path d="M3.75 0a1 1 0 0 0-.8.4L.1 4.2a.5.5 0 0 0-.1.3V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4.5a.5.5 0 0 0-.1-.3L13.05.4a1 1 0 0 0-.8-.4h-8.5ZM8.5 4h6l.5.667V5H1v-.333L1.5 4h6V1h1v3ZM8 7.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z"/>
              </svg>
              </button>
            </div>
            <div className="flex flex-wrap items-center pt-3 pb-1" >
              <div className="flex items-center space-x-2">
                <div className="flex -space-x-1">
                {help.helps?.map(help =>{
                      return <Helper key={help.id} help={help} />
                } )}
                </div>
                <span className="text-sm">
                  Helped by <span className="font-semibold"> {post.helper} </span> and
                  <span className="font-semibold"> {post.helperNumb} others</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </custom-card3>
    </div>
    </>
  )
}
export default Pub